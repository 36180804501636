import React, { Component } from 'react'
import { connect } from 'react-redux'
import HomeProfiled from './HomeProfiled';
import { setAppClass } from '../../store/actions/globalStylesActions';
import { fetchAction } from '../../store/actions/fetchActions';
import { Redirect } from 'react-router-dom'

class Home extends Component {
    state = {
        player_type: null,
        player_title: null,
        player_url: null,
    }
    // recomendedInterval;
    // channelsFull;

    componentDidMount(){
// console.log('home mount', this.props);
        const backgroundEl = document.getElementById("body-background")
        backgroundEl.classList.remove("cover")
        this.props.setAppClass('home')
    }

    // componentWillUnmount() {
    //     clearInterval(this.recomendedInterval);
    // }

    render() {
// console.log('home render', this.props)
        const { auth } = this.props
        const { loading } = this.props.api_data
        let { error } = this.props.api_data
            
        // if (!auth.token || error) {
        if (error) {
            console.log(error)
            return <Redirect to="/" />
        }

        // const items = payload && payload.recommended ? payload.recommended.items : []

        const homeContent = (auth && auth.profile) ?
        (
            <div className="homepage">
                <HomeProfiled appElRef={this.props.appElRef} />
            </div>
        ) : (
            <div className="homepage">
                {loading}
            </div>
        )

        return (
            <>
                { homeContent }
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        auth: state.auth,
        // global_styles: state.global_styles,
        api_data: state.api_data,
        player: state.player,
        cookies: ownProps.cookies,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey) => { dispatch(fetchAction(token, endpoint, source, subkey)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)