import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import RegisterProgressBar from 'components/elements/RegisterProgressBar';
import style from './style.module.sass';

const RegisterTerms = ({
    item,
    content,
    handleStepBtn,
    step,
    handleTermsCheck,
    termsAccepted,
    loading,
}) => {
    if (!content) return null;

    return (
        <div className={style.termsContainer}>
            <div className={style.progressBarWrapper}>
                <RegisterProgressBar step={step} paddingBottom={'16px'} />
            </div>
            <h1>
                <FormattedMessage id="register_terms" />
            </h1>
            <p>
                <FormattedMessage id="register_terms_sub" />
            </p>
            <div className={style.termsContent}>
                <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
                <div className={style.termsCheckboxWrapper}>
                    <input
                        className="form-check-input"
                        name="terms_accepted"
                        type="checkbox"
                        id="termsCheck"
                        onChange={handleTermsCheck}
                    />
                    <label
                        className="form-check-label d-flex align-items-center"
                        htmlFor="termsCheck"
                    >
                        <FormattedMessage id="sign_up_terms_consent" />
                    </label>
                </div>
            </div>
            <button
                type="button"
                onClick={handleStepBtn}
                data-product-id={item.id}
                data-step="3"
                className={classnames(
                    style.acceptButton,
                    'button',
                    'btn-primary-bright',
                    'btn-loading',
                    {
                        loading: loading,
                    }
                )}
                disabled={!termsAccepted}
            >
                {loading && <span className="spinner" />}
                <FormattedMessage id="profile_button_confirm" />
            </button>
        </div>
    );
};

export default RegisterTerms;
