import { SET_SEARCH_VALUE } from '../actions/searchActions'

const initState = {
  value: '',
  sugestion_clicked: false,
}

const reducer = (state = initState, action) => {
    // console.log('searchReducer action', action)
    switch (action.type) {
        case SET_SEARCH_VALUE:
            return {
                ...state,
                value: action.payload,
            }
        default: return state
    }
}

export default reducer