import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { getLangProp } from 'components/addons/ProductsHelper';
import CheckedIcon from 'img/checked-icon.svg';
import style from './style.module.sass';

const SingleProduct = ({
    lang,
    langDefault,
    product,
    productProperties,
    onSelect,
    mobile,
    withProperties,
}) => {
    if (!product) return null;

    const selectButton = (
        <button
            type="button"
            onClick={onSelect}
            data-product-id={product.id}
            data-step="1"
            className={classnames('button', 'btn-lg', {
                'btn-outline-secondary': !product.is_promoted || !mobile,
                'btn-primary': mobile && product.is_promoted,
            })}
        >
            <FormattedMessage id="package_button_order" />
        </button>
    );

    return (
        <div
            className={classnames(style.compareGridOption, {
                [style.promoted]: product.is_promoted,
            })}
        >
            {product.is_promoted && (
                <div className={style.compareGridPromoted}>
                    <FormattedMessage id="sign_up_promoted" />
                </div>
            )}
            <div
                className={classnames(style.compareGridProductHead, {
                    [style.promoted]: product.is_promoted,
                })}
            >
                <img
                    src={getLangProp(product, 'banner', lang, langDefault)}
                    alt="banner"
                />
                <p>{getLangProp(product, 'title', lang, langDefault)}</p>
                <span className={style.regularPrice}>
                    {getLangProp(product, 'price', lang, langDefault)}
                    /
                    <FormattedMessage id={'plan_monthly'} />
                </span>
                <span
                    className={classnames(style.discountedPrice, {
                        [style.hidden]: !product.discounted_price,
                    })}
                >
                    {getLangProp(
                        product,
                        'discounted_price',
                        lang,
                        langDefault
                    )}
                    /
                    <FormattedMessage id={'plan_monthly'} />
                </span>
                {mobile && selectButton}
            </div>
            {productProperties.map((property) => (
                <Fragment key={property.name}>
                    <span
                        className={classnames(style.compareGridProperty, {
                            [style.visible]: withProperties,
                        })}
                    >
                        {getLangProp(property, 'title', lang, langDefault)}
                    </span>
                    <span className={style.compareGridText}>
                        {product.properties.includes(property.id) && (
                            <img src={CheckedIcon} alt="YES" />
                        )}
                    </span>
                </Fragment>
            ))}
            {!mobile && (
                <div className={style.compareGridOrderButton}>
                    {selectButton}
                </div>
            )}
        </div>
    );
};

export default SingleProduct;
