import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PlayIcon from '../../img/play-icon.svg'
import ImdbIcon from '../../img/imdb-icon.svg'
import {ItemPlayLink} from "../addons/ItemPlayLink";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SmartItemWide = props => {
    const { i, index, item, section, template } = props
    const { id, trailers, duration } = item
    const { covers, title, imdb, released, age_rating, description, tva_contents, audio_tracks } = item.metadata
// console.log('wide item:', item)
    const cover = covers ? covers[0] : null
    // const poster = posters ? posters[0] : null
    const imdbRating = imdb ? imdb['rating'].toFixed(1) : null
    const ageRating = age_rating + '+'
    const instantLink = '/play?type=' + i + '&vod_id=' + id + '&section=' + section
    // let playLink = '/vod-about?vod_id=' + item.id + '&section=' + section + '&index=' + index
    // if (section === '5') {
    //     playLink = '/play?type=vod&vod_id=' + item.id + '&section=' + section
    // } else if (i === 'group') {
    //     playLink = '/group?id=' + item.id + '&section=' + section + '&index=' + index + '&template=' + template
    // }

    const playLink = ItemPlayLink({
        itemId: item.id,
        section: section,
        index: index,
        template: template,
        i: i
    })

    const instantButton = i !== 'group' ?
    (
        <NavLink className={"button btn-primary-bright btn-lg d-flex me-3"} to={instantLink}>
            <img src={PlayIcon} alt='' className={"me-2"} />
            <div className={"w-100 text-nowrap"}>
                <FormattedMessage id="vod_event_action_watch" />
            </div>
        </NavLink>
    ) : null

    const aboutButton = (
        <NavLink className={"button btn-secondary btn-lg d-flex me-3"} to={playLink}>
            <div className={"w-100 text-nowrap"}>
                <FormattedMessage id="vod_event_action_about" />
            </div>
        </NavLink>
    )

    const trailerLink = '/play?type=vod_trailer&vod_id=' + id + '&section=' + section
    const trailerButton = (
        <NavLink className={"button btn-secondary btn-lg d-flex"} to={trailerLink}>
            <div className={"w-100"}>
                <FormattedMessage id="vod_event_action_trailer" />
            </div>
        </NavLink>
    )

    return (
            <div className="smart-item-wide">
                <img className="cover" src={cover} alt="" />
                <div className="description">
                    <img className={"about-provider"} src={item.logo} alt="" />
                    <h1 style={{marginBottom: '14px'}}>{title}</h1>
                    <div className={"d-flex align-items-center mb-3 tags-box"}>
                        {imdb ?
                            <div className={"imdb-box"}>
                                <img src={ImdbIcon} alt="" />
                                {imdbRating}
                            </div> : null
                        }
                        {age_rating ? <div className={"age-box"}>{ageRating}</div> : null}
                        {released ? <div>{released}</div> : null}
                        {duration ? <div><span>|</span>{parseInt(duration/60) + "min."}</div> : null}
                        {tva_contents ? <div><span>|</span>{tva_contents.join(', ')}</div> : null}
                        {audio_tracks ? <div className={"audio-tracks-box"}><span>|</span>{audio_tracks.join(' \u2981 ')}</div> : null}
                    </div>

                    <p style={{display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden'}}>{description}</p>

                    <div className="d-flex">
                        {instantButton}
                        {aboutButton}
                        {trailers && trailers.length !== 0 ? trailerButton : null}
                    </div>
                </div>
            </div>
        )
}

export default SmartItemWide