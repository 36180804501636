import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import style from './style.module.sass';

const PostpaidThanks = () => {
    return (
        <div className={style.container}>
            <h1>
                <FormattedMessage id="sign_up_thank_you_header" />
            </h1>
            <p>
                <FormattedMessage id="sign_up_thank_you_sub_header" />
            </p>
            <Link
                to="/"
                className={classnames(
                    style.returnLink,
                    'button',
                    'btn-primary-bright',
                    'btn-lg'
                )}
            >
                <FormattedMessage id="sign_up_return_to_main" />
            </Link>
            <a
                href="https://www.cgates.lt/kontaktai"
                className={style.helpLink}
            >
                <FormattedMessage id="sign_up_help_link" />
            </a>
        </div>
    );
};

export default PostpaidThanks;
