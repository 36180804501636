import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl";
import { createProfile } from '../../store/actions/profilesActions'
import Circle1 from '../../img/circle-1-green.svg'
import Circle2 from '../../img/circle-2.svg'
import Circle3 from '../../img/circle-3.svg'
import ChannelLogo from '../../img/channel-logo-1.svg'
import Subscriptions2 from './Subscriptions2'
import SubscriptionsIcon from '../../img/subscriptions-icon.svg'


class Subscriptions1 extends Component {

    handleContinueClick = (e) => {
        e.preventDefault()
        this.props.setModalState('subscriptions-modal-2', <FormattedMessage id="settings_action_subscription" />, <Subscriptions2 />, 'Subscriptions1', <SubscriptionsIcon />);
    }

    render() {
        return (
          <div>

            <div className="indicators-row">

              <div>
                <img src={Circle1} alt="" />
                <p>Products</p>
              </div>

              <div>
                <img src={Circle2} alt="" />
                <p>Extras</p>
              </div>

              <div>
                <img src={Circle3} alt="" />
                <p>Confirmation</p>
              </div>

            </div>

            <div className="accordion" id="accordionExample">

              <div className="card">
                <div className="card-header" id="headingOne">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <p className="card-header-title">MINI Package</p>
                    <p className="card-header-content">Channels quantity info</p>
                  </button>
                </div>

                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div className="card-body scrolling-wrapper-flexbox">

                    <div>
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                    </div>

                    <div>
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                    </div>

                    <div>
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                    </div>

                    <div>
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                      <img src={ChannelLogo} alt="" />
                    </div>

                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingTwo">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <p className="card-header-title">MEDIUM Package</p>
                    <p className="card-header-content">Channels quantity info</p>
                  </button>
                </div>

                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div className="card-body">

                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingThree">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <p className="card-header-title">MAXI Package</p>
                    <p className="card-header-content">Channels quantity info</p>
                  </button>
                </div>

                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div className="card-body">

                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="headingFour">
                  <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    <p className="card-header-title">ULTRA Package</p>
                    <p className="card-header-content">Channels quantity info</p>
                  </button>
                </div>

                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                  <div className="card-body">
                    
                  </div>
                </div>
              </div>

            </div>
            <div className="btn-div">
              <div className="row btn-row">
                <div className="col pl-0 pr-3">
                  <button className="button btn-secondary">Cancel</button>
                </div>
                <div className="col pl-3 pr-0">
                  <button className="continue-button" onClick={this.handleContinueClick}>Continue</button>
                </div>
              </div>
            </div>

          </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
      return {
          createProfile: (profile) => dispatch(createProfile(profile)),
          setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) }        
      }
}

export default connect(null, mapDispatchToProps)(Subscriptions1)
