import {getCookie, handleCookie} from "./CookiesHandler";

/**
 *
 * @param cookieName
 * @param defaultList
 * @param defaultValue
 * @returns {*[]}
 */
export const getPriorityList = ({cookieName = '', defaultList = [], defaultValue = ''}) => {
  const priorityCookie = getCookie(cookieName)
// console.log('priorityCookie', priorityCookie)
  let priority = [defaultValue]
  if (priorityCookie === "") {
    priority = defaultList
  }
  let priorityOrdered = []

  if(priorityCookie !== "") {
    try {
      const priorityFromCookie = JSON.parse(priorityCookie)
      //-- jei random kukį, pertvarkom pagal jį gautus opšinus
      //-- imam tik tuos kukio masyvo elementus, kurie yra opšinuose
      for (const element of priorityFromCookie) {
        if(defaultList.includes(element)) {
          priorityOrdered.push(element)
        }
      }
      //-- pridedam kukyje nerastus, bet opšinuose esančius elementus
      for (const element of priorityOrdered) {
        if(!priorityFromCookie.includes(element)) {
          priorityOrdered.push(element)
        }
      }
// console.log('priority ordered', priorityOrdered)
    } catch(e) {
      console.log('priority cookie parse fail', e)
      handleCookie({operation: 'remove', name: this.priorityCookieName})
    }
  } else {
    priorityOrdered = priority
  }

  return priorityOrdered
}
