export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
// export const SET_SUGESTION_CLICKED = 'SET_SUGESTION_CLICKED'

export const setSearchValue = (value) => {
    return {
        type: SET_SEARCH_VALUE,
        payload: value
    }
}

// export const setSugestionClicked = (value) => {
//     return {
//         type: SET_SUGESTION_CLICKED,
//         payload: value
//     }
// }
