const Cities = [
    ['', ''],
    ['Akmenės m.', 'Akmenės m.'],
    ['Alytaus m.', 'Alytaus m.'],
    ['Anykščių m.', 'Anykščių m.'],
    ['Ariogalos m.', 'Ariogalos m.'],
    ['Baltosios Vokės m.', 'Baltosios Vokės m.'],
    ['Birštono m.', 'Birštono m.'],
    ['Biržų m.', 'Biržų m.'],
    ['Daugų m.', 'Daugų m.'],
    ['Druskininkų m.', 'Druskininkų m.'],
    ['Dusetų m.', 'Dusetų m.'],
    ['Dūkšto m.', 'Dūkšto m.'],
    ['Eišiškių m.', 'Eišiškių m.'],
    ['Elektrėnų m.', 'Elektrėnų m.'],
    ['Ežerėlio m.', 'Ežerėlio m.'],
    ['Gargždų m.', 'Gargždų m.'],
    ['Garliavos m.', 'Garliavos m.'],
    ['Gelgaudiškio m.', 'Gelgaudiškio m.'],
    ['Grigiškių m.', 'Grigiškių m.'],
    ['Ignalinos m.', 'Ignalinos m.'],
    ['Jiezno m.', 'Jiezno m.'],
    ['Jonavos m.', 'Jonavos m.'],
    ['Joniškėlio m.', 'Joniškėlio m.'],
    ['Joniškio m.', 'Joniškio m.'],
    ['Jurbarko m.', 'Jurbarko m.'],
    ['Kaišiadorių m.', 'Kaišiadorių m.'],
    ['Kalvarijos m.', 'Kalvarijos m.'],
    ['Kauno m.', 'Kauno m.'],
    ['Kavarsko m.', 'Kavarsko m.'],
    ['Kazlų Rūdos m.', 'Kazlų Rūdos m.'],
    ['Kelmės m.', 'Kelmės m.'],
    ['Kėdainių m.', 'Kėdainių m.'],
    ['Kybartų m.', 'Kybartų m.'],
    ['Klaipėdos m.', 'Klaipėdos m.'],
    ['Kretingos m.', 'Kretingos m.'],
    ['Kudirkos Naumiesčio m.', 'Kudirkos Naumiesčio m.'],
    ['Kupiškio m.', 'Kupiškio m.'],
    ['Kuršėnų m.', 'Kuršėnų m.'],
    ['Lazdijų m.', 'Lazdijų m.'],
    ['Lentvario m.', 'Lentvario m.'],
    ['Linkuvos m.', 'Linkuvos m.'],
    ['Marijampolės m.', 'Marijampolės m.'],
    ['Mažeikių m.', 'Mažeikių m.'],
    ['Molėtų m.', 'Molėtų m.'],
    ['Naujosios Akmenės m.', 'Naujosios Akmenės m.'],
    ['Nemenčinės m.', 'Nemenčinės m.'],
    ['Neringos m.', 'Neringos m.'],
    ['Obelių m.', 'Obelių m.'],
    ['Pabradės m.', 'Pabradės m.'],
    ['Pagėgių m.', 'Pagėgių m.'],
    ['Pakruojo m.', 'Pakruojo m.'],
    ['Palangos m.', 'Palangos m.'],
    ['Pandėlio m.', 'Pandėlio m.'],
    ['Panemunės m.', 'Panemunės m.'],
    ['Panevėžio m.', 'Panevėžio m.'],
    ['Pasvalio m.', 'Pasvalio m.'],
    ['Plungės m.', 'Plungės m.'],
    ['Priekulės m.', 'Priekulės m.'],
    ['Prienų m.', 'Prienų m.'],
    ['Radviliškio m.', 'Radviliškio m.'],
    ['Ramygalos m.', 'Ramygalos m.'],
    ['Raseinių m.', 'Raseinių m.'],
    ['Rietavo m.', 'Rietavo m.'],
    ['Rokiškio m.', 'Rokiškio m.'],
    ['Rūdiškių m.', 'Rūdiškių m.'],
    ['Salantų m.', 'Salantų m.'],
    ['Sedos m.', 'Sedos m.'],
    ['Simno m.', 'Simno m.'],
    ['Skaudvilės m.', 'Skaudvilės m.'],
    ['Skuodo m.', 'Skuodo m.'],
    ['Smalininkų m.', 'Smalininkų m.'],
    ['Subačiaus m.', 'Subačiaus m.'],
    ['Šakių m.', 'Šakių m.'],
    ['Šalčininkų m.', 'Šalčininkų m.'],
    ['Šeduvos m.', 'Šeduvos m.'],
    ['Šiaulių m.', 'Šiaulių m.'],
    ['Šilalės m.', 'Šilalės m.'],
    ['Šilutės m.', 'Šilutės m.'],
    ['Širvintų m.', 'Širvintų m.'],
    ['Švenčionėlių m.', 'Švenčionėlių m.'],
    ['Švenčionių m.', 'Švenčionių m.'],
    ['Tauragės m.', 'Tauragės m.'],
    ['Telšių m.', 'Telšių m.'],
    ['Tytuvėnų m.', 'Tytuvėnų m.'],
    ['Trakų m.', 'Trakų m.'],
    ['Troškūnų m.', 'Troškūnų m.'],
    ['Ukmergės m.', 'Ukmergės m.'],
    ['Utenos m.', 'Utenos m.'],
    ['Užvenčio m.', 'Užvenčio m.'],
    ['Vabalninko m.', 'Vabalninko m.'],
    ['Varėnos m.', 'Varėnos m.'],
    ['Varnių m.', 'Varnių m.'],
    ['Veisiejų m.', 'Veisiejų m.'],
    ['Ventos m.', 'Ventos m.'],
    ['Viekšnių m.', 'Viekšnių m.'],
    ['Vievio m.', 'Vievio m.'],
    ['Vilkaviškio m.', 'Vilkaviškio m.'],
    ['Vilkijos m.', 'Vilkijos m.'],
    ['Vilniaus m.', 'Vilniaus m.'],
    ['Virbalio m.', 'Virbalio m.'],
    ['Visagino m.', 'Visagino m.'],
    ['Zarasų m.', 'Zarasų m.'],
    ['Žagarės m.', 'Žagarės m.'],
    ['Žiežmarių m.', 'Žiežmarių m.'],
    ['Kita', 'Kita']
]

export default Cities