import React from 'react'
import TextField from './TextField'
import Logo from '../../img/logo.svg'
import {FormattedMessage} from "react-intl";

const EnterEmail = props => {
    const { handleSubmit, email, setEmail, emailEmpty, setEmailEmpty } = props

    // const emptyError = 'E-mail can not be empty'

    return (
        <form className="password-recovery-form" onSubmit={e => handleSubmit(e)}>
            <img className="cgates-logo" src={Logo} alt="cgates logo" />
            <h1>Password recovery</h1>
            <TextField
                field={email}
                setField={setEmail}
                fieldCondition={emailEmpty}
                setFieldCondition={setEmailEmpty}
                error={<FormattedMessage id="register_email_empty"/>}
                label={<FormattedMessage id="register_email"/>}
                type='email'
            />
            <input className="button" type="submit" value="CONFIRM" />
        </form>
    )
}

export default EnterEmail