import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import style from './style.module.sass';

const CompareDropDown = ({ defaultValue, options, onChange, secondary }) => {
    const dropDownRef = useRef(null);
    const [selection, setSelection] = useState(defaultValue || options[0]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const onClickOutside = (e) => {
            if (!dropDownRef.current) return null;
            if (!dropDownRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', onClickOutside);
        document.addEventListener('touchstart', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
            document.removeEventListener('touchstart', onClickOutside);
        };
    }, [dropDownRef, setIsOpen]);

    useEffect(() => {
        if (!options.some((item) => item.value === selection.value)) {
            setSelection(secondary ? options[1] : options[0]);
            onChange(secondary ? options[1].value : options[0].value);
        }
    }, [selection, options, secondary, onChange]);

    const onButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const onOptionClick = (option) => () => {
        setSelection(option);
        setIsOpen(false);
        onChange(option.value);
    };

    return (
        <div ref={dropDownRef} className={style.compareDropDown}>
            <button onClick={onButtonClick} className={style.compareSelect}>
                {selection.label}
                <span
                    className={classnames(style.compareChevron, {
                        [style.active]: isOpen,
                    })}
                />
            </button>
            <ul
                className={classnames(style.compareDropDownList, {
                    [style.active]: isOpen,
                })}
            >
                {options.map((item) => (
                    <li key={item.value}>
                        <button
                            type="button"
                            onClick={onOptionClick(item)}
                            className={style.compareDropDownOption}
                        >
                            {item.label}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CompareDropDown;
