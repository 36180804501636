import { combineReducers } from 'redux'
import authReducer from './authReducer'
import apiReducer from './apiReducer'
import modalReducer from './modalReducer'
import modalSimpleReducer from "./modalSimpleReducer";
import globalStylesReducer from './globalStylesReducer'
import playerReducer from './playerReducer'
import searchReducer from './searchReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  // user: userReducer,
  api_data: apiReducer,
  // tv_state: tvStateReducer,
  modal_state: modalReducer,
  modal_simple_state: modalSimpleReducer,
  global_styles: globalStylesReducer,
  search_state: searchReducer,
  player: playerReducer,
});

export default rootReducer
