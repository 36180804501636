import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {channelsEndpoint, epggridEndpoint, searchEndpoint} from '../../store/actions/apiEndpoints'
import { fetchAction } from '../../store/actions/fetchActions'
import { setAppClass } from '../../store/actions/globalStylesActions'
import { setSearchValue } from '../../store/actions/searchActions'
import Loading from '../auth/Loading'
import SmartItem from '../elements/SmartItem'
import FlagIcon from '../../img/flag-icon.svg'
import {EpgToChannelDays} from "../../store/actions/ApiKeysMap";
import { useIntl } from 'react-intl'

const SearchNew = props => {
// console.log('SearchNew epg', props.api_data.payload.epg)
// console.log('SearchNew channels', props.api_data.payload.channels)
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const searchValueMounted = useRef(false)
    const items = props.api_data.payload.search ? props.api_data.payload.search.items.items : null
    const searchValue = props.searchState.value

    useEffect(() => {
        props.setAppClass('search-page')
        if (!props.api_data.payload.channels) {
            props.fetchAction(
                props.auth.token,
                channelsEndpoint(props.auth.lang),
                'SearchNew',
                null,
                null,
                ['id', 'lcn']
            )
        } else if (!props.api_data.payload.epg) {
            props.fetchAction(
                props.auth.token,
                epggridEndpoint({
                    channels : Object.keys(props.api_data.payload['channels']['maps']['id']),
                    pastDays : 0,
                    futureDays : 1,
                    lang : props.auth.lang
                }),
                'SearchNew',
                EpgToChannelDays
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.api_data.payload.channels])

    useEffect(() => {
        if (searchValue === '') {
            setLoading(false)
        } else {
            setLoading(true)
        }

        const delayDebounceFn = setTimeout(() => {
            if (searchValue !== '' && searchValueMounted.current) {
                props.fetchAction(props.auth.token, searchEndpoint(searchValue, props.auth.lang, 0, 30, 5), 'SearchNew', 'search')
            }
        }, 1000)

        return () => {
            searchValueMounted.current = true
            clearTimeout(delayDebounceFn)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
        setLoading(false)
    }, [items])

    const NoData = () => {
        return (
            <div className="w-100 text-center mt-5">
                <p className={"mt-5 text-white"}><FormattedMessage id="filter_no_match" /></p>
            </div>
        )
    }

    const NoInput = () => {
        return (
            <div className="w-100 text-center mt-5">
                <img className={"mt-3"} src={FlagIcon} alt='' />
                <p className={"mt-3 text-white"}><FormattedMessage id="search_help" /></p>
            </div>
        )
    }
/*
                       i,
                       index,
                       section,
                       item,
                       epg,
                       channels,
                       adultPinStampValid,
                       profileAge,
                       proportion,
                       template,
                       bookmark

 */
    const Data = () => {
        const nowStamp = Date.now() / 1000 | 0
        const adultPinStampValid = props.global_styles.adult_pin_stamp && (props.global_styles.adult_pin_stamp + 180 > nowStamp)
        return (
            items.map((item, index) => {
                const key = Object.keys(item)[0]
                return (
                    <div key={index} className="search-img-wrapper fade-in">
                        <SmartItem
                            i={key}
                            index={index}
                            section='search'
                            item={item[key]}
                            epg={props.api_data.payload.epg}
                            // lang={props.auth.lang}
                            channels={props.api_data.payload.channels}
                            adultPinStampValid={adultPinStampValid}
                            // itemClass={'medium_16_9'}
                            proportion={'16_9'}
                            template='16_9_medium'
                            key={index}
                        />
                    </div>
                )
            })
        )
    }

    let outputContent

    if (searchValue === '') {
        outputContent = <NoInput />
    } else if (loading) {
        outputContent = <Loading/>
    } else if (items !== null && items.length !== 0) {
        outputContent = <Data />
    } else {
        outputContent = <NoData />
    }

    return <>
        <div className="text-center search-input-container">
            <input
                className={"search-input"}
                autoFocus
                type='text'
                autoComplete='off'
                placeholder={intl.formatMessage({id: 'search_query_hint'})}
                onChange={e => props.setSearchValue(e.target.value)}
                value={searchValue}
            />
        </div>
        <div className="d-flex flex-wrap justify-content-start page-margins">
            {outputContent}
        </div>
    </>
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        api_data: state.api_data,
        global_styles: state.global_styles,
        searchState: state.search_state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) },
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        setSearchValue: (value) => { dispatch(setSearchValue(value)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchNew)