export const ItemPlayLink = ({itemId, section, index, template, i}) => {
  let playLink = '/vod-about?vod_id=' + itemId + '&section=' + section + '&index=' + index
  if (section === '5') {
    playLink = '/play?type=vod&vod_id=' + itemId + '&section=' + section
  } else if (i === 'group') {
    playLink = '/group?id=' + itemId + '&section=' + section + '&index=' + index + '&template=' + template
  }

  return playLink
}
