import config from '../../config/config'
import {handleCookie} from "../../components/addons/CookiesHandler";
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const PIN_ENTER_SUCCESS = 'PIN_ENTER_SUCCESS';
export const SET_APP_LANG = 'SET_APP_LANG';
export const SET_STAT_HELLO = 'SET_STAT_HELLO';
export const DateTimeOptions = {
  month: 'long',
  day: 'numeric',
  hour: "numeric",
  minute: "numeric"
}

export const pinEnterSuccess = (profile, preserve_stamp) => {
// console.log('pinEnterSuccess', profile, preserve_stamp)
  return {
    type: PIN_ENTER_SUCCESS,
    preserve_stamp: preserve_stamp,
    payload: profile
  }
}

export const setAppLang = (lang) => {
// console.log('const setAppLang', lang)
  handleCookie({
    name: config['appLangCookieName'],
    value: lang,
    expireDays: 365
  })

  return {
    type: SET_APP_LANG,
    payload: lang
  }
}

export const setStatHello = (value) => {
  return {
    type: SET_STAT_HELLO,
    payload: value
  }
}

export const fetchUser = () => {
  return dispatch => {
    dispatch(fetchUsersRequest())
    fetch(config['clientURI'] + "/user", {
        credentials: 'include' // fetch won't send cookies unless you set credentials
    })
    .then(response => response.json())
    .then(response => {
        if(response.error) {
          throw(response.error);
        }
        dispatch(fetchUsersSuccess(response));
        return response;
    })
    .catch(error => {
        dispatch(fetchUsersFailure(error));
    })
  }
}
    
export const fetchUsersRequest = () => {
// console.log('fetchUsersRequest')  
  return {
    type: FETCH_USER_REQUEST
  }
}
  
export const fetchUsersSuccess = (user) => {
  // console.log('fetchUsersSuccess', user)
  return {
    type: FETCH_USER_SUCCESS,
    payload: user
  }
}
  
export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USER_FAILURE,
    payload: error
  }
}
