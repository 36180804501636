import { SET_MODAL_SIMPLE_STATE } from '../actions/modalSimpleStateActions'

const initState = {
    style: {width: '794px'},
    bodyStyle: null,
    headerStyle: {marginLeft: '4px', marginRight: '4px', marginBottom: '24px'},
    show: false,
    header: null,
    body: null,
    footer: null,
    closeOnClick: false,
    hideClose: false,
    overlayOnly: false,
    overlayOnClose: false
}

const modalSimpleReducer = (state = initState, action) => {
// console.log('modalSimpleReducer:', state, action.payload)
    switch (action.type) {
        case SET_MODAL_SIMPLE_STATE:
            if (action.payload.show === false) {
                return {
                    ...initState,
                    ...action.payload,
                }
            }
            return {
                ...state,
                ...action.payload,
            }
        default: return state
    }
}

export default modalSimpleReducer