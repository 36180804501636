import React from 'react'
import {Link} from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom'
import EpgListDays from './EpgListDays'
import {FormattedMessage} from "react-intl";
// import Spinner from '../../img/spinner.svg'

const EpgList = ({
                     items,
                     remindersMap,
                     profileAge,
                     loading,
                     genreSelected,
                     channelSelectedId,
                     epgSelectedId,
                     weekDays,
                     nowListIndex,
                     scrollTop,
                     nowDay,
                     activeDay,
                     onclick,
                     setDay,
                     adultBlur
                 }) => {

// console.log('epg list loading, items', loading, items.length, !items.length || loading === true, items)

    const DataLoading = () => {
        return (
            <div className="loader">
                {/*<img className="loader-img" src={Spinner} alt="Loading"/>*/}
                <span className="loader-img"/>
            </div>
        )
    }

    const List = () => {
        // console.log('Epg DataArrived weekDays', weekDays, 'nowListIndex', nowListIndex, 'epgSelectedId', epgSelectedId, 'scrollTop', scrollTop, 'nowDay', nowDay, 'activeDay', activeDay)

        const list = items.length ? (items.map((epg, i) => {
            // console.log('epg: ', epg)
            let disabled = ""
            if (typeof epg.metadata.age_rating !== "undefined" && (profileAge && profileAge < epg.metadata.age_rating)) {
                disabled = " disabled"
            }

            const activeClass = epgSelectedId === epg.id ? " active" : ""

            // let listIcon = (epg.start > nowTimestamp && epg.stop <= nowTimestamp) ? RecordIcon : ''
            let lateClass = ""
            if (epg.late) {
                lateClass = " late"
            }

            const reminderIcon = (remindersMap !== null && typeof remindersMap[epg.id] !== "undefined") ?
                <span className={"control-icon notification-icon" + lateClass}/>
            : null

            const liveIcon = (i === nowListIndex) ?
                <span className={"control-icon epg-list-live"}/>
            : null

            // const reminderIcon = <span className={"control-icon notification-icon" + lateClass}/>
            // const liveIcon = <span className={"control-icon epg-list-live"}/>

            const {season_no, episode_no} = epg.metadata
            let seasonEpisode = ""
            if (season_no) {
                if (episode_no) {
                    seasonEpisode = 'S' + season_no + 'E' + episode_no
                } else {
                    seasonEpisode = 'S' + season_no
                }
            } else if (episode_no) {
                seasonEpisode = 'E' + episode_no
            }

            return (
                <Link
                    className={disabled} key={epg.id}
                    to={'/tv/' + (genreSelected ? genreSelected : 'all') + '/' + channelSelectedId + '/' + epg.id}
                    onClick={() => onclick(i)}
                >
                    <div className={"movie-row" + activeClass + lateClass}>
                        {disabled ?
                            <span className={"control-icon movie-list-icon-disabled"}/>
                            : null
                        }
                        <p className={"movie-list-time"}>{epg.startFormatted}</p>
                        {reminderIcon}
                        {liveIcon}
                        <p className={"movie-list-title"}>{
                            adultBlur ? <FormattedMessage id="parental_blocked_string_mask" /> : epg.metadata.title
                        }</p>
                        <p className={"movie-list-episode"}>{seasonEpisode}</p>
                    </div>
                </Link>
            )
        })) : null

        return (
            <>
                {list}
            </>
        )
    }

    const scrollStop = (scrollValues) => {
        if (!scrollValues.scrollTop) {
            return false
        }
        const indexAtStop = positionToIndex(scrollValues.scrollTop)
        // setDay(items[indexAtStop + positionOffset]['epgDay'], scrollValues.scrollTop)
        setDay(items[indexAtStop]['epgDay'], scrollValues.scrollTop)
    }

    return (
        <>
            <EpgListDays weekDays={weekDays} nowDay={nowDay} activeDay={activeDay} setDay={setDay}/>
            <Scrollbar
                thumbYProps={{className: "thumbY"}}
                scrollTop={scrollTop}
                onScrollStop={scrollStop}
            >
            {/*<div className={"epg-list"}>*/}
                {(!items.length || loading === true) ? <DataLoading/> : <List/>}
            {/*</div>*/}
            </Scrollbar>
        </>
    )
}


/*
todėl, kad buvo
.movie-row {
  height: 38px;
  margin-bottom: 8px;
}

yra
.movie-row {
  height: 60px;
}
*/
// const rowHeight = 46
// export const positionOffset = 4

const rowHeight = 60
export const positionOffset = 4

export const indexToPosition = (index) => {
    return index * rowHeight - positionOffset * rowHeight
    // return index * rowHeight
}

export const positionToIndex = (position) => {
    return Math.round(position / rowHeight)
}

export const getEpgProgress = (start, stop, now) => {
    let progress = 0
    if (stop && now < stop && now >= start) {
        const total = stop - start
        const remain = stop - now
        // progress = Math.round(100 - remain / total * 100)
        progress = (100 - remain / total * 100).toFixed(1)
    } else if (stop && stop < now) {
        progress = 100
    }

    return progress
}

export default EpgList
