import React from 'react'

const RegisterProgressBar = ({step, paddingBottom}) => {
    return (
        <div className={"d-flex"} style={{paddingBottom: (paddingBottom ? paddingBottom : 0)}}>
            <div className={"d-flex"}>
                {[...Array(5)].map((_, index) => (
                    <div
                        key={index}
                        data-step={step}
                        data-index={index + 1}
                        className={"progress-circle" + ((step >= index + 1) ? " active" : "")}
                    />
                ))}
            </div>
        </div>
    )
}

export default RegisterProgressBar