import React from 'react';
import classnames from 'classnames';

const APP_LANGS = ['lt', 'en', 'ru'];

const LanguageDropDown = ({ selectedLang, onSetLanguage }) => {
    return (
        <div className="dropdown">
            <button
                className="button btn-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ textTransform: 'uppercase' }}
            >
                {selectedLang}
            </button>
            <ul className="dropdown-menu">
                {APP_LANGS.map((lang, index) => (
                    <li key={index}>
                        <button
                            className={classnames('btn-link', 'w-100', {
                                active: lang === selectedLang,
                            })}
                            onClick={
                                lang !== selectedLang
                                    ? () => onSetLanguage(lang)
                                    : undefined
                            }
                        >
                            <b className={'text-uppercase'}>{lang}</b>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LanguageDropDown;
