import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
// import { FormattedMessage, useIntl } from "react-intl";
// import { createProfile } from '../../store/actions/profileActions'
import Circle1 from '../../img/circle-1-green.svg'
import Circle2 from '../../img/circle-2-green.svg'
import Circle3 from '../../img/circle-3-green.svg'


class Subscriptions3 extends Component {

    render() {
        return (
          <div>

            <div className="indicators-row">

              <div>
                <img src={Circle1} alt="" />
                <p>Products</p>
              </div>

              <div>
                <img src={Circle2} alt="" />
                <p>Extras</p>
              </div>

              <div>
                <img src={Circle3} alt="" />
                <p>Confirmation</p>
              </div>

            </div>

            <div className="confirmation">

              <div className="confirmation-row">
                <h2>Full content!</h2>
                <p>20$</p>
              </div>

              <div className="confirmation-row">
                <h2>Sports fan</h2>
                <p>20$</p>
              </div>

              <div className="confirmation-row">
                <h2>VOD</h2>
                <p>20$</p>
              </div>

              <div className="confirmation-row">
                <h2>Fishing</h2>
                <p>20$</p>
              </div>

              <hr />

              <div className="confirmation-row total">
                <h2>TOTAL</h2>
                <p>80$</p>
              </div>

            </div>

            <div className="btn-div">
              <div className="row btn-row">
                <div className="col pl-0 pr-3">
                  <button className="button btn-secondary">Cancel</button>
                </div>
                <div className="col pl-3 pr-0">
                  <button className="continue-button" onClick={this.handleContinueClick}>Continue</button>
                </div>
              </div>
            </div>

          </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
      return {
          // createProfile: (profile) => dispatch(createProfile(profile)),
          setModalState: (id, title, body, back_button) => { dispatch(setModalState(id, title, body, back_button)) }        
      }
}

export default connect(null, mapDispatchToProps)(Subscriptions3)
