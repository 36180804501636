import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'
import {setAppClass, setShopItem, removeShopItem} from '../../store/actions/globalStylesActions'
import {fetchAction, fetchSuccess} from '../../store/actions/fetchActions'
import ProductTypes from "../elements/ProductTypes";
import ProductList from "../elements/ProductList";
import {
    productGroupsEndpoint,
    productItemsEndpoint,
    productSubscribeEndpoint
} from "../../store/actions/apiEndpoints";
import {ProductGroupsMap, ProductsMap, VodAboutWrap} from "../../store/actions/ApiKeysMap";
import config from "../../config/config";
import {getCookie, handleCookie} from "../addons/CookiesHandler";
import {FormattedMessage} from "react-intl";
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";
import PinInput from "../elements/PinInput";

class Shop extends Component {
    constructor(props){
        super(props)
        this.channelsListRef = React.createRef()
    }

    state = {
        pinHasError: false,
        pinValue: '',
        redirectTo: '',
    }

    loadingElement = ''
    cookieRedirectUrl = ''
    shopCategory = ''
    loadingCategory = false
    loadingList = false
    productRequested = false
    productGroupsRequested = false
    prepaidCustomersRequested = false
    notOrdered = false
    redirectedTo = ''
    skipToProduct = ''
    renderStamps = {}

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search)
        if (searchParams.get('not_ordered')) {
            this.notOrdered = true
        }

        this.props.setAppClass('shop')
        if (this.props.appElRef && this.props.appElRef.current) {
            this.props.appElRef.current.className = 'App shop'
        }

        this.cookieRedirectUrl = getCookie('shop_redirect_url')
    }

    productGroupsRefresh(noFilter) {
        if (!this.props.auth.profile) {
            return false
        }
        this.loadingElement = 'category'
        this.props.fetchAction(
            this.props.auth.token,
            productGroupsEndpoint({
                customer_id: this.props.auth.profile.customer_id,
                chid: (noFilter || this.props.match.params.category === 'svod' || this.props.match.params.category === 'tvod') ? null : this.props.match.params.product_id,
                vodprovid: (noFilter || this.props.match.params.category === 'channel') ? null : this.props.match.params.product_id,
                lang: this.props.auth.lang,
                category: this.props.match.params.category
            }),
            this.constructor.name,
            ProductGroupsMap,
            noFilter ? null : {
                chid: (noFilter || this.props.match.params.category === 'svod' || this.props.match.params.category === 'tvod') ? null : this.props.match.params.product_id,
                vodprovid: (noFilter || this.props.match.params.category === 'channel') ? null : this.props.match.params.product_id,
            }
        )
        this.handleCloseClick()
    }

    fetchData({path, exactPath, statePath, dataKey, isPublic, params, productId, smartivusId, stateToSet}) {
        let url = path
        if (!exactPath) {
            if (isPublic) {
                url = config['publicApiURI'] + '/' + path
            } else {
                url = config['registerURI'] + path
            }
        }
        const transformedPath = statePath ? statePath : path.replace(/\//g, '').replace(/-/g, '_')

        let newState = {
            ...this.state,
            page: [transformedPath],
            loading: true,
            error: '',
        }

        this.setState(newState)

        fetch(url, params)
            .then(response => {
                if (response.status === 204 || response.status === 404) {
                    if (response.status === 204) {
                        const newState = {
                            ...this.state,
                            formSupports: {},
                            formDataErrors: {},
                            formSupportsErrors: {},
                            loading: false,
                            error: ''
                        };

                        if (stateToSet) {
                            Object.assign(newState, stateToSet);
                        }
                        this.setState(newState)

                        if (this.props.modal_simple_state.show) {
                            this.props.setModalSimpleState({
                                show: false
                            })
                        }
                    } else {
                        this.setState({
                            ...this.state,
                            error: '404',
                            [transformedPath]: response.status === 404 ? [] : {},
                        })
                    }
                    return null;
                } else {
                    return response.json()
                }
            })
            .then((response) => {
// console.log('then response:', response)
                if (response.errors) {
                    const formSupportsErrors = Object.keys(response.errors).reduce(function(acc, key) {
                        acc[key] = true;
                        return acc;
                    }, {})
                    const formSupports = Object.keys(response.errors).reduce(function(acc, key) {
                        acc[key] = response.errors[key].join(" ")
                        return acc;
                    }, {})

                    this.setState({
                        ...this.state,
                        formSupports: formSupports,
                        formDataErrors: formSupportsErrors,
                        formSupportsErrors: formSupportsErrors,
                        loading: false,
                        error: '*'
                    })
                } else {
// console.log('then setState:', transformedPath, dataKey, stateToSet, response)
                    const newState = {
                        ...this.state,
                        [transformedPath]: dataKey ? response[dataKey] : response,
                        formSupports: {},
                        formDataErrors: {},
                        formSupportsErrors: {},
                        loading: false,
                        error: ''
                    };

                    if (stateToSet) {
                        Object.assign(newState, stateToSet);
                    }

                    this.setState(newState)
                }
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: error.message,
                })
            })
        ;
    }


    componentDidUpdate(prevProps, prevState) {
        if (!this.prepaidCustomersRequested && this.props.auth.token && this.props.userinfo && this.props.userinfo.customer_id.items) {
            /*
            Dėl galiojančios kortelės:
            1. GET api/v2/prepaid-customers/{customer}/payment-methods ir pažiūrėt, ar kortelė dar galioja is_token_valid, ir ar kortelė defaultinė is_default
            2. GET api/v2/prepaid-customers/{customer} ir pasitikrint ar has_valid_cc_token: true, kas atlieką tą patį tikrinimą, kaip ir pirmam žingsnyje.
            3. Sugaudyt errorus pirkimo metu
             */
            this.fetchData({
                path: 'prepaid-customers/' + this.props.userinfo.customer_id.items,
                params: {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
                statePath: 'payment_methods'
            })

            this.prepaidCustomersRequested = true
        }

        if (!this.props.match.params.product_id && this.cookieRedirectUrl) {
            handleCookie({
                operation: 'remove',
                name: 'shop_redirect_url'
            })
            this.cookieRedirectUrl = ''
        }

        if (!this.props.match.params.product_id && this.props.match.params.category) {
            this.shopCategory = this.props.match.params.category
        }
        if (this.props.match.params.product_id && this.props.match.params.product_id !== this.productRequested) {
            if (this.props.match.params.category === 'channel' || this.props.match.params.category === 'svod' || this.props.match.params.category === 'tvod') {
                this.productGroupsRefresh()
            } else {
                this.loadingElement = 'list'
                this.props.fetchAction(
                    this.props.auth.token,
                    productItemsEndpoint({
                        id: this.props.match.params.product_id,
                        lang: this.props.auth.lang
                    }),
                    this.constructor.name,
                    ProductsMap
                )
            }
            this.productRequested = this.props.match.params.product_id
        // } else if (!this.props.match.params.product_id && !this.productGroupsRequested) {
        } else if ((!this.props.product_groups || !this.props.product_groups.items) && !this.productGroupsRequested) {
            this.productGroupsRefresh(true)
            this.productGroupsRequested = true
        }
    }

    handlePinEntered = (enteredPin) => {
        this.setState({
            ...this.state,
            pinValue: enteredPin
        })

        if (!enteredPin && this.state.error) {
            this.setState({
                ...this.state,
                error: ''
            })
        }
    }

    subscribeClick = (action) => {
        const pinToCheck = this.props.auth.profile.pin ? this.props.auth.profile.pin : this.props.auth.profile.pin_with_default

        this.props.setModalSimpleState({
            show: true,
            closeOnClick: false,
            header: "pin_required_title",
            style: {width: "400px"},
            headerStyle: {marginBottom: "40px"},
            hideClose: true,
            body: (
                <div>
                    <div className="pt-3">
                        <FormattedMessage id={action} />
                    </div>

                    {!this.props.alertType ? (
                        <form onSubmit={(e) => this.handleSubscribeSet(e, this.state.pinValue)}>
                            <div className="form-group" style={{marginBottom: '47px', marginTop: '16px'}}>
                                <div className={"d-flex"}>
                                    <PinInput onPinEntered={this.handlePinEntered} hasError={this.state.pinHasError} pinToCheck={pinToCheck} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="button btn-secondary btn-lg me-2" onClick={(e) => this.handleCloseClick(e)}>
                                    <FormattedMessage id={"profile_button_cancel"} />
                                </button>
                                <button className="button btn-primary btn-lg">
                                    <FormattedMessage id={"pin_dialog_button_ok_text"} />
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="row confirm-cancel-row">
                            <div className="buttons">
                                <button className="button btn-primary btn-lg" onClick={() => window.history.back()}>
                                    <FormattedMessage id={"common_dialog_button_ok_text"} />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )
        })

        return true
    }

    handleCloseClick = (e) => {
        this.props.setModalSimpleState({
            show: false,
        })
    }

    handleStopShowing = (response, productId) => {
        const searchParams = new URLSearchParams(this.cookieRedirectUrl)
        let productKey = this.props.match.params.category === 'svod' ? 'provider_id' : 'id'
        if (searchParams.get('channel_id')) {
            productKey = 'channel_id'
        }
        const nowStamp = (Date.now() / 1000 | 0).toString()
        let purchasedItems = {}

        if (response.status === 200) {
            if (productKey === 'channel_id') {
                for (const productItem of this.props.product_selected.items) {
                    purchasedItems[productItem.id.toString()] = nowStamp
                }
            } else {
                purchasedItems[productId] = nowStamp
            }

            this.props.setShopItem({
                [productKey]: purchasedItems
            })
        } else if(response.status === 204) {
            purchasedItems[productId] = nowStamp
            this.props.removeShopItem({
                [productKey]: purchasedItems
            })
        }

        if (response.status === 200 || response.status === 204) {
            const shopChapter = '/shop' + (this.shopCategory ? '/' + this.shopCategory : '');
            //-- pamodifikuojam props.vod_about į subscribed:true, nes užsiklausus vod'o
            //-- būsena subscribed dar būna nespėję pasikeist
            if (this.props.vod_about) {
                VodAboutWrap({ ...this.props.vod_about, subscribed: true })
            }

            this.setState({
                ...this.state,
                // confirmShow: false,
                redirectTo: this.cookieRedirectUrl ? this.cookieRedirectUrl : shopChapter
            })
            this.productGroupsRefresh(true)
        }
    }

    handleSubscribeSet = (e, pinValue) => {
        e.preventDefault()
        const pinToCheck = this.props.auth.profile.pin ? this.props.auth.profile.pin : this.props.auth.profile.pin_with_default
        let pinHasError = false
        if (pinToCheck !== pinValue) {
            pinHasError = true
        } else {
            this.sendSubscribeSet()
            return false
        }

        this.setState({
            ...this.state,
            pinHasError: pinHasError
        })
    }

    sendSubscribeSet = () => {
        let shopAction
        const productId = parseInt(this.props.match.params.product_id)
        const productKeys = ['chid', 'vodprovid']
        let product
        for (const productKey of productKeys) {
            if (this.props.product_groups[productKey]) {
                for (const i in this.props.product_groups[productKey]) {
                    if (!this.props.product_groups[productKey][i]) {
                        continue
                    }
                    for (product of this.props.product_groups[productKey][i]['items']) {
                        if (product['id'] === productId || productKey === 'vodprovid') {
                            if (product['subscribed']) {
                                shopAction = 'DELETE'
                            } else {
                                shopAction = 'POST'
                            }
                            break
                        }
                    }
                    if (shopAction) {
                        break
                    }
                }
            }
            if (shopAction) {
                break
            }
        }

        if (!shopAction) {
            if (this.props.product_groups['items']) {
                for (const i in this.props.product_groups['items']) {
                    for (product of this.props.product_groups['items'][i]['products']) {
                        if (product['id'] === productId) {
                            if (product['subscribed']) {
                                shopAction = 'DELETE'
                            } else {
                                shopAction = 'POST'
                            }
                            break
                        }
                    }
                    if (shopAction) {
                        break
                    }
                }
            }
        }

        if (!shopAction || !product.id) {
            console.log('no shopAction', product.id)
            return false
        }

        const url = config['apiURI'] + "/" + productSubscribeEndpoint({
            id: product.id,
            customer_id: this.props.auth.profile.customer_id,
            // customer_id: 521767068,
            purchase_option: 1,
            lang: this.props.auth.lang,
        })

        //-- testuojam įsivaizduodami, kad užsisakymas suveikė
// console.log('shopAction:', shopAction)
//         this.handleStopShowing({status: 200}, productId)
//         return false

        fetch(url, {
            method: shopAction,
            headers: {
                'Authorization': 'Bearer ' + this.props.auth.token,
            },
        })
            .then(
                response => this.handleStopShowing(response, productId)
            )
            .catch(
                error => console.log('submit error', error)
            )
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (!nextProps.match.params.category) {
            this.notOrdered = false
        }

        this.redirectedTo = ''
        this.skipToProduct = ''

        //-- kažkada buvo pageidavimas redirektinti į pirmą produktą, po to persigalvojo į pirmą
        //-- persigalvojo, kad verčiau pirma parodyti produktų sąrašą

        if (nextProps.match.params.category &&
            nextProps.match.params.category === 'channel' &&
            nextProps.product_groups &&
            nextProps.product_groups.chid &&
            nextProps.product_groups.chid[nextProps.match.params['product_id']]
        ) {
            const index = 0
            if (nextProps.product_groups.chid[nextProps.match.params['product_id']]['items'][index]) {
                this.skipToProduct = '/shop/product/' + nextProps.product_groups.chid[nextProps.match.params['product_id']]['items'][index]['id'] + '?filter=' + nextProps.match.params['product_id']
            }
        }

        return !(
            (
                this.props.product_selected &&
                this.props.product_selected === nextProps.product_selected &&
                this.renderStamps['product_selected']
            )
            &&
            (
                this.props.match.params.category &&
                this.props.match.params.category === nextProps.match.params.category &&
                this.renderStamps['category']
            )
            &&
            (
                this.props.match.params.product_id &&
                this.props.match.params.product_id === nextProps.match.params.product_id &&
                this.renderStamps['product_id']
            )
            &&
            this.state.pinHasError === nextState.pinHasError
            &&
            this.props.modal_simple_state.show
        )
    }

    render(){
        const nowStamp = Date.now() / 1000 | 0

        if (this.state.redirectTo && this.state.redirectTo !== this.redirectedTo) {
            this.redirectedTo = this.state.redirectTo
            this.setState({
                ...this.state,
                redirectTo: ''
            })
            return <Redirect to={this.redirectedTo} />
        } else if (this.skipToProduct) {
            return <Redirect to={this.skipToProduct} />
        }

        let { auth, api_error, channels, product_groups, product_selected, vodprovid } = this.props

        if (!auth.token || api_error || !this.props.auth || !this.props.auth.profile) {
            return <Redirect to="/" />
        }

        if (product_selected) {
            this.renderStamps['product_selected'] = nowStamp
        }
        if (this.props.match.params.category) {
            this.renderStamps['category'] = nowStamp
        }
        if (this.props.match.params.product_id) {
            this.renderStamps['product_id'] = nowStamp
        }

        const notOrderedMessage = this.notOrdered ? <p><FormattedMessage id="product_purchase_channel_user_info"/></p> : null

        return (
            <div className="d-flex align-items-stretch" style={{minHeight: '100vh'}}>
                <div key={'types_section'} className="scrollable-column text-center" style={{width: '18.5%'}}>
                    <ProductTypes
                        product_groups={product_groups}
                        loading={this.loadingCategory}
                        selected={this.props.match.params.category}
                    />
                </div>
                <div key={'items_section'} className={"d-flex align-items-start flex-wrap"} style={{paddingTop: '78px', paddingLeft: '74px', paddingRight: '68px', width: '81.5%'}}>
                    {notOrderedMessage}
                    <ProductList
                        channels={channels}
                        product_groups={product_groups}
                        vodprovid={vodprovid}
                        product_selected={product_selected}
                        loading={this.props.loading}
                        selected={this.props.match.params.category}
                        product_id={this.props.match.params.product_id}
                        subscribeClick={(a) => this.subscribeClick(a)}
                        lang={this.props.auth.lang}
                        ccValid={this.state.payment_methods && this.state.payment_methods.has_valid_cc_token}
                        userInfo={this.props.userinfo}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //-- todėl, kad nerenderina, kai per dažni apdeitai
    let productGroups;
    if (state.api_data &&
        state.api_data.payload &&
        state.api_data.payload['product_groups'] &&
        state.api_data.payload['product_groups']['items']
    ) {
        productGroups = state.api_data.payload['product_groups']
    }

    return {
        auth: state.auth,
        api_error: state.api_data ? state.api_data.error : null,
        userinfo: state.api_data.payload['userinfo'],
        global_styles: state.global_styles,
        loading: state.api_data ? state.api_data.loading : null,
        modal_simple_state: state.modal_simple_state,
        product_groups: productGroups,
        vodprovid: state.api_data.payload.product_groups ? state.api_data.payload['product_groups']['vodprovid'] : null,
        product_selected: (state.api_data &&
            state.api_data.payload &&
            state.api_data.payload['product_selected']
        ) ? state.api_data.payload['product_selected'] : null,
        vod_about: state.api_data && state.api_data.payload ? state.api_data.payload.vod_about : null,
        channels: (state.api_data && state.api_data.payload.channels) ? state.api_data.payload.channels : {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
        fetchSuccess: (payload, source, subkey) => { dispatch(fetchSuccess(payload, source, subkey)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) },
        setShopItem: (app_item) => { dispatch(setShopItem(app_item)) },
        removeShopItem: (app_item) => { dispatch(removeShopItem(app_item)) },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shop))
