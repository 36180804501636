import React from 'react'
import { connect } from 'react-redux'
import { setAppLang } from '../../store/actions/authActions'

const LanguageSelectDropdown = props => {
    const { setAppLang, auth } = props

    return (
        <div className="language-dropdown">
            <div className="dropdown-toggle">
                <p>{auth.lang}</p>
            </div>

            <div className="dropdown-content">
                <button type='button' onClick={() => setAppLang('lt')}>LT</button>
                <button type='button' onClick={() => setAppLang('en')}>EN</button>
                <button type='button' onClick={() => setAppLang('ru')}>RU</button>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAppLang: (app_lang) => { dispatch(setAppLang(app_lang)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectDropdown)