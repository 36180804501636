import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { setAppClass } from '../../store/actions/globalStylesActions'
import { setAppLang } from '../../store/actions/authActions'
import '../../css/forms.css'
import TextField from './TextField'
import LanguageSelectDropdown from './LanguageSelectDropdown'
import Logo from '../../img/logo.svg'
import Cities from "../addons/Cities";

const SignUpForm = props => {
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [city, setCity] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [emailBadFormat, setEmailBadFormat] = useState(false)
    const [phoneBadFormat, setPhoneBadFormat] = useState(false)
    const [passwordTooShort, setPasswordTooShort] = useState(false)
    const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false)
    const [nameEmpty, setNameEmpty] = useState(false)
    const [surnameEmpty, setSurnameEmpty] = useState(false)
    const [cityEmpty, setCityEmpty] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let locale = params.get('locale')
    if (locale === null)
        locale = 'lt'
    else if (locale === 'en_GB')
        locale = 'en'
    else if (locale === 'en_US')
        locale = 'en'
    else if (locale !== 'lt' && locale !== 'en' && locale !== 'ru')
        locale = 'lt'

    const { history, setAppClass, setAppLang } = props

    useEffect(() => {
        setAppClass('sign-up')
        setAppLang(locale)
    }, [setAppClass, setAppLang, locale])

    const handleSubmit = e => {
        e.preventDefault()

        let canSubmit = true

        if (!name.length) {
            setNameEmpty(true)
            canSubmit = false
        }

        // if (!surname.length) {
        //     setSurnameEmpty(true)
        //     canSubmit = false
        // }

        if (!city.length) {
            setCityEmpty(true)
            canSubmit = false
        }

        if (!/.+@.+\..+/.test(email)) {
            setEmailBadFormat(true)
            canSubmit = false
        }

        if (phone.substring(0,4) !== '3706' || phone.length !== 11) {
            setPhoneBadFormat(true)
            canSubmit = false
        }

        if (password.length < 8) {
            setPasswordTooShort(true)
            canSubmit = false
        }

        if (password !== passwordConfirmation) {
            setPasswordsDoNotMatch(true)
            canSubmit = false
        }

        if (canSubmit) {
            history.push({
                pathname: '/sign-up/conditions',
                state: {
                    name: name,
                    surname: surname,
                    city: city,
                    email: email,
                    phone: phone,
                    password: password
                }
            })
        }
    }

    return (
        <form className='sign-up-form' onSubmit={e => handleSubmit(e)}>
            <img className='cgates-logo' src={Logo} alt='cgates logo' />

            <LanguageSelectDropdown />

            <div className='text-fields'>
                <div className='text-field' style={{textAlign: 'center'}}>
                    <p><FormattedMessage id="register_header" /></p>
                </div>

                <TextField field={name} setField={setName} fieldCondition={nameEmpty} setFieldCondition={setNameEmpty}
                           name='name' type='text' error=<FormattedMessage id="register_name_empty" />
                           label=<FormattedMessage id="register_name" /> />

                <TextField field={surname} setField={setSurname} fieldCondition={surnameEmpty}
                           setFieldCondition={setSurnameEmpty} name='surname' type='text'
                           error=<FormattedMessage id="register_surname_empty" />
                           label=<FormattedMessage id="register_surname" /> />

                <TextField field={city} cities={Cities} setField={setCity} fieldCondition={cityEmpty}
                           setFieldCondition={setCityEmpty} name='city' type='option'
                           error=<FormattedMessage id="register_city_empty" />
                           label=<FormattedMessage id="register_city" /> />

                <TextField field={email} setField={setEmail} fieldCondition={emailBadFormat}
                           setFieldCondition={setEmailBadFormat} name='email' type='text'
                           error=<FormattedMessage id="register_email_format" />
                           label=<FormattedMessage id="register_email" /> />

                <TextField field={phone} setField={setPhone} fieldCondition={phoneBadFormat}
                           setFieldCondition={setPhoneBadFormat} name='phone' type='tel'
                           error=<FormattedMessage id="register_phone_format" />
                           label=<FormattedMessage id="register_phone" /> />

                <TextField field={password} setField={setPassword} fieldCondition={passwordTooShort}
                           setFieldCondition={setPasswordTooShort} name='password' type='password'
                           error=<FormattedMessage id="register_password_length" />
                           label=<FormattedMessage id="register_password" /> />

                <TextField field={passwordConfirmation} setField={setPasswordConfirmation}
                           fieldCondition={passwordsDoNotMatch} setFieldCondition={setPasswordsDoNotMatch}
                           name='passwordConfirmation' type='password' password={password}
                           error=<FormattedMessage id="register_password_mismatch" />
                           label=<FormattedMessage id="register_password_confirm" /> />
            </div>

            <button className='button' type='submit'><FormattedMessage id="register_continue" /></button>
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) },
        setAppLang: (app_lang) => { dispatch(setAppLang(app_lang)) }
    }
}

export default connect(null, mapDispatchToProps)(SignUpForm)