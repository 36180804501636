import React from 'react';
import CloseIcon from 'img/close-icon.svg';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setModalSimpleState } from 'store/actions/modalSimpleStateActions';
import style from './style.module.sass';

const ModalSimple = ({
    header,
    headerStyle,
    body,
    footer,
    show,
    style: styleProp,
    bodyStyle,
    closeOnClick,
    hideClose,
    overlayOnly,
    overlayOnClose,
}) => {
    const dispatch = useDispatch();
    const handleStopShowing = () => {
        dispatch(
            setModalSimpleState({
                show: false,
                overlayOnly: overlayOnClose,
            })
        );
    };

    return (
        <div style={{ display: !show && !overlayOnly ? 'none' : 'block' }}>
            <div className={style.modalOverlay} />
            {!overlayOnly && (
                <div className={style.notificationWindow} style={styleProp}>
                    {!hideClose && (
                        <button
                            className="close-btn"
                            onClick={handleStopShowing}
                        >
                            <img
                                className="close-icon"
                                src={CloseIcon}
                                alt=""
                            />
                        </button>
                    )}
                    {header && (
                        <div style={headerStyle}>
                            <h1>
                                {header.includes('_') ? (
                                    <FormattedMessage id={header} />
                                ) : (
                                    header
                                )}
                            </h1>
                        </div>
                    )}
                    {body && (
                        <div
                            onClick={
                                closeOnClick ? handleStopShowing : undefined
                            }
                            style={bodyStyle ? bodyStyle : undefined}
                        >
                            {body}
                        </div>
                    )}
                    {footer && <div className={style.footer}>{footer}</div>}
                </div>
            )}
        </div>
    );
};

export default ModalSimple;
