import React, { useState } from 'react';
import classnames from 'classnames';
import { getLangProp } from 'components/addons/ProductsHelper';
import { FormattedMessage } from 'react-intl';
import ErrorIcon from 'img/error-icon.svg';
import config from 'config/config';
import ProductItem from 'components/elements/ProductItem';
import RegisterProgressBar from 'components/elements/RegisterProgressBar';
import ChannelModal from 'components/elements/ChannelModal';
import FormInput from './FormInput';
import style from './style.module.sass';

const YourChoice = ({
    item,
    lang,
    langDefault,
    channels,
    handleStepBtn,
    step,
    error,
    handleInputClear,
    handleInputFocus,
    formDataErrors,
    formSupports,
    formSupportsErrors,
}) => {
    const [channelList, setChannelList] = useState(null);

    const onOpenChannels = () => {
        if (!item.chids) return;

        const filteredChannels = channels.filter((channel) =>
            item.chids.includes(channel.id)
        );
        setChannelList(filteredChannels);
    };

    const onCloseChannels = () => {
        setChannelList(null);
    };

    return (
        <div className={style.pageContainer}>
            <div
                className={classnames(style.progressWrapper, {
                    [style.hidden]: item.is_postpaid,
                })}
            >
                <div
                    className="product-box choice"
                    style={{ background: 'none', padding: 0 }}
                />
                <RegisterProgressBar step={step} />
            </div>
            <div className={style.mainWrapper}>
                <div className={style.selectedPlanWrapper}>
                    <span className={style.yourChoice}>
                        <FormattedMessage id={'sign_your_choice'} />
                        {':'}
                    </span>
                    <ProductItem
                        id={item.id}
                        name={item.name}
                        chids={item.chids}
                        discount_percentages={item.discount_percentages}
                        smartivus_id={item.smartivus_id}
                        onOpenModal={onOpenChannels}
                        price={getLangProp(item, 'price', lang, langDefault)}
                        discounted_price={getLangProp(
                            item,
                            'discounted_price',
                            lang,
                            langDefault
                        )}
                        banner={getLangProp(item, 'banner', lang, langDefault)}
                        placeholder_text_1={getLangProp(
                            item,
                            'placeholder_text_1',
                            lang,
                            langDefault
                        )}
                        placeholder_text_2={getLangProp(
                            item,
                            'placeholder_text_2',
                            lang,
                            langDefault
                        )}
                        placeholder_text_3={getLangProp(
                            item,
                            'placeholder_text_3',
                            lang,
                            langDefault
                        )}
                        additionalDetails={getLangProp(
                            item,
                            'additional_terms',
                            lang,
                            langDefault
                        )}
                        withDetails
                        className={style.productCard}
                    />
                    <div style={{ padding: '24px', marginTop: '6px' }}>
                        <div
                            className={'cursor-pointer'}
                            onClick={(event) => {
                                event.preventDefault();
                                handleStepBtn(event);
                            }}
                        >
                            <b data-step={'0'}>
                                <FormattedMessage id="sign_up_change_plan_link" />
                            </b>
                        </div>
                    </div>
                </div>
                <div className={style.formWrapper}>
                    <h1>
                        <FormattedMessage
                            id={
                                item.is_prepaid
                                    ? 'sign_up_header'
                                    : 'sign_up_send_contacts_header'
                            }
                        />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={
                                item.is_prepaid
                                    ? 'sign_up_sub_header'
                                    : 'sign_up_send_contacts_sub_header'
                            }
                        />
                    </p>
                    {item.is_prepaid && error && (
                        <div className={style.errorLine}>
                            <img src={ErrorIcon} alt="Error" />
                            <p>
                                <FormattedMessage id={'sign_up_user_exists'} />
                                {' – '}
                                <a href={`${config['clientURI']}/login`}>
                                    <FormattedMessage id="sign_up_user_exists_login" />
                                </a>
                                <FormattedMessage
                                    id={'sign_up_user_exists_help'}
                                />{' '}
                                <a href="https://www.cgates.lt/kontaktai">
                                    <FormattedMessage id="sign_up_user_exists_help_link" />
                                </a>
                                {'.'}
                            </p>
                        </div>
                    )}
                    <form className={style.formInputs}>
                        {item.is_prepaid && (
                            <FormInput
                                id="inputEmail"
                                name="email"
                                type="email"
                                messageId="register_email"
                                onClearInput={handleInputClear}
                                handleInputFocus={handleInputFocus}
                                error={formDataErrors['email']}
                                support={formSupports['email']}
                                supportError={formSupportsErrors['email']}
                            />
                        )}
                        {!item.is_prepaid && (
                            <FormInput
                                id="inputName"
                                name="name"
                                type="text"
                                messageId="register_name"
                                onClearInput={handleInputClear}
                                handleInputFocus={handleInputFocus}
                                error={formDataErrors['name']}
                                support={formSupports['name']}
                                supportError={formSupportsErrors['name']}
                            />
                        )}
                        <FormInput
                            id="inputPhone"
                            name="phone"
                            type="text"
                            messageId="register_phone"
                            onClearInput={handleInputClear}
                            handleInputFocus={handleInputFocus}
                            error={formDataErrors['phone']}
                            support={formSupports['phone']}
                            supportError={formSupportsErrors['phone']}
                        />
                        {item.is_postpaid && (
                            <label
                                className={classnames(style.marketingCheck, {
                                    [style.error]:
                                        formDataErrors['consent_call'],
                                })}
                            >
                                <input
                                    type="checkbox"
                                    name="consent_call"
                                    className="form-check-input"
                                />
                                <span className={style.marketingCheckLabel}>
                                    <FormattedMessage id="sign_up_marketing_consent_label" />
                                </span>
                            </label>
                        )}
                        <button
                            type="button"
                            onClick={handleStepBtn}
                            data-product-id={item.id}
                            data-step="2"
                            className={classnames(
                                style.subButton,
                                'button',
                                'btn-primary-bright',
                                'btn-lg'
                            )}
                        >
                            <FormattedMessage
                                id={
                                    item.is_prepaid
                                        ? 'subscribe_action_btn'
                                        : 'sign_up_make_agreement_btn'
                                }
                            />
                        </button>
                    </form>
                </div>
            </div>
            <ChannelModal channels={channelList} onClose={onCloseChannels} />
        </div>
    );
};

export default YourChoice;
