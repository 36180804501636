import React from 'react'
import HomeItemSmart from "./HomeItemSmart"
import {FormattedMessage} from "react-intl"
// import {format} from "date-fns"
import {getEpgProgress} from "./EpgList"
// https://editor.method.ac/
import PlaceholderPoster from "../../img/placeholder-poster.svg"
import PlaceholderScreenshot from "../../img/placeholder-image.svg"
import RestrictedPoster from "../../img/placeholder-poster_restricted.svg"
import RestrictedScreenshot from "../../img/placeholder-image_restricted.svg"
import {useSelector} from 'react-redux'
import {ItemPlayLink} from "../addons/ItemPlayLink";


/**
 *
 * @param i
 * @param index
 * @param section
 * @param item
 * @param epg
 * @param channels
 * @param adultPinStampValid
 * @param profileAge
 * @param proportion
 * @param template
 * @param bookmark
 * @returns {JSX.Element}
 * @constructor
 */
const SmartItem = ({
                       i,
                       index,
                       section,
                       item,
                       epg,
                       channels,
                       adultPinStampValid,
                       profileAge,
                       proportion,
                       template,
                       bookmark
}) => {
// console.log('SmartItem:', i, index, section, item, epg, channels, proportion, template, bookmark)
// console.log('smartItem', i, index, section, bookmark)
//     return <div>
//         {i}: {index}
//     </div>

    const intlObjDateTime = useSelector((state) => state.auth.intlObjDateTime)
    if (!intlObjDateTime) {
        return null
    }

    const nowStamp = Date.now() / 1000 | 0
    const nowDayStamp = ~~(nowStamp / 86400)
    let image = ""
    let imgKey = 'screenshots'
    let defaultImgUsed = false

    let defaultImg = PlaceholderScreenshot
    let restrictedImg = RestrictedScreenshot
    if (proportion === '2_3') {
        imgKey = 'posters'
        defaultImg = PlaceholderPoster
        restrictedImg = RestrictedPoster
    }

    if (item && item['metadata']) {
        if (item['metadata'] && typeof item['metadata'][imgKey] !== 'undefined') {
            if (item['metadata'][imgKey][0]) {
                image = item['metadata'][imgKey][0]
            } else {
                image = defaultImg
                defaultImgUsed = true
            }
        } else {
            if (typeof item['channel_id'] !== 'undefined') {
                if (channels['maps'] && typeof channels['maps']['id'][item['channel_id']] !== 'undefined') {
                    image = channels['items'][channels['maps']['id'][item['channel_id']]][imgKey][0]
                }
            } else {
                image = defaultImg
                defaultImgUsed = true
            }
        }
    } else {
        if (item && typeof item[imgKey] !== 'undefined') {
            image = item[imgKey][0]
        } else if (item && proportion === '16_9' && typeof item['screenshot'] !== 'undefined') {
            image = item['screenshot']
        } else {
            image = defaultImg
            defaultImgUsed = true
        }
    }

    if (i === 'vod') {
        return (
            <HomeItemSmart {...getVodState({
                item,
                adultPinStampValid,
                profileAge,
                image,
                section,
                bookmark,
                index,
                proportion,
                i,
                template,
                restrictedImg,
                defaultImgUsed,
            })} />
        )
    } else if (i === 'live') {
        let nowEpg = null
        const channelDayEpgs = epg[item['id']]['items'][nowDayStamp]
        if (channelDayEpgs) {
            for (const element of channelDayEpgs) {
                if (nowStamp >= element.start && nowStamp < element.stop) {
                    nowEpg = element
                    break;
                }
            }
        }
// channel, epg, image, imgKey, proportion, template
        return (
            <HomeItemSmart {...getLiveState({
                item,
                adultPinStampValid,
                profileAge,
                nowEpg,
                image,
                restrictedImg,
                section,
                imgKey,
                proportion,
                template,
                intlObjDateTime
            })} />
        )
    } else if (i === 'catchup') {
        if (!channels || !channels['maps'] || !channels['maps']['id']) {
            console.log('no channels')
            return null
        }

        const channel = channels['items'][channels['maps']['id'][item['channel_id']]]
        if (!channel) {
            console.log('no catchup channel', section, item)
            return null
        }

        // eslint-disable-next-line no-use-before-define
        const bookmarkStamp = bookmark ? bookmark['bookmark'] : null
        return (
            <HomeItemSmart {...getCatchupState({
                channel,
                adultPinStampValid,
                profileAge,
                item,
                image,
                restrictedImg,
                index,
                section,
                bookmarkStamp,
                proportion,
                template,
                intlObjDateTime
            })} />
        )
    } else if (i === 'group') {
        return (
            <HomeItemSmart {...getVodState({
                item,
                adultPinStampValid,
                profileAge,
                image,
                restrictedImg,
                section,
                bookmark,
                index,
                proportion,
                i,
                template,
                defaultImgUsed
            })} />
        )
    } else if (i === 'product') {
// console.log('product item', template, item)
        const productLogo = (template !== "channel" ?
                <div key={item.id + "_logo"} className="product-logo">
                    {
                        item.subscribed ?
                        <FormattedMessage id="product_group_already_purchased"/>
                        :
                        item.price
                    }
                </div>
                :
                null
        )

        const productLabel =
            <div key={item.id + '_title'} className={"product-label text-center"}>
                {item.name ? item.name : item.title}
            </div>

        return (
            <>
            {productLogo}
            <HomeItemSmart {...getProductState({
                item,
                image,
                restrictedImg,
                section,
                bookmark,
                index,
                proportion,
                i,
                template,
                epg,
                defaultImgUsed,
                productLabel
            })} />
            </>
        )
    } else {
        console.log('new i:', i, item, image, section, bookmark)
    }

    return null
}

const getProductState = ({item, image, template, proportion, epg, defaultImgUsed, productLabel}) => {
    return {
        id: item.id,
        type: 'product',
        image: image,
        logo: null,
        hasProgress: false,
        hasTitle: false,
        title: item.metadata ? item.metadata.title : '',
        label: "Product",
        ageRating: item.metadata && item.metadata['age_rating'] ? item.metadata['age_rating'] + '+' : '',
        time: null,
        playLink: (typeof item.purchase_options === 'undefined' && epg) ? '#' : '/shop/' + template + '/' + item.id,
        template: template,
        proportion: proportion,
        defaultImgUsed: defaultImgUsed,
        validUntil: item.valid_until,
        productLabel: productLabel
    }
}

const formatSeriesEpisode = (metadata) => {
    const {season_no, episode_no} = metadata
    if (episode_no) {
        if (!season_no) {
            return "E" + episode_no
        } else {
            return "S" + season_no + " E" + episode_no
        }
    }

    return null
}


/**
 *
 * @param item
 * @param adultPinStampValid
 * @param image
 * @param section
 * @param bookmark
 * @param index
 * @param proportion
 * @param i
 * @param template
 * @param defaultImg
 * @param defaultImgUsed
 * @returns {{image, onHoverText: JSX.Element, episode: (string|null), label: string, ageRating: string, type: string, title: string, hasProgress: boolean, genres: (*|string), logo: null, playLink: string, id, hasTitle: boolean, time: null, released: string}|null}
 */
const getVodState = ({item, adultPinStampValid, profileAge, image, section, bookmark, index, proportion, i, template, restrictedImg, defaultImgUsed}) => {
// console.log('getVodState item:', item)
    let progress = 0
    let hasProgress = false
    if (bookmark && bookmark['bookmark']) {
        progress = (bookmark['bookmark'] / item.duration * 100).toFixed(2)
        hasProgress = true
    }
    // let playLink = '/vod-about?vod_id=' + item.id + '&section=' + section + '&index=' + index
    // if (section === '5') {
    //     playLink = '/play?type=vod&vod_id=' + item.id + '&section=' + section
    // } else if (i === 'group') {
    //     playLink = '/group?id=' + item.id + '&section=' + section + '&index=' + index + '&template=' + template
    // }

    const playLink = ItemPlayLink({
        itemId: item.id,
        section: section,
        index: index,
        template: template,
        i: i
    })

    let adultBlur = false
    if (item.metadata && item.metadata['age_rating']) {
        if (
            (profileAge && profileAge < 18 && item.metadata['age_rating'] > profileAge) ||
            (item.metadata['age_rating'] > 18 && !adultPinStampValid)
        ) {
            adultBlur = true
        }
    }

    return {
        id: item.id,
        type: 'vod',
        adultBlur: adultBlur,
        image: adultBlur ? restrictedImg : image,
        logo: item.logo,
        hasProgress: hasProgress,
        hasTitle: true,
        onHoverText: <FormattedMessage id="vod_event_action_watch"/>,
        section: section,
        title: item.metadata ? item.metadata.title : '',
        progress: progress,
        label: "VOD",
        released: item.metadata && item.metadata.released ? item.metadata.released : '',
        ageRating: item.metadata && item.metadata['age_rating'] ? item.metadata['age_rating'] + '+' : '',
        genres: item.metadata && item.metadata['tva_contents'] ? item.metadata['tva_contents'].join(', ') : '',
        description: item.metadata ? item.metadata.description : '',
        imdb: item.metadata.imdb && item.metadata.imdb ? item.metadata.imdb['rating'].toFixed(1) : null,
        duration: item.duration,
        time: null,
        episode: item.metadata ? formatSeriesEpisode(item.metadata) : null,
        playLink: playLink,
        proportion: proportion,
        defaultImgUsed: defaultImgUsed,
        template: template
    }
}


/**
 * @param channel
 * @param epg
 * @param image
 * @param index
 * @param section
 * @param bookmarkStamp
 * @param proportion
 * @param template
 * @returns Object
 */
const getCatchupState = ({ channel, adultPinStampValid, profileAge,
                             item,
                             image, restrictedImg,
                             index,
                             section,
                             bookmarkStamp,
                             proportion,
                             template,
                             intlObjDateTime
                         }) => {
    const nowStamp = Date.now() / 1000 | 0
    const epgStartDate = item ? new Date(item.start * 1000) : null
    const progress = getEpgProgress(item.start, item.stop, bookmarkStamp ? bookmarkStamp : nowStamp)
    let adultBlur = false
    if (channel['age_rating']) {
        if (
            (profileAge && profileAge < 18 && channel['age_rating'] > profileAge) ||
            (channel['age_rating'] > 18 && !adultPinStampValid)
        ) {
           adultBlur = true
        }
    }

    return {
        id: item.id,
        type: 'catchup',
        logo: channel.logo,
        hasProgress: true,
        hasTitle: true,
        adultBlur: adultBlur,
        image: adultBlur ? restrictedImg : image,
        onHoverText: <FormattedMessage id="playable_item_group_title_continue"/>,
        section: section,
        title: item ? item.metadata.title : channel.name,
        progress: item ? progress : 0,
        label: "Catchup",
        datetime: epgStartDate ? intlObjDateTime.format(epgStartDate) : null,
        episode: item ? (item.metadata ? formatSeriesEpisode(item.metadata) : null) : null,
        playLink: '/play?type=' + channel['channel_type'] + '&channel_id=' + channel.id + '&epg_id=' + (item ? item.id : '') + '&index=' + index + '&section=' + section,
        genres: item.metadata && item.metadata['tva_contents'] ? item.metadata['tva_contents'].join(', ') : '',
        description: item.metadata ? item.metadata.description : '',
        imdb: item.metadata.imdb && item.metadata.imdb ? item.metadata.imdb['rating'].toFixed(1) : null,
        duration: item.duration,
        reminder: !progress,
        proportion: proportion,
        template: template
    }
}


/**
 * @param channel
 * @param image
 * @param imgKey
 * @param proportion
 * @param template
 * @returns Object
 */
const getLiveState = ({
                          item, adultPinStampValid, profileAge,
                          nowEpg,
                          image, restrictedImg,
                          imgKey,
                          proportion,
                          template,
                          section,
                          intlObjDateTime
                      }) => {
    const nowStamp = Date.now() / 1000 | 0
    const epgStartDate = nowEpg ? new Date(nowEpg.start * 1000) : null
    let adultBlur = false
    let outputImg = image

    if (item.ageRating) {
        if (
            (profileAge && profileAge < 18 && item.ageRating > profileAge) ||
            (item.ageRating > 18 && !adultPinStampValid)
        ) {
            adultBlur = true
        }
    } else if (nowEpg && nowEpg.metadata[imgKey]) {
        outputImg = nowEpg.metadata[imgKey][0]
    }

    return {
        id: item.id,
        type: 'live',
        image: outputImg,
        logo: item.logo,
        hasProgress: true,
        hasTitle: true,
        adultBlur: adultBlur,
        onHoverText: <FormattedMessage id="action_watch"/>,
        section: section,
        title: nowEpg ? nowEpg.metadata.title : item.name,
        progress: nowEpg ? getEpgProgress(nowEpg.start, nowEpg.stop, nowStamp) : 0,
        label: "Live",
        genres: item.metadata && item.metadata['tva_contents'] ? item.metadata['tva_contents'].join(', ') : '',
        description: item.metadata ? item.metadata.description : '',
        imdb: item.metadata && item.metadata.imdb && item.metadata.imdb ? item.metadata.imdb['rating'].toFixed(1) : null,
        duration: item.duration,
        datetime: epgStartDate ? intlObjDateTime.format(epgStartDate) : null,
        episode: nowEpg ? (nowEpg.metadata ? formatSeriesEpisode(nowEpg.metadata) : null) : null,
        playLink: '/play?type=' + item['channel_type'] + '&channel_id=' + item['id'] + '&epg_id=' + (nowEpg ? nowEpg.id : ''),
        proportion: proportion,
        template: template
    }
}

export default SmartItem