import React from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {FormattedMessage} from "react-intl"

const ToastNotification = ({ title, body }) => {
  let hideNotif = title === "";
  if (!hideNotif) {
    toast.info(<Display />);
  }

  function Display() {
    return (
      <div>
        <h4><FormattedMessage id={title}/></h4>
        <p style={{color:"unset"}}>{body}</p>
      </div>
    );
  }

  return (
    <ToastContainer
      autoClose={10000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={false}
    />
  );
};

ToastNotification.defaultProps = {
  title: "...",
  body: "...",
};

ToastNotification.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ToastNotification;
