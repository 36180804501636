import React from 'react';
import LeftArrow from 'img/carousel-left-icon.svg';
import RightArrow from 'img/carousel-right-icon.svg';

export const ButtonGroup = ({
    next,
    previous,
    carouselState,
    sectionTemplate,
}) => {
    const { totalItems, currentSlide, slidesToShow } = carouselState;
    return (
        <div className={'carousel-button-group ' + sectionTemplate}>
            <button
                className={
                    currentSlide === 0
                        ? 'disable'
                        : 'carousel-button carousel-button-left'
                }
                onClick={previous}
            >
                <img src={LeftArrow} alt="" />
            </button>

            <button
                className={
                    currentSlide + slidesToShow >= totalItems
                        ? 'disable'
                        : 'carousel-button carousel-button-right'
                }
                onClick={next}
            >
                <img src={RightArrow} alt="" />
            </button>
        </div>
    );
};

export const CrewButtonGroup = ({ next, previous, carouselState }) => {
    const { totalItems, currentSlide, slidesToShow } = carouselState;
    return (
        <div className="carousel-button-group">
            <button
                className={
                    currentSlide === 0
                        ? 'disable'
                        : 'carousel-button carousel-button-left'
                }
                onClick={previous}
            >
                <span className="carousel-control-prev-icon" />
            </button>

            <button
                className={
                    currentSlide + slidesToShow >= totalItems
                        ? 'disable'
                        : 'carousel-button carousel-button-right'
                }
                onClick={next}
            >
                <span className="carousel-control-next-icon" />
            </button>
        </div>
    );
};

export const responsive_big = {
    desktop_big: {
        breakpoint: { max: 3000, min: 1600 },
        items: 4,
        slidesToSlide: 4,
        // partialVisibilityGutter: 20
    },
    desktop_medium: {
        breakpoint: { max: 1600, min: 1366 },
        items: 3,
        slidesToSlide: 3,
        // partialVisibilityGutter: 30
    },
    desktop_small: {
        breakpoint: { max: 1366, min: 1024 },
        items: 2,
        slidesToSlide: 2,
        // partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

export const responsive_medium = {
    desktop_big: {
        breakpoint: { max: 3000, min: 1600 },
        items: 5,
        slidesToSlide: 5,
        // partialVisibilityGutter: 10
    },
    desktop_medium: {
        breakpoint: { max: 1600, min: 1366 },
        items: 4,
        slidesToSlide: 4,
        // partialVisibilityGutter: 20
    },
    desktop_small: {
        breakpoint: { max: 1366, min: 1024 },
        items: 3,
        slidesToSlide: 3,
        // partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
};

export const responsive_small = {
    desktop_big: {
        breakpoint: { max: 3000, min: 1600 },
        items: 6,
        slidesToSlide: 6,
        // partialVisibilityGutter: 10
    },
    desktop_medium: {
        breakpoint: { max: 1600, min: 1366 },
        items: 5,
        slidesToSlide: 5,
        // partialVisibilityGutter: 20
    },
    desktop_small: {
        breakpoint: { max: 1366, min: 1024 },
        items: 4,
        slidesToSlide: 4,
        // partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 2,
    },
};

export const responsive_screen_wide = {
    desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 0,
    },
};

export const responsive_crew = {
    desktop: {
        breakpoint: { max: 3000, min: 1801 },
        items: 12,
        slidesToSlide: 4,
    },
};

export const responsive_products = {
    desktop: {
        breakpoint: { max: 3000, min: 800 },
        items: 3,
        slidesToSlide: 1,
        // partialVisibilityGutter: 10
    },
};
