import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from "react-intl"

    const Genres = ({items, loading, selected, channelGenres}) => {
        const DataArrived = () => {
            const activeAll = (!selected || selected === "all") ? " active" : ""
            let list = []
            for (const genre of items) {
                if (typeof channelGenres[genre.id] === 'undefined') {
                    continue
                }
                const activeClass = parseInt(selected) === genre.id ? " active" : ""
                list.push(
                    <li key={genre.id}>
                        <NavLink className={"button btn-secondary d-flex" + activeClass} to={'/tv/' + genre.id}>
                            {genre.name}
                        </NavLink>
                    </li>
                )
            }

            return (
                <ul className="genres-list">
                    <li key="all">
                        <NavLink className={"button btn-secondary d-flex" + activeAll} to={'/tv'} activeClassName="">
                            <FormattedMessage id="action_all" />
                        </NavLink>
                    </li>
                    {list}
                </ul>
            )
        }

        return (
            (!items || !channelGenres || loading === true) ? null : <DataArrived />
        )

      }
      
      export default Genres
