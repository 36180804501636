import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CrewButtonGroup, responsive_crew } from './CarouselComponents'
import ActorPlaceholder from "../../img/actor-placeholder.svg";

const VodAboutSection = props => {
    return (
        <div className="crew-slider">
            <Carousel
                responsive={responsive_crew}
                draggable={false}
                // ssr={true}
                customTransition={"700ms"}
                // containerClass="carousel-container"
                itemClass="crew-carousel"
                partialVisible={true}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<CrewButtonGroup />}
            >

                {props.section.map((actor, index) => {
                    const picture = actor.picture ? actor.picture : ActorPlaceholder
                    return <div className={"actor-container"} key={"actor_" + index}><img src={picture} alt='' />{actor.name}</div>
                })}

            </Carousel>
        </div>
    )
}

export default VodAboutSection