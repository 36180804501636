import React, { Component } from 'react'
import {CarouselSection} from '../elements/CarouselSection'
// import Spinner from '../../img/spinner.svg'
import { connect } from 'react-redux'
import { fetchAction } from '../../store/actions/fetchActions'
import {
    bookmarksEndpoint,
    channelsEndpoint,
    epggridEndpoint,
    smartRowsEndpoint
} from "../../store/actions/apiEndpoints";
import {EpgToChannelDays, BookmarksMap} from "../../store/actions/ApiKeysMap";
import {setAppClass} from "../../store/actions/globalStylesActions";

class HomeProfiled extends Component {

    fetchStamps = {}
    smartFetchStamps = {}
    smartRenderStamps = {}
    renderStamp = 0
    // arrivedStamps = {}
    liveRenderInterval = null
    smartRowsArrived = false
    epgArrived = false
    carouselItems = []
    carouselProportions = []
    carouselHeights = []
    // basicPayload = ['home', 'bookmarks', 'channels']
    basicPayload = ['home', 'channels']
    rowsWithEpg = ['home.on-tv-now', 'home.most-popular-on-tv', 'home.live']
    loading = true
    adultPinStampValid = false

    componentDidMount() {
// console.log('home profiled mount', this.props.auth)
        const nowStamp = Date.now() / 1000 | 0
        if (this.props.global_styles.adult_pin_stamp && (this.props.global_styles.adult_pin_stamp + 180 > nowStamp)) {
            this.adultPinStampValid = true
        }
        this.props.setAppClass('home')
        if (this.props.api_data.payload['home'] && this.props.api_data.payload['home']['items']['items'].length) {
            this.forceUpdate()
        }
        clearInterval(this.liveRenderInterval)
        //-- todo pamažinti intervalą iki 5 minučių
        this.liveRenderInterval = setInterval(() => {
            this.forceUpdate()
        }, 6000000)
    }

    componentWillUnmount() {
        clearInterval(this.liveRenderInterval)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
// console.log('componentDidUpdate props', this.props)
// console.log('componentDidUpdate fetchStamps', Object.keys(this.fetchStamps))
        // jei pasikeičia profilis kraunam home
        const nowStamp = Date.now() / 1000 | 0
        if (this.props.auth.profile
            && !this.fetchStamps['home']
            && (!this.props.api_data.payload['home'] || this.props.auth.profile_stamp > this.props.api_data.payload['home']['version'])
        ) {
            this.fetchStamps['home'] = nowStamp
            this.props.fetchAction(
                this.props.auth.token,
                smartRowsEndpoint({
                    'id': 'home',
                    'lang': this.props.auth.lang,
                    'filter_profile': this.props.auth.profile.id
                }),
                this.constructor.name,
                'home'
            )
        } else if (this.props.api_data.payload['home'] && this.props.api_data.payload['home']['version']) {
            this.fetchStamps['home'] = this.props.api_data.payload['home']['version']
        }

        // jei pasikeičia profilis kraunam channels ir product_groups
        if (this.props.auth.profile
            && !this.fetchStamps['channels']
            && (
                !this.props.api_data.payload['channels'] ||
                this.props.auth.profile_stamp > this.props.api_data.payload['channels']['version']
            )
        ) {
            this.fetchStamps['channels'] = nowStamp
            this.props.fetchAction(
                this.props.auth.token,
                channelsEndpoint(this.props.auth.lang),
                this.constructor.name,
                null,
                null,
                ['id', 'lcn']
            )
        } else if (this.props.api_data.payload['channels'] && this.props.api_data.payload['channels']['version']) {
            this.fetchStamps['channels'] = this.props.api_data.payload['channels']['version']
        }

        // jei pasikeičia profilis kraunam bukmarkus
        if (this.props.auth.profile
            // && !this.props.userinfo['is_demo']
            && (!this.fetchStamps['bookmarks'] || this.props.auth.profile_stamp > this.fetchStamps['bookmarks'])
            // && (!this.props.api_data.payload['bookmarks'] || this.props.auth.profile_stamp > this.props.api_data.payload['bookmarks']['version'])
        ) {
            this.fetchStamps['bookmarks'] = nowStamp
            this.props.fetchAction(
                this.props.auth.token,
                bookmarksEndpoint(
                    {
                        'lang': this.props.auth.lang,
                        'filter_profile': this.props.auth.profile.id
                    }),
                this.constructor.name,
                BookmarksMap
            )
        }

        // jei pasikeičia profilis ir turim gavę naujus channels, kraunam epg
        if (this.props.api_data.payload['channels']
            && this.props.api_data.payload['channels']['maps']
            && this.props.api_data.payload['channels']['maps']['id']
            && this.props.auth.profile
            && !this.fetchStamps['epg']
            && (
                !this.props.api_data.payload['epg'] ||
                this.props.auth.profile_stamp > this.props.api_data.payload['epg']['version']
            )
        ) {
// console.log('fetch epg grid', this.props.api_data.payload['channels']['maps'])
            this.fetchStamps['epg'] = nowStamp
            this.props.fetchAction(
                this.props.auth.token,
                epggridEndpoint({
                    channels : Object.keys(this.props.api_data.payload['channels']['maps']['id']),
                    pastDays : 0,
                    futureDays : 1,
                    lang : this.props.auth.lang
                }),
                this.constructor.name,
                EpgToChannelDays
            )
        } else if (this.props.api_data.payload['epg'] && this.props.api_data.payload['epg']['version']) {
            this.fetchStamps['epg'] = this.props.api_data.payload['epg']['version']
        }
    }

    shouldComponentUpdate = (nextProps) => {
// console.log('shouldComponentUpdate?', this.props.global_styles.app_class, this.props.appElRef.current.className)
        if (this.props.appElRef.current.className !== 'App home') {
            this.props.appElRef.current.className = 'App home'
        }

        const nowStamp = Date.now() / 1000 | 0

        if (!this.fetchStamps || !Object.keys(this.fetchStamps).length) {
            return true
        }

// console.log('group length?', this.basicPayload)
        if (this.fetchStamps && Object.keys(this.fetchStamps).length !== 0) {
            for (const i of this.basicPayload) {
                if (!this.props.api_data.payload[i]) {
                    return false
                }
            }

            //-- jei gautas home ir jis turi itemų, patikrinam ar tie itemai gauti
            if (this.props.api_data.payload['home'] && this.props.api_data.payload['home']['items']['items'].length) {
                this.loading = false
                this.smartRowsArrived = true
                for (const item of this.props.api_data.payload['home']['items']['items']) {
                    const { group } = item
// console.log('group item in payload?', item)
                    if (group && !this.props.api_data.payload[group['id']]) {
// console.log('still loading', group['id'])
                        this.loading = true
                        //-- nereikia laukti tų smartrowsų, kuriems reikia EPG
                        if (!this.rowsWithEpg.includes(group['id']) && !this.props.api_data.payload['epg']) {
                            this.smartRowsArrived = true
                        }
                        if (!this.fetchStamps[group['id']] || nowStamp > (this.fetchStamps[group['id']] + 15)) {
                            this.fetchStamps[group['id']] = nowStamp
                            // token, endpoint, source, subkey, params, mapping
                            this.props.fetchAction(
                                this.props.auth.token,
                                smartRowsEndpoint({
                                    'id': group['id'],
                                    'lang': this.props.auth.lang,
                                    'filter_profile': this.props.auth.profile.id,
                                    'page_limit': null
                                }),
                                this.constructor.name,
                                group['id']
                            )
                        }
                    }
                }

                // if (!this.loading && this.smartRowsArrived && this.carouselItems.length) {
                //     for (const i in this.carouselItems) {
                //         if (!this.carouselItems[i]) {
                //             continue
                //         }
                //         let imageHeight = 0
                //         if (this.carouselProportions[i] === '16_9') {
                //             imageHeight = (this.carouselItems[i]['state']['itemWidth'] * 9 / 16 | 0) - 7
                //         } else {
                //             imageHeight = (this.carouselItems[i]['state']['itemWidth'] * 3 / 2 | 0) - 13
                //         }
                //         if (this.carouselItems[i]['listRef']['current'] && this.carouselHeights[i] !== imageHeight) {
                //             this.carouselItems[i]['listRef']['current'].style.minHeight = imageHeight + 'px'
                //             this.carouselHeights[i] = imageHeight
                //         }
                //     }
                // }
                if (!this.loading && this.smartRowsArrived && (!this.renderStamp || nowStamp > this.renderStamp + 15)) {
                    return true
                }
            } else {
// console.log('return: false, nes net neturim payload home')
                return false
            }
        }

        //-- jei dar nerenderinti rowsai, kuriems reikalingi epg, ir jau turim epg, perrendinam
        for (const i of this.rowsWithEpg) {
            if (!this.smartRenderStamps[i]) {
// console.log(i, 'not in smartRenderStamps...')
                if (this.props.api_data.payload['epg']) {
                    return true
                }
            }
        }

// console.log('return: daėjo iki false')
        return false
    }

    CarouselHandler = (el, proportion) => {
        this.carouselItems.push(el)
        this.carouselProportions.push(proportion)
        this.carouselHeights.push(0)
    }

    render() {
        const nowStamp = Date.now() / 1000 | 0
        if (this.smartRowsArrived) {
            this.renderStamp = nowStamp
        }

        const DataLoading = () => {
            return (
                <div className="loader">
                    {/*<img className="loader-img" src={Spinner} alt="" />*/}
                    <span className="loader-img"/>
                </div>
            )
        }

        const DataArrived = () => {
// console.log('DataArrived!', this.props.api_data.payload.bookmarks)
            this.carouselItems = []
            this.carouselHeights = []
            return (
                this.props.api_data.payload.home.items.items.map((section, index) => {
                    //-- jei neturim epg, nerodom sekcijų, kurioms jis reikalingas
                    if(
                        (
                            (
                                section.group.id === 'home.on-tv-now' ||
                                section.group.id === 'home.most-popular-on-tv' ||
                                section.group.id === 'home.live'
                            )
                            && !this.props.api_data.payload['epg']
                        )
                        || !this.props.api_data.payload[section.group.id]
                        || !this.props.api_data.payload[section.group.id]['total']
                        || !this.props.api_data.payload[section.group.id]['total']['items']
                    )
                    {
                        return null
                    } else {
                        this.smartRenderStamps[section.group.id] = nowStamp
                        return (
                            <CarouselSection
                                key={"section_" + index}
                                sectionIndex={index}
                                sectionId={section.group.id}
                                sectionItem={section.group}
                                items={this.props.api_data.payload[section.group.id].items.items}
                                channels={this.props.api_data.payload.channels}
                                lang={this.props.lang}
                                epg={this.props.api_data.payload.epg}
                                bookmarks={this.props.api_data.payload.bookmarks}
                                profileAge={this.props.auth.profile.age}
                                adultPinStampValid={this.adultPinStampValid}
                                CarouselHandler={this.CarouselHandler}
                            />
                        )
                    }
                })
            )
        }

        return (
            <div className="section">
                {this.loading ?
                    <div>
                        <DataLoading />
                    </div>
                    :
                    <DataArrived />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api_data: state.api_data,
        userinfo: state.api_data.payload['userinfo'],
        auth: state.auth,
        global_styles: state.global_styles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeProfiled)
