import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setModalState} from '../../store/actions/modalStateActions'
import {FormattedMessage} from "react-intl";
import {createProfile} from '../../store/actions/profilesActions'
import config from '../../config/config'
import AvatarList from '../elements/AvatarList'
import {avatarsEndpoint} from '../../store/actions/apiEndpoints';
import {fetchAction} from '../../store/actions/fetchActions';
import ProfilesList from '../elements/ProfilesList';
import ProfilesIcon from '../../img/profiles-icon.svg';
import SettingsList from '../elements/SettingsList';
import {pinEnterSuccess} from '../../store/actions/authActions';


class ProfileForm extends Component {

    state = {
        "id": "",
        "name": "",
        "age": "",
        "pin": "",
        "needs_pin_to_login": false,
        "avatar_id": 16,
        "type": "add",
    }

    ageRatingUnrestricted = 0
    ageRatingChildren = 7
    ageRatingTeenagers = 14
    ageRatingAdults = 18

    componentDidMount() {
        this.props.fetchAction(this.props.auth.token, avatarsEndpoint(), this.constructor.name)
        // console.log('Profile Form componentDidMount', this.props)
        // this.setState(this.props.profile)
        if (this.props.profile) {
            this.setState({
                "id": this.props.profile.id,
                "name": this.props.profile.name,
                "age": this.props.profile.age,
                "pin": this.props.profile.pin,
                "needs_pin_to_login": this.props.profile.needs_pin_to_login,
                "avatar_id": this.props.profile.avatar.id,
                "type": "edit",
            })
        }
    }

    updateProfile = () => {
        if (!this.props.auth.profile.master_flag) {
            fetch(config['apiURI'] + "/customer/profile/" + this.state.id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + this.props.auth.token,
                },
            }).then(response => response.json())
                .then(response => {
                    return this.props.pinEnterSuccess(response, true);
                });
        }
    }

    setActiveAvatar = (avatarID) => {
        this.setState({
            ...this.state,
            "avatar_id": avatarID,
        })
    }

    handleChange = e => {
        let value = e.target.value
        // if (value === 'on') {
        //     value = true
        // } else if (value === 'off') {
        //     value = false
        // }
        const name = e.target.name;
// console.log('handleChange', name, value)
        if (name === 'pin') {
            if (value.length > 4) {
                value = value.substring(0, 4)
            }
        }
        this.setState({
            [name]: value
        })
    }

    handleSwitch = () => {
        this.setState(prevState => {
            return {
                ...this.state,
                needs_pin_to_login: !prevState.needs_pin_to_login,
            }
        })
    }

    handleAgeChange = e => {
        const name = e.target.name;
        const value = e.target.value;

        let age = 0
        if (value === 'infants')
            age = this.ageRatingChildren - 1
        else if (value === 'children')
            age = this.ageRatingTeenagers - 1
        else if (value === 'teenagers')
            age = this.ageRatingAdults - 1
        else if (value === 'all')
            age = this.ageRatingUnrestricted

        this.setState({
            [name]: age
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // console.log("avatar id submitted: ", this.state.avatar_id)

        let encodedData = [];
        let url = this.state.type === "edit" ? config['apiURI'] + "/customer/profile/" + this.state.id : config['apiURI'] + "/customer/profile";

        let myData = {
            "name": this.state.name,
            "age": this.state.age,
            "pin": this.state.pin,
            "avatar_id": this.state.avatar_id,
            "needs_pin_to_login": this.state.needs_pin_to_login,
            "can_buy": true,
        }

        for (let key in myData) {
            if (myData.hasOwnProperty(key)) {
                encodedData.push(key + '=' + encodeURIComponent(myData[key]));
            }
        }
        encodedData = encodedData.join('&');

        // console.log("encoded data", encodedData)

        fetch(url, {
            method: this.state.type === "edit" ? 'PUT' : 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.props.auth.token,
                'Content-Type': "application/x-www-form-urlencoded",
            },
            body: encodedData
        }).then((response) => {
            // console.log(response)
            this.updateProfile()
            return (
                this.props.auth.profile.master_flag ?
                    this.props.setModalState('profiles-list', <FormattedMessage id="profile_manage_title"/>,
                        <ProfilesList/>, 'SettingsList', <ProfilesIcon/>) :
                    this.props.setModalState('settings-list', null, <SettingsList/>)
            )
        });
        // this.props.createProfile(this.state)
    }

    render() {

        const radioButtons = (
            <div className='form-group age-group'>
                <h5><FormattedMessage id="profile_age_input_hint"/></h5>

                <label className='container'>
                    <input className='checkbox' type='radio' value='infants' name='age' onChange={this.handleAgeChange}
                           checked={this.state.age === 6}/>
                    <span className='checkmark'/>
                    <FormattedMessage id="age_choice_infants"/>
                </label>
                <hr/>

                <label className='container'>
                    <input className='checkbox' type='radio' value='children' name='age' onChange={this.handleAgeChange}
                           checked={this.state.age === 13}/>
                    <span className='checkmark'/>
                    <FormattedMessage id="age_choice_children"/>
                </label>
                <hr/>

                <label className='container'>
                    <input className='checkbox' type='radio' value='teenagers' name='age'
                           onChange={this.handleAgeChange} checked={this.state.age === 17}/>
                    <span className='checkmark'/>
                    <FormattedMessage id="age_choice_teenagers"/>
                </label>
                <hr/>

                <label className="container">
                    <input className="checkbox" type="radio" value="all" name="age" onChange={this.handleAgeChange}
                           checked={this.state.age === 0}/>
                    <span className="checkmark"/>
                    <FormattedMessage id="age_choice_allow_all"/>
                </label>
                <hr/>
            </div>
        )

        return (
            this.props.api_data.payload.avatars ? (
                <div className="">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <input type="hidden" id="id" value={this.state.id}/>
                            <label className="labels-text"><FormattedMessage id="profile_name_input_hint"/></label>
                            <input
                                name="name"
                                type="text" id="name"
                                className="form-control form-input-field"
                                onChange={this.handleChange}
                                value={this.state.name}
                                placeholder="Profile name"
                            />
                        </div>

                        <div className="form-group">
                            <label className="labels-text"><FormattedMessage id="profile_pin_input_hint"/></label>
                            <input
                                name="pin"
                                type="text"
                                id="pin"
                                className="form-control form-input-field"
                                onChange={this.handleChange}
                                value={this.state.pin}
                                placeholder="Pin"
                            />
                        </div>

                        <div className="form-group">
                            <div className="subtitle-disabled">
                                <label className="labels-text"><FormattedMessage id="profile_pin_flag_needs_to_login_review"/></label>
                                <div className="toggle">
                                    <label className="switch">
                                        <input
                                            name="needs_pin_to_login"
                                            type="checkbox"
                                            checked={this.state.needs_pin_to_login}
                                            onChange={this.handleSwitch}
                                        />
                                        <span className="slider round"/>
                                    </label>
                                </div>
                            </div>
                        </div>

                        {this.props.auth.profile.master_flag ? radioButtons : null}

                        <AvatarList avatars={this.props.api_data.payload.avatars.items}
                                    setActiveAvatar={this.setActiveAvatar} currentAvatar={this.state.avatar_id}/>
                        {/*{console.log("active avatar id: ", this.state.avatar_id)}*/}

                        <div className="form-group btn-div">
                            <div className="row confirm-cancel-row">
                                <div className="col pl-0 pr-3">
                                    <button type="button"
                                            className="btn btn-block btn-secondary watch-button confirm-cancel-button"
                                            data-bs-dismiss="modal"><FormattedMessage id="profile_button_cancel"/></button>
                                </div>
                                <div className="col pl-3 pr-0">
                                    <button type="submit"
                                            className="btn btn-block btn-primary watch-button confirm-cancel-button">
                                        <FormattedMessage id="profile_button_confirm"/></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            ) : null
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        api_data: state.api_data,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createProfile: (profile) => dispatch(createProfile(profile)),
        setModalState: (id, title, body, back_button) => {
            dispatch(setModalState(id, title, body, back_button))
        },
        fetchAction: (token, endpoint) => {
            dispatch(fetchAction(token, endpoint))
        },
        pinEnterSuccess: (profile, preserve_stamp) => dispatch(pinEnterSuccess(profile, preserve_stamp)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
