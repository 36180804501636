import config from '../../config/config'
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'
// export const SET_PLAYER_EPG = 'SET_PLAYER_EPG'

/**
 * @param token
 * @param endpoint
 * @param source
 * @param subkey
 * @param params
 * @param mapping
 * @returns {(function(...[*]=))|null}
 */
export const fetchAction = (token, endpoint, source, subkey, params, mapping) => {
    let serverURI = config['apiURI']
    if (!token) {
        serverURI = config['publicApiURI']
    }
// console.log('** fetchAction:', !token, serverURI, endpoint)
    return dispatch => {
        dispatch(fetchRequest(source))
        // customer/profiles
        let init = {
            // method: 'GET',
            // mode: 'cors',
            // credentials: 'same-origin',
        }
        if (token) {
            init['headers'] = {
                'Authorization' : 'Bearer ' + token
            }
        }

        fetch( serverURI+ "/" + endpoint, init)
            .then(response => response.json())
            .then(response => {
                // console.log('fetch response', response)
                if(response.error) {
                    throw(response.error);
                }
                dispatch(fetchSuccess(response, source, subkey, params, mapping));
                return response;
            })
            .catch(error => {
                dispatch(fetchFailure(error, source, subkey));
            })
    }
}

export const fetchRequest = (source) => {
    // console.log('** const fetchRequest', source)
    return {
        source: source,
        type: FETCH_REQUEST
    }
}

/**
 *
 * @param payload
 * @param source
 * @param subkey
 * @param params
 * @param mapping
 * @returns {{subkey, payload: {}, source, type: string, params}}
 */
export const fetchSuccess = (payload, source, subkey, params, mapping) => {
// console.log('** fetchSuccess', payload, source, subkey, params, mapping)
    let payloadOutput = {}
    let now = Date.now() / 1000 | 0
    // https://jsperf.com/alternative-isfunction-implementations/9
// console.log('typeof(subkey):', typeof(subkey))
    if (typeof(subkey) == 'function') {
        payloadOutput = subkey(payload, params)
    } else if (subkey) {
        for (let i in payload) {
            if (typeof payloadOutput[subkey] === 'undefined') {
                payloadOutput[subkey] = {}
            }
            payloadOutput[subkey][i] = {
                version: now,
                items: payload[i],
                maps: (Array.isArray(mapping) && Array.isArray(payload[i])) ? mapItems(mapping, payload[i]) : null
                // maps: typeof payload[i] === 'object' ? mapItems(mapping, payload[i]) : null
                // maps: mapItems(mapping, payload[i])
            }
        }
    } else {
        for (let i in payload) {
            payloadOutput[i] = {
                version: now,
                items: payload[i],
                maps: (Array.isArray(mapping) && Array.isArray(payload[i])) ? mapItems(mapping, payload[i]) : null
                // maps: typeof payload[i] === 'object' ? mapItems(mapping, payload[i]) : null
            }
        }
    }

    return {
        type: FETCH_SUCCESS,
        source: source,
        subkey: subkey,
        params: params,
        payload: payloadOutput
    }
}

//-- jei pageidauja, prideda masyvą(-us) mapping[i] : index
const mapItems = (mapping, items) => {
// console.log('mapItems', mapping, items)
    if (mapping) {
        let maps = {}

        items.map((item, index) => {
            for (const field of mapping) {
                if (typeof maps[field] === 'undefined') {
                    maps[field] = {}
                }
// console.log(field, item, item[field], typeof item[field])
                if (typeof item[field] !== 'undefined') {
                    maps[field][item[field]] = index
                } else if(typeof item === 'object') {
                    const key = Object.keys(item)[0]
                    maps[field][item[key][field]] = index
                }
            }
            return null
        })

        return maps
    }
    return null
}

export const fetchFailure = (error, source, subkey) => {
    // if (error === 'Unauthorized' || error.includes('NetworkError')) {
    // if (error === 'Unauthorized') {
    //     window.location.reload()
    // }
console.log('*** fetchFailure', typeof error, source, error)

    return {
        type: FETCH_FAILURE,
        source: source,
        subkey: subkey,
        payload: {}
    }
}
