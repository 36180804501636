import React from 'react'
import { connect } from 'react-redux'
import SmartItem from './SmartItem'
import {FormattedMessage} from "react-intl";

const ShowSimilar = props => {
// console.log('loading:', props.api_data.loading)
// console.log('search:', props.api_data.payload.search)
    const items = props.api_data.payload.search ? props.api_data.payload.search.items.items : null

    const NoData = () => {
        return (
            <div><FormattedMessage id="no_data" /></div>
        )
    }

    const DataLoading = () => {
        return (
            <div className="loader">
                <span className="loader-img"/>
            </div>
        )
    }

    const Data = () => {
        return (
            items.map((item, index) => {
                const key = Object.keys(item)[0]
                return (
                    <div key={index} className="search-img-wrapper fade-in">
                        <SmartItem
                            i={key}
                            index={index}
                            item={item[key]}
                            epg={props.api_data.payload.epg}
                            lang={props.auth.lang}
                            channels={props.api_data.payload.channels}
                            itemClass={"medium_16_9"}
                            proportion={"16_9"}
                            key={index}
                        />
                    </div>
                )
            })
        )
    }


    if (props.api_data.loading) {
        return <DataLoading/>
    } else if (items && items.length) {
        return <div className={"d-flex flex-wrap justify-content-start similar-container"}><Data /></div>
    } else {
        return <NoData />
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        api_data: state.api_data
    }
}

export default connect(mapStateToProps, null)(ShowSimilar)