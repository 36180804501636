import React from 'react'
import {FormattedMessage} from "react-intl";
import InfoIcon from "../../img/info-icon.svg";

const PlaceholderItem = ({id, text, chids, conditions, handleModal}) => (
    (text !== null && typeof text !== 'undefined') ?
    <div>
        <hr />
        <div className={"d-flex align-items-center"} style={{marginBottom: '3px'}}>
            <h3 className={"flex-grow-1"}>{text}</h3>
            {
                (chids || conditions) ?
                    <img
                        className={"flex-shrink-0 cursor-pointer"} src={InfoIcon} alt=""
                        data-product-id = {id}
                        data-modal-type ={chids ? "channels" : "conditions"}
                        onClick={(event) => {
                            event.preventDefault();
                            handleModal(event);
                        }}
                    />
                    :
                    null
            }
        </div>
    </div>
        :
    null
)

const YourChoiceItem = ({
                         id,
                         discount_percentages,
                         name,
                         chids,
                         smartivus_id,
                         banner,
                         price,
                         handleModal,
                         discounted_price,
                         placeholder_text_1,
                         placeholder_text_2,
                         placeholder_text_3,

}) => (
        <div className="product-box choice">
            <div className={"d-flex" + (banner ? " justify-content-center" : "")}>
                <h3><FormattedMessage id={"sign_your_choice"}/></h3>
            </div>
            {banner ?
                <div className={"justify-content-center"}>
                    <img className={"img-fluid"} src={banner} alt={""}/>
                </div>
                :
                null
            }
            <div className={"d-flex justify-content-between product-box-head"}>
                <div className={"align-items-center"}>
                    <h1>{name}</h1>
                </div>
                <div>
                    <span className={"btn-primary" + discount_percentages ? " invisible" : ""}>{discount_percentages}</span>
                </div>
            </div>
            <div className={"product-box-price"}>
                <h2>{price}</h2><span>/<FormattedMessage id={"plan_monthly"}/></span>
                <div className={"discounted" + discounted_price ? " invisible" : ""}>{discounted_price}/<FormattedMessage id={"plan_monthly"}/></div>
            </div>
            {PlaceholderItem({ id: id, text: placeholder_text_1, chids: chids, handleModal})}
            {PlaceholderItem({ id: id, text: placeholder_text_2})}
            {PlaceholderItem({ id: id, text: placeholder_text_3})}
            {PlaceholderItem({ id: id, text: <FormattedMessage id={"sign_up_additional_conditions"}/>, conditions: true, handleModal})}
        </div>
)

export default YourChoiceItem