import React from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorIcon from 'img/error-icon.svg';
import Cities from 'components/addons/Cities';
import RegisterProgressBar from 'components/elements/RegisterProgressBar';

const RegisterAccountForm = ({
    step,
    handleStepBtn,
    handleInputFocus,
    formData,
    error,
    formDataErrors,
    formSupports,
    formSupportsErrors,
    loading,
}) => {
    //-- jei atsiuntė supporto laukelių, kurių nėra formoje, sudedam juos į error'ą
    const formFields = [
        'name',
        'lastname',
        'city',
        'password',
        'password_confirmation',
    ];
    const unsupportedFields = Object.keys(formSupports).filter(
        (key) => !formFields.includes(key)
    );
    if (unsupportedFields) {
        if (error === '*') {
            error = '';
        }
        for (const unsupportedField of unsupportedFields) {
            error += formSupports[unsupportedField] + ' ';
        }
    }

    const citiesToOptions = () => {
        const options = Cities.msap((selectItem, key) => {
            return (
                <option key={key} value={selectItem[0]}>
                    {selectItem[1]}
                </option>
            );
        });

        return (
            <select
                name="city"
                // onChange={e => handleChange(e)}
                defaultValue={formData['city'] ? formData['city'] : ''}
                className="form-control"
                id="inputCity"
                onFocus={handleInputFocus}
                onBlur={handleInputFocus}
            >
                {options}
            </select>
        );
    };

    //     const handleChange = () => {
    // console.log('change!')
    //     }

    return (
        <>
            <div className={'register-detail w-100'}>
                <RegisterProgressBar step={step} paddingBottom={'16px'} />
                <h1 style={{ marginBottom: '41px' }}>
                    <FormattedMessage id={'sign_up_account_form_header'} />
                </h1>

                <div className={'w-100'}>
                    {error && error !== '*' ? (
                        <div
                            className={'d-flex error-box'}
                            style={{ marginBottom: '30px' }}
                        >
                            <div className="flex-shrink-0 d-flex align-items-start">
                                <img
                                    src={ErrorIcon}
                                    alt=""
                                    style={{ paddingRight: '14px' }}
                                />
                            </div>
                            <div className={'w-100'}>{error}</div>
                        </div>
                    ) : null}

                    <form>
                        <div
                            className={
                                'd-flex input-box' +
                                (formDataErrors['name'] ? ' has-error' : '')
                            }
                        >
                            <div className="w-100">
                                <label
                                    className={'inside-label'}
                                    htmlFor="inputName"
                                >
                                    <FormattedMessage id={'register_name'} />
                                    <sup>*</sup>
                                </label>
                                <div className="">
                                    <input
                                        type={'text'}
                                        name={'name'}
                                        autoComplete={'off'}
                                        defaultValue={
                                            formData['name']
                                                ? formData['name']
                                                : ''
                                        }
                                        className="form-control"
                                        id="inputName"
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'supporting-text' +
                                (!formSupports['name'] ? ' invisible' : '') +
                                (formSupportsErrors['name'] ? ' has-error' : '')
                            }
                        >
                            {formSupports['name']
                                ? formSupports['name']
                                : '&nbsp;'}
                        </div>

                        <div
                            className={
                                'd-flex input-box' +
                                (formDataErrors['lastname'] ? ' has-error' : '')
                            }
                        >
                            <div className="w-100">
                                <label
                                    className={'inside-label'}
                                    htmlFor="inputLastname"
                                >
                                    <FormattedMessage id={'register_surname'} />
                                    <sup>*</sup>
                                </label>
                                <div className="">
                                    <input
                                        type={'text'}
                                        name={'lastname'}
                                        autoComplete={'off'}
                                        className="form-control"
                                        defaultValue={
                                            formData['lastname']
                                                ? formData['lastname']
                                                : ''
                                        }
                                        id="inputLastname"
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'supporting-text' +
                                (!formSupports['lastname']
                                    ? ' invisible'
                                    : '') +
                                (formSupportsErrors['lastname']
                                    ? ' has-error'
                                    : '')
                            }
                        >
                            {formSupports['lastname']
                                ? formSupports['lastname']
                                : '&nbsp;'}
                        </div>

                        <div
                            className={
                                'd-flex input-box' +
                                (formDataErrors['city'] ? ' has-error' : '')
                            }
                        >
                            <div className="w-100">
                                <label
                                    className={'inside-label'}
                                    htmlFor="inputCity"
                                >
                                    <FormattedMessage id={'register_city'} />
                                </label>
                                <div className="">{citiesToOptions()}</div>
                            </div>
                        </div>
                        <div
                            className={
                                'supporting-text' +
                                (!formSupports['city'] ? ' invisible' : '') +
                                (formSupportsErrors['city'] ? ' has-error' : '')
                            }
                        >
                            {formSupports['city']
                                ? formSupports['city']
                                : '&nbsp;'}
                        </div>

                        <div
                            className={
                                'd-flex input-box' +
                                (formDataErrors['password'] ? ' has-error' : '')
                            }
                        >
                            <div className="w-100">
                                <label
                                    className={'inside-label'}
                                    htmlFor="inputPassword"
                                >
                                    <FormattedMessage
                                        id={'register_password'}
                                    />
                                    <sup>*</sup>
                                </label>
                                <div className="">
                                    <input
                                        type={'password'}
                                        name={'password'}
                                        className="form-control"
                                        id="inputPassword"
                                        defaultValue={
                                            formData['password']
                                                ? formData['password']
                                                : ''
                                        }
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'supporting-text' +
                                (!formSupports['password']
                                    ? ' invisible'
                                    : '') +
                                (formSupportsErrors['password']
                                    ? ' has-error'
                                    : '')
                            }
                        >
                            {formSupports['password']
                                ? formSupports['password']
                                : '&nbsp;'}
                        </div>

                        <div
                            className={
                                'd-flex input-box' +
                                (formDataErrors['password_confirmation']
                                    ? ' has-error'
                                    : '')
                            }
                        >
                            <div className="w-100">
                                <label
                                    className={'inside-label'}
                                    htmlFor="inputPasswordConfirm"
                                >
                                    <FormattedMessage
                                        id={'register_password_confirm'}
                                    />
                                    <sup>*</sup>
                                </label>
                                <div className="">
                                    <input
                                        type={'password'}
                                        name={'password_confirmation'}
                                        className="form-control"
                                        id="inputPasswordConfirm"
                                        defaultValue={
                                            formData['password_confirmation']
                                                ? formData[
                                                      'password_confirmation'
                                                  ]
                                                : ''
                                        }
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputFocus}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={
                                'supporting-text' +
                                (!formSupports['password_confirmation']
                                    ? ' invisible'
                                    : '') +
                                (formSupportsErrors['password_confirmation']
                                    ? ' has-error'
                                    : '')
                            }
                        >
                            {formSupports['password_confirmation']
                                ? formSupports['password_confirmation']
                                : '&nbsp;'}
                        </div>

                        <div
                            className={''}
                            style={{ marginTop: '31px', marginBottom: '154px' }}
                        >
                            <button
                                className={
                                    'button btn-primary-bright btn-lg btn-loading' +
                                    (loading ? ' loading' : '')
                                }
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleStepBtn(event);
                                }}
                                data-step={'5'}
                            >
                                {loading ? <span className="spinner" /> : null}
                                <FormattedMessage
                                    id={'common_dialog_button_ok_text'}
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default RegisterAccountForm;
