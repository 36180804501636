import React from 'react'

const Avatar = ({ id, url, isActive, setActive, setActiveAvatar }) => (

  <div className="avatar-card">
    <button 
      type="button"
      onClick={() => {
        setActive(id);
        setActiveAvatar(id);
      }}
      className={`navLink ${isActive ? 'active' : ''}` }
    >
      <img className="avatar-image" src={url} alt="1 avatar" />
    </button>
  </div>

)

export default Avatar