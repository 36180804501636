import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppleButton from 'img/apple-btn.svg';
import AndroidButton from 'img/android-btn.svg';
import HuaweiButton from 'img/huawei-btn.svg';
import CgatesLogo from 'img/logo.svg';
import LanguageDropDown from 'components/elements/LanguageDropDown';
import style from './style.module.sass';

const MobileHomePage = ({ lang, setAppLang }) => {
    return (
        <div className={style.pageWrapper}>
            <div className={style.navWrapper}>
                <img src={CgatesLogo} alt="CGates" />
                <LanguageDropDown
                    selectedLang={lang}
                    onSetLanguage={setAppLang}
                />
            </div>
            <h1 className={style.title}>
                <FormattedMessage id="mobile_login_header" />
            </h1>
            <p className={style.subText}>
                <FormattedMessage id="mobile_login_text_2" />
            </p>
            <a href="https://cgates.tv/register" className={style.linkButton}>
                <FormattedMessage id="register_main_header" />
            </a>
            <p className={style.bottomText}>
                <FormattedMessage id="mobile_login_text_1" />
            </p>
            <div className={style.storeWrapper}>
                <a href="https://play.google.com/store/apps/details?id=lt.cgates.app">
                    <img src={AndroidButton} alt="Google Play Store" />
                </a>
                <a href="https://apps.apple.com/lt/app/cgates-tv/id1571190054">
                    <img src={AppleButton} alt="Apple App Store" />
                </a>
                <a href="https://appgallery.huawei.com/app/C104834981">
                    <img src={HuaweiButton} alt="Huawei AppGallery" />
                </a>
            </div>
        </div>
    );
};

export default MobileHomePage;
