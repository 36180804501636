import React, { useState } from 'react';
import classnames from 'classnames';
import { getLangProp } from 'components/addons/ProductsHelper';
import ProductItem from 'components/elements/ProductItem';
import ChannelModal from 'components/elements/ChannelModal';
import style from './style.module.sass';

const ProductsMultiCarousel = ({
    dataItems,
    lang,
    langDefault,
    handleStepBtn,
    purchasedProductId,
    channels,
    changeMode,
}) => {
    const [slide, setSlide] = useState(0);
    const [channelList, setChannelList] = useState(null);
    const maxSlide = dataItems.length > 3 ? dataItems.length - 3 : 0;

    const onNext = () => {
        setSlide(slide + 1);
    };

    const onPrevious = () => {
        setSlide(slide - 1);
    };

    const onOpenModal = (productId) => {
        const clickedProduct = dataItems.find((el) => el.id === productId);
        const filteredChannels = channels.filter((item) =>
            clickedProduct.chids.includes(item.id)
        );
        setChannelList(filteredChannels);
    };

    const onCloseModal = () => {
        setChannelList(null);
    };

    return (
        <div className={style.carouselWrapper}>
            {slide > 0 && (
                <button
                    type="button"
                    onClick={onPrevious}
                    className={classnames(style.carouselButton, style.prev)}
                >
                    <span className={style.chevronLeft} />
                </button>
            )}
            {slide < maxSlide && (
                <button
                    type="button"
                    onClick={onNext}
                    className={classnames(style.carouselButton, style.next)}
                >
                    <span className={style.chevronRight} />
                </button>
            )}
            <ul
                className={style.carouselList}
                style={{ transform: `translateX(-${slide * 34}%)` }}
            >
                {dataItems.map((item, index) => {
                    return (
                        <li key={item.id}>
                            <ProductItem
                                id={item.id}
                                purchasedProductId={purchasedProductId}
                                changeMode={changeMode}
                                chids={item.chids}
                                name={item.name}
                                is_promoted={item.is_promoted}
                                discount_percentages={item.discount_percentages}
                                smartivus_id={item.smartivus_id}
                                handleStepBtn={handleStepBtn}
                                inactive={index < slide || index > slide + 2}
                                onOpenModal={onOpenModal}
                                price={getLangProp(
                                    item,
                                    'price',
                                    lang,
                                    langDefault
                                )}
                                discounted_price={getLangProp(
                                    item,
                                    'discounted_price',
                                    lang,
                                    langDefault
                                )}
                                banner={getLangProp(
                                    item,
                                    'banner',
                                    lang,
                                    langDefault
                                )}
                                placeholder_text_1={getLangProp(
                                    item,
                                    'placeholder_text_1',
                                    lang,
                                    langDefault
                                )}
                                placeholder_text_2={getLangProp(
                                    item,
                                    'placeholder_text_2',
                                    lang,
                                    langDefault
                                )}
                                placeholder_text_3={getLangProp(
                                    item,
                                    'placeholder_text_3',
                                    lang,
                                    langDefault
                                )}
                                placeholder_image_1={getLangProp(
                                    item,
                                    'placeholder_image_1',
                                    lang,
                                    langDefault
                                )}
                                placeholder_image_2={getLangProp(
                                    item,
                                    'placeholder_image_2',
                                    lang,
                                    langDefault
                                )}
                                placeholder_image_3={getLangProp(
                                    item,
                                    'placeholder_image_3',
                                    lang,
                                    langDefault
                                )}
                            />
                        </li>
                    );
                })}
            </ul>
            <ChannelModal channels={channelList} onClose={onCloseModal} />
        </div>
    );
};

export default ProductsMultiCarousel;
