import React, { Component } from 'react'
import { connect } from 'react-redux'
import {fetchAction, fetchSuccess} from '../../store/actions/fetchActions'
// import {Redirect} from 'react-router-dom'
import {VodAboutWrap} from "../../store/actions/ApiKeysMap";
import {channelsEndpoint, smartRowsEndpoint} from "../../store/actions/apiEndpoints";
import {CarouselSection} from "../elements/CarouselSection";
import VodAboutMain from "../elements/VodAboutMain";
import Loading from "../auth/Loading";
import SmartItem from "../elements/SmartItem";
import {TemplateToProportion} from "../addons/TemplateToProportion";
import {setAppClass} from "../../store/actions/globalStylesActions";
// import {setAppClass} from "../../store/actions/globalStylesActions";

class Group extends Component {
    fetchStamps = {}
    renderStamps = {}
    searchParamsGroupId
    renderedGroupId
    searchParamsIndex = ''
    searchParamsSection = ''
    searchParamsTemplate = ''
    seasonKeys = []
    catchupInSeries = false
    carouselItems = []
    carouselProportions = []
    groupsInSection = false
    groupItem
    userRequested = false
    profilesRequested = false

    componentDidMount() {
        this.props.setAppClass('group')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
// console.log('on update:', this.props)
        const nowStamp = Math.round(Date.now() / 1000)
        const searchParams = new URLSearchParams(this.props.location.search)
        this.searchParamsGroupId = searchParams.get('id')
        this.searchParamsSection = searchParams.get('section')
        this.searchParamsIndex = searchParams.get('index')
        this.searchParamsTemplate = searchParams.get('template')
        // this.renderedGroupId = this.searchParamsGroupId

        //-- randam paklikintą elementą ir pasidedam jį į vodabout propsą
        const keyIndex = 0

        if (this.searchParamsGroupId.includes("epg.") && !this.props.channels.items && !this.fetchStamps['channels']) {
            this.props.fetchAction(
                this.props.auth.token,
                channelsEndpoint(this.props.auth.lang),
                this.constructor.name,
                null,
                null,
                ['id', 'lcn']
            )
            this.fetchStamps['channels'] = nowStamp
        }

        if (!this.props.api_data.payload[this.searchParamsSection]) {
            //-- jei nėra sekcijos, reikia ją parsifečinti
            if (!this.props.auth.profile) {
                return true
            }

            if (!this.fetchStamps[this.searchParamsSection]) {
                this.props.fetchAction(
                    this.props.auth.token,
                    smartRowsEndpoint({
                        'id': this.searchParamsSection,
                        'lang': this.props.auth.lang,
                        'filter_profile': this.props.auth.profile.id
                    }),
                    this.constructor.name,
                    this.searchParamsSection
                )
                this.fetchStamps[this.searchParamsSection] = nowStamp
            }

            return true
        }

        const key = Object.keys(this.props.api_data.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex])[keyIndex]
        this.groupItem = this.props.api_data.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex][key]
        if(!this.props.vod_about || this.props.vod_about.id !== this.groupItem.id) {
            this.props.fetchSuccess(
                this.groupItem,
                this.constructor.name,
                VodAboutWrap
            )
            this.fetchStamps[this.searchParamsSection] = nowStamp
        }

        //-- fečinam paklikinto elemento id smartrowsą
        if (
            !this.fetchStamps[this.groupItem['id']] || nowStamp > this.fetchStamps[this.groupItem['id']] + 1
        )
        {
            this.props.fetchAction(
                this.props.auth.token,
                smartRowsEndpoint({
                    'id': this.groupItem['id'],
                    'lang': this.props.auth.lang,
                    'filter_profile': this.props.auth.profile.id,
                    'page_limit': 300
                }),
                this.constructor.name,
                this.groupItem['id']
            )
            this.fetchStamps[this.groupItem['id']] = nowStamp
        }

        //-- jei parfečintas groupItem['id']
        if (
            this.props.api_data.payload[this.groupItem['id']] &&
            this.props.api_data.payload[this.groupItem['id']]['items']['items']
        ) {
// console.log('iterate and fetch? ' + this.groupItem['id'], this.groupItem['template'], this.props.api_data.payload[this.groupItem['id']]['items']['items'])
            // ir jis row, jį iteruojam ir fečinam jo sudedamąsias
            if (this.groupItem['template'] === 'row') {
                this.groupsInSection = true

                // eslint-disable-next-line array-callback-return
                this.props.api_data.payload[this.groupItem['id']].items.items.map(({group}) => {
                    if (typeof group !== 'undefined') {
                        if (!this.fetchStamps[group.id] || this.fetchStamps[this.groupItem['id']] > this.fetchStamps[group.id]) {
                            this.fetchStamps[group.id] = nowStamp
                            this.props.fetchAction(
                                this.props.auth.token,
                                smartRowsEndpoint({
                                    'id': group.id,
                                    'lang': this.props.auth.lang,
                                    'filter_profile': this.props.auth.profile.id
                                }),
                                this.constructor.name,
                                group.id
                            )
                        }
                    }
                })
            // } else {
                // this.props.appElRef.current.className = 'App search-page'
// console.log('no fetch because not a row but', this.groupItem['template'])
            }

        }

    }

    CarouselHandler = (el, proportion) => {
        this.carouselItems.push(el)
        this.carouselProportions.push(proportion)
    }

    shouldComponentUpdate = (nextProps) => {
// console.log('shouldComponentUpdate?')
        if (!this.fetchStamps || !Object.keys(this.fetchStamps).length) {
// console.log('basic yes!', !this.fetchStamps, !Object.keys(this.fetchStamps).length)
            return true
        } else {
            for (let i in this.fetchStamps) {
                if (!this.renderStamps[i] || this.fetchStamps[i] > this.renderStamps[i]) {
// console.log('yes:', !this.renderStamps[i], '||', this.fetchStamps[i] > this.renderStamps[i])
                    return true
                }
            }

            const searchParams = new URLSearchParams(nextProps.location.search)
            const searchParamsGroupId = searchParams.get('id')
            const searchParamsSection = searchParams.get('section')
            const searchParamsIndex = searchParams.get('index')
            if (
                searchParamsGroupId !== this.searchParamsGroupId ||
                searchParamsSection !== this.searchParamsSection ||
                searchParamsIndex !== this.searchParamsIndex
            ) {
// console.log('yes params:', searchParamsGroupId !== this.searchParamsGroupId, searchParamsSection !== this.searchParamsSection, searchParamsIndex !== this.searchParamsIndex)
                return true
            }

// console.log('no...')
            return false
        }
    }

    componentWillUnmount = () => {
        const rootElem = document.getElementById("root")
        rootElem.removeAttribute("style")
    }

    render() {
        const nowStamp = Math.round(Date.now() / 1000)

        let dataArrived = false
        if (this.groupItem && this.props.api_data.payload[this.groupItem['id']]) {
            dataArrived = true
            for (let i in this.fetchStamps) {
                if (!this.props.api_data.payload[i]) {
                    dataArrived = false
                    break
                }
            }
        }

        if (dataArrived) {
            for (let i in this.fetchStamps) {
                this.renderStamps[i] = nowStamp
            }
        }

        const SeasonsSection = () => {
// console.log('SeasonsSection:', this.groupItem['id'], this.props.api_data.payload[this.groupItem['id']].items)
            if (dataArrived) {
                return (
                    this.props.api_data.payload[this.groupItem['id']].items.items.map((section, index) => {
                        return (
                            <div className={"season-section"} key={"section_" + index}>
                                <CarouselSection
                                    sectionIndex={index}
                                    sectionId={section.group.id}
                                    sectionItem={section.group}
                                    items={this.props.api_data.payload[section.group.id] ? this.props.api_data.payload[section.group.id].items.items : null}
                                    channels={this.props.api_data.payload.channels}
                                    lang={this.props.lang}
                                    epg={this.props.api_data.payload.epg}
                                    bookmarks={this.props.api_data.payload.bookmarks}
                                    CarouselHandler={this.CarouselHandler}
                                />
                            </div>
                        )
                    })
                )
            } else {
                return null
            }
        }

        const GroupSection = () => {
            if (dataArrived) {
                // const proportion= TemplateToProportion(this.groupItem)
                const proportion= TemplateToProportion(
                    {
                        row_template: this.groupItem.template,
                        template: this.groupItem.row_template
                    }
                )
                this.groupItem['template'] = this.groupItem['template'].replace(/[\W_]+/g, '_') + '_group'
                const keyIndex = 0
                return (
                    this.props.api_data.payload[this.groupItem['id']]['items']['items'].map((item, index) => {
                        const key = Object.keys(item)[keyIndex]
                        return (
                            <div key={index} className={"fade-in search-img-wrapper"}>
                                <SmartItem
                                    key={index}
                                    i={key}
                                    section={this.groupItem['id']}
                                    index={index}
                                    item={item[key]}
                                    epg={this.props.api_data.payload.epg}
                                    lang={this.props.lang}
                                    channels={this.props.api_data.payload.channels}
                                    proportion={proportion}
                                    template={this.groupItem['template']}
                                    // bookmark={bookmarks ? getBookmark(i, internal[i]['id'], sectionId) : null}
                                />
                            </div>
                        )
                    })
                )
            } else {
                return null
            }
        }

        // const sectionIndex = 0
        return (
            dataArrived ?
                <div style={{marginBottom: '2em'}}>
                    <VodAboutMain props={this.props} fetchStamps={this.fetchStamps} series={true} />
                    {
                        <div className={"d-flex flex-wrap justify-content-start" + (this.groupsInSection ? "" : " page-margins")}>
                            {this.groupsInSection ? <SeasonsSection/> : <GroupSection/>}
                        </div>
                    }
                </div> : <Loading />
        )
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        player: state.player,
        api_data: state.api_data,
        vod_about: state.api_data.payload.vod_about,
        auth: state.auth,
        channels: (state.api_data && state.api_data.payload.channels) ? state.api_data.payload.channels : {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        fetchSuccess: (payload, source, subkey) => { dispatch(fetchSuccess(payload, source, subkey)) },
        setAppClass: (app_class) => {dispatch(setAppClass(app_class))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Group)
