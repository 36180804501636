import React from 'react'
import SmartItem from "./SmartItem";
import {FormattedMessage} from "react-intl";
import FlagIcon from "../../img/flag-icon.svg";
import LeftIcon from "../../img/carousel-left-icon.svg";
import {useHistory} from "react-router-dom";
import PlaceholderScreenshot from "../../img/placeholder-image.svg"
import {LazyLoadImage} from "react-lazy-load-image-component";
import BlankScreenshot from "../../img/blank-screenshot.svg";

const ProductList = ({
                         product_groups,
                         product_selected,
                         channels,
                         loading,
                         selected,
                         product_id,
                         subscribeClick,
                         lang,
                         ccValid,
                         userInfo
}) => {
    const productId = parseInt(product_id)
    const history = useHistory()
    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get("filter")
    const hasGroupWithId15 = userInfo && userInfo.groups && userInfo.groups.items.some(group => group.id === 15)

    const DataLoading = () => {
        return (
            <div className="loader">
                <span className="loader-img"/>
            </div>
        )
    }

    const NoData = ({item, list}) => {
        if (item) {
            return (
                <Product item={item} list={list}/>
            )
        }
        return (
            <div className="w-100 d-flex justify-content-center">
                <div className="col-4 text-center" style={{minWidth: '300px'}}>
                    <img src={FlagIcon} alt=""/>
                    <p className="pt-4"><FormattedMessage id="products_for_channel_no_data"/></p>
                </div>
            </div>
        )
    }

    const changeProduct = (e) => {
        e.preventDefault()
        const clickedProductId = e.target.dataset.id
        history.push('/shop/product/' + clickedProductId + '?filter=' + filter)
    }

    const Product = ({item, list}) => {
        const optionsList = filter ? product_groups['chid'][filter]['items'].map((el, i) => {
            return (
                <div
                    className={"fade-in smart-row-item" + (el.id === productId ? " active" : "")} key={i}
                >
                    <div className={"position-relative"}>
                        <LazyLoadImage
                            alt={""}
                            src={el.screenshot ? el.screenshot : PlaceholderScreenshot}
                            placeholderSrc={BlankScreenshot}
                            className={"smart-row-img cursor-pointer"}
                            effect="blur"
                            data-id={el.id}
                            onClick={changeProduct}
                            // wrapperClassName={"lazy_channel"}
                        />
                        <div className="product-label text-center">{el.title}</div>
                    </div>
                </div>
            )
        }) :
        <div className="fade-in position-relative smart-row-item">
            <img alt="" src={item.screenshot ? item.screenshot : PlaceholderScreenshot} className="smart-row-img" />
        </div>

        return(
            <>
                <div className={"w-100"} style={{marginBottom: '2rem'}}>
                    <button className="button btn-lg btn-secondary" onClick={() => history.goBack()}>
                        <img className={"btn-icon"} src={LeftIcon} alt="" />
                        <FormattedMessage id={'product_go_back'} />
                    </button>
                </div>
                <div className={"d-flex"} style={{height: '100%'}}>
                    <div style={{width: '22%', zIndex: 0}}>
                        {optionsList}
                    </div>
                    <ProductDetail item={item} list={list}/>
                </div>
            </>
        )
    }

    const ProductDetail = ({item, list}) => {
        let purchased = null
        let validUntil = null
        if (item && item.subscribed) {
            purchased = <FormattedMessage id={'product_group_already_purchased'} />
            if (item.valid_until > 0) {
                const startDate = new Date(item.valid_until * 1000)
                const intlObjTime = new Intl.DateTimeFormat(lang, {dateStyle: 'long'})
                validUntil = intlObjTime.format(startDate)
                purchased = <FormattedMessage id={'product_group_already_purchased_until'} />
            }
        } else if(item && !item.subscribed) {
            purchased = item.price
        }

        const price = <h3 style={{marginBottom: '32px'}}>{purchased} {validUntil}</h3>
        const subscribeBtn = (item && !item.channel_type && (!item.valid_until || !item.subscribed)) ?
            <button
                className={"button btn-lg px-5" + (item.subscribed ? " btn-secondary" : " btn-primary-bright")}
                onClick={(e) => subscribeClick('subscription_dialog_product_' + (item.subscribed ? 'un' : '') + 'subscribing')}
                style={{marginBottom: '40px'}}
                disabled={(!item.subscribed && !ccValid && hasGroupWithId15 ? "disabled" : null)}
            >
                <FormattedMessage id={'product_group_' + (item.subscribed ? 'un' : '') + 'subscribe'} />
            </button>
            :
            null

        return (
            <div className="product-detail" style={{maxWidth: "78%"}}>
                {
                    selected === 'channel' ?
                        <>
                            <h1 style={{paddingBottom: '16px'}}>{item ? item.name : null}</h1>
                            {
                                !item.subscribed && !ccValid && hasGroupWithId15 ?
                                    <div className={"input-box has-error mb-4 p-3"}>
                                        <FormattedMessage id={'register_card_missing_error'} />
                                    </div>
                                    :
                                    null
                            }
                            {subscribeBtn}
                        </>
                        :
                        <>
                            <h1 style={{paddingBottom: '16px'}}>{item ? item.title : null}</h1>
                            {price}
                            {
                                !item.subscribed && !ccValid && hasGroupWithId15 ?
                                    <div className={"input-box has-error mb-4 p-3"}>
                                        <FormattedMessage id={'register_card_missing_error'} />
                                    </div>
                                    :
                                    null
                            }
                            {subscribeBtn}
                            <p>{item ? item.description : null}</p>
                            {list.length ?
                                <>
                                    <h4 style={{marginBottom: '24px', paddingTop: '40px'}}><FormattedMessage id={"product_description_channels"} /></h4>
                                    <div className="d-flex flex-wrap justify-content-start">{list}</div>
                                </>
                                :
                                null
                            }
                        </>
                }
            </div>
        )
    }

    const DataArrived = () => {
        let lcnKeys = []
        let list = []

        if (product_groups != null && typeof product_groups['items'] !== 'undefined' && product_groups['items'].length === 0) {
            return <NoData/>
        } else if(!product_groups) {
            return null
        }
        if (!selected || selected === 'channels') {
            for (const i in channels['maps']['lcn']) {
                if (typeof i === 'undefined') {
                    console.log('channel lcn undefined', channels['maps']['lcn'][i])
                    continue
                }
                lcnKeys.push(parseInt(i))
            }
            const lcnSorted = lcnKeys.sort((a, b) => a - b)
            if (lcnSorted.length === 0) {
                return <NoData/>
            }
            list = lcnSorted.map((lcnNo, i) => {
                const index = channels['maps']['lcn'][lcnNo]
                const channel = channels['items'][index]
                if (channel['subscribed'] || !channel['subscribable']) {
                    return null
                }
                return (
                    <div key={index} className="search-img-wrapper fade-in">
                        <SmartItem
                            i={'product'}
                            index={index}
                            item={channel}
                            epg={null}
                            lang={lang}
                            itemClass={'medium_16_9'}
                            proportion={'16_9'}
                            template={'channel'}
                        />
                    </div>
                )
            })
        } else {
            let listSource = []
            if (selected === 'channel') {
                if (typeof product_groups['chid'] === 'undefined' || typeof product_groups['chid'][product_id] === 'undefined') {
                    return null
                }
                listSource = product_groups['chid'][product_id]['items']
            } else if (selected === 'product') {
                if (!product_selected || typeof product_selected === 'undefined' || typeof product_selected['items'] === 'undefined') {
                    return null
                }
                listSource = product_selected['items']
            } else {
                const selectedInt = parseInt(selected)
                if (
                    typeof product_groups['maps'] !== 'undefined' &&
                    typeof product_groups['maps']['id'][selectedInt] !== 'undefined'
                ) {
                    listSource = product_groups['items'][product_groups['maps']['id'][selectedInt]]['products']
                }
            }

            if (product_id) {
                list = listSource.map((item, i) => {
                    return (
                        <div key={item.id} className="square-product-container">
                            <img src={item.poster} title={item.name} alt={""} className="square-product-image" />
                        </div>
                    )
                })
            } else {
                list = listSource.map((item, i) => {
                    return (
                        <div key={i + '_product'} className={"fade-in search-img-wrapper"}>
                            <SmartItem
                                i={'product'}
                                index={i}
                                item={item}
                                epg={product_id}
                                lang={lang}
                                channels={channels}
                                itemClass={'medium_16_9'}
                                proportion={'16_9'}
                                template={typeof item.purchase_options === 'undefined' ? 'channel' : 'product'}
                            />
                        </div>
                    )
                })
            }
            if (listSource.length === 0) {
                let groupItem
                if (
                    selected === 'product' &&
                    product_groups &&
                    product_groups['items']
                ) {
                    for (const item of product_groups['items']) {
                        groupItem = item['products'].find(
                            element => element.id === productId
                        )
                        if (groupItem) {
                            break
                        }
                    }
                }
                if (
                    !groupItem &&
                    product_groups['vodprovid'] &&
                    product_groups['vodprovid'][product_id] &&
                    product_groups['vodprovid'][product_id]['items']
                ) {
                    groupItem = product_groups['vodprovid'][product_id]['items'][0]
                }
                return <NoData item={groupItem} list={list} />
            }
        }

        let item = null
        if (product_id) {
            if (selected === 'channel') {
                item = channels['items'][channels['maps']['id'][product_id]]
            } else if (selected === 'product') {
                if (product_groups['items']) {
                    for (const productGroup of product_groups['items']) {
                        item = productGroup['products'].find(
                            element => element.id === productId
                        )
                        if (item) {
                            break
                        }
                    }
                }
                if (!item && product_groups['chid']) {
                    for (const i in product_groups['chid']) {
                        item = product_groups['chid'][i]['items'].find(
                            element => element.id === productId
                        )
                        if (item) {
                            break
                        }
                    }
                }
            } else if (
                (selected === 'tvod' || selected === 'svod') &&
                product_groups &&
                product_groups['vodprovid'] &&
                product_groups['vodprovid'][product_id]
            ) {
                item = product_groups['vodprovid'][product_id]['items'][0]
            } else if (product_groups && product_groups['maps'] && product_groups['maps']['id'][selected]) {
                item = product_groups['items'][product_groups['maps']['id'][selected]]['products'].find(
                    element => element.id === productId
                )
            }
        }

        return (
            product_id ?
                <Product item={item} list={list}/>
                :
                list
        )
    }

    return (
        loading === true ? <DataLoading/> : <DataArrived/>
    )
}

export default ProductList
