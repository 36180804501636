import React from 'react'
import {NavLink} from 'react-router-dom';
import {LazyLoadImage} from "react-lazy-load-image-component";
import BlankPoster from "../../img/blank-poster.svg"
import BlankScreenshot from "../../img/blank-screenshot.svg"
import { FormattedMessage } from "react-intl"
import ImdbIcon from "../../img/imdb-icon.svg";
import ClockIcon from "../../img/clock-icon.svg";

const HomeItemSmart = (state) => {
// console.log('HomeItemSmart:', state)
    const image = {"alt": "", "src": state.image, "class": "smart-row-img"}

    const dateTimeCapitalized = state.datetime ? state.datetime.charAt(0).toUpperCase() + state.datetime.slice(1) : null

    // const titleClass = state.type === 'vod' ? 'title-text-vod' : 'title-text'

    return (
    <>
        <NavLink
            to={state.playLink} key={state.id}
            className="carousel-image-btn"
        >
            <div className="fade-in smart-row-item">
                <div className={"position-relative"}>
                    <LazyLoadImage
                        alt={""}
                        src={image.src}
                        placeholderSrc={state.proportion === '16_9' ? BlankScreenshot : BlankPoster}
                        className={image.class}
                        effect="blur"
                        // wrapperClassName={"h-100 w-100" + state.type === 'product' ? ' channel-item' : ''}
                        wrapperClassName={"lazy_" + (state.template ? state.template : '')}
                    />

                    {state.hasProgress ?
                        <>
                            <div className="progress-bar" role="progressbar" style={{width: (state.progress * 0.94) + '%'}}/>
                            <div className="progress"/>
                        </>
                        :
                        <div className="progress hidden"/>
                    }

                    {state.productLabel ? state.productLabel : null}
                </div>

                {
                    state.type !== "product" ?
                    <div className="info-box d-flex">
                        {/*{state.type !== 'vod' && state.logo ?*/}
                        {state.logo && state.proportion === "16_9" ?
                            <div className={"logo-box text-center"}>
                                <img className="smart-row-logo" src={state.logo} alt=''/>
                            </div> : null
                        }

                        {state.proportion === "16_9" ?
                            <div className="labels">
                                {state.type !== "vod" || state.proportion === "16_9" || state.proportion === "row" || state.defaultImgUsed ?
                                    <div className={"single-line"}>
                                        {state.adultBlur ? <FormattedMessage id="parental_blocked_string_mask" /> :  state.title}
                                    </div> : null
                                }
                                {(state.episode || dateTimeCapitalized) ?
                                    <div className={"episode-time"}>
                                        {state.episode ? state.episode + " " : null}
                                        {dateTimeCapitalized ?
                                            <div className={"inline-element"}>{state.episode ? "\u2981 " : null}{dateTimeCapitalized}</div>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                            </div> : null
                        }
                    </div>
                        :
                        state.validUntil ?
                            <img className="valid-until" src={ClockIcon} alt="" />
                            : null
                }
            </div>
        </NavLink>
        {(state.type === 'catchup' || state.type === 'vod') ?
            <div className="hovered-content">
                <div className={"hovered-content-header"}>{state.title}</div>
                <div className={"popover-arrow"} />
                {state.imdb || state.ageRating || state.released || state.duration ?
                    <div className={"d-flex align-items-center tags-box"} style={{marginTop: '0.8rem'}}>
                        {state.imdb ?
                            <div className={"imdb-box"}>
                                <img src={ImdbIcon} alt="" />
                                {state.imdb}
                            </div> : null
                        }
                        {state.ageRating ? <div className={"age-box"}>{state.ageRating}</div> : null}
                        {state.released ? <div>{state.released}</div> : null}
                        {state.duration ? <div><span>&nbsp;|</span>{parseInt(state.duration/60) + "min."}</div> : null}
                    </div> : null
                }
                {state.description ? <div
                    style={{
                        display: '-webkit-box',
                        WebkitLineClamp: state.proportion === '16_9' ? 5 : 12,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        marginTop: '0.8rem'
                    }}
                >{state.description}</div> : null}
                {state.genres ? <div style={{marginTop: '0.8rem'}}>
                    <FormattedMessage id="vod_item_info_header_genres"/>: {state.genres}
                </div> : null}
            </div> : null
        }
    </>
    )
}

export default HomeItemSmart