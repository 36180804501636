import React, { useState } from 'react'
import TextField from './TextField'

const NewPassword = props => {
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [passwordTooShort, setPasswordTooShort] = useState(false)
    const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false)

    const { email, history } = props

    const successMessage = 'An email has been sent to ' + email + '. In order to complete the subscription ' +
        'process, please check your inbox and click on the link in the email we have just sent You. Do not ' +
        ' forget to check the ‘junk’ folder if You cannot find the email. After that, You can login ' +
        'using Your username and password.'
    const lengthError = 'Password must have at least 8 characters'
    const matchError = 'The passwords do not match'

    const handleSubmit = e => {
        e.preventDefault()

        let canSubmit = true

        if (password.length < 8) {
            setPasswordTooShort(true)
            canSubmit = false
        }

        if (password !== passwordConfirmation) {
            setPasswordsDoNotMatch(true)
            canSubmit = false
        }

        if (canSubmit)
            history.push({
                pathname: '/status',
                state: {
                    status: 'Success',
                    message1: successMessage,
                    button1Text: 'OPEN EMAIL APP',
                }
            })
    }

    return (
        <form className="new-password-form" onSubmit={e => handleSubmit(e)}>
            <h1>Enter new password</h1>

            <div className="text-fields">
                <TextField field={password} setField={setPassword} fieldCondition={passwordTooShort}
                           setFieldCondition={setPasswordTooShort} error={lengthError} label="Password"
                           type="password" />

                <TextField field={passwordConfirmation} setField={setPasswordConfirmation}
                           fieldCondition={passwordsDoNotMatch} setFieldCondition={setPasswordsDoNotMatch}
                           error={matchError} label="Re-enter password" type="password" password={password} />
            </div>

            <input className="button" type="submit" value="CONFIRM" />
        </form>
    )
}

export default NewPassword