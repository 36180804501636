import React, { Component } from 'react'
import Language from './Language'
import { Droppable } from 'react-beautiful-dnd'
import AudioIcon from '../../img/audio-icon.svg'
import SubtitleIcon from '../../img/subtitle-icon.svg'

class Column extends Component {

  state = {
    checked: true,
  }

  handleSwitch = () => {
    this.setState(prevState => {
      return {
        ...this.state,
        checked: !prevState.checked,
      }
    })
  }

  render() {
    const icon = this.props.type === "audio" ? AudioIcon : SubtitleIcon
    const Switch = () => {
      return (
        <div className="subtitle-disabled">
          <div className="title">Disabled</div>
          <div className="toggle">
            <label className="switch">
              <input type="checkbox" checked={this.state.checked} onChange={this.handleSwitch} />
              <span className="slider round"/>
            </label>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="language-title">
          <img src={icon} alt="icon" />
          <div className="language-title-text">{this.props.column.title}</div>
        </div>
        {this.props.type === "subtitle" ? <Switch /> : null}
        {!this.state.checked || this.props.type === "audio" ? 
          <Droppable droppableId={this.props.column.id}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {this.props.languages.map((language, index) => (
                <Language key={language.id} language={language} index={index} drag={this.props.drag} />
              ))}
              {provided.placeholder}
            </div>
          )}
          </Droppable>
        : null}
      </div>
    )
  }
}

export default Column