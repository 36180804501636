// import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../actions/userActions';
import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    PIN_ENTER_SUCCESS,
    SET_APP_LANG,
    SET_STAT_HELLO,
    DateTimeOptions
} from '../actions/authActions'

const defaultLang = 'lt'

const initState = {
    lang: defaultLang,
    intlObjDateTime: new Intl.DateTimeFormat(defaultLang, DateTimeOptions),
    stat_hello: null,
    profile: null,
    email: null,
    preferred_username: null,
    profile_stamp: null,
    token: null
}

const reducer = (state = initState, action) => {
    // console.log('authReducer action', action)     
    switch (action.type) {
        case FETCH_USER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload.token,
                locale: action.payload.locale,
                email_verified: action.payload.email_verified
            }
        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                token: null,
                error: action.payload
            }
        case PIN_ENTER_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                profile_stamp: action.preserve_stamp ? state.profile_stamp : Math.round(Date.now() / 1000)
            }
        case SET_APP_LANG:
// console.log('SET_APP_LANG', action.payload)
            return {
                ...state,
                lang: action.payload,
                intlObjDateTime: new Intl.DateTimeFormat(action.payload, DateTimeOptions)
            }
        case SET_STAT_HELLO:
            return {
                ...state,
                stat_hello: action.payload,
            }
        default:
            return state
    }
}

export default reducer