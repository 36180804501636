import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from "react-intl"

    const ProductTypes = ({product_groups, loading, selected}) => {
        const DataArrived = () => {
            const activeAll = (!selected || selected === 'channels') ? ' active' : ''
            const list = (!product_groups || !product_groups.items) ? null : product_groups.items.map(category => {
                let activeClass = (parseInt(selected) === category.id ? ' active' : '')

                return (
                    <li className={"category-list-element"} key={category.id}>
                        <NavLink to={'/shop/' + category.id} className={"category-text" + activeClass}>
                            {category.title}
                        </NavLink>
                    </li>
                )
            })
            return (
                <>
                    <h1 style={{marginTop: "80px"}}>
                        <FormattedMessage id={"main_menu_products"} />
                    </h1>
                    <ul className="category-list" style={{marginTop: "119px"}}>
                        <li className={"category-list-element"} key="all">
                            <NavLink to={'/shop/channels'} className={"category-text" + activeAll}>
                                <FormattedMessage id="product_group_manual_channels" />
                            </NavLink>
                        </li>
                        {list}
                    </ul>
                </>
            )
        }

        return (
            (!product_groups || loading === true) ? null : <DataArrived />
        )
    }
      
    export default ProductTypes
