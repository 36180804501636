import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import CloseIcon from 'img/close-icon.svg';
import style from './style.module.sass';

const BasicModal = ({
    children,
    show,
    title,
    className,
    onClose,
    overlayOnly,
    noClose,
}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const onClickOutside = (e) => {
            if (!modalRef.current) return null;
            if (!modalRef.current.contains(e.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', onClickOutside);
        document.addEventListener('touchstart', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
            document.removeEventListener('touchstart', onClickOutside);
        };
    }, [modalRef, onClose]);

    if (!show) return null;

    return (
        <div className={style.container}>
            {!overlayOnly && (
                <div ref={modalRef} className={style.bodyWrapper}>
                    {!noClose && onClose && (
                        <button
                            type="button"
                            onClick={onClose}
                            className={style.closeButton}
                        >
                            <img src={CloseIcon} alt="X" />
                        </button>
                    )}
                    {title && <h2 className={style.title}>{title}</h2>}
                    <div
                        className={classnames(style.contentWrapper, {
                            [className]: className,
                        })}
                    >
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BasicModal;
