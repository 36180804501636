import { SET_APP_CLASS } from '../actions/globalStylesActions'
import { SET_ADULT_PIN } from '../actions/globalStylesActions'
import { SET_SHOP_ITEM } from '../actions/globalStylesActions'
import { REMOVE_SHOP_ITEM } from '../actions/globalStylesActions'

const initState = {
    app_class: '',
    adult_pin: '',
    adult_pin_stamp: 0,
    shop_items: {}
}

const reducer = (state = initState, action) => {
    // console.log('authReducer action', action)     
    let output = {}
    switch (action.type) {
        case SET_APP_CLASS:
            return {
                ...state,
                app_class: action.payload,
            }
        case SET_ADULT_PIN:
            return {
                ...state,
                adult_pin: action.payload,
                adult_pin_stamp: action.payload === 0 ? 0 : Date.now() / 1000 | 0,
                // adult_pin_stamp: 0,
            }
        case SET_SHOP_ITEM:
            const payloadKeys = Object.keys(action.payload)
            for (const payloadKey of payloadKeys) {
                if (state.shop_items[payloadKey]) {
                    output[payloadKey] = {
                        ...state.shop_items[payloadKey], ...action.payload[payloadKey]
                    }
                } else {
                    output[payloadKey] = action.payload[payloadKey]
                }
            }
            return {
                ...state,
                shop_items: output
            }
        case REMOVE_SHOP_ITEM:
            const stateKeys = Object.keys(state.shop_items)
            for (const stateKey of stateKeys) {
                if (action.payload[stateKey]) {
                    continue
                }
                output[stateKey] = state.shop_items[stateKey]
            }

            return {
                ...state,
                shop_items: output
            }

        default: return state
    }
}

export default reducer