export const PLAYER_SET_EPGINDEX = 'PLAYER_SET_EPGINDEX'
export const PLAYER_SET_VODINDEX = 'PLAYER_SET_VODINDEX'
export const PLAYER_SET_EPGID = 'PLAYER_SET_EPGID'
export const PLAYER_SET_VODID = 'PLAYER_SET_VODID'
export const PLAYER_SET_EPG = 'PLAYER_SET_EPG'
export const PLAYER_SET_VOD = 'PLAYER_SET_VOD'
export const PLAYER_SET_VODGENRES = 'PLAYER_SET_VODGENRES'
export const PLAYER_SET_CHANNELID = 'PLAYER_SET_CHANNELID'
export const PLAYER_SET_CHANNEL_LOADING = 'PLAYER_SET_CHANNEL_LOADING'
export const PLAYER_SET_CHANNEL_LOADED = 'PLAYER_SET_CHANNEL_LOADED'
export const PLAYER_SET_OPEN = 'PLAYER_SET_OPEN'
export const PLAYER_SET_MUTED = 'PLAYER_SET_MUTED'
export const PLAYER_SET_VOLUME = 'PLAYER_SET_VOLUME'
  
export const setPlayerEpgIndex = (i) => {
  return {
    item: i,
    type: PLAYER_SET_EPGINDEX
  }
}

export const setPlayerEpgId = (id) => {
  return {
    item: id,
    type: PLAYER_SET_EPGID
  }
}

export const setPlayerVodIndex = (i) => {
  return {
    item: i,
    type: PLAYER_SET_VODINDEX
  }
}

export const setPlayerVodId = (id) => {
  return {
    item: id,
    type: PLAYER_SET_VODID
  }
}

export const setPlayerVod = (items) => {
  return {
    items: items,
    type: PLAYER_SET_VOD
  }  
}

export const setPlayerVodGenres = (items) => {
  return {
    items: items,
    type: PLAYER_SET_VODGENRES
  }  
}

export const setPlayerEpg = (items) => {
  return {
    items: items,
    type: PLAYER_SET_EPG
  }  
}

export const setPlayerMuted = (status) => {
  return {
    status: status,
    type: PLAYER_SET_MUTED
  }
}

export const setPlayerVolume = (value) => {
  return {
    value: value,
    type: PLAYER_SET_VOLUME
  }
}
  
export const setPlayerChannelId = (id) => {
  return {
    item: id,
    type: PLAYER_SET_CHANNELID
  }
}

export const setPlayerChannelLoading = (id) => {
  return {
    item: id,
    type: PLAYER_SET_CHANNEL_LOADING
  }
}

export const setPlayerChannelLoaded = (id) => {
  return {
    item: id,
    type: PLAYER_SET_CHANNEL_LOADED
  }
}
  
export const setPlayerOpen = (status) => {
  return {
    open: status,
    type: PLAYER_SET_OPEN
  }
}  
