import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl"
import ProfilesList from './ProfilesList'
import Languages from './Languages'
import About from './About'
import ProfilesIcon from '../../img/profiles-icon.svg'
import LangIcon from '../../img/lang-icon.svg'
import InfoIcon from '../../img/info-icon.svg'
import LogoutIcon from '../../img/logout-icon.svg'
import ProfileForm from '../elements/ProfileForm'
import { pinEnterSuccess } from '../../store/actions/authActions'
import config from '../../config/config'
import { handleCookie } from '../addons/CookiesHandler'
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";
import { withRouter } from 'react-router-dom'

class SettingsList extends Component {
    state = {
        confirmShow: false
    }

    handleProfilesClick = (e) => {
      this.props.auth.profile.master_flag === true ? 
        this.props.setModalState('profiles-list', <FormattedMessage id="profile_manage_title" />, <ProfilesList />, 'SettingsList', <ProfilesIcon />) : 
        this.props.setModalState('profile-edit', <FormattedMessage id="profile_action_edit" />, <ProfileForm profile={e} />, 'ProfilesList')
    }
    
    handleLanguageClick = (e) => {
      e.preventDefault()
      this.props.setModalState('language-modal', <FormattedMessage id="settings_action_language" />, <Languages />, 'SettingsList', <LangIcon />);
    }

    handleRegisterClick = (e, action) => {
      e.preventDefault()
console.log('action:', action)
        this.props.modal_state.modal_obj.obj.toggle();
        this.props.history.push('/register?action=' + action);
    }

    handleAboutClick = (e) => {
        e.preventDefault()
        this.props.setModalState('about-modal', <FormattedMessage id="settings_action_about" />, <About />, 'SettingsList', <InfoIcon />);
    }

    // handleStopShowing = () => {
    //     this.setState({
    //         ...this.state,
    //         confirmShow: false
    //     })
    // }

    handleLogoutClick = (e) => {
// console.log('handleLogoutClick', this.props.userStatus)
        this.props.modal_state.modal_obj.obj.toggle()
        if (this.props.userStatus) {
            this.props.setModalSimpleState({
                show: true,
                overlayOnly: false,
                overlayOnClose: !this.props.auth.profile,
                closeOnClick: false,
                hideClose: false,
                header: "settings_action_confirm_logout",
                style: {width: "30%"},
                bodyStyle: {bottom: 0, position: 'absolute', left: 0, right: 0, margin: '48px 44px'},
                body: (
                    <div className="btn-group w-100 justify-content-center">
                        {this.props.auth.profile ? (
                            <button className="button btn-primary btn-lg me-2 w-50" onClick={() => this.handleProfileReset()}>
                                <FormattedMessage id="settings_action_change_profile" />
                            </button>
                        ) : null}

                        <button className="button btn-secondary btn-lg w-50" onClick={() => this.handleProfileReset(true)}>
                            <FormattedMessage id="settings_action_logout" />
                        </button>
                    </div>
                )
            })
        } else {
            this.handleProfileReset(true)
        }
    }
    
    handleProfileReset = (logout) => {
// console.log('handleProfileReset', logout)
        handleCookie({operation: 'remove', name: 'profile'})
        this.props.pinEnterSuccess(null)
        if (logout) {
            window.location.href = config['clientURI'] + "/logout"
        } else {
            window.location.reload()
        }
    }
    
    render() {
        const hasGroupWithId15 = this.props.userinfo && this.props.userinfo.groups && this.props.userinfo.groups.items.some(group => group.id === 15)
        const profiledList = this.props.auth.profile && (this.props.userStatus || typeof this.props.userStatus === 'undefined') ? (
            <>
                <button className="button btn-link btn-lg text-start text-nowrap"
                        onClick={() => this.handleProfilesClick(this.props.auth.profile)}>
                    <img className="dropdown-icon" src={ProfilesIcon} alt="" />
                    { this.props.auth.profile.master_flag ?
                        <FormattedMessage id="profile_manage_title" /> :
                        <FormattedMessage id="profile_action_edit" /> }
                </button>
                <button className="button btn-link btn-lg text-start text-nowrap" onClick={this.handleLanguageClick}>
                    <img className="dropdown-icon" src={LangIcon} alt="" />
                    <FormattedMessage id="settings_action_language" />
                </button>
                {
                    hasGroupWithId15 ?
                        <>
                            <button className="button btn-link btn-lg text-start text-nowrap" onClick={(e) => this.handleRegisterClick(e, 'change')}>
                                <img className="dropdown-icon" src={LangIcon} alt="" />
                                <FormattedMessage id="settings_change_plan" />
                            </button>
                            <button className="button btn-link btn-lg text-start text-nowrap" onClick={(e) => this.handleRegisterClick(e, 'add_card')}>
                                <img className="dropdown-icon" src={LangIcon} alt="" />
                                <FormattedMessage id="settings_add_payment_card" />
                            </button>
                        </>
                    : null
                }
                <button className="button btn-link btn-lg text-start text-nowrap" onClick={this.handleAboutClick}>
                    <img className="dropdown-icon" src={InfoIcon} alt="" />
                    <FormattedMessage id="settings_action_about" />
                </button>
            </>
        ) : null

        return (
            <div className={"d-flex flex-column justify-content-start"}>
                {profiledList}
                <button className="button btn-link btn-lg text-start text-nowrap" onClick={this.handleLogoutClick}>
                    <img className="dropdown-icon" src={LogoutIcon} alt="" />
                    <FormattedMessage id="settings_action_logout" />
                </button>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {  
    return {
        auth: state.auth,
        userinfo: state.api_data.payload['userinfo'],
        modal_state: state.modal_state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
        setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) },
        pinEnterSuccess: (profile) => dispatch(pinEnterSuccess(profile))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsList))