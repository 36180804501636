import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import config from 'config/config';
import YourChoice from 'components/elements/YourChoice';
import { getLangProp } from 'components/addons/ProductsHelper';
import RegisterTerms from 'components/elements/RegisterTerms';
import EmailConfirm from 'components/elements/EmailConfirm';
import RegisterAccountForm from 'components/elements/RegisterAccountForm';
import YourChoicePayments from 'components/elements/YourChoicePayments';
import RegisterPaymentWaiting from 'components/elements/RegisterPaymentWaiting';
import PostpaidThanks from 'components/elements/PostpaidThanks';
import RegisterMsgPage from 'components/elements/RegisterMsgPage';
import { setModalSimpleState } from 'store/actions/modalSimpleStateActions';
import PinInput from 'components/elements/PinInput';
import ProductItem from 'components/elements/ProductItem';
import SubscriptionOptions from './SubscriptionOptions';
import style from './style.module.sass';

class Register extends Component {
    constructor(props) {
        super(props);
        this.compareContainerRef = React.createRef();
        this.modalConfirmBtnRef = React.createRef();
    }

    state = {
        web_products: null,
        loading: true,
        step: 0,
        error: '',
        pin: '',
        lang: null,
        langDefault: 'lt',
        product: null,
        productId: null,
        purchasedProductId: null,
        registration_purchases: null,
        smartivusId: null,
        showModal: false,
        termsAccepted: false,
        callsAccepted: false,
        formData: {},
        formDataErrors: {},
        formSupports: {},
        formSupportsErrors: {},
        filter_status: false,
        slider_status: false,
        changeMode: '',
    };

    channelsFetched = false;
    freshSet = false;
    termsRequested = false;
    passwordPosted = false;
    termsPosted = false;
    pinRequested = false;
    paymentRequested = false;
    transactionRequested = false;
    purchaseRequested = false;
    // changeMode = ''
    purchasedProductSearched = null;
    purchasesRequested = false;
    methodsRequested = false;
    webProductsRequested = false;
    stepValue = 0;
    productIdValue = 0;
    smartivusIdValue = 0;
    orderReference = null;
    referenceRequested = false;
    errorModalShown = false;
    intervalId = null;
    transactionsRequestCount = 0;

    componentDidMount() {
        window.location.href = "https://www.cgates.lt/televizija/programele/uzsakymas/";

        // todo vietoj https://cgatestv-beta.cgates.lt padaryti konfigo kėjų
        // config['customerURL'] = 'https://cgatestv-beta.cgates.lt'
        // config['customerURL'] = 'https://localhost:3000'
        const backgroundEl = document.getElementById('body-background');
        backgroundEl.classList.add('cover');

        const urlParams = new URLSearchParams(window.location.search);
        // const freshParam = urlParams.get("fresh")
        const stepParam = urlParams.get('step');
        const productIdParam = urlParams.get('product_id');
        const smartivusIdParam = urlParams.get('smartivus_id');
        this.orderReference = urlParams.get('order_reference');
        this.stepValue = stepParam ? parseInt(stepParam) : 0;
        this.productIdValue = productIdParam ? parseInt(productIdParam) : 0;
        this.smartivusIdValue = smartivusIdParam
            ? parseInt(smartivusIdParam)
            : 0;
        this.webProductsRequested = true;
        this.fetchData({
            path: 'web-products',
            dataKey: 'data',
            step: this.stepValue,
            productId: this.productIdValue,
            smartivusId: this.smartivusIdValue,
        });
        this.fetchData({
            path: 'web-product-properties',
            step: this.stepValue,
            productId: this.productIdValue,
            smartivusId: this.smartivusIdValue,
        });

        this.updateModeFromUrl();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    updateModeFromUrl = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const action = urlParams.get('action');
        let newState = { changeMode: action };
        if (action === 'change' && this.state.step !== 0) {
            newState['step'] = 0;
        }
        this.setState(newState);
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            this.handleStopShowing();
        }
    };

    fetchTransactionsFunction = ({ step = 7, stepOnError = 7 } = {}) => {
        this.fetchData({
            path: 'prepaid-transactions/' + this.orderReference,
            params: {
                method: 'GET',
            },
            step: step,
            stepOnError: stepOnError,
            statePath: 'prepaid_transactions',
        });
        this.transactionsRequestCount += 1;
        if (
            this.transactionsRequestCount >= 5 ||
            (this.state['prepaid_transactions'] &&
                (this.state['prepaid_transactions']['payment_state'] ===
                    'settled' ||
                    this.state['prepaid_transactions']['payment_state'] ===
                        'failed'))
        ) {
            this.setState({
                ...this.state,
                loading: false,
                step: 8,
            });
            clearInterval(this.intervalId);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.updateModeFromUrl();
        }

        if (
            this.state.changeMode &&
            this.props.userinfo &&
            this.props.auth.token
        ) {
            if (!this.purchasesRequested && this.state.step !== 9) {
                this.purchasedProductSearched = null;
                this.purchasesRequested = true;
                this.fetchData({
                    path:
                        'prepaid-customers/' +
                        this.props.userinfo.customer_id.items +
                        '/purchases/',
                    params: {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                    step: 0,
                    stepOnError: 0,
                    statePath: 'registration_purchases',
                });
            }

            //-- pasiklausiam, kur nukreipti apmokėti
            if (this.state.changeMode === 'add_card') {
                if (!this.paymentRequested) {
                    this.fetchData({
                        path:
                            'prepaid-customers/' +
                            this.props.userinfo.customer_id.items +
                            '/payment-methods',
                        params: {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                customer_ip: '1.2.3.4',
                                customer_url:
                                    config['customerURL'] + '/register?step=9',
                                locale: this.state.lang
                                    ? this.state.lang
                                    : this.state.langDefault,
                            }),
                        },
                        step: 9,
                        stepOnError: 9,
                        statePath: 'payment_methods',
                    });
                    this.paymentRequested = true;
                } else if (this.state.payment_methods) {
                    window.location.href =
                        this.state.payment_methods.payment_link;
                }
            } else if (this.state.step === 9) {
                if (this.orderReference && !this.referenceRequested) {
                    this.fetchTransactionsFunction({ step: 9, stepOnError: 9 });
                    this.intervalId = setInterval(
                        () =>
                            this.fetchTransactionsFunction({
                                step: 9,
                                stepOnError: 9,
                            }),
                        10000
                    );
                    this.referenceRequested = true;
                }

                if (
                    this.state['prepaid_transactions'] &&
                    (this.state['prepaid_transactions']['payment_state'] ===
                        'settled' ||
                        this.state['prepaid_transactions']['payment_state'] ===
                            'failed')
                ) {
                    clearInterval(this.intervalId);
                    this.purchaseRequested = true;
                }
            }

            if (
                this.state.formDataErrors['product_id'] ||
                this.state.formDataErrors['smartivus_id'] ||
                this.state['registration_confirm']
            ) {
                //-- jei registration_confirm, be errorų nurodom fečinti purchases
                if (
                    (this.state.formDataErrors['product_id'] ||
                        this.state.formDataErrors['smartivus_id']) &&
                    !this.errorModalShown
                ) {
                    this.props.setModalSimpleState({
                        show: true,
                        header: 'register_error_header',
                        style: { width: '400px' },
                        body: (
                            <div>
                                <div className="pt-3">
                                    <FormattedMessage id="register_card_missing_error" />
                                </div>
                                <div className="d-flex justify-content-end pt-5">
                                    <NavLink
                                        className={
                                            'button btn-primary-bright btn-lg d-flex'
                                        }
                                        to={'/register?action=add_card'}
                                    >
                                        <div className={'w-100 text-nowrap'}>
                                            <FormattedMessage id="settings_add_payment_card" />
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        ),
                    });
                    this.errorModalShown = true;
                } else {
                    this.purchasedProductSearched = null;
                    this.purchasesRequested = false;
                    this.setState({
                        ...this.state,
                        formSupports: {},
                        formDataErrors: {},
                        formSupportsErrors: {},
                        registration_confirm: null,
                        registration_purchases: null,
                        purchasedProductId: null,
                        loading: false,
                        error: '',
                    });
                    if (
                        !this.errorModalShown &&
                        this.props.modal_simple_state.show
                    ) {
                        this.props.setModalSimpleState({
                            show: false,
                        });
                    }
                }
            }
        } else if (
            this.state.changeMode &&
            this.props.userinfo &&
            this.props.userinfo.is_demo &&
            this.props.userinfo.is_demo.items
        ) {
            const url = new URL(window.location.href);
            const searchParams = new URLSearchParams(url.search);
            searchParams.delete('action');
            url.search = searchParams.toString();
            window.history.pushState({}, '', url.toString());
            this.setState({
                ...this.state,
                changeMode: '',
            });
            // } else if(!this.changeMode && this.props.userinfo && this.props.userinfo.is_demo && !this.props.userinfo.is_demo.items) {
            //     this.changeMode = 'change'
            // http://localhost:3000/register?order_reference=pq9DdvRWl1Apl5AKL25gPwnYr&payment_reference=06c95eab1b114169902c27329d0a6e53f44f0a1cd71a029dd1587284f24fecf4&step=9
        } else if (
            !this.state.changeMode &&
            this.props.userinfo &&
            this.props.auth.token &&
            this.orderReference &&
            this.state.step === 9
        ) {
            if (this.orderReference && !this.referenceRequested) {
                this.fetchTransactionsFunction({ step: 9, stepOnError: 9 });
                this.intervalId = setInterval(
                    () =>
                        this.fetchTransactionsFunction({
                            step: 9,
                            stepOnError: 9,
                        }),
                    10000
                );
                this.referenceRequested = true;
            }

            if (
                this.state['prepaid_transactions'] &&
                (this.state['prepaid_transactions']['payment_state'] ===
                    'settled' ||
                    this.state['prepaid_transactions']['payment_state'] ===
                        'failed')
            ) {
                clearInterval(this.intervalId);
            }
        }

        //-- jei turim produktus ir nupirktus produktus, bet neturim nupirkto produkto id, jį randam
        if (
            this.state.web_products &&
            this.state.registration_purchases &&
            (!this.purchasedProductSearched ||
                this.purchasedProductSearched !==
                    this.state.purchasedProductId ||
                this.state.deletedProductId)
        ) {
            const purchasedProduct = this.state.registration_purchases.find(
                (el) =>
                    el.is_subscribed === true &&
                    el.product_id !== this.state.deletedProductId
            );
            this.purchasedProductSearched = purchasedProduct
                ? purchasedProduct.product_id
                : null;
            if (
                this.state.purchasedProductId !== this.purchasedProductSearched
            ) {
                this.setState({
                    ...this.state,
                    purchasedProductId: this.purchasedProductSearched,
                });
            }
        }

        if (!this.state.web_products && !this.webProductsRequested) {
            this.webProductsRequested = true;
            this.fetchData({
                path: 'web-products',
                dataKey: 'data',
                step: this.stepValue,
                productId: this.productIdValue,
                smartivusId: this.smartivusIdValue,
            });
            this.fetchData({
                path: 'web-product-properties',
                step: this.stepValue,
                productId: this.productIdValue,
                smartivusId: this.smartivusIdValue,
            });
        }

        const urlParams = new URLSearchParams(window.location.search);
        const freshParam = urlParams.get('fresh');
        if (freshParam) {
            if (!this.freshSet) {
                this.setState({
                    ...this.state,
                    step: 0,
                    product: null,
                    productId: null,
                });
                this.freshSet = true;
                urlParams.delete('fresh');
                const updatedUrl =
                    urlParams.toString() === ''
                        ? `${window.location.origin}${window.location.pathname}`
                        : `${window.location.origin}${
                              window.location.pathname
                          }?${urlParams.toString()}`;
                window.history.pushState(null, '', updatedUrl);
            }
            // this.fetchData({path: 'web-products', dataKey: 'data', step: 0})
            // this.fetchData({path: 'web-product-properties', step: 0})
            // this.productsRequested = true
        } else {
            this.freshSet = false;
        }

        if (this.state.step !== prevState.step) {
            if (typeof this.state.step !== 'undefined') {
                const backgroundEl = document.getElementById('body-background');
                if (this.state.step > 0) {
                    backgroundEl.classList.remove('cover');
                } else {
                    backgroundEl.classList.add('cover');
                }
            }
        }

        // if (this.state.step !== 7 && this.state.productId && this.state.productId !== prevState.productId) {
        if (
            this.state.productId &&
            (!this.state.product ||
                this.state.productId !== this.state.product.id)
        ) {
            this.getProduct();
        }

        if (!this.channelsFetched && this.state.web_products) {
            for (const product of this.state.web_products) {
                if (product.chids && product.chids.length) {
                    this.fetchData({
                        path: 'channels',
                        dataKey: 'channels',
                        isPublic: true,
                        step: 0,
                    });
                    break;
                }
            }
            this.channelsFetched = true;
        }

        if (this.state['step'] === 2 && !this.termsRequested) {
            let termsConfigKey = 'termsURL_' + this.props.auth.lang;
            if (typeof config[termsConfigKey] === 'undefined') {
                termsConfigKey = 'termsURL_' + this.state.langDefault;
            }
            fetch(config[termsConfigKey])
                .then((response) => response.text())
                .then((result) =>
                    this.setState({
                        ...this.state,
                        terms: result,
                    })
                );
            this.termsRequested = true;
        }

        //-- kai ateina laikas, postinam slaptažodžius
        if (
            this.state['step'] === 5 &&
            !this.passwordPosted &&
            this.state.formData['password'] &&
            this.state.formData['password_confirmation'] &&
            !this.state.error
        ) {
            this.fetchData({
                path:
                    'customer-registrations/' +
                    this.state.customer_registrations.id +
                    '/complete',
                params: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        password: this.state.formData['password'],
                        password_confirmation:
                            this.state.formData['password_confirmation'],
                    }),
                },
                step: 5,
                stepOnError: 4,
                statePath: 'customer_registrations',
            });
            this.passwordPosted = true;
        }

        //-- postinam, kad nusiųstų patvirtinimo mailą ir gautume pin_testing
        //-- reikia postinti po termsų posto rezultato, nes kitaip perrašys pin'ą į null
        if (
            this.state['step'] === 3 &&
            this.termsPosted &&
            !this.pinRequested
        ) {
            this.fetchData({
                path:
                    'customer-registrations/' +
                    this.state.customer_registrations.id +
                    '/request-pin',
                params: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                step: 3,
                stepOnError: 3,
                statePath: 'customer_registrations',
            });
            this.pinRequested = true;
        }

        //-- pasiklausiam, kur nukreipti apmokėti
        if (this.state['step'] === 6 && !this.paymentRequested) {
            this.fetchData({
                path:
                    'prepaid-customers/' +
                    this.state.customer_registrations.smartivus_id +
                    '/payment-methods',
                params: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        customer_ip: '1.2.3.4',
                        customer_url:
                            config['customerURL'] +
                            '/register?step=7&smartivus_id=' +
                            this.state.customer_registrations.smartivus_id +
                            '&product_id=' +
                            this.state.customer_registrations.product_id,
                        locale: this.state.lang
                            ? this.state.lang
                            : this.state.langDefault,
                    }),
                },
                step: 7,
                stepOnError: 7,
                statePath: 'payment_methods',
            });

            this.paymentRequested = true;
            this.transactionRequested = false;
        }

        //-- nukreipimas į mokėjimą kortele
        if (
            this.state['step'] === 7 &&
            this.state.payment_methods &&
            this.state.payment_methods.status === 'initial' &&
            this.state.payment_methods.payment_link &&
            !this.transactionRequested
        ) {
            this.transactionRequested = true;
            window.location.href = this.state.payment_methods.payment_link;
        }

        //-- grįžus iš apmokėjimo kortele
        if (this.state.step === 7 && this.state.smartivusId) {
            if (this.orderReference && !this.referenceRequested) {
                this.fetchTransactionsFunction();
                this.intervalId = setInterval(
                    this.fetchTransactionsFunction,
                    10000
                );
                this.referenceRequested = true;
            }

            if (
                this.state['prepaid_transactions'] &&
                this.state['prepaid_transactions']['payment_state'] ===
                    'settled' &&
                !this.purchaseRequested
            ) {
                this.fetchData({
                    path:
                        'prepaid-customers/' +
                        this.state.smartivusId +
                        '/purchases/',
                    params: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            product_id: this.state.productId,
                        }),
                    },
                    step: 7,
                    stepOnError: 7,
                    statePath: 'callback_purchases',
                });
                clearInterval(this.intervalId);
                this.purchaseRequested = true;
            }
        }
    }

    fetchData({
        path,
        exactPath,
        statePath,
        dataKey,
        isPublic,
        params,
        step,
        productId,
        smartivusId,
        stepOnError,
        stateToSet,
    }) {
        let url = path;
        if (!exactPath) {
            if (isPublic) {
                url = config['publicApiURI'] + '/' + path;
            } else {
                url = config['registerURI'] + path;
            }
        }
        const transformedPath = statePath
            ? statePath
            : path.replace(/\//g, '').replace(/-/g, '_');

        let newState = {
            ...this.state,
            page: [transformedPath],
            loading: true,
            error: '',
        };
        if (productId) {
            newState['productId'] = productId;
        }
        if (smartivusId) {
            newState['smartivusId'] = smartivusId;
        }
        this.setState(newState);

        fetch(url, params)
            .then((response) => {
                if (response.status === 204 || response.status === 404) {
                    if (response.status === 204) {
                        const newState = {
                            ...this.state,
                            formSupports: {},
                            formDataErrors: {},
                            formSupportsErrors: {},
                            loading: false,
                            step: step,
                            error: '',
                        };

                        if (stateToSet) {
                            Object.assign(newState, stateToSet);
                        }
                        this.setState(newState);

                        if (this.props.modal_simple_state.show) {
                            this.props.setModalSimpleState({
                                show: false,
                            });
                        }
                    } else {
                        this.setState({
                            ...this.state,
                            error: '404',
                            [transformedPath]:
                                response.status === 404 ? [] : {},
                        });
                    }
                    return null;
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                if (response.errors) {
                    const formSupportsErrors = Object.keys(
                        response.errors
                    ).reduce(function (acc, key) {
                        acc[key] = true;
                        return acc;
                    }, {});
                    const formSupports = Object.keys(response.errors).reduce(
                        function (acc, key) {
                            acc[key] = response.errors[key].join(' ');
                            return acc;
                        },
                        {}
                    );

                    this.setState({
                        ...this.state,
                        formSupports: formSupports,
                        formDataErrors: formSupportsErrors,
                        formSupportsErrors: formSupportsErrors,
                        loading: false,
                        step: stepOnError,
                        error: '*',
                    });
                } else {
                    const newState = {
                        ...this.state,
                        [transformedPath]: dataKey
                            ? response[dataKey]
                            : response,
                        formSupports: {},
                        formDataErrors: {},
                        formSupportsErrors: {},
                        loading: false,
                        step: step,
                        error: '',
                    };

                    if (stateToSet) {
                        Object.assign(newState, stateToSet);
                    }

                    this.setState(newState);
                }
            })
            .catch((error) => {
                this.setState({
                    ...this.state,
                    loading: false,
                    error: error.message,
                });
            });
    }

    handleSwitch = () => {
        this.channelsFetched = false;
        this.setState((prevState) => {
            return {
                ...this.state,
                slider_status: !prevState.slider_status,
            };
        });
    };

    handleFilter = (filterValue) => {
        this.setState(() => {
            return {
                ...this.state,
                filter_status: filterValue === 'postpaid',
            };
        });
    };

    handleTermsCheck = (e) => {
        this.setState({
            ...this.state,
            termsAccepted: e.target.checked,
        });
    };

    handleStepBtn = (e) => {
        let step = e.target.dataset.step
            ? parseInt(e.target.dataset.step)
            : null;
        const productId = e.target.dataset.productId
            ? parseInt(e.target.dataset.productId)
            : null;
        const product = this.state.web_products.find(
            (el) => el.id === productId
        );

        let formDataErrors = {};
        let formSupports = {};
        let formSupportsErrors = {};
        if (step === 0) {
            if (this.state.changeMode) {
                this.handleProductConfirm(productId);
            }
            this.setState({
                ...this.state,
                step: 0,
            });
        } else if (step === 1) {
            if (!this.state.customer_registrations) {
                //-- pasiimam sesijos id
                this.fetchData({
                    path: 'customer-registrations',
                    params: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            is_prepaid: product.is_prepaid,
                            product_id: productId,
                        }),
                    },
                    productId: productId,
                    step: 1,
                    stepOnError: 0,
                });
            } else {
                this.setState({
                    ...this.state,
                    productId: productId,
                    step: 1,
                });
            }
        } else if (step === 2) {
            //-- formos su emailu ir phone submitas
            let formData = {};
            const postFormData = new FormData(e.target.parentElement);
            if (product.is_prepaid) {
                formData['email'] = postFormData
                    .get('email')
                    .replace(/\s/g, '');
            } else {
                formData['name'] = postFormData.get('name');
            }
            formData['phone'] = postFormData
                .get('phone')
                .replace(/[^0-9]/g, '');
            // consent_call -- 'on' / null
            formData['consent_call'] =
                postFormData.get('consent_call') === 'on';
            if (product.is_prepaid) {
                if (!formData['email']) {
                    formDataErrors['email'] = true;
                } else if (!/.+@.+\..+/.test(formData['email'])) {
                    formDataErrors['email'] = true;
                    formSupportsErrors['email'] = true;
                    formSupports['email'] = (
                        <FormattedMessage id="register_email_format" />
                    );
                }
            }
            if (!product.is_prepaid && !formData['name']) {
                formDataErrors['name'] = true;
            }
            //-- jei postpaidas, turi sutikti, kad paskambintų
            if (!product.is_prepaid && !formData['consent_call']) {
                formDataErrors['consent_call'] = true
            }
            if (!formData['phone']) {
                formDataErrors['phone'] = true;
            } else if (
                formData['phone'].substring(0, 4) !== '3706' ||
                formData['phone'].length !== 11
            ) {
                formDataErrors['phone'] = true;
                formSupportsErrors['phone'] = true;
                formSupports['phone'] = (
                    <FormattedMessage id="register_phone_format" />
                );
            }
            if (
                Object.keys(formDataErrors).length > 0 ||
                Object.keys(formSupportsErrors).length > 0
            ) {
                this.setState((prevState) => {
                    return {
                        ...this.state,
                        productId: productId,
                        step: 1,
                        formData: formData,
                        formDataErrors: formDataErrors,
                        formSupports: formSupports,
                        formSupportsErrors: formSupportsErrors,
                    };
                });
            } else {
                let requestBody = { phone: formData['phone'] };
                if (product.is_prepaid) {
                    requestBody['email'] = formData['email'];
                } else {
                    requestBody['name'] = formData['name'];
                    requestBody['consent_call'] = formData['consent_call'];
                }

                const params = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                };
                this.fetchData({
                    path:
                        'customer-registrations/' +
                        this.state.customer_registrations.id,
                    params: params,
                    step: step,
                    stepOnError: step - 1,
                    statePath: 'customer_registrations',
                });

                this.setState({
                    ...this.state,
                    // callsAccepted: formData['consent_call'],
                    formData: formData,
                    formDataErrors: formDataErrors,
                    formSupports: formSupports,
                    formSupportsErrors: formSupportsErrors,
                });
            }
            this.termsPosted = false;
            this.pinRequested = false;
        } else if (step === 3) {
            //-- sutikimo su taisyklėmis submitas
            this.fetchData({
                path:
                    'customer-registrations/' +
                    this.state.customer_registrations.id,
                params: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        consent_call: this.state.callsAccepted,
                        terms_accepted: this.state.termsAccepted,
                    }),
                },
                step: step,
                stepOnError: step,
                statePath: 'customer_registrations',
            });
            this.termsPosted = true;
        } else if (step === 4) {
            //-- pino sutikrinimas
            if (
                this.state.pin !== this.state.customer_registrations.pin_testing
            ) {
                this.setState({
                    ...this.state,
                    step: step - 1,
                    error: '*',
                });
            } else {
                this.fetchData({
                    path:
                        'customer-registrations/' +
                        this.state.customer_registrations.id +
                        '/verify-pin',
                    params: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ pin: this.state.pin }),
                    },
                    step: step,
                    stepOnError: step,
                    statePath: 'customer_registrations',
                });
            }
        } else if (step === 5) {
            //-- Paskyros duomenų sutikrinimas
            const postFormData = new FormData(
                e.target.parentElement.parentElement
            );
            let formData = {};
            const formFields = [
                'name',
                'lastname',
                'city',
                'password',
                'password_confirmation',
            ];
            for (const field of formFields) {
                formData[field] = postFormData.get(field);
            }
            const requiredFields = [
                'name',
                'lastname',
                'password',
                'password_confirmation',
            ];
            let errorMessage = '';
            for (const field of requiredFields) {
                if (!formData[field]) {
                    formDataErrors[field] = true;
                    errorMessage = (
                        <FormattedMessage id="sign_up_input_required" />
                    );
                }
            }

            const regexPattern = /^[a-zA-Z0-9~@#$%^&*()_+{}|:”<>?]+$/;
            if (formData['password'] && formData['password'].length < 8) {
                errorMessage = (
                    <FormattedMessage id="register_password_length" />
                );
            } else if (!regexPattern.test(formData['password'])) {
                errorMessage = (
                    <FormattedMessage id="register_password_invalid" />
                );
            } else if (
                formData['password'] &&
                formData['password_confirmation'] &&
                formData['password'] !== formData['password_confirmation']
            ) {
                errorMessage = (
                    <FormattedMessage id="register_password_mismatch" />
                );
            }

            if (
                Object.keys(formDataErrors).length > 0 ||
                Object.keys(formSupportsErrors).length > 0 ||
                errorMessage
            ) {
                this.setState((prevState) => {
                    return {
                        ...this.state,
                        step: step - 1,
                        error: errorMessage,
                        formData: formData,
                        formDataErrors: formDataErrors,
                        formSupports: formSupports,
                        formSupportsErrors: formSupportsErrors,
                    };
                });
            } else {
                this.passwordPosted = false;
                this.fetchData({
                    path:
                        'customer-registrations/' +
                        this.state.customer_registrations.id,
                    params: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name: formData['name'],
                            lastname: formData['lastname'],
                            city: formData['city'],
                        }),
                    },
                    step: step,
                    stepOnError: step - 1,
                    statePath: 'customer_registrations',
                });

                this.setState({
                    ...this.state,
                    formData: formData,
                    formDataErrors: formDataErrors,
                    formSupports: formSupports,
                    formSupportsErrors: formSupportsErrors,
                    error: '',
                });
            }
        } else if (step === 6) {
            this.paymentRequested = false;
            const postFormData = new FormData(
                e.target.parentElement.parentElement
            );
            this.fetchData({
                path:
                    'prepaid-customers/' +
                    this.state.customer_registrations.smartivus_id,
                params: {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        recurring_payments_terms_accepted:
                            postFormData.get(
                                'recurring_payments_terms_accepted'
                            ) === 'on',
                    }),
                },
                step: step,
                stepOnError: step - 1,
                statePath: 'recurring_payments_terms_accepted',
            });
        }
    };

    handlePinEntered = (enteredPin, hasError) => {
        const button = this.modalConfirmBtnRef.current;
        if (button && !hasError) {
            button.disabled = false;
        } else if (button) {
            button.disabled = true;
        }

        this.setState({
            ...this.state,
            pin: enteredPin,
        });

        if (!enteredPin && this.state.error) {
            this.setState({
                ...this.state,
                error: '',
            });
        }
    };

    getProduct() {
        if (this.state.web_products) {
            const product = this.state.web_products.find(
                (el) => el.id === this.state.productId
            );
            this.setState({
                ...this.state,
                product,
            });
        }
    }

    handleStopShowing = () => {
        this.setState({
            ...this.state,
            showModal: false,
        });
    };

    handleInputClear = (id) => {
        const inputEl = document.getElementById(id);
        if (inputEl) {
            inputEl.value = '';
        }
    };

    handleInputFocus = (e) => {
        e.target.parentElement.parentElement.parentElement.classList.toggle(
            'focus'
        );
    };

    handleCloseClick = (e) => {
        this.props.setModalSimpleState({
            show: false,
        });
    };

    handlePurchaseSubmit = (e, productId) => {
        e.preventDefault();
        const pinToCheck = this.props.auth.profile.pin
            ? this.props.auth.profile.pin
            : this.props.auth.profile.pin_with_default;
        if (this.state.pin === pinToCheck) {
            const button = this.modalConfirmBtnRef.current;
            button.disabled = true;
            button.classList.add('loading');
            const loaderSpan = document.createElement('span');
            loaderSpan.className = 'spinner';
            button.prepend(loaderSpan);
            if (
                !this.state.purchasedProductId ||
                productId !== this.state.purchasedProductId
            ) {
                this.fetchData({
                    path:
                        'prepaid-customers/' +
                        this.props.userinfo.customer_id.items +
                        '/purchases/',
                    params: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            product_id: productId,
                            calc_only: false,
                        }),
                    },
                    step: 0,
                    stepOnError: 0,
                    statePath: 'registration_confirm',
                });
            } else {
                const purchasedProduct = this.state.registration_purchases.find(
                    (el) =>
                        el.is_subscribed === true &&
                        el.id !== this.state.purchasedProductId
                );
                if (!purchasedProduct) {
                    alert(
                        'Purchased product ' +
                            this.state.purchasedProductId +
                            ' not in subscribed purchases.'
                    );
                } else {
                    this.fetchData({
                        path:
                            'prepaid-customers/' +
                            this.props.userinfo.customer_id.items +
                            '/purchases/' +
                            purchasedProduct.id,
                        params: {
                            method: 'DELETE',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        },
                        step: 0,
                        stepOnError: 0,
                        statePath: 'registration_confirm',
                        stateToSet: {
                            deletedProductId: this.state.purchasedProductId,
                            purchasedProductId: null,
                        },
                    });
                }
            }
        } else {
            this.handlePinEntered(this.state.pin, true);
        }
    };

    handleProductConfirm = (productId) => {
        const pinToCheck = this.props.auth.profile.pin
            ? this.props.auth.profile.pin
            : this.props.auth.profile.pin_with_default;
        this.props.setModalSimpleState({
            show: true,
            header: 'pin_required_title',
            style: { width: '400px' },
            body: (
                <div>
                    <div className="pt-3">
                        <FormattedMessage
                            id={
                                !this.state.purchasedProductId
                                    ? 'sign_up_subscribe_confirm'
                                    : this.state.purchasedProductId ===
                                      productId
                                    ? 'sign_up_remove_confirm'
                                    : 'sign_up_change_confirm'
                            }
                        />
                    </div>
                    <form
                        onSubmit={(e) =>
                            this.handlePurchaseSubmit(e, productId)
                        }
                    >
                        <div
                            className="form-group"
                            style={{ marginBottom: '47px', marginTop: '16px' }}
                        >
                            <div className={'d-flex'}>
                                <PinInput
                                    onPinEntered={(pin, hasError) =>
                                        this.handlePinEntered(pin, hasError)
                                    }
                                    pinToCheck={pinToCheck}
                                    passwordType={true}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                className="button btn-secondary btn-lg me-2"
                                onClick={(e) => this.handleCloseClick(e)}
                            >
                                <FormattedMessage
                                    id={'profile_button_cancel'}
                                />
                            </button>
                            <button
                                className="button btn-primary-bright btn-lg btn-loading"
                                disabled={true}
                                ref={this.modalConfirmBtnRef}
                            >
                                <FormattedMessage
                                    id={'pin_dialog_button_ok_text'}
                                />
                            </button>
                        </div>
                    </form>
                </div>
            ),
        });
    };

    handleModal = (e) => {
        const productId = parseInt(e.target.dataset.productId);
        const modalType = e.target.dataset.modalType;
        const product = this.state.web_products.find(
            (el) => el.id === productId
        );
        let channelsList = [];
        if (product && product.chids && this.state.channels) {
            for (const chid of product.chids) {
                const channel = this.state.channels.find(
                    (el) => el.id === chid
                );
                if (channel) {
                    channelsList.push(
                        <div key={channel.id} className="square-container">
                            <img
                                src={channel.logo}
                                title={channel.name}
                                alt=""
                                className="img-fluid square-image"
                            />
                        </div>
                    );
                }
            }
        }
        this.props.setModalSimpleState({
            show: true,
            header:
                modalType === 'channels'
                    ? 'sign_up_channels_list'
                    : 'sign_up_additional_conditions',
            body:
                modalType === 'channels' ? (
                    <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ paddingTop: '16px' }}
                    >
                        {channelsList}
                    </div>
                ) : (
                    getLangProp(
                        product,
                        'additional_terms',
                        this.props.auth.lang,
                        this.state.langDefault
                    )
                ),
        });
    };

    render() {
        if (
            this.state.changeMode === 'add_card' ||
            this.state.changeMode === ''
        ) {
            return <RegisterPaymentWaiting />;
        }

        return (
            <section
                className={classnames(style.sectionWrapper, {
                    [style.initial]: this.state.step === 0,
                })}
            >
                <div
                    className={style.registerContainer}
                    style={{
                        maxWidth:
                            this.state.step === 0 ||
                            this.state.step === 1 ||
                            this.state.step === 2
                                ? undefined
                                : this.state.step === 5 || this.state.step === 6
                                ? '925px'
                                : '517px',
                    }}
                >
                    {this.state.step === 0 && (
                        <SubscriptionOptions
                            filterStatus={this.state.filter_status}
                            onFilterClick={this.handleFilter}
                            changeMode={this.state.changeMode}
                            productsList={this.state.web_products}
                            purchasedProductId={this.state.purchasedProductId}
                            channels={this.state.channels}
                            lang={this.props.auth.lang}
                            langDefault={this.state.langDefault}
                            handleStepBtn={this.handleStepBtn}
                            productProperties={
                                this.state.web_product_properties
                            }
                            handleSwitch={this.handleSwitch}
                            sliderStatus={this.state.slider_status}
                        />
                    )}
                    {this.state.step === 1 &&
                        this.state.web_products &&
                        this.state.product &&
                        this.state.productId && (
                            <YourChoice
                                item={this.state.product}
                                lang={this.props.auth.lang}
                                langDefault={this.state.langDefault}
                                channels={this.state.channels}
                                handleStepBtn={this.handleStepBtn}
                                handleInputClear={this.handleInputClear}
                                handleInputFocus={this.handleInputFocus}
                                step={this.state.step}
                                error={this.state.error}
                                formData={this.state.formData}
                                formDataErrors={this.state.formDataErrors}
                                formSupports={this.state.formSupports}
                                formSupportsErrors={
                                    this.state.formSupportsErrors
                                }
                            />
                        )}
                    {this.state.step === 2 && this.state.product.is_prepaid && (
                        <RegisterTerms
                            item={this.state.product}
                            content={this.state.terms}
                            step={this.state.step}
                            termsAccepted={this.state.termsAccepted}
                            handleTermsCheck={this.handleTermsCheck}
                            handleStepBtn={this.handleStepBtn}
                            loading={this.state.loading}
                        />
                    )}
                    {this.state.step === 2 &&
                        !this.state.product.is_prepaid && <PostpaidThanks />}
                    {this.state.step === 3 && (
                        <EmailConfirm
                            step={3}
                            email={this.state.formData['email']}
                            handleStepBtn={this.handleStepBtn}
                            handlePinEntered={this.handlePinEntered}
                            hasError={this.state.error}
                            pin={this.state.pin}
                        />
                    )}
                    {this.state.step === 4 && (
                        <RegisterAccountForm
                            step={4}
                            handleStepBtn={this.handleStepBtn}
                            email={this.state.formData['email']}
                            handleInputFocus={this.handleInputFocus}
                            error={this.state.error}
                            formData={this.state.formData}
                            formDataErrors={this.state.formDataErrors}
                            formSupports={this.state.formSupports}
                            formSupportsErrors={this.state.formSupportsErrors}
                            loading={this.state.loading}
                        />
                    )}
                    {this.state.step === 5 && (
                        <YourChoicePayments
                            item={this.state.product}
                            lang={this.props.auth.lang}
                            langDefault={this.state.langDefault}
                            handleStepBtn={this.handleStepBtn}
                            handleModal={this.handleModal}
                            step={this.state.step}
                            formSupports={this.state.formSupports}
                            loading={this.state.loading}
                        />
                    )}
                    {this.state.step === 6 && (
                        <RegisterPaymentWaiting loading={this.state.loading} />
                    )}
                    {this.state.step === 7 &&
                        (this.state.loading ? (
                            <RegisterPaymentWaiting />
                        ) : this.state.registration_purchases &&
                          this.state.registration_purchases.message ? (
                            <RegisterMsgPage
                                headerId={'register_payment_fail_header'}
                                message={
                                    this.state.registration_purchases.message
                                }
                            />
                        ) : this.state['prepaid_transactions'] &&
                          (this.state['prepaid_transactions'][
                              'has_change_in_progress'
                          ] ||
                              this.state['prepaid_transactions'][
                                  'is_in_progress'
                              ]) ? (
                            <RegisterPaymentWaiting textId={'is_in_progress'} />
                        ) : this.state['prepaid_transactions'] &&
                          (this.state['prepaid_transactions'][
                              'payment_state'
                          ] === 'abandoned' ||
                              this.state['prepaid_transactions'][
                                  'payment_state'
                              ] === 'chargebacked' ||
                              this.state['prepaid_transactions'][
                                  'payment_state'
                              ] === 'voided' ||
                              this.state['prepaid_transactions'][
                                  'payment_state'
                              ] === 'refunded' ||
                              this.state['prepaid_transactions'][
                                  'payment_state'
                              ] === 'failed') ? (
                            <RegisterMsgPage
                                headerId={'register_payment_fail_header'}
                                textId={'register_payment_fail'}
                            />
                        ) : this.state.callback_purchases &&
                          this.state.callback_purchases.product_id ===
                              this.state.productId ? (
                            <RegisterMsgPage
                                headerId={'register_payment_success_header'}
                                textId={'register_payment_success'}
                                hideContacts={true}
                                bottomButtons={
                                    <NavLink
                                        className={
                                            'button btn-primary-bright btn-lg d-flex'
                                        }
                                        to={'/authorize/login'}
                                    >
                                        <div className={'w-100 text-nowrap'}>
                                            <FormattedMessage id="demo_dialog_cancel" />
                                        </div>
                                    </NavLink>
                                }
                            />
                        ) : this.state.formSupports ? (
                            this.state.formSupports['smartivus_id'] ? (
                                <RegisterMsgPage
                                    headerId={'register_payment_fail_header'}
                                    message={
                                        this.state.formSupports['smartivus_id']
                                    }
                                    body={
                                        <div
                                            className={
                                                'btn-group w-100 justify-content-center'
                                            }
                                        >
                                            <button
                                                className={
                                                    'button btn-secondary btn-lg w-50'
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.handleStepBtn(e);
                                                }}
                                                data-step={'5'}
                                            >
                                                <FormattedMessage id="register_another_card_btn" />
                                            </button>
                                        </div>
                                    }
                                />
                            ) : this.state.formSupports['product_id'] ? (
                                this.state.product ? (
                                    <RegisterMsgPage
                                        headerId={
                                            'product_group_already_purchased'
                                        }
                                        message={
                                            this.state.formSupports[
                                                'product_id'
                                            ]
                                        }
                                        body={
                                            <ProductItem
                                                id={this.state.product.id}
                                                purchasedProductId={
                                                    this.state.formSupports[
                                                        'product_id'
                                                    ]
                                                }
                                                changeMode={false}
                                                chids={this.state.product.chids}
                                                name={this.state.product.name}
                                                is_promoted={
                                                    this.state.product
                                                        .is_promoted
                                                }
                                                discount_percentages={
                                                    this.state.product
                                                        .discount_percentages
                                                }
                                                smartivus_id={
                                                    this.state.product
                                                        .smartivus_id
                                                }
                                                // handleStepBtn = {handleStepBtn}
                                                handleModal={this.handleModal}
                                                price={getLangProp(
                                                    this.state.product,
                                                    'price',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                discounted_price={getLangProp(
                                                    this.state.product,
                                                    'discounted_price',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                banner={getLangProp(
                                                    this.state.product,
                                                    'banner',
                                                    this.state.product.lang,
                                                    'en'
                                                )}
                                                placeholder_text_1={getLangProp(
                                                    this.state.product,
                                                    'placeholder_text_1',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                placeholder_text_2={getLangProp(
                                                    this.state.product,
                                                    'placeholder_text_2',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                placeholder_text_3={getLangProp(
                                                    this.state.product,
                                                    'placeholder_text_3',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                placeholder_image_1={getLangProp(
                                                    this.state.product,
                                                    'placeholder_image_1',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                placeholder_image_2={getLangProp(
                                                    this.state.product,
                                                    'placeholder_image_2',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                                placeholder_image_3={getLangProp(
                                                    this.state.product,
                                                    'placeholder_image_3',
                                                    this.state.lang,
                                                    this.state.langDefault
                                                )}
                                            />
                                        }
                                    />
                                ) : (
                                    <div>
                                        {this.state.formSupports['product_id']}
                                    </div>
                                )
                            ) : (
                                <RegisterPaymentWaiting />
                            )
                        ) : this.state.payment_methods &&
                          this.state.payment_methods.status === 'failed' ? (
                            <RegisterMsgPage
                                headerId={'register_payment_fail_header'}
                            />
                        ) : this.purchaseRequested &&
                          this.state.payment_methods &&
                          this.state.payment_methods.status ? (
                            <PostpaidThanks />
                        ) : null)}
                    {this.state.step === 8 && (
                        <RegisterMsgPage
                            headerId={'register_error_header'}
                            textId={'register_error'}
                        />
                    )}
                    {this.state.step === 9 &&
                        this.props.userinfo &&
                        this.props.auth.token &&
                        (this.state['prepaid_transactions'] &&
                        !this.state['prepaid_transactions']['is_in_progress'] &&
                        !this.state['prepaid_transactions'][
                            'has_change_in_progress'
                        ] ? (
                            <RegisterMsgPage
                                headerId={
                                    'sign_up_card_updated_header' +
                                    (this.state['prepaid_transactions'][
                                        'payment_state'
                                    ] !== 'settled'
                                        ? '_failed'
                                        : '')
                                }
                                textId={
                                    this.state['prepaid_transactions'][
                                        'payment_state'
                                    ] !== 'settled'
                                        ? 'register_try_again'
                                        : 'sign_up_card_updated'
                                }
                                hideContacts={true}
                                bottomButtons={
                                    this.state['prepaid_transactions'][
                                        'payment_state'
                                    ] !== 'settled' ? (
                                        <NavLink
                                            className={
                                                'button btn-primary-bright btn-lg d-flex me-3'
                                            }
                                            to={'/register?action=add_card'}
                                        >
                                            <div
                                                className={'w-100 text-nowrap'}
                                            >
                                                <FormattedMessage id="settings_add_payment_card" />
                                            </div>
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            className={
                                                'button btn-primary-bright btn-lg d-flex me-3'
                                            }
                                            to={'/register?action=change'}
                                        >
                                            <div
                                                className={'w-100 text-nowrap'}
                                            >
                                                <FormattedMessage id="settings_change_plan" />
                                            </div>
                                        </NavLink>
                                    )
                                }
                            />
                        ) : (
                            <RegisterPaymentWaiting />
                        ))}
                    {this.state.step === 9 &&
                        !(this.props.userinfo && this.props.auth.token) && (
                            <div>Not connected.</div>
                        )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userinfo: state.api_data.payload['userinfo'],
        modal_simple_state: state.modal_simple_state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalSimpleState: (props) => {
            dispatch(setModalSimpleState(props));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
