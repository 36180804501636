import React from 'react';
import BasicModal from 'components/elements/BasicModal';
import { FormattedMessage } from 'react-intl';
import style from './style.module.sass';

const ChannelModal = ({ channels, onClose }) => {
    if (!channels) return null;

    return (
        <BasicModal
            title={<FormattedMessage id="sign_up_channels_list" />}
            show={channels}
            onClose={onClose}
            className={style.modalContent}
        >
            <div className={style.channelLogoWrapper}>
                {channels.map((item) => (
                    <div key={item.id} className={style.channelLogoBox}>
                        <img
                            src={item.logo}
                            title={item.name}
                            alt={item.name}
                        />
                    </div>
                ))}
            </div>
        </BasicModal>
    );
};

export default ChannelModal;
