const crypto = require('crypto');
const slaptasRaktas = 'slaptas_raktas_labai';

function encrypt(text, secretKey) {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(secretKey), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return iv.toString('hex') + ':' + encrypted.toString('hex');
}

function decrypt(text, secretKey) {
  try {
    let textParts = text.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');
    const encryptedText = Buffer.from(textParts.join(':'), 'hex');
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(secretKey), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  } catch (error) {
    console.error('Decrypt:', error.message);
    // deleteCookie('slapuko_pavadinimas');
    return null;
  }
}

// Pakeičiam raktą į 32 baitų raktą
function getKey(secretKey) {
  return crypto.createHash('sha256').update(String(secretKey)).digest('base64').substr(0, 32);
}

/**
 * @param operation
 * @param path
 * @param name
 * @param value
 * @param exdays
 * @param contexts
 * https://github.com/GoogleChromeLabs/samesite-examples/blob/master/javascript.md
 */
export const handleCookie = ({
                                operation = 'add',
                                path = '/',
                                name = '',
                                value = '',
                                expireDays = 0,
                                contexts = 'first'
}) => {
// console.log('handleCookie', operation, path, name, value, expireDays)
  if (!name && operation === 'add') {
    return false
  }

  let expires = '';
  if (operation === "add") {
    const d = new Date();
    if (expireDays) {
      d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
      expires = "expires=" + d.toUTCString() + ";";
    }
  } else if (operation === "remove") {
    expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  }

  let contextString = 'SameSite=Lax'
  if (contexts !== 'first') {
    contextString = 'SameSite=None;Secure'
  }

  value = encrypt(value, getKey(slaptasRaktas));

  document.cookie = name + "=" + value + ";" + expires + "path=" + path + ";" + contextString;
}

/**
 *
 * @param cname
 * @returns {string}
 */
export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      // return c.substring(name.length, c.length);
      return decrypt(c.substring(name.length, c.length), getKey(slaptasRaktas));
    }
  }
  return "";
}
