export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const SET_MODAL_OBJ = 'SET_MODAL_OBJ';

/**
 * @param id
 * @param title
 * @param body
 * @param back_button
 * @param header_icon
 * @param no_close
 * @returns {{payload: {header_icon, id, title, body, back_button}, type: string}}
 */
export const setModalState = (id, title, body, back_button, header_icon, no_close) => {
    return {
        type: SET_MODAL_STATE,
        payload: {
            id: id,
            title: title,
            body: body,
            back_button: back_button,
            header_icon: header_icon,
            no_close: no_close
        }
    }
}

export const setModalObj = (obj) => {
    return {
        type: SET_MODAL_OBJ,
        payload: {
            obj: obj
        }
    }
}