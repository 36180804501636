export const createProfile = (profile) => {
    //-- with thunk
console.log('createProfile action', profile)    
    return (dispatch, getState) => {
        //-- make call
        dispatch({
            type: 'CREATE_PROFILE',
            profile: profile
        });
    }

}