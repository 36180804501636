import React, {Component} from 'react'
import {CarouselSection} from '../elements/CarouselSection'
// import Spinner from '../../img/spinner.svg'
import {connect} from 'react-redux'
import {fetchAction} from '../../store/actions/fetchActions'
import {bookmarksEndpoint, smartRowsEndpoint} from "../../store/actions/apiEndpoints";
import {BookmarksMap} from "../../store/actions/ApiKeysMap";
// import FlagIcon from "../../img/flag-icon.svg";
import {setAppClass} from "../../store/actions/globalStylesActions";

class Vod extends Component {

    fetchStamps = {}
    smartFetchStamps = {}
    renderStamp = 0
    arrivedStamps = {}
    smartRowsArrived = false
    carouselItems = []
    carouselProportions = []
    carouselHeights = []
    basicPayload = ['vod']
    loading = true
    adultPinStampValid = false

    componentDidMount() {
// console.log('Vod mount', this.props.auth, this.props.auth.profile)
        // if (!this.props.auth || !this.props.auth.profile) {
        //     return <Redirect to="/" />
        // }
        // this.props.setAppClass('vod')

        this.props.setAppClass('home')

        if (this.props.auth && this.props.auth.profile) {
            this.props.fetchAction(
                this.props.auth.token,
                bookmarksEndpoint(
                    {
                        'lang': this.props.auth.lang,
                        'filter_profile': this.props.auth.profile.id
                    }),
                this.constructor.name,
                BookmarksMap
            )
        }
    }

    shouldComponentUpdate = (nextProps) => {
// console.log('shouldComponentUpdate?', this.props.auth.profile, nextProps.auth.profile)
        const nowStamp = Date.now() / 1000 | 0

        if (!this.fetchStamps || !Object.keys(this.fetchStamps).length) {
// console.log('yes!')
            return true
        }

        if (this.fetchStamps && Object.keys(this.fetchStamps).length !== 0) {
            // for (let i in this.fetchStamps) {
            for (const i of this.basicPayload) {
                // if (!this.props.api_data.payload[i] && i !== 'vod') {
                if (!this.props.api_data.payload[i]) {
                    // console.log('no... no basic payload:', i)
                    return false
                }
            }

            //-- jei gautas vod ir jis turi itemų, patikrinam ar tie itemai gauti
            if (this.props.api_data.payload['vod'] && this.props.api_data.payload['vod']['items']['items'].length) {
                this.loading = false
                this.smartRowsArrived = true
                for (const item of this.props.api_data.payload['vod']['items']['items']) {
                    const { group } = item
// console.log('group item in payload?', item)
                    if (group && !this.props.api_data.payload[group['id']]) {
// console.log('still loading', group['id'])
                        this.loading = true
                        this.smartRowsArrived = false
                        if (!this.fetchStamps[group['id']] || nowStamp > (this.fetchStamps[group['id']] + 20)) {
                            // console.log('about to fetch', group['id'], this.fetchStamps[group['id']])
                            this.fetchStamps[group['id']] = nowStamp
                            this.props.fetchAction(
                                this.props.auth.token,
                                smartRowsEndpoint({
                                    'id': group['id'],
                                    'lang': this.props.auth.lang,
                                    'filter_profile': this.props.auth.profile.id,
                                    'page_limit': null
                                }),
                                this.constructor.name,
                                group['id']
                            )
                        }
                    } else if (group && this.props.api_data.payload[group['id']]) {
                        this.arrivedStamps[group['id']] = nowStamp
                    }
                }
                if (!this.loading && this.smartRowsArrived && (!this.renderStamp || nowStamp > this.renderStamp + 20)) {
                    return true
                }
            } else {
                return false
            }
        }

        return false
    }

    CarouselHandler = (el, proportion) => {
        this.carouselItems.push(el)
        this.carouselProportions.push(proportion)
        this.carouselHeights.push(0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
// console.log('Vod componentDidUpdate', this.props.auth.profile)
        const nowStamp = Date.now() / 1000 | 0
        if (this.props.global_styles.adult_pin_stamp && (this.props.global_styles.adult_pin_stamp + 180 > nowStamp)) {
            this.adultPinStampValid = true
        }
        if (this.props.auth.profile && (!this.fetchStamps['vod'] || this.props.auth.profile_stamp > this.fetchStamps['vod'] + 15)) {
            this.fetchStamps['vod'] = nowStamp
            this.props.fetchAction(
                this.props.auth.token,
                smartRowsEndpoint({
                    id: 'vod',
                    lang: this.props.auth.lang,
                    filter_profile: this.props.auth.profile.id,
                    age_offset: 0,
                    page_limit: 100
                }),
                this.constructor.name,
                'vod'
            )
        }

        // console.log('set carousel Height?', !this.loading && this.smartRowsArrived && this.carouselItems.length, !this.loading, this.smartRowsArrived, this.carouselItems.length)
        if (!this.loading && this.smartRowsArrived && this.carouselItems.length) {
            for (const i in this.carouselItems) {
                if (!this.carouselItems[i]) {
                    // console.log('no carousel item:', i)
                    continue
                }
// console.log('state', this.carouselItems[i]['state'])
                let imageHeight = 0
                if (this.carouselProportions[i] === '16_9') {
                    imageHeight = (this.carouselItems[i]['state']['itemWidth'] * 9 / 16 | 0) - 7
                } else {
                    imageHeight = (this.carouselItems[i]['state']['itemWidth'] * 3 / 2 | 0) - 13
                }
                if (this.carouselItems[i]['listRef']['current'] && this.carouselHeights[i] !== imageHeight && imageHeight > 0) {
// console.log('height set:', i, this.carouselHeights[i], imageHeight)
                    this.carouselItems[i]['listRef']['current'].style.minHeight = imageHeight + 'px'
                    this.carouselHeights[i] = imageHeight
                }
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.liveRenderInterval)
    }

    render() {
        // console.log('render! loading:', this.loading)
        const nowStamp = Date.now() / 1000 | 0

        // if (!this.loading) {
        //     console.log('loading over.')
        // } else {
        //     console.log('still loading...')
        // }

        const Loading = () => {
            return (
                <div className="loader">
                    <span className="loader-img"/>
                    {/*<img className="loader-img" src={Spinner} alt="" />*/}
                </div>
            )
        }

        const NoData = () => {
            return (
                <div className="grouped-text"/>
            )
        }

// console.log('profile:', this.props.auth)
        // if (this.constructor.name === source) {
        if (this.loading && this.props.auth.profile) {
            return <Loading />
        } else if (this.props.api_data.payload.vod && !this.renderStamp) {
            this.renderStamp = nowStamp
            this.carouselItems = []
            this.carouselHeights = []
            return (
                this.props.api_data.payload.vod.items.items.map((section, index) => {
                    if (
                        !this.props.api_data.payload[section.group.id]
                        || !this.props.api_data.payload[section.group.id]['total']
                        || !this.props.api_data.payload[section.group.id]['total']['items']
                    ) {
                        return null
                    }

                    return (
                        <div key={"section_" + index}>
                            <CarouselSection
                                sectionIndex={index}
                                sectionId={section.group.id}
                                sectionItem={section.group}
                                items={this.props.api_data.payload[section.group.id].items.items}
                                channels={null}
                                epg={null}
                                lang={this.props.lang}
                                adultPinStampValid={this.adultPinStampValid}
                                CarouselHandler={this.CarouselHandler}
                            />
                        </div>
                    )
                })
            )
        } else {
            return <NoData />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        api_data: state.api_data,
        auth: state.auth,
        global_styles: state.global_styles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vod)
