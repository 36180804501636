import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import DragDropLines from '../../img/drag-drop-lines.svg'
import DragDropArrows from '../../img/drag-drop-arrows.svg'

class Language extends Component {
  render() {

    let DragDropIcon = DragDropLines
    if (this.props.language.id === this.props.drag) {
      DragDropIcon = DragDropArrows
    }

    return (
      <Draggable draggableId={this.props.language.id} index={this.props.index} >
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className="language"
          >
            <img src={DragDropIcon} alt={this.props.language.content}/>
            <p>{this.props.language.content}</p>
          </div>
        )}
      </Draggable>
    )
  }
}

export default Language