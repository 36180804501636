import React from 'react'
import {FormattedMessage} from "react-intl";

const RegisterPaymentWaiting = ({textId}) => {
    return (
        <div className={"w-100 text-center"} style={{paddingTop: '76px'}}>
            <h1 style={{marginBottom: '72px'}}>
                <FormattedMessage id={textId ? textId : "sign_up_payment_waiting"}/>
            </h1>
            <div className={"d-flex justify-content-center"}>
                <div className={"circular-progress"}/>
            </div>
        </div>
    )
}

export default RegisterPaymentWaiting