import { setTimelineDots, setTimelineStep, timelineDuration } from '../../components/addons/DashControlBar'

export const EpgToChannelDays = (payload, params) => {
// console.log('EpgToChannelDays', payload['epg'], params)
    let outputRoot = {}
    const version = Date.now() / 1000 | 0

    for (let i in payload) {
        let output = {}
        outputRoot[i] = output
        // if (params && params.days) {
            for (const element of payload[i]) {
                const channelId = String(element.channel_id)
                const day = ~~(element.start / 86400)
                if (typeof output[channelId] === 'undefined') {
                    output[channelId] = {
                        version: version,
                        items: {
                            [day]: []
                        }
                    }
                } else if (typeof output[channelId]['items'][day] === 'undefined') {
                    output[channelId]['items'][day] = []
                }
                output[channelId]['items'][day].push(element)
            }
        // }
    }

    return outputRoot
}

export const BookmarksMap = (payload) => {
    let outputRoot = {
        items: payload['bookmarks']
    }
    for (const i in payload) {
        if (Array.isArray(payload[i])) {
            let output = {}
            for (const k in payload[i]) {
                if (typeof output[payload[i][k]['type']] === 'undefined') {
                    output[payload[i][k]['type']] = {}
                }
                output[payload[i][k]['type']][payload[i][k]['event_id']] = parseInt(k)
            }
            outputRoot['maps'] = output
        } else {
            outputRoot[i] = payload[i]
        }
    }

    return { bookmarks: outputRoot }
}

export const ProductGroupsMap = (payload, filter) => {
// console.log('ProductGroupsMap', payload, filter)
    let maps = { id: {}}
    if (Array.isArray(payload)) {
        if (filter != null
            && typeof payload[0] !== 'undefined'
            && typeof payload[0]['products'] !== 'undefined'
        ) {
            payload[0]['products'].forEach((item, i) => maps['id'][item['id']] = i)
        } else {
            payload.forEach((item, i) => maps['id'][item['id']] = i)
        }
    }

    let outputRoot = {
        items: (
            filter != null &&
            typeof payload[0] !== 'undefined' &&
            typeof payload[0]['products'] !== 'undefined'
        ) ? payload[0]['products'] : payload,
        maps: maps
    }
    if (filter != null) {
        return { product_groups: {
            chid: {
                [filter['chid']] : filter['chid'] != null ? outputRoot : null
            },
            vodprovid: {
                [filter['vodprovid']] : filter['vodprovid'] != null ? outputRoot : null
            },
        }}
    }

// console.log('product_groups', outputRoot)

    return { product_groups: outputRoot }
}

export const ProductsMap = (payload) => {
    let maps = { id: {}}
    if (Array.isArray(payload)) {
        payload.forEach((item, i) => maps['id'][item['id']] = i)
    }

    let outputRoot = {
        items: payload,
        maps: maps
    }

    return { product_selected: outputRoot }
}

export const RemindersMap = (payload) => {
    let outputRoot = {
        items: payload
    }
    if (payload && Array.isArray(payload)) {
        let epgMap = {}
        for (const i in payload) {
            epgMap[payload[i].epg.id] = i
        }
        outputRoot['epg_map'] = epgMap
    }

    return { reminders: outputRoot }
}

export const WishlistMap = (payload) => {
    let outputRoot = {}
    const itemKeys =['vod', 'group']

    if (payload && payload['items'] && Array.isArray(payload['items'])) {
        outputRoot['items'] = payload['items']
        let vodMap = {}
        for (const i in payload['items']) {
            for (const itemKey of itemKeys) {
                if (typeof payload['items'][i][itemKey] === 'undefined') {
                    continue
                }
                vodMap[payload['items'][i][itemKey]['id']] = i
            }
        }
        outputRoot['vod_map'] = vodMap
    }

    return { wishlist: outputRoot }
}

export const VodAboutWrap = (payload, params) => {
    return {
        vod_about: payload,
        vod_about_stamp: Math.round(Date.now() / 1000),
    }
}

//-- {payload} - output from GET https://s4-gw.cgates.lt/box4.0/channels/561401/sprites/from/1709624674/to/1709628000
export const SpritesToVodTimeline = (payload) => {
    return setTimelineDots(fillTimelineDots(payload, false, true))
}

export const SpritesToEpgTimeline = (payload, params) => {
    return setTimelineDots(fillTimelineDots(payload, params))
}

const fillTimelineDots = (payload, params, reverse) => {
    if (!payload || typeof payload['sprites'] === 'undefined' || !payload['sprites'].length) {
        return false
    }

    let timelineDots
    const duration = Math.round(timelineDuration / payload['step'])
// console.log('timelineDuration', timelineDuration)
// console.log('duration', duration)
    setTimelineStep(payload['step'])
    const pictureWidth = payload['scale_width'] * payload['tile_width']
    const pictureHeight = payload['scale_height'] * payload['tile_height']

    //-- susikuriam masyvą, kurio ilgis = šio epg ilgiui sekundėmis/step, tada on hover time lengvai išrinksim reikiamą
    //-- iteruodami gautus spraitus, konvertuojam jo start į masyvo indeksą, ir tą masyvo elementą užpildom reikšmėmis,
    //-- reikalingomis img'ui
    //-- plejeryje, jei hoverintas tuščias, iteruosim link pradžios ir imsim pirmą ne tuščią, bet ne labai tolimą
    timelineDots = Array(duration).fill(false)
    const lastDotIndex = timelineDots.length - 1
    const widthDots = Array(payload['tile_width']).fill(undefined)
    const heightDots = Array(payload['tile_height']).fill(undefined)
    if (reverse) {
        payload['sprites'].reverse()
    }
    let startStamp = 0
    if (params) {
        //-- offset 50
        startStamp = params['start'] + 50
    }
    for (let sprite of payload['sprites']) {
        let i = 0
        for (const h in heightDots) {
            for (const w in widthDots) {
                const frameStamp = sprite['start'] + payload['step'] * i
                const frameIndex = Math.round((frameStamp - startStamp) / payload['step'])
                if (frameIndex < 0) {
                    i += 1
                    continue
                } else if (frameIndex > lastDotIndex) {
                    break
                }
                timelineDots[frameIndex] = {
                    width: payload['scale_width'],
                    height: payload['scale_height'],
                    stamp: frameStamp,
                    background_url: sprite['sprite'],
                    background_x: payload['scale_width'] * (payload['tile_width'] - w) - pictureWidth,
                    background_y: payload['scale_height'] * (payload['tile_width'] - h) - pictureHeight
                }
                i += 1
            }
        }
    }
// console.log('timelineDots', timelineDots)

    return timelineDots
}
