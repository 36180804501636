import React, { Component } from 'react'
import Column from './Column'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { FormattedMessage } from "react-intl";
import config from '../../config/config'
import { handleCookie } from '../addons/CookiesHandler';
import { getPriorityList } from '../addons/PrioritiesHandler';

class DragDropList extends Component {

  dragColumn = 'column-1'

  componentDidMount() {
    const regionNamesLangs = new Intl.DisplayNames([this.props.auth.lang], { type: 'language' });
    const languagePriorityOrdered = getPriorityList({
      cookieName: config.audioPriorityCookieName,
      defaultList: config.languagePriorityOptions,
      defaultValue: this.props.auth.lang
    })
// console.log('languagePriorityOrdered', config.languagePriorityOptions, languagePriorityOrdered)
    let languagesDict = {}
    languagePriorityOrdered.forEach((id) => languagesDict[id] = {id: id, content: regionNamesLangs.of(id)})

    this.setState({
      columns: {
        "column-1": {
          id: this.dragColumn,
          title: this.props.type === 'audio' ? <FormattedMessage id="player_audio_language_title" /> : <FormattedMessage id="player_subtitle_language_title" />,
          languageIds: languagePriorityOrdered
        }
      },
      columnOrder: [this.dragColumn],
      languages: languagesDict
    })
  }

  onDragStart = result => {
    this.setState({
      ...this.state,
      drag: result.draggableId,
    })
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result

    this.setState({
      ...this.state,
      drag: null,
    })

    if (!destination) {
      return
    }

    if (
        destination.droppableId === source.droppableId &&
        // destination.idnex === source.index
        destination.index === source.index
    ) {
      return
    }

    const column = this.state.columns[source.droppableId]
    const newTaskIds = Array.from(column.languageIds)
    newTaskIds.splice(source.index, 1)
    newTaskIds.splice(destination.index, 0, draggableId)

    const newColumn = {
      ...column,
      languageIds: newTaskIds
    }

    const newState = {
      ...this.state,
      drag: null,
      columns: {
        ...this.state.columns,
        [newColumn.id]: newColumn,
      }
    }
// console.log('onDragEnd', newState['columns'][this.dragColumn]['languageIds'])
    handleCookie({
      name: config.audioPriorityCookieName,
      value: JSON.stringify(newState['columns'][this.dragColumn]['languageIds']),
      expireDays: 365
    })

    this.setState(newState)
  }

  render() {
    return (
        this.state !== null ? (
            <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
              {this.state.columnOrder.map(columnId => {
                const column = this.state.columns[columnId]
                const languages = column.languageIds.map(languageId => this.state.languages[languageId])

                return <Column key={column.id} column={column} languages={languages} type={this.props.type} drag={this.state.drag} />
              })}
            </DragDropContext>
        ) : null
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps, null)(DragDropList)