export const getLangProp = (item, prop, lang, langDefault) => {
  if (item[prop + '_' + lang]) {
    return item[prop + '_' + lang]
  }
  if (!item[prop + '_' + langDefault]) {
    // console.log('Product item not found:', item.id, prop, ' no language prop:', lang, langDefault)
    return null
  }

  return item[prop + '_' + langDefault]
}

