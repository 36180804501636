import React, { Component } from 'react'
import {FormattedMessage} from "react-intl";
import config from "../../config/config";

class Settings extends Component {

    handleClick = (todo) => {
        if (todo === 'logout') {
            window.location.href = config['clientURI'] + "/logout"
        } else if(todo === 'login') {
            window.location.href = config['clientURI'] + "/login"
        } else {
            // window.location.href = 'https://cgates.tv/sign-up?tenantId=5106ba62-5bbd-11eb-9eb7-005056825ba6&client_id=41f9bebf-2948-4f47-9dd3-d9b2edacacd3&nonce=&redirect_uri=http%3A%2F%2Flocalhost%3A9000%2Foauth-callback&response_mode=&response_type=code&scope=&state=&timezone=Europe%2FVilnius&metaData.device.name=Linux%20Firefox&metaData.device.type=BROWSER&code_challenge=&code_challenge_method=&user_code=&locale=lt'
            // window.location.href = 'https://cgates.tv/sign-up?tenantId=5106ba62-5bbd-11eb-9eb7-005056825ba6&client_id=41f9bebf-2948-4f47-9dd3-d9b2edacacd3&nonce=&redirect_uri=https%3A%2F%2Fcgates.tv%2Fauthorize%2Foauth-callback&response_mode=&response_type=code&scope=&state=&timezone=Europe%2FVilnius&metaData.device.name=Linux%20Firefox&metaData.device.type=BROWSER&code_challenge=&code_challenge_method=&user_code=&locale=lt'
            window.location.href = '/register'
        }
    }

    render(){
        return (
            <div className="container">
                <div className="row align-items-center" style={{minHeight: '10rem', maxWidth: '600px'}}>
                    <div className="col-12 mt-5">
                        <h2 className="text-center"><FormattedMessage id="demo_dialog_title"/></h2>
                    </div>
                    <div className="col-12 mt-5">
                        <p className="text-center"><FormattedMessage id="demo_dialog_message"/></p>
                    </div>
                    <div className="col-12 mt-5 text-center">
                        <button className="button btn-primary btn-lg w-100" onClick={() => this.handleClick('login')}>
                            <FormattedMessage id="demo_dialog_cancel" />
                        </button>
                    </div>
                    <div className="col-12 mt-3 text-center">
                        <button className="button btn-secondary btn-lg w-100" onClick={() => this.handleClick('register')}>
                            <FormattedMessage id="demo_dialog_confirm" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings