import React from 'react'
import ConditionsContent from "../auth/ConditionsContent";
import config from '../../config/config'

const Privacy = ({lang}) => {
    let privacyURL = ''
    const privacyConfigKey = 'privacyURL_' + lang

    if (typeof config[privacyConfigKey] !== 'undefined') {
        privacyURL = config[privacyConfigKey]
    }

    return (
        <div>
            <ConditionsContent fileURL={privacyURL} />
        </div>
    )
}

export default Privacy
