import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import Media from 'react-media'
import ReactMarkdown from 'react-markdown'
import CloseIcon from '../../img/close-icon.svg'
import Logo from '../../img/logo.svg'
import LanguageSelectDropdown from './LanguageSelectDropdown'
import rehypeRaw from 'rehype-raw'

const ConditionsContent = props => {
    const [content, setContent] = useState('')

    const { setShowConditions, fileURL } = props

    useEffect(() => {
        fetch(fileURL)
            .then(response => response.text())
            .then(result => setContent(result))
    }, [fileURL])

    return setShowConditions ? (
        <div className="conditions-content-page">
            <img className="cgates-logo" src={Logo} alt="cgates logo"/>
            <LanguageSelectDropdown/>
            <Media query='(max-width: 768px)' render={() => (
                <button className="close-button" onClick={() => setShowConditions(false)}>
                    <img src={CloseIcon} alt='close icon'/>
                </button>
            )}
            />
            <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]}/>
            <button className="button" onClick={() => setShowConditions(false)}><FormattedMessage id="register_back"/>
            </button>
        </div>
    ) : (
        <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]}/>
    )
}

export default ConditionsContent