import React, { useState } from 'react';
import InfoIcon from 'img/info-icon.svg';
import classnames from 'classnames';
import DetailsModal from './DetailsModal';
import style from './style.module.sass';

const PlaceholderItem = ({
    id,
    text,
    image,
    chids,
    conditions,
    handleModal,
    onOpenModal,
    additionalDetails,
    compact,
}) => {
    const [detailsOpen, setDetailsOpen] = useState(false);

    if (!text && !image) return null;

    const wrapperClasses = classnames(style.placeholderWrapper, {
        [style.compact]: compact,
    });

    const textWrapperClasses = classnames(style.placeholderText, {
        [style.compact]: compact,
    });

    const onDetailsToggle = () => {
        setDetailsOpen(!detailsOpen);
    };

    return (
        <div className={wrapperClasses}>
            {!!text && (
                <div className={textWrapperClasses}>
                    <h3>{text}</h3>
                    {(chids || conditions) && (
                        <button
                            type="button"
                            data-product-id={id}
                            data-modal-type={chids ? 'channels' : 'conditions'}
                            onClick={
                                additionalDetails
                                    ? onDetailsToggle
                                    : onOpenModal
                                    ? () => onOpenModal(id)
                                    : handleModal
                            }
                            className={style.infoButton}
                        >
                            <img
                                src={InfoIcon}
                                alt="Info"
                                className="flex-shrink-0 cursor-pointer"
                            />
                        </button>
                    )}
                </div>
            )}
            {!!image && (
                <div className="justify-content-center">
                    <img className="img-fluid" src={image} alt="" />
                </div>
            )}
            {additionalDetails && (
                <DetailsModal
                    show={detailsOpen}
                    details={additionalDetails}
                    onClose={onDetailsToggle}
                />
            )}
        </div>
    );
};

export default PlaceholderItem;
