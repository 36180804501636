import React from 'react'
import { FormattedMessage } from 'react-intl'
import Loading from './Loading'
import Logo from '../../img/logo.svg'

const Registering = props => {
    const { setLoading } = props

    const handleCancel = () => {
        setLoading(false)
    }

    return (
        <div className='registering-page'>
            <img className='cgates-logo' src={Logo} alt='cgates logo' />
            <p className='title'><FormattedMessage id="register_loading_header" /></p>
            <p className='text'><FormattedMessage id="register_loading_message" /></p>
            <div className='loader-container'>
                <Loading />
            </div>
            <button className='button' onClick={handleCancel}><FormattedMessage id="register_cancel" /></button>
        </div>
    )
}

export default Registering