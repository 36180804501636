import { 
  PLAYER_SET_EPGINDEX, 
  PLAYER_SET_VODINDEX, 
  PLAYER_SET_EPGID, 
  PLAYER_SET_VODID, 
  PLAYER_SET_VOD, 
  PLAYER_SET_VODGENRES, 
  PLAYER_SET_EPG,
  PLAYER_SET_MUTED, 
  PLAYER_SET_VOLUME, 
  PLAYER_SET_OPEN, 
  PLAYER_SET_CHANNELID,
  PLAYER_SET_CHANNEL_LOADING,
  PLAYER_SET_CHANNEL_LOADED 
} from '../actions/playerActions';

const initState = {
  open : false,
  muted : false,
  volume : 0.5,
  epg : null,
  epg_id : null,
  epg_index : null,
  channel_id : null,
  channel_loading : null,
  channel_loaded : null,
  vod_id : null,
  vod_index : null,
  vod_genres_map : null,
  vod : null
}

const reducer = (state = initState, action) => {  
      switch (action.type) {
        case PLAYER_SET_EPGINDEX:
          return {
            ...state,
            epg_index: action.item,
          }
        case PLAYER_SET_VODINDEX:
          return {
            ...state,
            vod_index: action.item,
          }
        case PLAYER_SET_EPGID:
          return {
            ...state,
            epg_id: action.item,
          }
        case PLAYER_SET_VODID:
          return {
            ...state,
            vod_id: action.item,
          }
        case PLAYER_SET_VOD:
          return {
            ...state,
            vod: action.items,
          }
        case PLAYER_SET_VODGENRES:
          return {
            ...state,
            vod_genres_map: action.items,
          }
        case PLAYER_SET_EPG:
          return {
            ...state,
            epg: action.items,
          }
        case PLAYER_SET_MUTED:
          return {
            ...state,
            muted: action.status,
          }
        case PLAYER_SET_VOLUME:
          return {
            ...state,
            volume: action.value,
          }
        case PLAYER_SET_CHANNELID:
          return {
            ...state,
            channel_id: action.item,
          }
        case PLAYER_SET_CHANNEL_LOADING:
          return {
            ...state,
            channel_loading: action.item,
          }
        case PLAYER_SET_CHANNEL_LOADED:
          return {
            ...state,
            channel_loaded: action.item,
          }
        case PLAYER_SET_OPEN:
          return {
            ...state,
            open: action.open,
          }
        default: return state
      }
    }
  
export default reducer