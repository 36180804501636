import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Genres from '../elements/Genres'
import Channels from '../elements/Channels'
import EpgList, { indexToPosition, getEpgProgress } from '../elements/EpgList'
import EpgDetail from '../elements/EpgDetail'
import { setAppClass } from '../../store/actions/globalStylesActions'
import { fetchAction, fetchSuccess } from '../../store/actions/fetchActions'
import {
    genresEndpoint,
    channelsEndpoint,
    epggridEndpoint
} from '../../store/actions/apiEndpoints'
import {EpgToChannelDays} from '../../store/actions/ApiKeysMap'
import { Redirect } from 'react-router-dom'
// import { useIntl } from 'react-intl'

class Tv extends Component {
    constructor(props){
        super(props)
        this.channelsListRef = React.createRef()
    }

    channelSelected = null
    genresRequested = false
    channelsRequested = false
    genresChecked = false
    genresUsed = {}
    epgChannelLoadedId = ''
    //-- epg redux'e versija, kai jų ten daugiau, nei 4 dienos
    epgReduxOldVersion = null
    reduxEpgVersion = null
    iteratedVersion = null
    iteratedDays = 0
    // versionRendered = null
    //-- kai true, užkrovus kanalus paklikinam pirmąjį
    selectFirstEpg = true
    timeoutElapsed = false
    loadingElement = 'genres'
    loadingGenres = false
    loadingChannels = false
    loadingEpg = false
    scrollTop = null
    prevListIndex = null
    weekDays = {}
    fetchStamps = {}
    renderStamps = {}
    adultBlur = false
    channelGenresCollected = false
    channelGenres = {}

    state = {
        epg: [],
        channelGenres: {},
        nowEpg: null,
        nowDay: null,
        activeDay: null,
        nowListIndex: null,
        activeListIndex: null,
        // genreSelected: null,
        channelSelectedId: null,
        epgSelected: null,
        epgProgress: null
    }

    handleChannelClick = (e) => {
        //-- jei paspaudė channel
        this.timeoutElapsed = true
        this.loadChannelEpg(e)
    }

    loadChannelEpg = (e) => {
        //-- jei paspaudė kitą kanalą, arba dar joks nebuvo paspaustas
        //-- paimam jį į this.channelSelected
        //-- kraunam jo epg
        e = parseInt(e) | null
        if (e && this.props.channels && this.props.channels.items && this.props.channels.items.length && (!this.channelSelected || this.channelSelected.id !== e)) {
            this.loadingElement = 'epg'
            this.channelSelected = this.props.channels.items[this.props.channels['maps']['id'][e]]
            const nowTimestamp = Date.now() / 1000 | 0

            //-- ir jo dar nėra reduxe, arba net jei ir yra, bet mažiau, nei 3 dienos, fečinam iš apio
            //-- tik jei ką tik nefečinom
            const reduxEpgDays = this.props.epg && this.props.epg[e] ? Object.keys(this.props.epg[e]['items']).length : 0
            this.reduxEpgVersion = this.props.epg && this.props.epg[e] ? this.props.epg[e]['version'] : null
// console.log('if:', reduxEpgDays < 5, !this.reduxEpgVersion, nowTimestamp > this.reduxEpgVersion)

            if (
                reduxEpgDays < 5 &&
                (!this.reduxEpgVersion || nowTimestamp > this.reduxEpgVersion)
            ) {
// console.log('fetchAction?')
                this.props.fetchAction(
                    this.props.auth.token,
                    epggridEndpoint({
                        channels: [this.channelSelected.id],
                        pastDays : 14,
                        futureDays : 5,
                        lang : this.props.auth.lang
                    }),
                    this.constructor.name,
                    EpgToChannelDays,
                    {days: true}
                )
                this.fetchStamps['epg'] = nowTimestamp
            }

            clearInterval(this.interval)
            //-- todo pamažinti intervalą iki minutės
            this.interval = setInterval(() => {
                this.timeoutElapsed = true
// console.log('set state:', this.state)
                this.setState({
                    ...this.state,
                    epgProgress: this.state.nowEpg ? getEpgProgress(this.state.epgSelected.start, this.state.epgSelected.stop, Date.now() / 1000 | 0) : 0,
                })
                this.loadChannelEpg(this.channelSelected.id)
            }, 60000)
        }
    }

    handleEpgClick = (i) => {
        this.loadingElement = 'epg-detail'
        const epgSelected = this.state.epg[i]
        this.setState({
            ...this.state,
            activeListIndex: i,
            epgSelected: epgSelected,
            epgProgress: this.state.nowEpg ? getEpgProgress(epgSelected.start, epgSelected.stop, Date.now() / 1000 | 0) : 0,
        })
    }

    setDay = (day, scrollTop, index) => {
        // console.log('setDay', day, scrollTop, index)
        if (index) {
            scrollTop = indexToPosition(index)
        }
        this.scrollTop = scrollTop

        this.setState({
            ...this.state,
            activeDay: day
        })
    }

    componentDidMount() {
// console.log('tv componentDidMount', this.props)
// console.log('tv componentDidMount', typeof this.props.genres.items === 'undefined', !this.genresRequested)
        if (this.props.match.params) {
            if (this.props.match.params.channel_id) {
                this.loadChannelEpg(this.props.match.params.channel_id)
            }
        }

        this.props.setAppClass('tv')
        if (this.props.appElRef && this.props.appElRef.current) {
            this.props.appElRef.current.className = 'App tv'
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    shouldComponentUpdate(nextProps, nextState) {
        //-- jei dar to nedarėm, ir yra kanalai, susikuriam channelGenres
        if (
            !this.channelGenresCollected &&
            nextProps.api_data.payload.channels &&
            nextProps.api_data.payload.channels.items
        )
        {
            for (const channelItem of nextProps.api_data.payload.channels.items) {
                if (this.props.auth.profile &&
                    this.props.auth.profile.age &&
                    channelItem['age_rating']  &&
                    channelItem['age_rating'] > this.props.auth.profile.age)
                {
                    continue
                }
                this.channelGenres[channelItem['genre_id']] = true
            }
            this.channelGenresCollected = true
        }

        //-- kad nebūtų tučia įėjus, klikinam ant pirmo kanalo
        if (this.selectFirstEpg && !this.channelSelected) {
// console.log('click on first channel?', this.selectFirstEpg && !this.channelSelected)
            if (this.props.channels && this.props.channels.items)
            {
                const lcnSorted = Object.keys(this.props.channels['maps']['lcn']).sort()
                //-- ieškom pirmo subscribinto kanalo
                for (const i in lcnSorted) {
                    const firstChannel = this.props.channels['items'][this.props.channels['maps']['lcn'][lcnSorted[i]]]
                    if (firstChannel['subscribed']) {
// console.log('click on first channel!')
                        this.loadChannelEpg(firstChannel.id)
                        break
                    }
                }
            }
            return true
        }

        //-- jei nėra epgChannelLoadedId arba jis !== channelSelected.id
        //-- jei jis yra reduxe
        //-- jį iteruojam, kad paruoštume rodymui ir pasidedam į steitą
        //-- kartu randam esamo momento epg
        const nowDate = Date.now()
        const nowTimestamp = nowDate / 1000 | 0
        const channelSelectedId = String(this.channelSelected.id)
        // const nextReduxEpgVersion = (nextProps.epg && nextProps.epg[channelSelectedId]) ? nextProps.epg[channelSelectedId]['version'] : null
        this.reduxEpgVersion = (this.props.epg && this.props.epg[channelSelectedId]) ? this.props.epg[channelSelectedId]['version'] : null
// console.log('TV shouldComponentUpdate?', this.iteratedVersion, nowTimestamp, this.reduxEpgVersion, nextReduxEpgVersion)
// console.log('this.loadingElement:', this.loadingElement)
        let epgItems
        let days
        if (nextProps.epg && nextProps.epg[String(this.channelSelected.id)]) {
            if (this.channelSelected.id) {
                epgItems = nextProps.epg[String(this.channelSelected.id)]['items']
                days = Object.keys(epgItems).sort()
            }
        } else {
// console.log('no epg, o no days...')
            return true
        }

        if (
            (
                (
                    this.loadingElement === 'epg'
                    || this.loadingElement === 'epg-detail'
                )
                &&
                (
                    this.iteratedDays !== days.length ||
                    this.epgChannelLoadedId !== channelSelectedId ||
                    (this.iteratedVersion + 10) < nowTimestamp
                )
            )
            ||
            (
                nextProps.epg
                && nextProps.epg[String(this.channelSelected.id)]
                && this.epgChannelLoadedId
                && this.channelSelected.id !== parseInt(this.epgChannelLoadedId)
            )
           )
        {

        // const epgItemsTop = nextProps.epg[String(this.channelSelected.id)]['items']
        // const daysTop = Object.keys(epgItemsTop).sort()
// console.log('TV shouldComponentUpdate?', days)

            const nowStamp = Date.now() / 1000 | 0
            const adultPinStampValid = this.props.global_styles.adult_pin_stamp && (this.props.global_styles.adult_pin_stamp + 180 > nowStamp)
            this.adultBlur = this.channelSelected['age_rating'] && this.channelSelected['age_rating'] >= 18 && !adultPinStampValid;

            this.weekDays = {}
            this.iteratedDays = days.length
// console.log('days:', days)
            let list = []
            let nowEpg = null
            let i = 0
            let nowListIndex = null
            let activeListIndex = null
            const epgIdParam = parseInt(this.props.match.params.epg_id)

            const intlObjDay = new Intl.DateTimeFormat(this.props.auth.lang, {year: 'numeric', month: 'numeric', day: 'numeric'})
            const intlObjWeek = new Intl.DateTimeFormat(this.props.auth.lang, {month: 'numeric', day: 'numeric', weekday: 'short'})
            const intlObjTime = new Intl.DateTimeFormat(this.props.auth.lang, {hour: "numeric", minute: "numeric"})

            for (const day of days) {
                for (const epg of epgItems[day]) {
                    let late = false
                    const startDate = new Date(epg.start * 1000)
                    // const epgDay = format(startDate, 'yyyy-MM-dd')
                    const epgDay = intlObjDay.format(startDate)
                    if (typeof this.weekDays[epgDay] === 'undefined') {
                        this.weekDays[epgDay] = {
                            i: i,
                            title: intlObjWeek.format(startDate),
                        }
                    }
                    epg['epgDay'] = epgDay
                    // epg['startFormatted'] = format(startDate, 'p')
                    epg['startFormatted'] = intlObjTime.format(startDate)

                    if (epg.stop < nowTimestamp) {
                        late = true
                    } else if (!nowListIndex && epg.start < nowTimestamp && epg.stop >= nowTimestamp) {
                        nowEpg = epg
                        nowListIndex = i
                    }
                    epg['late'] = late
                    if (!activeListIndex && epgIdParam === epg.id) {
                        activeListIndex = i
                    }

                    list.push(epg)
                    i += 1
                }
            }

            if (!activeListIndex) {
                activeListIndex = nowListIndex
            }
            if (this.prevListIndex !== null && this.prevListIndex === nowListIndex) {
// console.log('yes...')
                return true
            }
// console.log('set state!')
            this.iteratedVersion = nowTimestamp
            this.epgChannelLoadedId = channelSelectedId
            this.prevListIndex = nowListIndex
            this.scrollTop = indexToPosition(nowListIndex)
            const options = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            const nowDay = new Intl.DateTimeFormat(this.props.auth.lang, options).format(nowDate)

            this.setState({
                ...this.state,
                channelGenres: this.channelGenres,
                epg: list,
                nowEpg: nowEpg,
                channelSelectedId: this.channelSelected.id,
                // activeListIndex: activeListIndex !== null ? activeListIndex : nowListIndex,
                activeListIndex: activeListIndex,
                epgSelected: list[activeListIndex],
                epgProgress: nowEpg ? getEpgProgress(nowEpg.start, nowEpg.stop, nowTimestamp) : 0,
                nowListIndex: nowListIndex,
                nowDay: nowDay,
                activeDay: nowDay
            })
// console.log('no...')
            return false
        }
// console.log('yes!')
        return true
    }

    componentDidUpdate(prevProps) {
// console.log('componentDidUpdate channelSelected:', this.channelSelected)
// console.log('componentDidUpdate channels:', this.props.channels.items)
// console.log('componentDidUpdate epg:', this.props.epg)
        const nowTimestamp = Date.now() / 1000 | 0
        if (!this.props.genres.items && !this.fetchStamps['tvgenres']) {
            this.props.fetchAction(
                this.props.auth.token,
                genresEndpoint(this.props.auth.lang),
                this.constructor.name,
                "tvgenres"
            )
            this.fetchStamps['tvgenres'] = nowTimestamp
        }

        if (!this.props.channels.items && !this.fetchStamps['channels']) {
            this.props.fetchAction(
                this.props.auth.token,
                channelsEndpoint(this.props.auth.lang),
                this.constructor.name,
                null,
                null,
                ['id', 'lcn']
            )
            this.fetchStamps['channels'] = nowTimestamp
        }

        //-- jei jau turim žanrus, perrenkam kanalus, kad pažiūrėtume, kurie žanrai juose panaudoti
        //-- perrašom žanrus rastaisiais
        if (typeof this.props.genres.items !== 'undefined' &&
            !this.genresChecked &&
            this.props.genres.items &&
            this.props.channels &&
            this.props.channels.items
        ) {
            for (const item of this.props.channels.items) {
                // if (!item['subscribed']) {
                //     continue
                // }
                if (typeof this.genresUsed[item['genre_id']] === 'undefined') {
                    this.genresUsed[item['genre_id']] = 1
                } else {
                    this.genresUsed[item['genre_id']] += 1
                }
            }
            let newGenres = []
            for (const item of this.props.genres.items) {
                if (typeof this.genresUsed[item['id']] !== 'undefined') {
                    newGenres.push(item)
                }
            }
// console.log('newGenres', newGenres)
            this.props.fetchSuccess({ genres: newGenres }, 'Tv', 'tvgenres')
            this.genresChecked = true
        }

        // //-- kad nebūtų tučia įėjus, klikinam ant pirmo kanalo
        // if (this.selectFirstEpg) {
        //     if (this.props.channels && this.props.channels.items)
        //     {
        //         if (!this.channelSelected) {
        //             const lcnSorted = Object.keys(this.props.channels['maps']['lcn']).sort()
        //             //-- ieškom pirmo subscribinto kanalo
        //             for (const i in lcnSorted) {
        //                 const firstChannel = this.props.channels['items'][this.props.channels['maps']['lcn'][lcnSorted[i]]]
        //                 if (firstChannel['subscribed']) {
        //                     this.loadChannel(firstChannel.id)
        //                     break
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    render(){
        const { genres, channels, loading, source, api_error } = this.props
// console.log('Tv render weekDays:', this.weekDays)
//         if (api_error || !this.props.auth || !this.props.auth.profile) {
        if (api_error) {
          return <Redirect to="/" />
        }
        switch (this.loadingElement && source === 'Tv') {
            case 'genres':
                this.loadingGenres = loading
                break
            case 'channels':
                this.loadingChannels = loading
                break
            case 'epg':
                this.loadingEpg = loading
                break
            default:
        }

        const nowTimestamp = Date.now() / 1000 | 0
// console.log('this.state.epg:', this.state.epg)
        if (this.state.epg && this.state.epg.length) {
            this.renderStamps['epg'] = nowTimestamp
        }

        return (
            <div className="page-margins" style={{marginTop: '1.2%'}}>
                <div className="w-100 overflow-auto">
                    <Genres
                        items={genres ? genres.items : null}
                        loading={this.loadingGenres}
                        selected={this.props.match.params.genre_id}
                        channelGenres={this.state.channelGenres}
                    />
                </div>
                <div className="d-flex">
                    <div className="col-channels movie-list-column overflow-auto">
                        <Channels
                            profileAge={this.props.auth.profile ? this.props.auth.profile.age : 7}
                            channels={channels}
                            loading={this.loadingChannels}
                            channelSelectedId={this.state.channelSelectedId}
                            genreSelected={this.props.match.params.genre_id}
                            onclick={this.handleChannelClick}
                            channelsListRef={this.channelsListRef}
                        />
                    </div>
                    <div className="col-epglist overflow-hidden movie-list-column">
                        <EpgList
                            items={this.state.epg}
                            remindersMap={this.props.api_data.payload.reminders ? this.props.api_data.payload.reminders.epg_map : null}
                            profileAge={this.props.auth.profile ? this.props.auth.profile.age : 7}
                            weekDays={this.weekDays}
                            loading={this.loadingEpg}
                            genreSelected={this.props.match.params.genre_id}
                            channelSelectedId={this.channelSelected ? this.channelSelected.id : null}
                            epgSelectedId={this.state.epgSelected ? this.state.epgSelected.id : null}
                            nowListIndex={this.state.nowListIndex}
                            activeListIndex={this.state.activeListIndex}
                            scrollTop={this.scrollTop}
                            nowDay={this.state.nowDay}
                            activeDay={this.state.activeDay}
                            onclick={this.handleEpgClick}
                            setDay={this.setDay}
                            adultBlur={this.adultBlur}
                        />
                    </div>
                    <div className="col-epgdetail overflow-auto">
                        <EpgDetail
                            epg={this.state.epgSelected}
                            progress={this.state.epgProgress}
                            remindersMap={this.props.api_data.payload.reminders ? this.props.api_data.payload.reminders.epg_map : null}
                            adultBlur={this.adultBlur}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
// console.log('state', state)
    return {
        auth: state.auth,
        api_error: state.api_data ? state.api_data.error : null,
        global_styles: state.global_styles,
        loading: state.api_data ? state.api_data.loading : null,
        source: state.api_data ? state.api_data.source : null,
        epg: state.api_data ? state.api_data.payload.epg : null,
        api_data: state.api_data ? state.api_data : null,
        genres: (state.api_data && state.api_data.payload && state.api_data.payload.tvgenres) ? state.api_data.payload.tvgenres.genres : {},
        channels: (state.api_data && state.api_data.payload.channels) ? state.api_data.payload.channels : {}
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSuccess: (payload, source, subkey) => { dispatch(fetchSuccess(payload, source, subkey)) },
        fetchAction: (token, endpoint, source, subkey, params, mapping) => { dispatch(fetchAction(token, endpoint, source, subkey, params, mapping)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tv))
