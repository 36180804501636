import React from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import PlaceholderItem from './PlaceholderItem';
import style from './style.module.sass';

const ProductItem = ({
    id,
    purchasedProductId,
    changeMode,
    chids,
    name,
    is_promoted,
    banner,
    price,
    discount_percentages,
    handleStepBtn,
    handleModal,
    discounted_price,
    inactive,
    onOpenModal,
    placeholder_text_1,
    placeholder_text_2,
    placeholder_text_3,
    placeholder_image_1,
    placeholder_image_2,
    placeholder_image_3,
    additionalDetails,
    className,
}) => (
    <div
        className={classnames(style.productBox, {
            [style.inactive]: inactive,
            [style.promoted]: is_promoted,
            [style.withDetails]: additionalDetails,
            [className]: className,
        })}
    >
        {!!is_promoted && (
            <div className={style.promotedBox}>
                <FormattedMessage id="sign_up_promoted" />
            </div>
        )}
        {!!banner && (
            <div className={style.productImage}>
                <img className="img-fluid" src={banner} alt="Banner" />
            </div>
        )}
        <div className={style.productBoxHead}>
            <h1>{name}</h1>
            {discount_percentages && (
                <span className={style.discountBadge}>
                    20%{discount_percentages}
                </span>
            )}
        </div>
        <div className={style.productBoxPrice}>
            <h2>{price}</h2>
            <span>
                /<FormattedMessage id="plan_monthly" />
            </span>
            <div
                className={classnames(style.discountedPrice, {
                    [style.hidden]: !discounted_price,
                })}
            >
                {discounted_price}/<FormattedMessage id="plan_monthly" />
            </div>
        </div>
        {!!handleStepBtn && (
            <div className="d-grid" style={{ marginBottom: '24px' }}>
                <button
                    type="button"
                    onClick={handleStepBtn}
                    data-product-id={id}
                    data-step={changeMode ? '0' : '1'}
                    className={classnames('button btn-lg', {
                        'btn-primary-bright': id !== purchasedProductId,
                        'btn-outline-secondary': id === purchasedProductId,
                    })}
                >
                    {changeMode && purchasedProductId ? (
                        id !== purchasedProductId ? (
                            <FormattedMessage id="subscribe_change_plan_link" />
                        ) : (
                            <FormattedMessage id="settings_remove_plan" />
                        )
                    ) : (
                        <FormattedMessage id="subscribe_action_btn" />
                    )}
                </button>
            </div>
        )}
        <PlaceholderItem
            id={id}
            text={placeholder_text_1}
            image={placeholder_image_1}
            chids={chids}
            handleModal={handleModal}
            onOpenModal={onOpenModal}
            compact={additionalDetails}
        />
        <PlaceholderItem
            id={id}
            text={placeholder_text_2}
            image={placeholder_image_2}
            compact={additionalDetails}
        />
        <PlaceholderItem
            id={id}
            text={placeholder_text_3}
            image={placeholder_image_3}
            compact={additionalDetails}
        />
        {additionalDetails && (
            <PlaceholderItem
                id={id}
                text={<FormattedMessage id={'sign_up_additional_conditions'} />}
                conditions
                additionalDetails={additionalDetails}
                compact
            />
        )}
    </div>
);

export default ProductItem;
