import React from 'react';
import { FormattedMessage } from 'react-intl';
import RegisterProgressBar from 'components/elements/RegisterProgressBar';
import PinInput from 'components/elements/PinInput';

const EmailConfirm = ({
    step,
    email,
    handleStepBtn,
    handlePinEntered,
    hasError,
    pin,
}) => {
    return (
        <div className={'register-detail w-100'}>
            <RegisterProgressBar step={step} paddingBottom={'16px'} />
            <h1 style={{ marginBottom: '15px' }}>
                <FormattedMessage id={'sign_up_email_confirm_header'} />
            </h1>
            <div>
                <FormattedMessage id={'sign_up_email_text_one'} />{' '}
                <button className={'btn-link'}>{email}</button>
            </div>
            <div>
                <FormattedMessage id={'sign_up_email_text_two'} />
            </div>
            <div
                className={'supporting-text'}
                style={{
                    paddingTop: '25px',
                    paddingLeft: 0,
                    marginBottom: '-2px',
                }}
            >
                <FormattedMessage id={'device_code_website_code_step'} />
            </div>
            <div className={'d-flex'}>
                <PinInput onPinEntered={handlePinEntered} hasError={hasError} />
            </div>
            <div
                className={''}
                style={{ marginTop: '31px', marginBottom: '154px' }}
            >
                <button
                    className="button btn-primary-bright btn-lg"
                    disabled={pin.length !== 4}
                    onClick={(event) => {
                        event.preventDefault();
                        handleStepBtn(event);
                    }}
                    data-step={'4'}
                >
                    <FormattedMessage id={'common_dialog_button_ok_text'} />
                </button>
            </div>
        </div>
    );
};

export default EmailConfirm;
