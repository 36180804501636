import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {ButtonGroup} from './CarouselComponents'
import {responsive_big, responsive_medium, responsive_small, responsive_screen_wide} from './CarouselComponents'
import SmartItem from './SmartItem'
import SmartItemWide from './SmartItemWide'
import {TemplateToProportion} from "../addons/TemplateToProportion";

/**
 *
 * @param sectionId
 * @param sectionIndex
 * @param sectionItem
 * @param items
 * @param lang
 * @param channels
 * @param epg
 * @param bookmarks
 * @param profileAge
 * @param adultPinStampValid
 * @param CarouselHandler
 * @returns {JSX.Element|null}
 * @constructor
 */
export const CarouselSection = ({
                                 sectionId,
                                 sectionIndex,
                                 sectionItem,
                                 items,
                                 lang,
                                 channels,
                                 epg,
                                 bookmarks,
                                 profileAge,
                                 adultPinStampValid,
                                 CarouselHandler
}) => {

    const sectionTemplate = sectionItem['row_template'].replace(/[\W_]+/g, '_')
    const proportion = TemplateToProportion(sectionItem)
    let size = responsive_medium
    switch (sectionItem['row_template']) {
        case '16:9-big':
            size = responsive_big
            break
        case '16:9-medium':
            size = responsive_medium
            break
        case '2:3-big':
            size = responsive_medium
            break
        case '2:3-medium':
            size = responsive_small
            break
        case 'screenwide':
            size = responsive_screen_wide
            break
        default:
            size = responsive_medium
    }

// console.log('size:', size)
    const onCarouselMove = (previousSlide, currentSlide, onMove) => {
// console.log('onCarouselMove:', previousSlide, currentSlide, onMove)
    }
// console.log('*** ', sectionItem.metadata.title, ' ***')
// console.log(sectionItem)
// console.log(items)
    const NormalSection = () => {
        return (
            <div className={"smart-row"}>
                <p className=''>{sectionItem.metadata.title}</p>
                <Carousel
                    ref={el => CarouselHandler(el, proportion)}
                    responsive={size}
                    draggable={false}
                    // ssr={true}
                    // centerMode={true}
                    customTransition={'800ms'}
                    containerClass="carousel-normal"
                    itemClass={sectionItem.id === "4" ? "live-class" : ""}
                    sliderClass={'_' + sectionTemplate}
                    partialVisible={true}
                    arrows={false}
                    customButtonGroup={<ButtonGroup sectionTemplate={'_' + sectionTemplate} />}
                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                        onCarouselMove(previousSlide, currentSlide, onMove);
                    }}
                >
                    {
                        // eslint-disable-next-line array-callback-return
                        items.map((internal, index) => {
// console.log('internal, index:', internal, index)
                            for (let i in internal) {
                                //-- live'e gali pasitaikyti itemų, kurių kanalų epg neišprašom iš epggrid'o
                                //-- tokius itemus iš karto čia ir skipinam
                                if (i === 'live') {
                                    if (!epg ||
                                        !epg[internal[i]['id']] ||
                                        !epg[internal[i]['id']] ||
                                        !epg[internal[i]['id']]['items']
                                    ) {
                                        continue
                                    }
                                }
                                return (
                                    <SmartItem
                                        key={sectionIndex + '-' + index}
                                        i={i}
                                        section={sectionId}
                                        index={index}
                                        item={internal[i]}
                                        epg={epg}
                                        lang={lang}
                                        channels={channels}
                                        proportion={proportion}
                                        template={sectionTemplate}
                                        profileAge={profileAge}
                                        adultPinStampValid={adultPinStampValid}
                                        bookmark={bookmarks ? getBookmark(i, internal[i]['id'], sectionId) : null}
                                    />
                                )
                            }
                        })
                    }
                </Carousel>
            </div>
        )
    }

    const getBookmark = (i, id, section) => {
        // if (section === 'home.continue-watching') {
        if (section === '5') {
            if (i === 'catchup') {
                if (bookmarks['items'] &&
                    typeof bookmarks['maps'] !== 'undefined' &&
                    typeof bookmarks['maps']['tv'] !== 'undefined' &&
                    typeof bookmarks['maps']['tv'][id] !== 'undefined'
                ) {
                    return bookmarks['items'][bookmarks['maps']['tv'][id]]
                }
            } else if (i === 'vod') {
                if (typeof bookmarks['maps'] !== 'undefined' &&
                    typeof bookmarks['maps']['vod'] !== 'undefined' &&
                    typeof bookmarks['maps']['vod'][id] !== 'undefined'
                ) {
                    return bookmarks['items'][bookmarks['maps']['vod'][id]]
                }
            }
        }
// console.log('getBookmark', bookmarks, i, id, section)
        return null
    }

    const WideSection = () => {
        return (
            <div>
                <Carousel
                    responsive={size}
                    draggable={false}
                    ssr={true}
                    customTransition={'800ms'}
                    containerClass='carousel-wide-container'
                    // itemClass='carousel-item-padding-40-px'
                    partialVisible={true}
                    arrows={false}
                    // customButtonGroup={<ButtonGroup/>}
                    customButtonGroup={<ButtonGroup sectionTemplate={'_' + sectionTemplate} />}
                    showDots={true}
                >
                    {
                        // eslint-disable-next-line array-callback-return
                        items.map((internal, index) => {
                            for (let i in internal) {
                                return <SmartItemWide
                                    key={sectionIndex + '-' + index}
                                    i={i}
                                    index={index}
                                    item={internal[i]}
                                    section={sectionId}
                                    profileAge={profileAge}
                                    template={sectionTemplate}
                                />
                            }
                        })
                    }
                </Carousel>
            </div>
        )
    }

    const Section = () => {
        if (proportion === 'screenwide')
            return <WideSection/>
        else
            return <NormalSection/>
    }

    return (
        items !== null ? <Section/> : null
    )
}
