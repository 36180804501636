import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ClearIcon from 'img/clear-icon.svg';
import ErrorIcon from 'img/error-icon.svg';
import style from './style.module.sass';

const FormInput = ({
    id,
    name,
    type,
    messageId,
    onClearInput,
    handleInputFocus,
    error,
    support,
    supportError,
}) => {
    return (
        <label
            className={classnames(style.formInput, {
                [style.error]: error,
            })}
        >
            <span className={style.formLabel}>
                <FormattedMessage id={messageId} />
            </span>
            <input
                name={name}
                type={type}
                autoComplete="off"
                onFocus={handleInputFocus}
                onBlur={handleInputFocus}
            />
            <button
                type="button"
                onClick={() => onClearInput(id)}
                className={style.formInputClearButton}
            >
                <img src={error ? ErrorIcon : ClearIcon} alt="X" />
            </button>
            {support && (
                <div
                    className={classnames(style.bottomText, {
                        [style.error]: supportError,
                    })}
                >
                    {support}
                </div>
            )}
        </label>
    );
};

export default FormInput;
