import React from 'react'
import {FormattedMessage} from "react-intl";

const RegisterMsgPage = ({headerId, textId, message, body, hideContacts, bottomButtons}) => {
    return (
        <div className={"w-100 text-center"} style={{paddingTop: '76px'}}>
            {headerId ?
                <h1 style={{marginBottom: '20px'}}>
                    <FormattedMessage id={headerId}/>
                </h1>
                : null
            }
            {textId ?
                <div className={"d-flex justify-content-center mt-5 mb-5"}>
                    <p><FormattedMessage id={textId} /></p>
                </div>
                : null
            }
            {message ?
                <div className={"d-flex justify-content-center mt-5 mb-5"}>
                    <p>{message}</p>
                </div>
                : null
            }
            {body ?
                <div className={"d-flex justify-content-center mt-5 mb-5"}>
                    {body}
                </div>
                : null
            }
            {hideContacts ?
                null
                :
                <div className={"d-flex justify-content-center pt-5 mb-5"}>
                    <div className="d-flex justify-content-center pb-4">
                        <div style={{maxWidth: '410px'}}>
                            <a href={"https://www.cgates.lt/televizija/programele/pagalba/"} className=""><FormattedMessage id="sign_up_help_link" /></a>
                        </div>
                    </div>
                </div>
            }
            {bottomButtons ?
                <div className={"d-flex justify-content-center pt-5 mb-5"}>
                    <div className="d-flex pb-4">
                        {bottomButtons}
                    </div>
                </div>
                :
                null
            }
        </div>
    )
}

export default RegisterMsgPage