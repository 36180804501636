import React, { Component } from 'react'
import InfoIcon from '../../img/info-icon.svg'
import PrivacyIcon from '../../img/privacy-icon.svg'
import AboutIcon from '../../img/about-icon.svg'
import { FormattedMessage } from "react-intl"
import { setModalState } from '../../store/actions/modalStateActions'
import { connect } from 'react-redux'
import Privacy from './Privacy'
// import Licences from './Licences'
import Software from './Software'

class About extends Component {

  handlePrivacyClick = (e) => {
    e.preventDefault()
    this.props.setModalState('privacy-modal', <FormattedMessage id="settings_action_privacy" />, <Privacy lang={this.props.auth.lang} />, 'About', <PrivacyIcon />);
  }

  // handleLicencesClick = (e) => {
  //   e.preventDefault()
  //   this.props.setModalState('licences-modal', <FormattedMessage id="sw_licences_title" />, <Licences />, 'About', <PrivacyIcon />);
  // }

  handleSoftwareClick = (e) => {
    e.preventDefault()
    this.props.setModalState('software-modal', <FormattedMessage id="sw_version_title" />, <Software />, 'About', <AboutIcon />);
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <button className="btn btn-link" onClick={this.handlePrivacyClick}>
              <img className="dropdown-icon" src={PrivacyIcon} alt="" />
              <FormattedMessage id="settings_action_privacy" />
            </button>
          </div>
        </div>
  
        {/*<div className="card">*/}
        {/*  <div className="card-header">*/}
        {/*    <button className="btn btn-link" onClick={this.handleLicencesClick}>*/}
        {/*      <img className="dropdown-icon" src={PrivacyIcon} alt="" />*/}
        {/*      <FormattedMessage id="sw_licences_title" />*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
  
        <div className="card">
          <div className="card-header">
            <button className="btn btn-link" onClick={this.handleSoftwareClick}>
              <img className="dropdown-icon" src={InfoIcon} alt="" />
              <FormattedMessage id="sw_version_title" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About)