import React from 'react';
import { FormattedMessage } from 'react-intl';
import BasicModal from 'components/elements/BasicModal';
import style from './style.module.sass';

const DetailsModal = ({ show, details, onClose }) => {
    return (
        <BasicModal
            show={show}
            title={<FormattedMessage id="sign_up_additional_conditions" />}
            onClose={onClose}
        >
            <p className={style.detailsText}>{details}</p>
        </BasicModal>
    );
};

export default DetailsModal;
