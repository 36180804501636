import React from 'react'
import {getLangProp} from "../addons/ProductsHelper";
import YourChoiceItem from "./YourChoiceItem";
import {FormattedMessage} from "react-intl";
import RegisterProgressBar from "./RegisterProgressBar";
import VisaIcon from "../../img/visa-icon.svg";
import MastercardIcon from "../../img/mastercard-icon.svg";
import ErrorIcon from "../../img/error-icon.svg";

const YourChoicePayments = ({
                                item,
                                lang,
                                langDefault,
                                handleStepBtn,
                                handleModal,
                                step,
                                formSupports,
                                loading
}) => {
// console.log("YourChoicePayments:", step, item)
    if (!item) {
        return null
    }
    return (
        <>
            <div className={"d-flex"}>
                <div className={"product-box choice"} style={{background: "none", padding: 0}}/>
                <RegisterProgressBar step={step} />
            </div>
            <div className={"d-flex"}>
                <div>
                    {<YourChoiceItem
                        id = {item.id}
                        name = {item.name}
                        chids={item.chids}
                        discount_percentages = {item.discount_percentages}
                        smartivus_id = {item.smartivus_id}
                        handleModal = {handleModal}
                        price = {getLangProp(item, 'price', lang, langDefault)}
                        discounted_price = {getLangProp(item, 'discounted_price', lang, langDefault)}
                        banner = {getLangProp(item, 'banner', lang, langDefault)}
                        placeholder_text_1 = {getLangProp(item, 'placeholder_text_1', lang, langDefault)}
                        placeholder_text_2 = {getLangProp(item, 'placeholder_text_2', lang, langDefault)}
                        placeholder_text_3 = {getLangProp(item, 'placeholder_text_3', lang, langDefault)}
                    />}
                </div>
                <div className={"register-detail w-100"}>
                    <h1 style={{lineHeight: 'inherit', marginBottom: '15px'}}>
                        <strong><FormattedMessage id={"sign_up_payments_header"}/></strong>
                    </h1>
                    <div style={{marginBottom: '31px'}}>
                        <p><strong><FormattedMessage id={"sign_up_payments_sub_header"}/></strong></p>
                    </div>
                    <hr/>
                    <div className={"d-flex justify-content-between"} style={{minHeight: '48px'}}>
                        <div className="d-flex align-items-center">
                            <strong><FormattedMessage id="sign_up_payments_card"/></strong>
                        </div>
                        <div className="flex-shrink-0 d-flex align-items-center">
                            <img className={"cursor-pointer"} src={MastercardIcon} alt="" style={{marginRight: '24px'}}/>
                            <img className={"cursor-pointer"} src={VisaIcon} alt=""/>
                        </div>
                    </div>
                    <hr/>
                    <div className={"w-100"} style={{marginTop: '39px'}}>
                        {
                            (formSupports['recurring_payments_terms_accepted']) ?
                                <div className={"d-flex error-box"} style={{marginBottom: '30px'}}>
                                    <div className="flex-shrink-0 d-flex align-items-start">
                                        <img
                                            src={ErrorIcon}
                                            alt=""
                                            style={{paddingRight: '14px'}}
                                        />
                                    </div>
                                    <div className={"w-100"}>{formSupports['recurring_payments_terms_accepted']}</div>
                                </div>
                                :
                                null
                        }
                        <form>
                            <div className={"d-flex"}>
                                <input className="form-check-input" name={"recurring_payments_terms_accepted"} type="checkbox" id="recurringConsentCheck" />
                                <label className="form-check-label d-flex align-items-center" htmlFor="recurringConsentCheck">
                                    <strong><FormattedMessage id="sign_up_payments_recurring_consent"/><sup>*</sup></strong>
                                </label>
                            </div>

                            <div style={{marginTop:"32px"}}>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        handleStepBtn(event);
                                    }}
                                    data-product-id = {item.id}
                                    data-step = {"6"}
                                    className={"button btn-primary-bright btn-lg btn-loading" + (loading ? " loading" : "")}
                                >
                                    { loading ?
                                        <span className="spinner"/>
                                        :
                                        null
                                    }
                                    <FormattedMessage id={"sign_up_payments_finish_btn"}/>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default YourChoicePayments