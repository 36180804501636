import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import CgatesLogo from 'img/logo.svg';
import SearchIcon from 'img/search-icon.svg';
import SettingsIcon from 'img/user-icon.svg';
import SettingsIcon2 from 'img/settings-icon-2.svg';
import SettingsList from 'components/elements/SettingsList';
import { setModalState } from 'store/actions/modalStateActions';
import { setAppLang } from 'store/actions/authActions';
import config from 'config/config';
import style from './style.module.sass';
import LanguageDropDown from 'components/elements/LanguageDropDown';

// https://fusionauth.io/blog/2020/03/10/securely-implement-oauth-in-react

const mapState = (state) => ({
    auth: state.auth,
    modal_state: state.modal_state,
    suggestions: state.api_data
        ? state.api_data.payload
            ? state.api_data.payload.search
                ? state.api_data.payload.search.suggestions
                : null
            : null
        : null,
    searchValue: state.search_state ? state.search_state.value : null,
    sugestionClicked: state.search_state
        ? state.search_state.sugestion_clicked
        : null,
});

const Navbar = ({ userStatus }) => {
    const { auth, modal_state } = useSelector(mapState);
    const dispatch = useDispatch();
    const location = useLocation();
    const menuRef = useRef(null);
    const hamburgerRef = useRef(null);

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const onClickOutside = (e) => {
            if (!hamburgerRef.current && !menuRef.current) return null;
            if (
                showMenu &&
                !hamburgerRef.current.contains(e.target) &&
                !menuRef.current.contains(e.target)
            ) {
                setShowMenu(false);
            }
        };
        document.addEventListener('mousedown', onClickOutside);
        document.addEventListener('touchstart', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
            document.removeEventListener('touchstart', onClickOutside);
        };
    }, [menuRef, hamburgerRef, showMenu, setShowMenu]);

    const vodAboutActive = location.pathname.includes('vod-about')
        ? ' active'
        : '';

    const onHamburgerClick = () => {
        setShowMenu(!showMenu);
    };

    const handleSettingsClick = () => {
        dispatch(
            setModalState(
                'settings-list',
                <FormattedMessage id="main_menu_settings" />,
                <SettingsList userStatus={userStatus} />,
                null,
                <SettingsIcon2 />
            )
        );
        modal_state.modal_obj.obj.toggle();
    };

    // const setAppLang = (lang) => {
    //     e.preventDefault();
    //     setAppLang(e.target.dataset.lang);
    //     setAppLang(lang);
    // };

    const askNotifier = () => {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notification');
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            // console.log('Notification.permission granted.');
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            // console.log('Notification.permission not denied');
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    // console.log('Notification.permission granted!');
                }
            });
        }
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    };

    const navItems = [
        {
            path: '/',
            exact: true,
            message: 'main_menu_home',
        },
        {
            path: '/tv',
            onClick: askNotifier,
            message: 'main_menu_tv',
        },
        {
            path: '/vod',
            message: 'main_menu_vod',
            classes: vodAboutActive,
        },
        {
            path: '/shop',
            message: 'main_menu_products',
            classes: 'navbar-links',
            hidden: !userStatus,
        },
    ];

    const containerClasses = classnames(style.container, 'navbar-expand-md');

    return (
        <nav className={containerClasses}>
            <button
                ref={hamburgerRef}
                type="button"
                onClick={onHamburgerClick}
                className={classnames(style.hamburgerButton, {
                    [style.active]: showMenu,
                })}
            >
                <span className={style.topLine} />
                <span className={style.centerLine} />
                <span className={style.bottomLine} />
            </button>
            <Link to="/" className={style.logoLink}>
                <img src={CgatesLogo} alt="" />
            </Link>
            <div className={style.solidBackdrop} />
            <ul
                ref={menuRef}
                className={classnames(style.navLinksList, 'navbar-nav', {
                    [style.active]: showMenu,
                })}
            >
                {navItems.map((item) => {
                    if (item.hidden) return null;

                    const onButtonClick = () => {
                        if (item.onClick) {
                            item.onClick();
                        }
                        setShowMenu(false);
                    };

                    return (
                        <li key={item.path} className="nav-item">
                            <NavLink
                                exact={item.exact}
                                to={item.path}
                                onClick={onButtonClick}
                                className={classnames('nav-link', item.classes)}
                            >
                                <FormattedMessage id={item.message} />
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
            <ul
                className={classnames('navbar-nav', style.userControlsList, {
                    [style.hidden]: location.pathname.endsWith('/register'),
                })}
            >
                <li className="nav-item d-flex align-items-center">
                    <NavLink
                        to="/search"
                        className="nav-link btn-navbar btn-circle"
                    >
                        <img src={SearchIcon} alt="Search" />
                    </NavLink>
                </li>
                {!userStatus && (
                    <li className="nav-item d-flex align-items-center">
                        <NavLink
                            to="/register?fresh=true"
                            className="nav-link btn-navbar-subscribe"
                        >
                            <FormattedMessage id="subscribe_action_btn" />
                        </NavLink>
                    </li>
                )}
                {!userStatus && (
                    <li className="nav-item d-flex align-items-center">
                        <a
                            href={config['clientURI'] + '/login'}
                            className="nav-link btn-navbar active"
                        >
                            <FormattedMessage id="demo_dialog_cancel" />
                        </a>
                    </li>
                )}
                {!!userStatus && (
                    <li className="nav-item d-flex align-items-center">
                        <button
                            type="button"
                            onClick={handleSettingsClick}
                            className="nav-link"
                        >
                            <img src={SettingsIcon} alt="Settings" />
                        </button>
                    </li>
                )}
                {!userStatus && (
                    <li className="nav-item">
                        <LanguageDropDown
                            selectedLang={auth.lang}
                            onSetLanguage={(lang) => dispatch(setAppLang(lang))}
                        />
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default Navbar;
