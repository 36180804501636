import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ProductsMultiCarousel from './ProductsMultiCarousel';
import ProductsCompare from './ProductsCompare';
import style from './style.module.sass';

const SubscriptionOptions = ({
    filterStatus,
    onFilterClick,
    changeMode,
    productsList,
    purchasedProductId,
    channels,
    lang,
    langDefault,
    handleStepBtn,
    productProperties,
    handleSwitch,
    sliderStatus,
}) => {
    let filteredProductsList;
    if (productsList) {
        const purchasedProduct = productsList.find(
            (product) => product.id === purchasedProductId
        );
        const otherProducts = productsList.filter(
            (product) => product.id !== purchasedProductId
        );
        filteredProductsList = [
            ...otherProducts.filter(
                (product) =>
                    (!filterStatus && product.is_prepaid) ||
                    (filterStatus && product.is_postpaid)
            ),
        ];
        if (purchasedProduct) {
            filteredProductsList.push(purchasedProduct);
        }
    }

    return (
        <div className={style.optionsWrapper}>
            <h1 className={style.optionsHeader}>
                <FormattedMessage id="register_main_header" />
            </h1>
            <div className={style.optionTypeSwitch}>
                <div
                    className={classnames(style.selectedSlider, {
                        [style.otherOption]: filterStatus,
                    })}
                />
                <div
                    onClick={() => onFilterClick('prepaid')}
                    className={classnames(style.optionType, {
                        [style.active]: !filterStatus,
                    })}
                >
                    <div className={style.optionTypeText}>
                        <h4>
                            <FormattedMessage id="plan_type_monthly" />
                        </h4>
                        <span className={style.optionTypeSubtext}>
                            <FormattedMessage id="plan_type_monthly_sub" />
                        </span>
                    </div>
                </div>
                {!changeMode && (
                    <div
                        onClick={() => onFilterClick('postpaid')}
                        className={classnames(style.optionType, 'd-flex', {
                            [style.active]: filterStatus,
                        })}
                    >
                        <div className={style.optionTypeText}>
                            <h4>
                                <FormattedMessage id="plan_type_subscribed" />
                            </h4>
                            <span className={style.optionTypeSubtext}>
                                <FormattedMessage id="plan_type_subscribed_sub" />
                            </span>
                        </div>
                        <div className={style.optionTypeBanner}>
                            <div className="button btn-primary">
                                <FormattedMessage id="plan_type_subscribed_btn" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={style.mobileOptionsSubTextWrapper}>
                {!filterStatus && (
                    <span className={style.mobileOptionsSubText}>
                        <FormattedMessage id="plan_type_monthly_sub" />
                    </span>
                )}
                {filterStatus && (
                    <span className={style.mobileOptionsSubText}>
                        <FormattedMessage id="plan_type_subscribed_sub" />
                    </span>
                )}
            </div>
            {filteredProductsList && (
                <ProductsMultiCarousel
                    dataItems={filteredProductsList}
                    purchasedProductId={purchasedProductId}
                    changeMode={changeMode}
                    channels={channels}
                    lang={lang}
                    langDefault={langDefault}
                    handleStepBtn={handleStepBtn}
                />
            )}
            {productsList && productProperties && (
                <ProductsCompare
                    products={productsList}
                    product_properties={productProperties}
                    lang={lang}
                    langDefault={langDefault}
                    handleSwitch={handleSwitch}
                    sliderStatus={sliderStatus}
                    handleStepBtn={handleStepBtn}
                />
            )}
        </div>
    );
};

export default SubscriptionOptions;
