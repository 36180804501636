import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from "react-intl"
import { positionOffset } from './EpgList'

const EpgListDays = ({weekDays, nowDay, activeDay, setDay}) => {

  let sorted = Object.keys(weekDays).sort()
  const activeFromLeft = 3

  //-- jei dienų per daug, kad visos tilptų, ištrinam tas, kurios mažiau nei per activeFromLeft nuo aktyviosios
  if (activeDay) {
    const daysTotal = sorted.length
    if (daysTotal > activeFromLeft) {
      const activeDayIndex = sorted.findIndex((a) => { return a === activeDay })
      if (activeDayIndex > activeFromLeft) {
        sorted.splice(0, activeDayIndex - activeFromLeft)
      }      
    }
  }

  const daysBarContent = sorted.map(day => {
// console.log('daysBarContent', day, activeDay)
    let activeClass = ''
    let dayTitle = weekDays[day]['title']
    let dayIndex = weekDays[day]['i']
    if (day === nowDay) {
      dayTitle = <FormattedMessage id="today" />
    }
    if (day === activeDay) {
      activeClass = ' active'
    }

    return (
      <div className={"date-container" + activeClass} key={day}>
        <Link className={"date-text"} to={'#' + day} onClick={() => setDay(day, null, dayIndex + positionOffset)}>
          {dayTitle}
        </Link>
        {/*<span className='vertical-bar'/>*/}
      </div>
    )
  })

  return (
    <div className="recent-dates">
      {/*<div className="recent-dates-cover"/>*/}
      {daysBarContent}
    </div>
  )
}

export default EpgListDays
