/**
 * http://cdn.dashjs.org/latest/jsdoc/module-MediaPlayer.html
 * http://cdn.dashjs.org/latest/jsdoc/MediaPlayerEvents.html
 *
 * https://github.com/Dash-Industry-Forum/dash.js
 *
 * @module DashControlBar
 * @param {object=} dashjsMediaPlayer - dashjs reference
 * @param {boolean=} displayUTCTimeCodes - true if time is displayed in UTC format, false otherwise
 */

import format from './format'
import config from '../../config/config'
import { MediaPlayer } from 'dashjs'
// import CloseIcon from '../../img/close-icon.svg'

// import {getPriorityList} from "./PrioritiesHandler";

var video,
    videoContainer,
    videoController,
    playerTopInfo,
    playerControl,
    // playPauseBtn,
    // bitrateListBtn,
    settingsBtn,
    langBtn,
    seekbar,
    seekbarPlay,
    seekbarBuffer,
    seekbarLive,
    thumbnailContainer,
    thumbnailElem,
    thumbnailTimeLabel,
    idSuffix
;

let videoControllerVisibleTimeout = 0;
let seeking = false;
export let player

let translatedStrings
let timelineDots
let timelineStep
let timelineStartStamp
let timelineStopStamp
let lastTimelineHover
let playbackType
let startDisplay
let stopDisplay
let userLocale
let controlsVisible = true
let mouseMoveStamp = 0
// let viewerProfileId = 0
let settingsBtnEventListener = false
let langBtnEventListener = false
export let audioTracksAvailable = {}
const timelineHoverDelay = 200
let currentBookmarks = []
let userInfo
export let currentEpg = null
export let timelineDuration
export let PlayerTimeUTC = 0
export let PlayerTime = 0
export let playerIsDynamic = false
export let isLiveEpg = false
export let isLivePlayback = false
// export let livePlaybackStamp = false
export let channelSelected = false

export let PlayerLastBuffer = 0
export let PlayerFirstBuffer = 0
export let seekbarWidth = 0
export const liveLatency = 30

    //************************************************************************************
    // THUMBNAIL CONSTANTS
    //************************************************************************************
    // Maximum percentage of player height that the thumbnail will fill
    const maxPercentageThumbnailScreen = 0.15;
    // Separation between the control bar and the thumbnail (in px)
    const bottomMarginThumbnail = 10;
    // Maximum scale so small thumbs are not scaled too high
    const maximumScale = 2;

export const playerInit = () => {
// console.log('vodItem', vodItem)
// console.log('playbackType', playbackType)
// console.log('currentEpg', currentEpg)

    player = MediaPlayer().create()
    player.initialize()
    player.attachView(document.querySelector(".videoContainer video")); /* tell the player which videoElement it should use */
    const controlbar = DashControlBar(MediaPlayer, player);
    controlbar.initialize();
    const subtitlesContainer = document.getElementById('subtitles-container');
    player.attachTTMLRenderingDiv(subtitlesContainer);
// console.log('version:', player.getVersion())
}

export const playerDestroy = () => {
    const controlbar = DashControlBar(MediaPlayer, player);
    controlbar.destroy();
}

/**
 * https://www.radiantmediaplayer.com/blog/detecting-eme-cdm-browser.html
 * @returns {boolean}
 */
export const ifWidevineSupported = (trueFunc, falseFunc) => {
    const config = [{
        "initDataTypes": ["cenc"],
        "audioCapabilities": [{
            "contentType": "audio/mp4;codecs=\"mp4a.40.2\""
        }],
        "videoCapabilities": [{
            "contentType": "video/mp4;codecs=\"avc1.42E01E\""
        }]
    }];
    try {
        navigator.requestMediaKeySystemAccess("com.widevine.alpha", config).then(function(mediaKeySystemAccess) {
            console.log('widevine support ok');
            trueFunc()
        }).catch(function(e) {
            console.log('no widevine support');
            console.log(e);
            falseFunc()
        });
    } catch (e) {
        console.log('no widevine support');
        console.log(e);
        falseFunc()
    }
}

export const DashControlBar = (MediaPlayer, dashjsMediaPlayer) => {
    // let playerMenus = {
    //     lang: {},
    //     settings: {}
    const getControlId = function (id) {
        return id + (idSuffix ? idSuffix : '');
    };
// }

    const onSeeked = function (event) {
        seeking = false;
        document.removeEventListener('mousemove', onSeekBarMouseMove, true);
        document.removeEventListener('mouseup', onSeeked, true);

        // seeking
        var mouseTime = calculateTimeByEvent(event);
        if (!isNaN(mouseTime)) {
            mouseTime = mouseTime < 0 ? 0 : mouseTime;
            if (playbackType === 'tv') {
                playerSetSource(null, mouseTime)
            } else {
                player.seek(mouseTime);
            }
        }

        onSeekBarMouseMoveOut(event);

        if (seekbarPlay) {
            seekbarPlay.style.width = (mouseTime / timelineDuration * 100) + '%';
        }
    };
// var onStreamTeardownComplete = function (/*e*/) {
    const onSeekBarMouseMoveOut = function (/*e*/) {
        if (!thumbnailContainer) return;
        thumbnailContainer.style.display = 'none';
    };
// setPlayBtn();
    const getBufferLevel = function () {
        const dashMetrics = player.getDashMetrics();
        let bufferLevel = 0;
        if (dashMetrics) {
            bufferLevel = dashMetrics.getCurrentBufferLevel('video', true);
            if (!bufferLevel) {
                bufferLevel = dashMetrics.getCurrentBufferLevel('audio', true);
            }
        }
        return bufferLevel;
    };
// };
    const createMenu = function (info, contentFunc) {
// console.log('createMenu *', info)
        const menuType = info.menuType;
        let settingsMenuEl = document.getElementById('settingsMenu')
        if (!settingsMenuEl) {
// console.log('new menu!')
            settingsMenuEl = document.createElement('div');
            settingsMenuEl.id = 'settingsMenu';
            settingsMenuEl.classList.add('menu');
            settingsMenuEl.classList.add('hide');
        }
        videoContainer.appendChild(settingsMenuEl);

        let langMenuEl = document.getElementById('langMenu')
        if (!langMenuEl) {
            langMenuEl = document.createElement('div');
            langMenuEl.id = 'langMenu';
            langMenuEl.classList.add('menu');
            langMenuEl.classList.add('hide');
        }
        videoContainer.appendChild(langMenuEl);

        // videoController.appendChild(el);

        switch (menuType) {
            case 'caption':
                // settingsMenuEl.appendChild(createMediaTypeMenu('caption'));
                // const captionMenuContent = getMenuContent(menuType, info.arr, contentFunc)
                // settingsMenuEl = createMenuContent(settingsMenuEl, captionMenuContent, 'caption', menuType + '-list');
                // setMenuItemsState(getMenuInitialIndex(info, menuType), menuType + '-list');

                langMenuEl.appendChild(createMediaTypeMenu('caption'));
                langMenuEl = createMenuContent(langMenuEl, getMenuContent(menuType, info.arr, contentFunc), 'caption', menuType + '-list');
                setMenuItemsState(getMenuInitialIndex(info, menuType), menuType + '-list');
                break;
            case 'track':
            // eslint-disable-next-line no-fallthrough
            case 'bitrate':
                if (info.video && info.video.length > 1) {
                    settingsMenuEl.appendChild(createMediaTypeMenu('video'));
                    const bitrateMenuContent = getMenuContent(menuType, info.video, contentFunc)
                    settingsMenuEl = createMenuContent(settingsMenuEl, bitrateMenuContent, 'video', 'video-' + menuType + '-list');
                    setMenuItemsState(getMenuInitialIndex(info.video, menuType, 'video'), 'video-' + menuType + '-list');
                }
                // if (info.audio && info.audio.length > 1) {
                //     settingsMenuEl.appendChild(createMediaTypeMenu('audio'));
                //     const bitrateMenuContent = getMenuContent(menuType, info.audio, contentFunc)
                //     settingsMenuEl = createMenuContent(settingsMenuEl, bitrateMenuContent, 'audio', 'audio-' + menuType + '-list');
                //     setMenuItemsState(getMenuInitialIndex(info.audio, menuType, 'audio'), 'audio-' + menuType + '-list');
                // }
                if (info.audio && info.audio.length > 1) {
                    langMenuEl.appendChild(createMediaTypeMenu('audio'));
                    langMenuEl = createMenuContent(langMenuEl, getMenuContent(menuType, info.audio, contentFunc), 'audio', 'audio-' + menuType + '-list');
                    setMenuItemsState(getMenuInitialIndex(info.audio, menuType, 'audio'), 'audio-' + menuType + '-list');
                }
                if (info.images && info.images.length > 1) {
                    settingsMenuEl.appendChild(createMediaTypeMenu('image'));
                    const bitrateMenuContent = getMenuContent(menuType, info.images, contentFunc, false)
                    settingsMenuEl = createMenuContent(settingsMenuEl, bitrateMenuContent, 'image', 'image-' + menuType + '-list');
                    setMenuItemsState(getMenuInitialIndex(info.images, menuType, 'image'), 'image-' + menuType + '-list');
                }
                break;
            default:
                break;
        }

        // window.addEventListener('resize', handleMenuPositionOnResize, true);

        return {
            settings: settingsMenuEl,
            lang: langMenuEl
        };
    };
// var positionMenu = function (menu, btn) {
    const positionMenu = function (menu, target) {
        menu.style.bottom = '6rem';
        menu.style.right = '0px';
    };

    const setMenuItemsState = function (value, type) {
// console.log('setMenuItemsState:', value, type)

        const self = typeof value === 'number' ? document.getElementById(type + 'Item_' + value) : this;
        if (!self) {
            return false
        }

        const nodes = self.parentElement.children;
        for (let i = 0; i < nodes.length; i++) {
            nodes[i].selected = false;
            nodes[i].classList.remove('menu-item-selected');
            nodes[i].classList.add('menu-item-unselected');
        }
        self.selected = true;
        self.classList.remove('menu-item-over');
        self.classList.remove('menu-item-unselected');
        self.classList.add('menu-item-selected');

        if (typeof type === 'undefined') { // User clicked so type is part of item binding.
            switch (self.name) {
                case 'video-bitrate-list':
                case 'audio-bitrate-list':
                    const cfg = {
                        'streaming': {
                            'abr': {
                                'autoSwitchBitrate': {}
                            }
                        }
                    };

                    if (self.index > 0) {
                        cfg.streaming.abr.autoSwitchBitrate[self.mediaType] = false;
                        player.updateSettings(cfg);
                        player.setQualityFor(self.mediaType, self.index - 1);
                    } else {
                        cfg.streaming.abr.autoSwitchBitrate[self.mediaType] = true;
                        player.updateSettings(cfg);
                    }
                    break;
                case 'image-bitrate-list':
                    player.setQualityFor(self.mediaType, self.index);
                    break;
                case 'caption-list':
                    player.setTextTrack(self.index - 1);
                    break;
                case 'video-track-list':
                case 'audio-track-list':
                    player.setCurrentTrack(player.getTracksFor(self.mediaType)[self.index]);
                    break;
                default:
                    break;
            }
        }
    };
// var onMenuClick = function (menu, btn) {
    const onMenuClick = function (menu, target) {
        if (menu.classList.contains('hide')) {
            menu.classList.remove('hide');
            menu.onmouseleave = function (/*e*/) {
                this.classList.add('hide');
            };
        } else {
            menu.classList.add('hide');
        }
        // menu.style.position = isFullscreen() ? 'fixed' : 'absolute';
        menu.style.position = 'fixed';
        positionMenu(menu, target);
    };

    const createMenuContent = function (menu, arr, mediaType, name) {
        for (let i = 0; i < arr.length; i++) {
            const item = document.createElement('li');
            item.id = name + 'Item_' + i;
            item.index = i;
            item.mediaType = mediaType;
            item.name = name;
            item.selected = false;
            item.textContent = arr[i];

            item.onmouseover = function (/*e*/) {
                if (this.selected !== true) {
                    this.classList.add('menu-item-over');
                }
            };
            item.onmouseout = function (/*e*/) {
                this.classList.remove('menu-item-over');
            };
            item.onclick = setMenuItemsState.bind(item);

            let el;
            if (mediaType === 'caption') {
                el = menu.querySelector('ul');
            } else {
                el = menu.querySelector('.' + mediaType + '-menu-content');
            }
            el.appendChild(item);
        }

        return menu;
    };

//     const getLabelForLocale = function (labels) {
// console.log('getLabelForLocale', labels)
//         const locales = getBrowserLocale();
//         for (let i = 0; i < labels.length; i++) {
//             for (let j = 0; j < locales.length; j++) {
//                 if (labels[i].lang && locales[j] && locales[j].indexOf(labels[i].lang) > -1) {
//                     return labels[i].text;
//                 }
//             }
//         }
//
//         return labels.length === 1 ? labels[0].text : null;
//     };

    const createMediaTypeMenu = function (type) {
// console.log('createMediaTypeMenu', type)
        const menuId = type + '-settings'
        const menuObj = document.getElementById(menuId)
        if (menuObj) {
            menuObj.remove();
        }
        const div = document.createElement('div');
        const title = document.createElement('div');
        const content = document.createElement('ul');

        div.id = menuId;

        // title.textContent = type.charAt(0).toUpperCase() + type.slice(1);
        title.textContent = translatedStrings['player_' + type + '_menu_title'];
        title.classList.add('menu-sub-menu-title');
// console.log('createMediaTypeMenu', type, title.textContent)

        content.id = type + 'Content';
        content.classList.add(type + '-menu-content');

        div.appendChild(title);
        div.appendChild(content);

        return div;
    };
    const getMenuContent = function (type, arr, contentFunc, autoswitch) {
// console.log('getMenuContent', type, arr, contentFunc, autoswitch)
// console.log('getMenuContent', type, arr)
        autoswitch = (autoswitch !== undefined) ? autoswitch : true;

        const content = [];
        arr.forEach(function (element, index) {
            content.push(contentFunc(element, index));
        });
        if (type !== 'track' && autoswitch) {
            content.unshift(contentFunc(null, NaN));
        }
        return content;
    };
    const isTracksEqual = function (t1, t2) {
        const sameId = t1.id === t2.id;
        const sameViewpoint = t1.viewpoint === t2.viewpoint;
        const sameLang = t1.lang === t2.lang;
        const sameRoles = t1.roles.toString() === t2.roles.toString();
        const sameAccessibility = t1.accessibility.toString() === t2.accessibility.toString();
        const sameAudioChannelConfiguration = t1.audioChannelConfiguration.toString() === t2.audioChannelConfiguration.toString();

        return (sameId && sameViewpoint && sameLang && sameRoles && sameAccessibility && sameAudioChannelConfiguration);
    };
    const getMenuInitialIndex = function (info, menuType, mediaType) {
        if (menuType === 'track') {
            const mediaInfo = player.getCurrentTrackFor(mediaType);
            let idx = 0;
            // eslint-disable-next-line array-callback-return
            info.some(function (element, index) {
                if (isTracksEqual(element, mediaInfo)) {
                    idx = index;
                    return true;
                }
            });
            return idx;
        } else if (menuType === 'bitrate') {
            const cfg = player.getSettings();
            if (cfg.streaming && cfg.streaming.abr && cfg.streaming.abr.initialBitrate) {
                return cfg.streaming.abr.initialBitrate['mediaType'] | 0;
            }
            return 0;
        } else if (menuType === 'caption') {
            return player.getCurrentTextTrackIndex() + 1;
        }
    };
    player = dashjsMediaPlayer;
    let captionMenu = null;
    let bitrateListMenu = null;
    let trackSwitchMenu = null;
    // var menuHandlersList = [];
    // var lastVolumeLevel = NaN;
    // displayUTCTimeCodes = displayUTCTimeCodes

    const initControls = function (suffix) {
        idSuffix = suffix;
        videoController = document.getElementById(getControlId('player-timeline'));
        playerTopInfo = document.getElementById(getControlId('player-top-info'));
        playerControl = document.getElementById(getControlId('control-buttons'));
        // playPauseBtn = document.getElementById(getControlId('playPauseBtn'));
        // bitrateListBtn = document.getElementById(getControlId('bitrateListBtn'));
        settingsBtn = document.getElementById(getControlId('settingsBtn'));
        langBtn = document.getElementById(getControlId('langBtn'));
        // captionBtn = document.getElementById(getControlId('captionBtn'));
        // trackSwitchBtn = document.getElementById(getControlId('trackSwitchBtn'));
        seekbar = document.getElementById(getControlId('seekbar'));
        seekbarPlay = document.getElementById(getControlId('seekbar-play'));
        seekbarBuffer = document.getElementById(getControlId('seekbar-buffer'));
        seekbarLive = document.getElementById(getControlId('seekbar-live'));
        // muteBtn = document.getElementById(getControlId('muteBtn'));
        // volumebar = document.getElementById(getControlId('volumebar'));
        // fullscreenBtn = document.getElementById(getControlId('fullscreenBtn'));
        // timeDisplay = document.getElementById(getControlId('videoTime'));
        // durationDisplay = document.getElementById(getControlId('videoDuration'));
        startDisplay = document.getElementById(getControlId('start-display'));
        stopDisplay = document.getElementById(getControlId('stop-display'));
        thumbnailContainer = document.getElementById(getControlId('thumbnail-container'));
        thumbnailElem = document.getElementById(getControlId('thumbnail-elem'));
        thumbnailTimeLabel = document.getElementById(getControlId('thumbnail-time-label'));
        // fwdBtn = document.getElementById(getControlId('forward-button'));
        // fwdFastBtn = document.getElementById(getControlId('forward-fast-button'));
    };


    //************************************************************************************
    // PLAYBACK
    //************************************************************************************

    // var setPlayBtn = function () {
    //     var span = document.getElementById(getControlId('iconPlayPause'));
    //     if (span !== null) {
    //         span.classList.remove('icon-pause');
    //         span.classList.add('icon-play');
    //     }
    // };

    const onPlayTimeUpdate = function (/*e*/) {
        if (!seeking) {
            playerIsDynamic = player.isDynamic()
            const nowStamp = Date.now() / 1000 | 0
            // const currentLiveLatency = player.getCurrentLiveLatency()
            PlayerTime = player.time()
            //-- naudojamas buferiui kompensuoti, kai config'e nustatyta jo nerodyti
            // if (!PlayerFirstBuffer) {
            if (isLiveEpg && isLivePlayback) {
                PlayerFirstBuffer = player.getCurrentLiveLatency()
            }
            PlayerTimeUTC = player.timeAsUTC() | 0
            if (seekbarLive) {
                // if (playerIsDynamic) {
                if (nowStamp < timelineStopStamp && playbackType !== 'vod' && playbackType !== 'vod_trailer') {
                    seekbarLive.style.width = (nowStamp - timelineStartStamp) / timelineDuration * 100 + '%'
                    seekbarLive.style.display = 'block'
                } else {
                    seekbarLive.style.display = 'none'
                }
            }
            if (seekbarPlay) {
                if (playerIsDynamic) {
// console.log('seekbarPlay dynamic', '(' + new Date(PlayerTimeUTC * 1000).toLocaleString('lt-LT') + ' - ' + new Date(timelineStartStamp * 1000).toLocaleString('lt-LT') + ') / ' + timelineDuration + ' * 100 =', parseInt((PlayerTimeUTC - timelineStartStamp) / timelineDuration * 100) + '%', PlayerTimeUTC - timelineStartStamp)
                    seekbarWidth = (PlayerTimeUTC - timelineStartStamp) / timelineDuration * 100
                } else {
// console.log('seekbarPlay not dynamic', PlayerTime + ' / ' + timelineDuration + ' * 100 = ', (PlayerTime / timelineDuration * 100) + '%')
                    seekbarWidth = (PlayerTime / timelineDuration * 100)
                }
                if (seekbarWidth > 100) {
                    seekbarWidth = 100
                }
                seekbarPlay.style.width = seekbarWidth + '%'
            }
            if (seekbarBuffer && config.playerBufferBar) {
                PlayerLastBuffer = getBufferLevel()
                if (playerIsDynamic) {
                    seekbarBuffer.style.width = ((PlayerTimeUTC - timelineStartStamp + PlayerLastBuffer) / timelineDuration * 100) + '%';
                } else {
                    seekbarBuffer.style.width = ((PlayerTime + PlayerLastBuffer) / timelineDuration * 100) + '%';
                }
            }

            if (playbackType === 'vod' || playbackType === 'vod_trailer') {
                startDisplay.textContent = player.convertToTimeCode(PlayerTime)
            }

            //--- jei 4 sekundes nejudina pelės, paslepiam kontrolus
            if (controlsVisible) {
                if (mouseMoveStamp && nowStamp - mouseMoveStamp > 3) {
                    videoController.classList.add('hide');
                    playerTopInfo.style.display = 'none';
                    playerControl.classList.add('hide');
                    controlsVisible = false;
                }
            }
        }
    };


    //************************************************************************************
    // SEEKING
    // ************************************************************************************

    const onSeeking = function (event) {
        //TODO Add call to seek in trick-mode once implemented. Preview Frames.
        seeking = true;
        var mouseTime = calculateTimeByEvent(event);
        if (seekbarPlay) {
            seekbarPlay.style.width = (mouseTime / timelineDuration * 100) + '%';
        }
        // setTime(mouseTime);
        document.addEventListener('mousemove', onSeekBarMouseMove, true);
        document.addEventListener('mouseup', onSeeked, true);
    };


    //************************************************************************************
    // Audio Video MENU
    //************************************************************************************

    const regionNamesLangs = new Intl.DisplayNames([userLocale], {type: 'language'});

    // const getBrowserLocale = function () {
    //     return (navigator.languages && navigator.languages.length) ? navigator.languages : [navigator.language];
    // };

    const onTracksAdded = function (e) {
// console.log('onTracksAdded')
        // Subtitles/Captions Menu //XXX we need to add two layers for captions & subtitles if present.
        if (!captionMenu || !bitrateListMenu || !trackSwitchMenu) {
            const contentFunc = function (element, index) {
                if (isNaN(index)) {
                    return translatedStrings['subs_off'];
                }
                return regionNamesLangs.of(element.lang)
            };
            const menus = createMenu({menuType: 'caption', arr: e.tracks}, contentFunc);
            captionMenu = menus.lang
            const func = function () {
                onMenuClick(captionMenu, 'caption');
            };
            if (!langBtnEventListener) {
                langBtn.addEventListener('click', func);
                langBtnEventListener = true
            }
            langBtn.classList.remove('hide');
        } else {
            setMenuItemsState(e.index + 1, 'caption-list');
        }
// console.log('playerMenus:', playerMenus)
    };

    const onStreamInitialized = function (/*e*/) {
// console.log('onStreamInitialized')
        audioTracksAvailable = {}
        if (playbackType !== 'tv') {
            timelineDuration = Math.round(player.duration())
            startDisplay.textContent = player.convertToTimeCode(0)
            stopDisplay.textContent = player.convertToTimeCode(timelineDuration)
        }

        let contentFunc;

        if (settingsBtn) {
            // destroyStreamMenu();
            const availableBitrates = {menuType: 'bitrate'};
            availableBitrates.audio = player.getBitrateInfoListFor('audio') || [];
            availableBitrates.video = player.getBitrateInfoListFor('video') || [];
            availableBitrates.images = player.getBitrateInfoListFor('image') || [];
            if (availableBitrates.audio.length > 1 || availableBitrates.video.length > 1 || availableBitrates.images.length > 1) {
                contentFunc = function (element, index) {
                    let result = isNaN(index) ? translatedStrings['player_quality_auto'] : Math.floor(element.bitrate / 1000) + ' kbps';
                    result += element && element.width && element.height ? ' (' + element.width + 'x' + element.height + ')' : '';
                    return result;
                };

                const menus = createMenu(availableBitrates, contentFunc);
                bitrateListMenu = menus.settings;
                const func = function () {
// console.log('onStreamInitialized func')
                    onMenuClick(bitrateListMenu, 'bitrate');
                };

                if (!settingsBtnEventListener) {
                    settingsBtn.addEventListener('click', func);
                    settingsBtnEventListener = true
                }
                settingsBtn.classList.remove('hide');
            } else if (!availableBitrates.video.length) {
                settingsBtn.classList.add('hide');
            }
        } else {
console.log('no settingsBtn...')
        }
// console.log('bitrateListMenu created', bitrateListMenu)
        //Track Switch Menu
        if (!trackSwitchMenu) {
            let availableTracks = {menuType: 'track'};
            availableTracks.audio = player.getTracksFor('audio');
            availableTracks.video = player.getTracksFor('video'); // these return empty arrays so no need to check for null
            if (availableTracks.audio.length > 1 || availableTracks.video.length > 1) {
                contentFunc = function (element) {
                    if (!element.lang) {
                        return []
                    }
                    const langSplitted = element.lang.split("-");
                    audioTracksAvailable[langSplitted[0]] = element
                    return regionNamesLangs.of(langSplitted[0])
                };
                trackSwitchMenu = createMenu(availableTracks, contentFunc);
// console.log('trackSwitchMenu:', trackSwitchMenu)
                const func = function () {
                    onMenuClick(trackSwitchMenu.lang, 'caption');
                };
                if (!langBtnEventListener) {
                    langBtn.addEventListener('click', func);
                    langBtnEventListener = true
                }
                langBtn.classList.remove('hide');
            }
        }
// console.log('playerMenus:', playerMenus)
    };

    const handleMenuPositionOnResize = function (/*e*/) {
        // if (captionMenu) {
        //     positionMenu(captionMenu, captionBtn);
        // }
        // if (bitrateListMenu) {
        //     positionMenu(bitrateListMenu, settingsBtn);
        // }
        // if (trackSwitchMenu) {
        //     positionMenu(trackSwitchMenu, trackSwitchBtn);
        // }
    };

    const destroyStreamMenu = function () {
// console.log('destroyStreamMenu!', bitrateListMenu)
        if (bitrateListMenu || captionMenu || trackSwitchMenu) {
            const el = document.getElementById('settingsMenu')
            if (el) {
                el.remove();
            }

            // menuHandlersList.forEach(function (item) {
            // bitrateListBtn.removeEventListener('click', item);
//                 settingsBtn.removeEventListener('click', item);
//             });
            // videoController.removeChild(bitrateListMenu);
            bitrateListMenu = null;
            captionMenu = null;
            trackSwitchMenu = null;
        }
    };

    //************************************************************************************
    //IE FIX
    //************************************************************************************

    const coerceIEInputAndChangeEvents = function (slider, addChange) {
        const fireChange = function (/*e*/) {
            const changeEvent = document.createEvent('Event');
            changeEvent.initEvent('change', true, true);
            changeEvent.forceChange = true;
            slider.dispatchEvent(changeEvent);
        };

        this.addEventListener('change', function (e) {
            let inputEvent;
            if (!e.forceChange && e.target.getAttribute('type') === 'range') {
                e.stopPropagation();
                inputEvent = document.createEvent('Event');
                inputEvent.initEvent('input', true, true);
                e.target.dispatchEvent(inputEvent);
                if (addChange) {
                    e.target.removeEventListener('mouseup', fireChange);//TODO can not clean up this event on destroy. refactor needed!
                    e.target.addEventListener('mouseup', fireChange);
                }
            }

        }, true);
    };

    const isIE = function () {
        return !!navigator.userAgent.match(/Trident.*rv[ :]*11\./);
    };

    //************************************************************************************
    // PUBLIC API
    //************************************************************************************

    return {
        initialize: function (suffix) {

            if (!player) {
                throw new Error('Please pass an instance of MediaPlayer.js when instantiating the ControlBar Object');
            }
            video = player.getVideoElement();
            if (!video) {
                throw new Error('Please call initialize after you have called attachView on MediaPlayer.js');
            }
// console.log('control bar initialize')
            initControls(suffix);
            video.controls = false;
            videoContainer = video.parentNode;
            settingsBtn.classList.add('hide');
            langBtn.classList.add('hide');
            // captionBtn.classList.add('hide');
            // if (trackSwitchBtn) {
            //     trackSwitchBtn.classList.add('hide');
            // }
            video.onmousemove = function (/*e*/) {
                mouseMoveStamp = Date.now() / 1000 | 0;
                if (controlsVisible === false) {
                    videoController.classList.remove('hide');
                    playerTopInfo.style.display = 'flex';
                    playerControl.classList.remove('hide');
                    controlsVisible = true;
                }
// console.log('video.onmousemove', mouseMoveStamp);
            };

            player.on(MediaPlayer.events.PLAYBACK_TIME_UPDATED, onPlayTimeUpdate, this);
            player.on(MediaPlayer.events.TEXT_TRACKS_ADDED, onTracksAdded, this);
            player.on(MediaPlayer.events.STREAM_INITIALIZED, onStreamInitialized, this);
            // player.on(MediaPlayer.events.STREAM_TEARDOWN_COMPLETE, onStreamTeardownComplete, this);
            // player.on(MediaPlayer.events.SOURCE_INITIALIZED, onSourceInitialized, this);
//SOURCE_INITIALIZED
            // playPauseBtn.addEventListener('click', onPlayPauseClick);
            // muteBtn.addEventListener('click', onMuteClick);
            // fullscreenBtn.addEventListener('click', onFullscreenClick);
            seekbar.addEventListener('mousedown', onSeeking, true);
            seekbar.addEventListener('mousemove', onSeekBarMouseMove, true);
            // set passive to true for scroll blocking listeners (https://www.chromestatus.com/feature/5745543795965952)
            seekbar.addEventListener('touchmove', onSeekBarMouseMove, { passive: true });
            seekbar.addEventListener('mouseout', onSeekBarMouseMoveOut, true);
            seekbar.addEventListener('touchcancel', onSeekBarMouseMoveOut, true);
            seekbar.addEventListener('touchend', onSeekBarMouseMoveOut, true);
            // volumebar.addEventListener('input', setVolume, true);
            // document.addEventListener('fullscreenchange', onFullScreenChange, false);
            // document.addEventListener('MSFullscreenChange', onFullScreenChange, false);
            // document.addEventListener('mozfullscreenchange', onFullScreenChange, false);
            // document.addEventListener('webkitfullscreenchange', onFullScreenChange, false);

            //IE 11 Input Fix.
            if (isIE()) {
                coerceIEInputAndChangeEvents(seekbar, true);
                // coerceIEInputAndChangeEvents(volumebar, false);
            }
        },

        show: function () {
            videoController.classList.remove('hide');
            playerControl.classList.remove('hide');
        },

        hide: function () {
            videoController.classList.add('hide');
            playerControl.classList.add('hide');
        },

        disable: function () {
            videoController.classList.add('disable');
            playerControl.classList.add('disable');
        },

        enable: function () {
            videoController.classList.remove('disable');
            playerControl.classList.remove('disable');
        },

        reset: function () {
console.log('reset!')
            window.removeEventListener('resize', handleMenuPositionOnResize);
            destroyStreamMenu();

            // menuHandlersList.forEach(function (item) {
            //     if (trackSwitchBtn) trackSwitchBtn.removeEventListener('click', item);
            //     if (captionBtn) captionBtn.removeEventListener('click', item);
            // });
            if (captionMenu) {
                videoController.removeChild(captionMenu);
                captionMenu = null;
                // captionBtn.classList.add('hide');
            }
            if (trackSwitchMenu) {
                videoController.removeChild(trackSwitchMenu);
                trackSwitchMenu = null;
                // trackSwitchBtn.classList.add('hide');
            }
            // menuHandlersList = [];
            seeking = false;

            if (seekbarPlay) {
                seekbarPlay.style.width = '0%';
            }

            if (seekbarBuffer) {
                seekbarBuffer.style.width = '0%';
            }
        },

        destroy: function () {
console.log('seek and destroy!')
            settingsBtnEventListener = false
            langBtnEventListener = false
            this.reset();

            // playPauseBtn.removeEventListener('click', onPlayPauseClick);
            // muteBtn.removeEventListener('click', onMuteClick);
            // fullscreenBtn.removeEventListener('click', onFullscreenClick);
            seekbar.removeEventListener('mousedown', onSeeking);
            // volumebar.removeEventListener('input', setVolume);
            seekbar.removeEventListener('mousemove', onSeekBarMouseMove);
            seekbar.removeEventListener('touchmove', onSeekBarMouseMove);
            seekbar.removeEventListener('mouseout', onSeekBarMouseMoveOut);
            seekbar.removeEventListener('touchcancel', onSeekBarMouseMoveOut);
            seekbar.removeEventListener('touchend', onSeekBarMouseMoveOut);

            player.off(MediaPlayer.events.PLAYBACK_TIME_UPDATED, onPlayTimeUpdate, this);
            player.off(MediaPlayer.events.TEXT_TRACKS_ADDED, onTracksAdded, this);
            player.off(MediaPlayer.events.STREAM_INITIALIZED, onStreamInitialized, this);
            // player.off(MediaPlayer.events.STREAM_TEARDOWN_COMPLETE, onStreamTeardownComplete, this);
            // player.off(MediaPlayer.events.SOURCE_INITIALIZED, onSourceInitialized, this);

            // document.removeEventListener('fullscreenchange', onFullScreenChange);
            // document.removeEventListener('MSFullscreenChange', onFullScreenChange);
            // document.removeEventListener('mozfullscreenchange', onFullScreenChange);
            // document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
        }
    };
};

export const isFullscreen = () => {
    return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreen || document.webkitIsFullScreen;
};

export const exitFullscreen = () => {
    window.removeEventListener('mousemove', onFullScreenMouseMove);
    clearFullscreenState();

    if (document.fullscreenElement) {
        document.exitFullscreen();
    } else if (document.exitFullscreen) {
       document.exitFullscreen(); 
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else {
        document.webkitCancelFullScreen();
    }
    videoController.classList.remove('video-controller-fullscreen');
};

export const enterFullscreen = () => {
    var element = videoContainer || video;

    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else {
        element.webkitRequestFullScreen();
    }
    videoController.classList.add('video-controller-fullscreen');
    window.addEventListener('mousemove', onFullScreenMouseMove);
    onFullScreenMouseMove();
};

export const setPlaybackType = (type) => {
    playbackType = type
}

export const setPlayerTime = (time) => {
    PlayerTime = time;
}

const clearFullscreenState = () => {
    clearTimeout(videoControllerVisibleTimeout);
    videoController.classList.remove('hide');
    playerTopInfo.style.display = 'flex';
    playerControl.classList.remove('hide');
};

const onFullScreenMouseMove = () => {
    clearFullscreenState();
    videoControllerVisibleTimeout = setTimeout(function () {
        videoController.classList.add('hide');
        playerTopInfo.style.display = 'none';
        playerControl.classList.add('hide');
    }, 4000);
};

const onMouseTime = (mouseTime, event) => {
    if (!timelineDots) {
        return false
    }
    const now = Date.now()
    const mouseStepTime = Math.round(mouseTime / timelineStep)
    let frameFound
    if (!lastTimelineHover || now - lastTimelineHover > timelineHoverDelay) {
// console.log('onMouseTime', mouseStepTime, mouseTime, timelineStartStamp, mouseTime + timelineStartStamp)
// console.log('timelineDots', timelineDots)
        //-- ieškodami netuščio doto,
        //-- iteruojam nuo hoverio pozicijos link pradžios
        //-- bet per toli nenueinam
        const maxSteps = 5
        let step = 0
        for (let i = mouseStepTime; i >= 0 && step <= maxSteps; i--) {
            if (timelineDots[i]) {
                frameFound = timelineDots[i]
                break
            }
            step += 1
        }
        thumbnailDisplay(frameFound, event, mouseTime)
        lastTimelineHover = now
    }
}

const thumbnailDisplay = (thumbnail, event, mouseTime) => {
// console.log('thumbnailDisplay', thumbnail, mouseTime, timelineDuration)
    if (!thumbnail) {
        return false
    }
    // Take into account page offset and seekbar position
    const elem = videoContainer || video;
    const videoContainerRect = elem.getBoundingClientRect();
    const seekbarRect = seekbar.getBoundingClientRect();
    const videoControllerRect = videoController.getBoundingClientRect();

    // Calculate time position given mouse position
    let left = event.clientX - seekbarRect.left;
    // Update timer and play progress bar if mousedown (mouse click down)
    if (seeking) {
        // setTime(mouseTime);
        if (seekbarPlay) {
            // seekbarPlay.style.width = (mouseTime / player.duration() * 100) + '%';
            seekbarPlay.style.width = (mouseTime / timelineDuration * 100) + '%';
        }
    }

    // Adjust left variable for positioning thumbnail with regards to its viewport
    left += (seekbarRect.left - videoContainerRect.left);
    // Take into account thumbnail control
    const ctrlWidth = parseInt(window.getComputedStyle(thumbnailElem).width);
    if (!isNaN(ctrlWidth)) {
        left -= ctrlWidth / 2;
    }

    let scale = (videoContainerRect.height * maxPercentageThumbnailScreen) / thumbnail.height;
    if (scale > maximumScale) {
        scale = maximumScale;
    }

    // Set thumbnail control position
    thumbnailContainer.style.left = left + 'px';
    thumbnailContainer.style.display = '';
    thumbnailContainer.style.bottom += Math.round(videoControllerRect.height + bottomMarginThumbnail) + 'px';
    thumbnailContainer.style.height = Math.round(thumbnail.height) + 'px';
    thumbnailElem.style.background = 'url(' + thumbnail.background_url + ') ' + thumbnail.background_x + 'px ' + thumbnail.background_y + 'px' 
    thumbnailElem.style.width = thumbnail.width + 'px';
    thumbnailElem.style.height = thumbnail.height + 'px';
    thumbnailElem.style.transform = 'scale(' + scale + ',' + scale + ')';

    if (thumbnailTimeLabel) {
        if (playbackType === 'tv') {
            thumbnailTimeLabel.textContent = format(new Date(thumbnail.stamp * 1000), 'pp')
        } else {
            thumbnailTimeLabel.textContent = player.convertToTimeCode(thumbnail.stamp)
        }
    }
}

export const setTimelineDots = (dots)  => {
    timelineDots = dots
}

export const setTimelineStep = (step)  => {
    timelineStep = step
}

export const setChannelSelected = (channel)  => {
    channelSelected = channel
}

export const setPlayerLocale = (locale)  => {
    userLocale = locale
}

export const setTranslatedStrings = (strings)  => {
    translatedStrings = strings
// console.log('translatedStrings', translatedStrings)    
}

export const setLivePlayback = (e) => {
    isLivePlayback = e
}

export const setVolume = (e) => {
    player.setVolume(e)
}

// export const setViewerProfile = (e) => {
//     viewerProfileId = e
// }

export const setMouseMoveStamp = (e) => {
    mouseMoveStamp = e
}

export const getVolume = () => {
    return player.getVolume()
}

export const setCurrentEpg = (epgItem) => {
    currentEpg = epgItem
    setTimelineDuration(currentEpg)
}

export const setUserInfo = (info) => {
    userInfo = info
}

export const setCurrentBookmarks = (items) => {
    currentBookmarks = items
}

export const setTimelineDuration = (epg, vodDuration)  => {
// console.log('setTimelineDuration', epg, vodDuration)
    if (typeof vodDuration === 'undefined') {
        if (!epg.stop) {
            epg.stop = Math.round(Date.now() / 1000)
        }
        timelineStartStamp = epg.start
        timelineStopStamp = epg.stop
        timelineDuration = Math.round(epg.stop - epg.start)
    } else {
        timelineStartStamp = 0
        timelineDuration = Math.round(vodDuration)
    }

    const nowStamp = Date.now() / 1000 | 0
    isLiveEpg = epg.stop > (nowStamp + PlayerFirstBuffer)
}

export const playerSetSource = (vodItem, seek) => {
    const nowStamp = Date.now() / 1000 | 0
    let url = ''
    if (playbackType === 'vod' || playbackType === 'vod_trailer') {
        if (playbackType === 'vod' && vodItem && !vodItem['subscribed'] && !vodItem['purchased']) {
            vodItem['url'] = null
        } else if (!vodItem) {
            return false
        }
        url = vodItem['url']
    } else if (!currentEpg) {
        console.log('no epg...')
        return true
    } else {
        isLiveEpg = false
        isLivePlayback = false
        let bookmarkStamp = 0
        if (currentEpg.stop > (nowStamp + PlayerFirstBuffer)) {
            isLiveEpg = true
            if (!seek || nowStamp - currentEpg.start - seek - liveLatency < 0) {
                isLivePlayback = true
            }
        }
// console.log('liveLatency:', liveLatency, 'isLivePlayback:', isLivePlayback)
        if (!seek && currentBookmarks) {
            if (currentBookmarks['maps'] && currentBookmarks['maps']['tv'] && typeof currentBookmarks['maps']['tv'][currentEpg.id] !== 'undefined') {
                bookmarkStamp = currentBookmarks['items'][currentBookmarks['maps']['tv'][currentEpg.id]]['bookmark']
            }
        }

        if (isLivePlayback && !seek) {
            url = channelSelected.addr.replace(['http', 'https'], 'https')
            // livePlaybackStamp = Date.now() / 1000 | 0
        } else if (channelSelected.catchup_addr) {
            let start = bookmarkStamp ? bookmarkStamp : currentEpg.start
            const from = nowStamp - start - seek
            // const to = nowStamp - currentEpg.stop
            const to = from - 30
            url = channelSelected.catchup_addr.replace(['http', 'https'], 'https')
            url = url.replace('{{from}}', 'now-' + from + 's').replace('{{to}}', 'now-' + to + 's')

            isLivePlayback = false
        } else {
            console.log('catchup_addr:', channelSelected.id, channelSelected.catchup_addr)
        }
    }

    const channelOrAsset = playbackType === 'tv' ?
        '&ch_id=' + (currentEpg ? currentEpg['channel_id'] : '')
        :
        '&vod_id=' + (vodItem ? vodItem['id'] : '') + '&vod_provider_id=' + (vodItem ? vodItem['provider_id'] : '') + '&vod_type=' + (vodItem ? vodItem['type'] : '')

    // const userId = btoa('63676174-6573-1000-8000-' + String(userInfo['customer_id']['items']).padStart(12, '0'))
    const userId = btoa('63676174-6573-1000-8000-' + parseInt(userInfo['customer_id']['items']).toString(16).padStart(12, '0'))

    const protData = {
        "com.widevine.alpha": {
            "serverURL":
                'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5CB537&user_id=' + userId +
                '&token=' + userInfo['drm_token']['items'] + channelOrAsset
        }
    };

    // const channelOrAsset = playbackType === 'tv' ? 'channel' : 'asset'
    // const playbackId = playbackType === 'tv' ? currentEpg['id'] : vodItem['id']
    //
    // const protData = {
    //     "com.widevine.alpha": {
    //         "serverURL": 'https://widevine-dash.ezdrm.com/widevine-php/widevine-foreignkey.php?pX=5CB537&CustomData=' + viewerProfileId + '&token=' + userInfo['drm_token'] + '&' + channelOrAsset + '=' + playbackId
    //     }
    // };
    player.setProtectionData(protData);

    if (!isLivePlayback) {
        // console.log('updateSettings!')
        player.updateSettings({
            'streaming': {
                'calcSegmentAvailabilityRangeFromTimeline': true,
                'jumpGaps': true,
                'jumpLargeGaps': true,
                'smallGapLimit': 1.5
            }
        })
    } else {
        player.updateSettings({
            'streaming': {
                'calcSegmentAvailabilityRangeFromTimeline': false,
            }
        })
    }

    // url = 'https://tr.live.cdn.cgates.lt/live/dash/562008/index.mpd'
// console.log('attachSource! ', url)
    player.attachSource(url)
}

const onSeekBarMouseMove = (event) => {
    if (!thumbnailContainer || !thumbnailElem) return;
    var mouseTime = calculateTimeByEvent(event);
// console.log('mouseTime', mouseTime)
    if (isNaN(mouseTime)) return;

    onMouseTime(mouseTime, event)
};

const calculateTimeByEvent = (event) => {
    const seekbarRect = seekbar.getBoundingClientRect();
    // return Math.floor(player.duration() * (event.clientX - seekbarRect.left) / seekbarRect.width);
    return Math.floor(timelineDuration * (event.clientX - seekbarRect.left) / seekbarRect.width);
};
