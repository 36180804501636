import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl";
import { createProfile } from '../../store/actions/profilesActions'
import config from '../../config/config'
import { setAppLang } from '../../store/actions/authActions'
import DragDropList from './DragDropList'
import FlagLT from '../../img/flag-lt.svg'
import FlagGB from '../../img/flag-gb.svg'
import FlagRU from '../../img/flag-ru.svg'

class Languages extends Component {

    setLangAndReload(language) {
        this.props.setAppLang(language)
        window.location.reload()
    }

    render() {

      let Flag = null;

        // eslint-disable-next-line default-case
      switch(this.props.auth.lang) {
        case "lt":
          Flag = FlagLT;
          break;
        case "en":
          Flag = FlagGB;
          break;
        case "ru":
          Flag = FlagRU;
      }

      return (
        <div className="w-100 lang-settings">
          <div className="interface-lang">
            <div className="d-flex align-items-center">
              <img src={Flag} alt={this.props.auth.lang} style={{paddingRight: '0.5rem'}} />
              <FormattedMessage id="language_menu" />:
            </div>

            <div className="options">
              {config.interfaceLanguages.map((language, index) => {
                  return <button key={index} className={language === this.props.auth.lang ? "active" : ""} onClick={() => this.setLangAndReload(language)}>{language}</button>
              })}
            </div>
          </div>

          <div className="lang">
            <div className="audio-lang">
              <DragDropList type="audio" />
            </div>
            <div className="subtitle-lang">
              <DragDropList type="subtitle" />
            </div>
          </div>
        </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
      return {
          createProfile: (profile) => dispatch(createProfile(profile)),
          setModalState: (id, title, body, back_button) => { dispatch(setModalState(id, title, body, back_button)) },
          setAppLang: (lang) => { dispatch(setAppLang(lang)) },
      }
}

export default connect(mapStateToProps, mapDispatchToProps)(Languages)
