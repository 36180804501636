import React from 'react'
import Avatar from './/Avatar'
import Scrollbar from 'react-scrollbars-custom'

const { useState } = React

const AvatarList = ({ avatars, setActiveAvatar, currentAvatar }) => {
//     useEffect(() => {
//         const scrollHorizontally = (e) => {
//             e = window.event || e;
//             var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
//             document.getElementById('yourDiv').scrollLeft -= (delta*40); // Multiplied by 40
//             e.preventDefault();
//         }
//         if (document.getElementById('yourDiv').addEventListener) {
//             // IE9, Chrome, Safari, Opera
//             document.getElementById('yourDiv').addEventListener("mousewheel", scrollHorizontally, false);
//             // Firefox
//             document.getElementById('yourDiv').addEventListener("DOMMouseScroll", scrollHorizontally, false);
//         } else {
//             // IE 6/7/8
//             document.getElementById('yourDiv').attachEvent("onmousewheel", scrollHorizontally);
//         }
//     });

    let [active, setActive] = useState(currentAvatar);
    active = currentAvatar;

    return (
        <div className="avatar-slider">
          <p>Choose avatar</p>
          {/* <div id='yourDiv'> */}
          <Scrollbar
            thumbXProps={{ className: "thumbX" }}
            style={{ height: 100}}
          >

            {avatars.map((avatar, index) => {
              return (
                <div className="avatar-card-new" key={index}>
                  <Avatar
                    setActive = {setActive}
                    setActiveAvatar = {setActiveAvatar}
                    {...avatar}
                    isActive={active === avatar.id}
                  />
                </div>
              )
            })}
          </Scrollbar>
          {/* </div> */}
        </div>
    )
}

export default AvatarList