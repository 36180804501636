const config = {
    clientURI: window.clientURI,
    customerURL: window.customerURL,
    apiURI: window.apiURI,
    publicApiURI: window.publicApiURI,
    registerURI: window.registerURI,
    sseURI: window.sseURI,
    statsURI: window.statsURI,
    interfaceLanguages: window.interfaceLanguages,
    playerBufferBar: window.playerBufferBar,
    languagePriorityOptions: window.languagePriorityOptions,
    audioPriorityCookieName: '_audio_priority',
    appLangCookieName: '_app_lang',
    termsURL_lt: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/CGates%20TV%20Paslaugos%20Tiekimo%20S%C4%85lygos.md',
    privacyURL_lt: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga.md',
    termsURL_en: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/CGates%20TV%20Paslaugos%20Teikimo%20S%C4%85lygos%20(EN).md',
    privacyURL_en: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga%20(EN).md',
    termsURL_ru: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/CGates%20TV%20Paslaugos%20Teikimo%20S%C4%85lygos%20(RU).md',
    privacyURL_ru: 'https://bitbucket.org/epuzzle/cgates-legal-docs/raw/master/Privatumo%20(Slapuk%C5%B3)%20Politika%20ir%20Duomen%C5%B3%20Apsauga%20(RU).md'
};

export default config