import {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILURE,
} from '../actions/fetchActions';

const initialState = {
    loading: false,
    payload: {},
    source: null,
    error: null
}

const reducer = (state = initialState, action) => {
// console.log('apiReducer', action, state)

    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                source: action.source,
                loading: true
            }
        case FETCH_SUCCESS:
            let payload
// console.log('reducer state', state)
// console.log('reducer action', action)
// console.log('reducer action.subkey', action.subkey)
// console.log('reducer action.payload', state.payload, action.payload)
            if (typeof action.payload !== 'undefined' && state.payload) {
                payload = state.payload
                const keys = Object.keys(action.payload)
                for (const key of keys) {
                    if (!Array.isArray(action.payload[key])) {
                        const subKeys = Object.keys(action.payload[key])
                        if (subKeys.length) {
                            for (const subKey of subKeys) {
                                if (typeof payload[key] == 'undefined') {
                                    payload[key] = { [subKey]: {} }
                                }
                                payload[key][subKey] = action.payload[key][subKey]
                            }
                        } else {
                            payload[key] = action.payload[key]
                        }
                    } else {
                        // payload[key] = action.payload[key]
                        payload[key] = { ...state.payload[key], ...action.payload[key] }
                    }
                }
            } else {
                payload = { ...state.payload, ...action.payload }
            }
// console.log('FETCH_SUCCESS return', payload)
            return {
                source: action.source,
                loading: false,
                payload: payload,
                error: null
            }
        case FETCH_FAILURE:
// console.log('Object.keys(action.payload):', Object.keys(action.payload), action)
            let emptyPayload = {}
            emptyPayload[action['subkey']] = { items: { items: [] } }

            return {
                source: action.source,
                loading: false,
                payload: { ...state.payload, ...emptyPayload },
                // error: action.payload
            }
        default: return state
    }
}

export default reducer
