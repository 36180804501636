import React, { useState, useRef, useEffect } from 'react'
// import { connect } from 'react-redux';
import {Link, NavLink} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import VodAboutSection from "./VodAboutSection";
import PlayIcon from "../../img/play-icon.svg";
// import ContinueIcon from "../../img/continue-icon.svg";
import {handleCookie} from "../addons/CookiesHandler";
// import PinDialogClass from "./PinDialogClass";
import WishListTransparent from "../../img/wish-list-transparent.svg";
import WishListGreen from "../../img/wish-list-green.svg";
import config from "../../config/config";
import {wishlistEndpoint} from "../../store/actions/apiEndpoints";
import ImdbIcon from "../../img/imdb-icon.svg";
import PinInput from "./PinInput";
import {connect} from "react-redux";
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";
import {setAdultPin} from "../../store/actions/globalStylesActions";

const VodAboutMain = ({
                          modal_simple_state,
                          setModalSimpleState,
                          setAdultPin,
                          props,
                          userDemo,
                          fetchStamps,
                          series = false,
                          wishlistRefresh,
}) => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const containerHeight = containerRef.current?.clientHeight;
        const contentHeight = contentRef.current?.clientHeight;
        if (contentHeight <= containerHeight) {
            setIsTruncated(false);
        } else {
            setIsTruncated(true);
        }
    }, []);
    // }, [containerRef.current, contentRef.current]);

    let loading = (!props.api_data.payload.vod_about || !props.auth.profile)
    for (let i in fetchStamps) {
        if (!props.api_data.payload[i]) {
            loading = true
            break
        }
    }

    const movie = props.api_data.payload.vod_about;
// console.log('movie:', movie)
    if (!movie) {
        return null
    }
    let wishlistAction = ''
    if (!series || (typeof props.api_data.payload.vod_about.id === 'string' && props.api_data.payload.vod_about.id.startsWith('vod.'))) {
        wishlistAction = 'add'
        if (
            props.api_data.payload.wishlist &&
            props.api_data.payload.wishlist.vod_map &&
            typeof props.api_data.payload.wishlist.vod_map[movie['id']] !== 'undefined'
        ) {
            wishlistAction = 'rm'
        }
    }

    let purchased = false
    if (
        (props.global_styles &&
        props.global_styles.shop_items &&
        props.global_styles.shop_items.provider_id &&
        props.global_styles.shop_items.provider_id[movie.provider_id.toString()])
        ||
        (props.global_styles &&
        props.global_styles.shop_items &&
        props.global_styles.shop_items.id &&
        props.global_styles.shop_items.id[movie.id.toString()])
    ) {
        purchased = true
    }

    let directors = '';
    if (movie.metadata.directors && movie.metadata.directors.length) {
        for (const element of movie.metadata.directors) {
            directors = directors ? (directors + ', ' + element.name) : element.name
        }
    }

    // let actors = ''
    // if (movie.metadata.actors && movie.metadata.actors.length) {
    //     for (const element of movie.metadata.actors) {
    //         actors = actors ? (actors + ', ' + element.name) : element.name
    //     }
    // }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
    const regionNames = new Intl.DisplayNames([props.auth.lang], { type: 'region' });

    let country = ''
    if (movie.metadata.countries && movie.metadata.countries.length) {
        for (const element of movie.metadata.countries) {
            if (element) {
                const regionName = regionNames.of(element)
                country = country ? (country + ', ' + regionName) : regionName
            }
        }
    }

    const { covers, posters, title, imdb, released, age_rating, description, actors, tva_contents, audio_tracks, subtitles } = movie.metadata
    const cover = covers ? covers[0] : null
    const poster = posters ? posters[0] : null
    const imdbRating = imdb ? imdb['rating'].toFixed(1) : null
    const ageRating = age_rating ? age_rating + '+' : null
    let playLink = '/play?type=vod&vod_id=' + movie.id
// console.log('movie:', movie)
    if (!movie.subscribed && !purchased && !userDemo) {
        playLink = '/shop/' + movie.type + '/' + (movie.type === 'svod' ? movie.provider_id : movie.id)
        handleCookie({
            operation: 'add',
            name: 'shop_redirect_url',
            value: window.location.pathname + window.location.search
        })
    }

    let adultPinStampValid = true
    let ageRestricted = false
    if (props.auth.profile && movie.metadata && movie.metadata['age_rating'] > 18 && !props.auth.profile.age) {
        const nowStamp = Date.now() / 1000 | 0
        if (!props.global_styles.adult_pin_stamp || (props.global_styles.adult_pin_stamp + 180 < nowStamp)) {
            adultPinStampValid = false
        }
    } else if (props.auth.profile &&
        props.auth.profile.age &&
        movie.metadata['age_rating'] &&
        movie.metadata['age_rating'] > props.auth.profile.age
    ) {
        ageRestricted = true
    }
// console.log('userDemo, ageRestricted, adultPinStampValid', userDemo, ageRestricted, adultPinStampValid)
    if (!userDemo && (ageRestricted || !adultPinStampValid)) {
        const pinToCheck = props.auth.profile.pin ? props.auth.profile.pin : props.auth.profile.pin_with_default
        if (modal_simple_state && (!modal_simple_state.show || modal_simple_state.overlayOnly)) {
            setModalSimpleState({
                show: true,
                overlayOnly: false,
                closeOnClick: false,
                header: ageRestricted ? "search_query_results_no_match" : "pin_required_title",
                style: {width: "400px"},
                headerStyle: {marginBottom: "40px"},
                hideClose: true,
                body: (
                    <div>
                        <div className="pt-3">
                            <FormattedMessage id={ageRestricted ? "content_is_blocked_title" : "content_needs_pin_message"} />
                        </div>

                        {ageRestricted ?
                            (
                                <div className="row confirm-cancel-row">
                                    <div className="buttons">
                                        <button className="button btn-primary btn-lg" onClick={() => window.history.back()}>
                                            <FormattedMessage id={"common_dialog_button_ok_text"} />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <form onSubmit={(e) => handlePinSubmit(e)}>
                                    <div className="form-group" style={{marginBottom: '47px', marginTop: '16px'}}>
                                        <div className={"d-flex"}>
                                            <PinInput
                                                onPinEntered={(pin, hasError) => handlePinEntered(pin, hasError)}
                                                pinToCheck={pinToCheck}
                                                passwordType={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="button btn-secondary btn-lg me-2" onClick={(e) => handleCloseClick(e)}>
                                            <FormattedMessage id={"profile_button_cancel"} />
                                        </button>
                                        <button className="button btn-primary btn-lg">
                                            <FormattedMessage id={"pin_dialog_button_ok_text"} />
                                        </button>
                                    </div>
                                </form>
                            )
                        }
                    </div>
                )
            })
        }
    } else if (userDemo && !adultPinStampValid) {
        if (modal_simple_state && !modal_simple_state.overlayOnly) {
            setModalSimpleState({
                // show: true,
                overlayOnly: true,
            })
        }
    // } else if (!userDemo && adultPinStampValid && modal_simple_state && (!modal_simple_state.show || modal_simple_state.overlayOnly)) {
    //     setModalSimpleState({
    //         show: true,
    //         overlayOnly: false,
    //     })
    }

    const moreModal = (e) => {
        setModalSimpleState({
            show: true,
            header: movie.metadata.title,
            body: movie.metadata.description
        })
    }

    const handleWishlistClick = (e) => {
        e.preventDefault()
        let xhr = new XMLHttpRequest()
        xhr.onload = () => {
            if (xhr.status === 201 && wishlistAction === 'add') {
                wishlistRefresh()
            } else if (xhr.status === 200 && wishlistAction === 'rm') {
                wishlistRefresh()
            }
        };
        xhr.onerror = (e) => {
            console.log("onerror", e.error);
        };
        const requesParams = {
            action: wishlistAction === 'add' ? 'post' : 'delete',
            profile_id: props.auth.profile.id,
            series: series,
            vod_id: wishlistAction === 'add' ? movie['id'] : props.api_data.payload.wishlist.items[props.api_data.payload.wishlist.vod_map[movie['id']]]['id']
        }

        if (wishlistAction === 'add') {
            xhr.open("POST", config['apiURI'] + "/" + wishlistEndpoint(requesParams), true);
            // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        } else {
            xhr.open("DELETE", config['apiURI'] + "/" + wishlistEndpoint(requesParams), true);
        }
        xhr.setRequestHeader("Authorization", "Bearer " + props.auth.token);
        xhr.send()

        // reminderAction === 'add' ? xhr.send(stringToSend) : xhr.send()
    }

    const playButton = (
        (movie.subscribed || purchased || userDemo) ?
        <NavLink className={"button btn-primary-bright btn-lg d-flex me-3"} to={playLink}>
            <img src={PlayIcon} alt="" className={"me-2"} />
            <div className={"w-100 text-nowrap"}>
                <FormattedMessage id="vod_event_action_watch" />
            </div>
        </NavLink>
        :
        <NavLink className={"button btn-primary-bright btn-lg d-flex me-3"} to={playLink}>
            {/*<img src={BasketIcon} alt="" className={"me-2"} />*/}
            <div className={"w-100 text-nowrap"}>
                <FormattedMessage id="action_buy" />
            </div>
        </NavLink>
    )

    const wishlistButton = (
        wishlistAction && props.auth && props.auth.token ?
            <NavLink className="button btn-secondary btn-lg d-flex me-3"
                to={""}
                onClick={(event) => {
                    event.preventDefault();
                    handleWishlistClick(event);
                }}
            >
                <img src={wishlistAction === 'add' ? WishListTransparent : WishListGreen} className={"me-2"} alt="" />
                <div className={"w-100 text-nowrap"}>
                    <FormattedMessage id={'action_' + (wishlistAction === 'add' ? 'add_to' : 'remove_from') + '_wishlist'} />
                </div>
            </NavLink>
            :
        null
    )

    const trailerLink = '/play?type=vod_trailer&vod_id=' + movie.id
    const trailerButton = (
        <NavLink className={"button btn-secondary btn-lg d-flex me-3"} to={trailerLink}>
            <div className={"w-100 text-nowrap"}>
                <FormattedMessage id="vod_event_action_trailer" />
            </div>
        </NavLink>
    )

    const MovieSelected = () => {
// console.log('movie.type:', movie.type, movie.type !== 'generic')
        if (movie.type === 'generic') {
            return  <div className={"page-margins"}><h1 style={{marginBottom: '20px', marginTop: '32px'}}>{title}</h1></div>
        }

        return (
            <div className={"section"}>
                <div className={"smart-item-wide"} style={{marginBottom: '30px'}}>
                    <img className={"cover"} src={cover} alt='' />
                    <div className={"description about-box d-flex"}>
                        <img className={"about-poster"} src={poster} alt='' />
                        <div className={"labels d-flex flex-column justify-content-end"}>
                            <img className={"about-provider"} src={movie.logo} alt='' />
                            <h1 style={{marginBottom: '14px'}}>{title}</h1>
                            <div className={"d-flex align-items-center tags-box"} style={{marginBottom: '32px'}}>
                                {imdb ?
                                    <div className={"imdb-box"}>
                                        <img src={ImdbIcon} alt="" />
                                        {imdbRating}
                                    </div> : null
                                }
                                {age_rating ? <div className={"age-box"}>{ageRating}</div> : null}
                                <span className={"text-bright d-flex"}>
                                                    {released ? <div>{released}</div> : null}
                                    {movie.duration ? <div><span>|</span>{parseInt(movie.duration/60) + "min."}</div> : null}
                                    {tva_contents ? <div><span>|</span>{tva_contents.join(', ')}</div> : null}
                                                </span>
                            </div>
                            {
                                series ? null : (
                                    <div className={"d-flex"}>
                                        {playButton}
                                        {movie.trailers ? trailerButton : null}
                                        {wishlistButton}
                                    </div>
                                )
                            }
                            <div className={"d-flex"} style={{marginTop: '18px'}}>
                                {
                                    description ?
                                        <div
                                            className={"movie-description"}
                                        >
                                            <div
                                                ref={containerRef}
                                                style={{display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden'}}
                                            >
                                                <div ref={contentRef}>{description}</div>
                                            </div>
                                            {isTruncated ?
                                                <Link
                                                    href = {"#"}
                                                    data-modal-type="conditions"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        moreModal(e);
                                                    }}>
                                                    <FormattedMessage id="vod_item_description_more" />
                                                </Link>
                                                : null
                                            }
                                        </div>
                                        : null
                                }
                                {
                                    audio_tracks || subtitles ?
                                        <div className={"movie-description bg-transparent"}>
                                            {
                                                audio_tracks ?
                                                    <>
                                                        <span className={"text-dim"}><FormattedMessage id="vod_item_info_header_languages"/>: </span>
                                                        {
                                                            audio_tracks.map((language, index) => (
                                                                (index ? ", " : "") + new Intl.DisplayNames([props.auth.lang], { type: "language" }).of(language)
                                                            ))
                                                        }
                                                        <br />
                                                    </>
                                                    : null
                                            }
                                            {/* atkomentuoti, kai bus teisinga info apie subtitrus */}
                                            {
                                                subtitles ?
                                                    <>
                                                        <span className={"text-dim"}><FormattedMessage id="vod_item_info_header_subtitles"/>: </span>
                                                        {
                                                            subtitles.map((language, index) => (
                                                                (index ? ", " : "") + new Intl.DisplayNames([props.auth.lang], { type: "language" }).of(language)
                                                            ))
                                                        }
                                                        <br />
                                                    </>
                                                : null
                                            }
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {actors ?
                    <div className="crew-box">
                        <h4><FormattedMessage id="vod_item_info_header_cast_and_crew"/></h4>
                        <VodAboutSection section={actors} />
                    </div>
                    : null
                }
            </div>
        )
    }

    const DataLoading = () => {
        return (
            <div className="loader">
                {/*<img className="loader-img" src={Spinner} alt="" />*/}
                <span className="loader-img"/>
            </div>
        )
    }

    const handleCloseClick = (e) => {
        e.preventDefault()
        setModalSimpleState({
            show: false,
            overlayOnly: false,
        })
        window.history.back()
    }

    const handlePinEntered = (enteredPin, hasError) => {
// console.log('handlePinEntered:', enteredPin, hasError)
        if (!hasError) {
            setAdultPin(Date.now() / 1000 | 0)
            setModalSimpleState({
                show: false,
            })
        }
    }

    const handlePinSubmit = (e) => {
        e.preventDefault()
// console.log('handlePinSubmit')
    }

    const DataArrived = () => {
// console.log('age_rating', movie.metadata['age_rating'])
// console.log('ageRestricted adultPinStampValid', ageRestricted, adultPinStampValid)
// console.log('props.modal_simple_state:', modal_simple_state)
        // if (ageRestricted) {
        //     return (
        //         <PinDialogClass
        //             confirmShow={true}
        //             alertType={true}
        //             handleSubscribeSet={handlePinSubmit}
        //             pinHasError={pinHasError}
        //             title={'search_query_results_no_match'}
        //             action={'content_is_blocked_title'}
        //         />)
        // } else if (!adultPinStampValid) {
        //     return (
        //         <PinDialogClass
        //             confirmShow={true}
        //             alertType={false}
        //             handleSubscribeSet={handlePinSubmit}
        //             handleCloseClick={handleCloseClick}
        //             pinHasError={pinHasError}
        //             title={'pin_required_title'}
        //             action={'content_needs_pin_message'}
        //         />)
        // }

        return <>
            <MovieSelected />
        </>
    }

    return (
        <>
            {
                loading ? <DataLoading /> : <DataArrived />
            }
        </>
    )

}

const mapStateToProps = state => {
    return {
        modal_simple_state: state.modal_simple_state,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAdultPin: (value) => { dispatch(setAdultPin(value)) },
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VodAboutMain)