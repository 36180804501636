import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setAppClass } from '../../store/actions/globalStylesActions'
import EnterEmail from './EnterEmail'
import NewPassword from './NewPassword'

const PasswordRecovery = props => {
    const [email, setEmail] = useState('')
    const [emailCorrect, setEmailCorrect] = useState(false)
    const [emailEmpty, setEmailEmpty] = useState(false)

    const { history, setAppClass } = props

    useEffect(() => {
        setAppClass('password-recovery')
    }, [setAppClass])

    const handleSubmit = e => {
        e.preventDefault()

        let canSubmit = true

        if (!email.length) {
            setEmailEmpty(true)
            canSubmit = false
        }

        if (canSubmit)
            setEmailCorrect(true)
    }

    if (emailCorrect)
        return <NewPassword email={email} history={history} />
    else
        return <EnterEmail handleSubmit={handleSubmit} email={email} setEmail={setEmail} emailEmpty={emailEmpty}
                           setEmailEmpty={setEmailEmpty} />
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(null, mapDispatchToProps)(PasswordRecovery)