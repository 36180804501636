import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setModalObj, setModalState} from '../../store/actions/modalStateActions'
import ProfilesList from '../elements/ProfilesList'
import ProfilesView from '../elements/ProfilesView'
import SettingsList from '../elements/SettingsList'
import {FormattedMessage} from "react-intl";
import ProfilesIcon from '../../img/profiles-icon.svg'
import BackIcon from '../../img/back-icon.svg'
import CloseIcon from '../../img/close-icon.svg'
import SubscriptionsIcon from '../../img/subscriptions-icon.svg'
import Subscriptions1 from '../elements/Subscriptions1'
import Subscriptions2 from '../elements/Subscriptions2'
import SettingsIcon2 from '../../img/settings-icon-2.svg'
import About from '../elements/About'
import InfoIcon from '../../img/info-icon.svg'
import { Modal } from "bootstrap";

class ModalElement extends Component {
    componentDidMount() {
        let modalObj = new Modal(document.getElementById("default-modal"))
        this.props.setModalObj(modalObj)
    }

    handleBackClick = (e) => {
        if (e === 'ProfilesList') {
            if (this.props.auth.profile.master_flag) {
                this.props.setModalState('profiles-list', <FormattedMessage id="profile_manage_title" />, <ProfilesList />, 'SettingsList', <ProfilesIcon />);
            } else {
                this.props.setModalState('settings-list', <FormattedMessage id="main_menu_settings" />, <SettingsList />, null, <SettingsIcon2 />);
            }
        } else if (e === 'SettingsList') {
            this.props.setModalState('settings-list', <FormattedMessage id="main_menu_settings" />, <SettingsList />, null, <SettingsIcon2 />);
        } else if (e === 'ProfilesView') {
            this.props.setModalState('profiles-view', null, <ProfilesView />, null, <ProfilesIcon />);
        } else if (e === 'Subscriptions1') {
            this.props.setModalState('subscriptions-modal-1', <FormattedMessage id="settings_action_subscription" />, <Subscriptions1 />, 'SettingsList', <SubscriptionsIcon />);
        } else if (e === 'Subscriptions2') {
            this.props.setModalState('subscriptions-modal-2', <FormattedMessage id="settings_action_subscription" />, <Subscriptions2 />, 'Subscriptions1', <SubscriptionsIcon />);
        } else if (e === 'About') {
            this.props.setModalState('about-modal', <FormattedMessage id="settings_action_about" />, <About />, 'SettingsList', <InfoIcon />);
        }
    }

    render() {
        const { modal_state } = this.props.modal_state
        const headerClass = modal_state.back_button ? "modal-header d-flex justify-content-between" : "modal-header"
        const headerBack = modal_state.back_button ? (
            <div className="back-btn">
                <button type="button" className="btn btn-info" onClick={() => this.handleBackClick(modal_state.back_button)}>
                    <img className="profiles-icons" src={BackIcon} alt="" />
                </button>
            </div>
        ) : null

        const headerIcon = modal_state.header_icon ? (
            <img className="profiles-icons" src={modal_state.header_icon.type} alt="" />
        ) : null

        const closeIcon = modal_state.no_close ? null : (
            <button type="button" className="close-btn" data-bs-dismiss="modal">
                <img className="profiles-icons" src={CloseIcon} alt="" />
            </button>
        )

        const header = modal_state.title ? (
            <div className={headerClass}>
                {headerBack}
                <div className="modal-title">
                    {headerIcon}
                    <span>{modal_state.title}</span>
                </div>
            </div>
        ) : null

        return <div className={"modal-dialog " + modal_state.id}>
            <div className="modal-content">
                {closeIcon}
                {header}
                <div className="modal-body">
                    {modal_state.body}
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        modal_state: state.modal_state,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) },
        setModalObj: (obj) => { dispatch(setModalObj(obj)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalElement)
