import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAppClass } from '../../store/actions/globalStylesActions'
import Logo from '../../img/logo.svg'

const Status = props => {
    const location = useLocation()
    const { history, setAppClass } = props

    useEffect(() => {
        setAppClass('status')
    }, [setAppClass])

    if (location.state === undefined)
        return <div className='unauthorised'><h1>Unauthorised</h1></div>

    const { status, messageOne, messageTwo, errors, buttonOne, buttonOneFunction, lang } = location.state

    const linkToSignUp = () => {
        history.push('/sign-up?locale=' + lang)
    }

    const linkToSignIn = () => {
        window.open('https://cgates.tv/oauth2/authorize?client_id=41f9bebf-2948-4f47-9dd3-d9b2edacacd3&redirect_uri=' +
            'https://cgates.tv/authorize/oauth-callback&response_type=code&locale=' + lang, '_self')
    }

    let handleClick = () => {
        if (buttonOneFunction === 'linkToSignUp')
            linkToSignUp()
        else if (buttonOneFunction === 'linkToSignIn')
            linkToSignIn()
    }

    //-- pagal pageidavimą https://epuzzle.atlassian.net/browse/PUZMW-1361
    let errorInline = null
    let errorParagraph = null

    if (errors) {
        errorInline = typeof errors[0] !== 'undefined' ? errors[0] : null
        errorParagraph = typeof errors[1] !== 'undefined' ? <p>{errors[1]}</p> : null
    }

    return (
        <div className='status-page'>
            <img className='cgates-logo' src={Logo} alt='cgates logo' />

            <div className='status'>
                <p>{status}</p>
            </div>

            <div className='message'>
                <p>{messageOne} {errorInline}</p>
                {errorParagraph}
                <p>{messageTwo}</p>
            </div>

            <div className='buttons-container'>
                {buttonOne ? <button className='button' onClick={handleClick}>{buttonOne}</button> : null}
                {/*{buttonTwo ? <button className='button second-button'>{buttonTwo}</button> : null}*/}
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(null, mapDispatchToProps)(Status)