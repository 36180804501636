import React from "react"

const Notification = (props) => {
    return (
        <div style={{display: props.show === false ? 'none' : 'block'}}>
            <div className="overlay"/>
            <div className="header">
                <p>{props.header}</p>
            </div>
            <div className="body">
                <p>{props.body}</p>
            </div>
            <div className="row confirm-cancel-row">
            <div className="buttons">
                <button className="button btn-secondary btn-lg" onClick={props.handleStopShowing}><p>Cancel</p></button>
                <button className="button btn-primary btn-lg" onClick={() => {props.handleDelete();props.handleStopShowing()}}><p>{props.buttonText}</p></button>
            </div>
            </div>
        </div>
    )
}

export default Notification