import React, {Component} from 'react'
import {injectIntl, FormattedMessage} from "react-intl"
import {connect} from 'react-redux'
import msgpack from 'msgpack-lite'
import {setAdultPin, setAppClass} from '../../store/actions/globalStylesActions'
import {getPriorityList} from "../addons/PrioritiesHandler"
import PlaceholderPoster from "../../img/placeholder-poster.svg"

import {
    player,
    currentEpg,
    setCurrentEpg,
    setUserInfo,
    setCurrentBookmarks,
    isFullscreen,
    exitFullscreen,
    enterFullscreen,
    setTimelineDuration,
    setPlaybackType,
    // LiveThreshold,
    setTranslatedStrings,
    playerSetSource,
    channelSelected,
    setChannelSelected,
    PlayerTimeUTC,
    PlayerTime,
    timelineDuration,
    // playerIsDynamic,
    isLiveEpg,
    isLivePlayback,
    // setLivePlayback,
    setPlayerTime,
    setVolume,
    getVolume,
    playerInit,
    // playerDestroy,
    setPlayerLocale,
    audioTracksAvailable,
    // setViewerProfile,
    setMouseMoveStamp,
} from '../addons/DashControlBar'
import {Redirect, withRouter} from 'react-router-dom'
import {MediaPlayer} from 'dashjs'
import '../../css/dash-controlbar.css'
import PrevButton from '../../img/prev-button.svg'
// import PrevFastButton from '../../img/prev-fast-button.svg'
import PlayButton from '../../img/play-button.svg'
import PauseButton from '../../img/pause-button.svg'
import ForwardButton from '../../img/forward-button.svg'
// import ForwardFastButton from '../../img/forward-fast-button.svg'
import FullScreenButton from '../../img/full-screen-button.svg'
import FullScreenExitButton from '../../img/full-screen-exit-button.svg'
import MuteButton from '../../img/mute-button.svg'
import MutedButton from '../../img/muted-button.svg'
import AdjustmentIcon from '../../img/adjusment-icon.svg'
import TextsIcon from '../../img/texts-icon.svg'
import BackIcon from '../../img/back-icon.svg'
import {
    setPlayerEpgId,
    setPlayerEpgIndex,
} from '../../store/actions/playerActions'
import {fetchAction} from '../../store/actions/fetchActions'
import {
    bookmarksEndpoint,
    epggridEndpoint, nextEndpoint,
    spritesEpgEndpoint,
    spritesVodEndpoint, vodAboutEndpoint
} from '../../store/actions/apiEndpoints'
import {
    BookmarksMap,
    EpgToChannelDays,
    SpritesToEpgTimeline,
    SpritesToVodTimeline,
    VodAboutWrap
} from '../../store/actions/ApiKeysMap'
import config from '../../config/config'
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import {handleCookie} from "../addons/CookiesHandler";
// import NotificationIcon from "../../img/notification-icon.svg";
// import PinDialogClass from "../elements/PinDialogClass";
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";
import PinInput from "../elements/PinInput";

class Play extends Component {
    currentItemIndex
    bookmarkPostStamp = 0
    bookmarkPostInterval = 60
    previewThreshold = 20
    secAfterStart = 0
    watchedEpgThreshold = 20
    epgChangeStamp = 0
    prevExists = false
    nextExists = false
    spritesOffset = 326
    startFormatted = null
    bookmarksInterval = null
    spritesFetchInterval = null
    statsPostInterval = null
    nextVodItem = null
    searchParamsType
    searchParamsSection
    searchParamsShop
    searchParamsIndex = null
    searchParamsChannelId = null
    searchParamsEpgId = null
    searchParamsVodId = null
    channelEpg = []
    playerVods = []
    spritesFetched
    epgFetched = {}
    vodPurchased = {}
    url = ''
    initializationComplete = false
    playbackTimeUpdated = false
    channelSubscribed = false
    globalStyleOnMount = ''
    pauseStamp = 0
    adultContent = false
    adultPinStampValid = false
    adultBlur = false
    ageRestricted = false
    latencyOnPause = 0
    seekOnPause = 0
    pauseOnLive = false
    nextChecked = false
    nextCanceled = false
    vodRequested = 0
    bookmarksRequested = false
    vodSet = false
    redirected = null
    translatedMap = {}

    state = {
        isLive: null,
        isEpg: null,
        currentTitle: null,
        currentAgeRating: null,
        currentDescription: null,
        currentLogo: null,
        startFormatted: null,
        stopFormatted: null,
        tva_contents: null,
        released: null,
        duration: null,
        volume: 0.5,
        volumeSaved: 0,
        muted: false,
        pinValue: '',
        pinHasError: false,
        redirectTo: null
    }

    constructor(props) {
console.log('constructor')
        super(props)
        this.letsGoBack = this.goBack.bind(this)
        // this.goBack = React.createRef()
        this.playPauseBtn = React.createRef()
        this.nextWidget = React.createRef()
        this.nextWidgetImage = React.createRef()
        this.nextWidgetCounter = React.createRef()
        this.nextWidgetTitle = React.createRef()
        this.nextWidgetText = React.createRef()
        this.prevBtn = React.createRef()
        // this.prevFastBtn = React.createRef()
        this.forwardBtn = React.createRef()
        // this.forwardFastBtn = React.createRef()
        this.controlBtnGroup = React.createRef()
        this.muteBtn = React.createRef()
        this.volumebar = React.createRef()
        this.fullScreenBtn = React.createRef()
        // this.videoController = React.createRef()
        this.seekBarLive = React.createRef()
        this.state = {
            redirectTo: null,
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    forwardAction(sameSource) {
        this.initializationComplete = false
// console.log('forwardAction', isLiveEpg)
        let nextItemIndex = this.currentItemIndex
        if (!isLiveEpg) {
            nextItemIndex += 1
            //-- jei nėra kito EPG, fečinam šito kanalo šitos dienos EPG
            if (typeof this.channelEpg[nextItemIndex] === 'undefined') {
                //-- fečinam tik jei šito timestampo dar nefečinom
                if (typeof this.epgFetched[this.channelEpg[this.currentItemIndex]['start']] === 'undefined') {
                    this.props.fetchAction(
                        this.props.auth.token,
                        epggridEndpoint({
                            channels: [this.channelEpg[this.currentItemIndex]['channel_id']],
                            pastDays: 0,
                            futureDays: 1,
                            lang: this.props.auth.lang,
                            fromStamp: this.channelEpg[this.currentItemIndex]['start']
                        }),
                        this.constructor.name,
                        EpgToChannelDays
                    )
                    this.epgFetched[this.channelEpg[this.currentItemIndex]['start']] = true
                } else {
                    //-- bandom krauti iš redukso į this.channelEpg
                    const dayStamps = Object.keys(this.props.payload.epg[this.searchParamsChannelId]['items']).sort()
                    const currentEpgId = this.channelEpg[this.currentItemIndex]['id']
                    if (dayStamps) {
                        this.channelEpg = []
                        for (const dayStamp of dayStamps) {
                            for (const item of this.props.payload.epg[this.searchParamsChannelId]['items'][dayStamp]) {
                                this.channelEpg.push(item)
                            }
                        }
                        nextItemIndex = this.channelEpg.findIndex(({id}) => id === currentEpgId) + 1
                    }
                }
            }
        }

        if (this.searchParamsType === 'tv') {
            this.setCurrentEpg(nextItemIndex, sameSource)
        } else {
            this.setCurrentVod(nextItemIndex)
        }
    }

    prevAction() {
        this.initializationComplete = false
        const nextItemIndex = this.currentItemIndex - 1
        if (this.searchParamsType === 'tv') {
            // setPlayerTimeUTC(0)
            this.setCurrentEpg(nextItemIndex)
        } else {
            this.setCurrentVod(nextItemIndex)
        }
    }

    playPauseAction() {
        if (player.isPaused()) {
            //-- apsisprendžiam, ar toliau rodyti laivą, ar jau iš kečupo
            const nowStamp = Date.now() / 1000 | 0
            let toLive = 0
            // if (isLivePlayback) {
                toLive = nowStamp - this.pauseStamp
            // }
            if (toLive > 120 && this.searchParamsType === 'tv') {
                playerSetSource(null, this.seekOnPause - 25)
            } else {
                player.play()
                this.playPauseBtn.current.src = PauseButton
            }
        } else {
            player.pause()
            this.playPauseBtn.current.src = PlayButton
        }
    }

    handleVolumeInput = e => {
        const volume = parseFloat(e.target.value)
        setVolume(volume)
    }

    handleVolumeChange = e => {
        const volume = Math.round(e * 100) / 100
        // console.log("volume change: ", volume)
        setVolume(volume)
        this.setState(
            {
                ...this.state,
                volume: volume
            }
        )
    }

    toggleMuteAction() {
        // console.log('toggleMute', this.state.muted)
        if (!this.state.muted) {
            this.setState(
                {
                    ...this.state,
                    muted: true,
                    volume: 0,
                    volumeSaved: getVolume()
                }
            )
            setVolume(0)
        } else {
            setVolume(this.state.volumeSaved)
            this.setState(
                {
                    ...this.state,
                    muted: false,
                    volume: this.state.volumeSaved
                }
            )
        }
    }

    toggleFullscreen() {
        if (isFullscreen()) {
            exitFullscreen()
            this.fullScreenBtn.current.src = FullScreenButton
        } else {
            enterFullscreen()
            this.fullScreenBtn.current.src = FullScreenExitButton
        }
    }

    setCurrentVod(i, type) {
// console.log('setCurrentVod()', i, this.playerVods)
        // this.redirectTo = null
        if (
            !this.playerVods ||
            !this.playerVods[i] ||
            (
                !this.playerVods[i]['subscribed'] && !this.vodPurchased[this.playerVods[i]['id']]
            )
        ) {
            playerSetSource(null)
            return false
        }

        const nowStamp = Date.now() / 1000 | 0
        if (this.playerVods[i]['vod_set'] && (this.playerVods[i]['vod_set'] + 10) > nowStamp) {
            return false
        }
        this.playerVods[i]['vod_set'] = nowStamp

        this.ageRestricted = false
        this.adultBlur = false
// console.log('age:', this.playerVods[i]['metadata']['age_rating'], this.props.auth.profile.age)
// console.log('this.adultPinStampValid:', this.adultPinStampValid)
        if (!this.adultPinStampValid && this.playerVods[i]['metadata']['age_rating'] > 18) {
            this.adultBlur = true
            return false
        } else if (this.props.auth.profile.age &&
            this.playerVods[i]['metadata']['age_rating'] &&
            this.playerVods[i]['metadata']['age_rating'] > this.props.auth.profile.age
        ) {
            this.adultBlur = true
            this.ageRestricted = true
            return false
        }
        this.handleAdultBlur()

        this.currentItemIndex = i
// console.log('set it!', this.playerVods[i])
        if (type === 'vod_trailer') {
            if (this.playerVods[i]['trailers'] && this.playerVods[i]['trailers'][0]) {
                this.setState({
                    ...this.state,
                    isEpg: null,
                    currentTitle: this.playerVods[i]['metadata']['title'],
                    currentAgeRating: this.playerVods[i]['metadata']['age_rating'],
                    currentDescription: this.playerVods[i]['metadata']['description'],
                    tva_contents: this.playerVods[i]['metadata']['tva_contents'],
                    released: this.playerVods[i]['metadata']['released'],
                    duration: this.playerVods[i]['duration'] ? parseInt(this.playerVods[i]['duration']) : 0,
                    currentLogo: this.playerVods[i]['logo'],
                    startFormatted: null,
                    stopFormatted: null
                })
                this.prevExists = false
                this.nextExists = false
                playerSetSource({
                    id: this.playerVods[i]['id'],
                    provider_id: this.playerVods[i].provider_id,
                    type: this.playerVods[i].type,
                    subscribed: this.playerVods[i].subscribed,
                    url: this.playerVods[i]['trailers'][0]
                })
            }

            return false
        }

        fetch(config['apiURI'] + "/vod/" + this.playerVods[i].id + '/play', {
            headers: {
                'Authorization': 'Bearer ' + this.props.auth.token
            }
        })
            .then(response => response.json())
            .then(response => {
                // console.log('fetch response', response)
                if (response.error) {
                    throw(response.error);
                }
                if (!response.url) {
                    console.log('no response url:', response)
                    return false
                }
                // console.log('vod play response:', response)
                // this.currentUrl = response.url
                this.prevExists = false
                this.nextExists = false
                if (typeof this.playerVods[i - 1] !== 'undefined') {
                    this.prevExists = true
                }
                if (typeof this.playerVods[i + 1] !== 'undefined') {
                    this.nextExists = true
                }

                //-- nuresetinam duration
                setTimelineDuration(false, this.playerVods[i].duration)
                const vodSource = {
                    id: this.playerVods[i].id,
                    provider_id: this.playerVods[i].provider_id,
                    subscribed: this.playerVods[i].subscribed,
                    purchased: !!this.vodPurchased[this.playerVods[i].id],
                    type: this.playerVods[i].type,
                    ...response
                }
                playerSetSource(vodSource)

                //-- fečinam vodo spraitus
                clearInterval(this.spritesFetchInterval)
                if (this.spritesFetched !== this.currentItemIndex) {
                    this.props.fetchAction(
                        this.props.auth.token,
                        // spritesVodEndpoint(this.playerVods[this.currentItemIndex].id),
                        spritesVodEndpoint(this.playerVods[i].id),
                        this.constructor.name,
                        SpritesToVodTimeline
                    )
                    this.spritesFetched = this.currentItemIndex
                }

                this.setState({
                    ...this.state,
                    isEpg: null,
                    currentTitle: this.playerVods[i]['metadata']['title'],
                    currentAgeRating: this.playerVods[i]['metadata']['age_rating'],
                    currentDescription: this.playerVods[i]['metadata']['description'],
                    tva_contents: this.playerVods[i]['metadata']['tva_contents'],
                    released: this.playerVods[i]['metadata']['released'],
                    duration: this.playerVods[i]['duration'] ? parseInt(this.playerVods[i]['duration']) : 0,
                    currentLogo: this.playerVods[i]['logo'],
                    startFormatted: null,
                    stopFormatted: null
                })

                // this.playerAttachSource()
            })
            .catch(error => {
                console.log('response error:', error)
            })
    }

    setCurrentEpg(i, sameSource) {
// console.log('setCurrentEpg i', i, this.channelEpg)
        if (!this.channelEpg || !this.channelEpg.length || typeof this.channelEpg[i] === 'undefined') {
            return null
        }
        this.currentItemIndex = i
        setCurrentEpg(this.channelEpg[i])
// console.log('setCurrentEpg!', i, currentEpg)
        this.prevExists = false
        this.nextExists = false
        if (typeof this.channelEpg[i - 1] !== 'undefined') {
            this.prevExists = true
        }
        if (typeof this.channelEpg[i + 1] !== 'undefined') {
            this.nextExists = true
        }

        const nowStamp = Math.round(Date.now() / 1000)

        //--- pasikeitus epg'ui, jei prieš tai buvusį žiūrėjo ne trumpiau, nei watchedEpgThreshold
        //--- išsiunčiam prieš tai buvusio bukmarką
        // if (
        //     this.currentItemIndex !== i &&
        //     this.epgChangeStamp &&
        //     (nowStamp - this.epgChangeStamp >= this.watchedEpgThreshold)
        // ) {
        //     this.postBookmark()
        // }

        const channelSelected = this.props.payload.channels.items[this.props.payload.channels.maps.id[this.searchParamsChannelId]]
// console.log('channelSelected:', channelSelected)
        setChannelSelected(channelSelected)
        // setTimelineDuration(currentEpg)
        const intlObjTime = new Intl.DateTimeFormat(this.props.auth.lang, {hour: "numeric", minute: "numeric"})
        const stopDate = new Date(currentEpg.stop * 1000)
// console.log('currentEpg:', currentEpg)
        this.setState({
            ...this.state,
            isEpg: true,
            isLive: isLivePlayback,
            currentTitle: currentEpg.metadata.title,
            currentAgeRating: channelSelected && channelSelected['age_rating'] ? channelSelected['age_rating'] : null,
            currentDescription: currentEpg.metadata.description,
            tva_contents: currentEpg.metadata.tva_contents,
            released: currentEpg.metadata.released,
            duration: null,
            currentLogo: this.props.payload.channels.items ? this.props.payload.channels.items[this.props.payload.channels.maps.id[this.searchParamsChannelId]].logo : null,
            startFormatted: currentEpg.startFormatted ?? intlObjTime.format(new Date(currentEpg.start * 1000)),
            stopFormatted: intlObjTime.format(stopDate)
        })

// console.log('epg adultPinStampValid:', this.adultPinStampValid, 'state.currentAgeRating:', this.state.currentAgeRating)
        this.adultBlur = false
        this.ageRestricted = false
        if (!this.adultPinStampValid &&
            channelSelected && channelSelected['age_rating'] >= 18
        ) {
            this.adultBlur = true
            this.handleAdultBlur()
            return false
        } else if (this.props.auth.profile.age &&
            channelSelected &&
            channelSelected['age_rating'] &&
            channelSelected['age_rating'] > this.props.auth.profile.age
        ) {
            this.adultBlur = true
            this.ageRestricted = true
            this.handleAdultBlur()
            return false
        }
        // this.handleAdultBlur()

        if (!sameSource) {
            playerSetSource(null, 0)
        }

        //-- užsiprašom šio epg spraitus
// console.log('sprites from to', new Date((currentEpg.start - this.spritesOffset) * 1000).toLocaleString('lt-LT'), new Date(currentEpg.stop * 1000).toLocaleString('lt-LT'))
        this.props.fetchAction(
            this.props.auth.token,
            spritesEpgEndpoint(currentEpg.channel_id, currentEpg.start - this.spritesOffset, currentEpg.stop),
            this.constructor.name,
            SpritesToEpgTimeline,
            {start: currentEpg.start, stop: currentEpg.stop}
        )

        //-- ir jei esam laiviniam, vis pakartojam kas 2,5 minutės
        clearInterval(this.spritesFetchInterval)
        if (isLiveEpg) {
            this.spritesFetchInterval = setInterval(() => {
                this.props.fetchAction(
                    this.props.auth.token,
                    spritesEpgEndpoint(currentEpg.channel_id, currentEpg.start - this.spritesOffset, currentEpg.stop - this.spritesOffset),
                    this.constructor.name,
                    SpritesToEpgTimeline,
                    {start: currentEpg.start, stop: currentEpg.stop}
                )
            }, 150000)
        }

        this.epgChangeStamp = nowStamp
    }

    handleAdultBlur() {
// console.log('ageRestricted adultBlur:', this.ageRestricted, this.adultBlur)
        if (this.adultBlur) {
            const pinToCheck = this.props.auth.profile.pin ? this.props.auth.profile.pin : this.props.auth.profile.pin_with_default

            this.props.setModalSimpleState({
                show: true,
                closeOnClick: false,
                header: "pin_required_title",
                style: {width: "400px"},
                headerStyle: {marginBottom: "40px"},
                hideClose: true,
                body: (
                    <div>
                        <div className="pt-3">
                            <FormattedMessage id={this.ageRestricted ? "content_is_blocked_title" : "content_needs_pin_message"} />
                        </div>

                        <form onSubmit={(e) => this.handlePinSubmit(e)}>
                            <div className="form-group" style={{marginBottom: '47px', marginTop: '16px'}}>
                                <div className={"d-flex"}>
                                    <PinInput
                                        onPinEntered={this.handlePinEntered}
                                        pinToCheck={pinToCheck}
                                        passwordType={true}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="button btn-secondary btn-lg me-2" onClick={(e) => this.handleCloseClick(e)}>
                                    <FormattedMessage id={"profile_button_cancel"} />
                                </button>
                                <button className="button btn-primary btn-lg">
                                    <FormattedMessage id={"pin_dialog_button_ok_text"} />
                                </button>
                            </div>
                        </form>
                    </div>
                )
            })
            return true
        }
    }

    setButtonsVisibility() {
        // console.log('setButtonsVisibility', this.prevExists, this.nextExists, isLivePlayback)
        //-- block or none?
        let forwardBtn = "none"
        let prevBtn = "none"

        if (this.prevExists && !isLivePlayback) {
            prevBtn = "block"
        }
        if (this.nextExists && !isLivePlayback) {
            forwardBtn = "block"
        }

        if (this.state.isLive !== isLivePlayback) {
            this.setState({
                    ...this.state,
                    isLive: isLivePlayback
                }
            )
        }

        // if (this.prevBtn.current && this.prevFastBtn.current && this.forwardBtn.current && this.forwardFastBtn.current) {
        if (this.prevBtn.current && this.forwardBtn.current) {
            this.prevBtn.current.style.display = prevBtn
            // this.prevFastBtn.current.style.display = prevFastBtn
            this.forwardBtn.current.style.display = forwardBtn
            // this.forwardFastBtn.current.style.display = forwardFastBtn
        }
    }

    checkForNext() {
        this.nextChecked = true
        if (typeof this.playerVods[this.currentItemIndex] === 'undefined') {
            return false
        }

        let xhr = new XMLHttpRequest()
        xhr.onload = () => {
            if (xhr.status === 200) {
                try {
                    const payload = JSON.parse(xhr.response);
                    this.nextWidgetTitle.current.innerHTML = ''
                    this.nextWidgetText.current.innerHTML = ''
                    this.nextVodItem = payload
                    if (payload.metadata) {
                        if (payload.metadata.covers && payload.metadata.covers[0]) {
                            this.nextWidgetImage.current.src = payload.metadata.covers[0]
                        } else {
                            this.nextWidgetImage.current.src = PlaceholderPoster
                        }
                        if (payload.metadata.title) {
                            this.nextWidgetTitle.current.innerHTML = payload.metadata.title
                        }
                        if (payload.metadata.episode_no) {
                            this.nextWidgetText.current.innerHTML = 'E' + payload.metadata.episode_no
                        }
                    }
                    this.nextWidgetCounter.current.innerHTML = ''
                } catch(e) {
                    console.log('onload parse error', e)
                }
            } else {
                // console.log('xhr.onload:', xhr.status, xhr.response);
            }
        };
        xhr.onerror = (e) => {
            console.log("onerror", e.error);
        };
        // this.playerVods[this.currentItemIndex]
        xhr.open("GET", config['apiURI'] + "/" + nextEndpoint({
            vod_id: this.playerVods[this.currentItemIndex].id,
            lang: this.props.auth.lang
        }), true);
        xhr.setRequestHeader("Authorization", "Bearer " + this.props.auth.token);
        xhr.send()
    }

    nextCancelAction() {
        this.nextWidget.current.style.display = 'none'
        this.nextCanceled = true
        console.log('next cancel!')
    }

    nextPlayAction() {
        this.nextWidget.current.style.display = 'none'
        this.nextCanceled = true
        // console.log('playerVods:', this.playerVods[0])
        // console.log('next play!')
        this.playerVods[0] = this.nextVodItem
        this.setCurrentVod(0)
    }

    playerBindActions() {
// console.log('playerObj.getSettings', this.playerObj.getSettings())
        player.on(MediaPlayer.events['PLAYBACK_ENDED'], (e) => {
            clearInterval(this.bookmarksInterval)
            clearInterval(this.statsPostInterval)
            console.log('PLAYBACK_ENDED', e)
            // this.forwardAction()
        })

        //-- https://flussonic.com/doc/video-playback/dash-playback/#rewind
        player.on(MediaPlayer.events['PLAYBACK_PAUSED'], (e) => {
            console.log('PLAYBACK_PAUSED')
            const nowStamp = Date.now() / 1000 | 0
            this.pauseStamp = nowStamp
            // if (isLivePlayback) {
            // if (!this.pauseStamp) {
            //     this.pauseOnLive = true
            // } else {
            //     this.pauseOnLive = false
            // }
            this.latencyOnPause = nowStamp - PlayerTimeUTC

            // console.log('nowStamp:', new Date(nowStamp * 1000).toLocaleTimeString('lt-LT'))
            // console.log('pauseStamp:', new Date(this.pauseStamp * 1000).toLocaleTimeString('lt-LT'))
            // console.log('PlayerTimeUTC:', new Date(PlayerTimeUTC * 1000).toLocaleTimeString('lt-LT'))
            // console.log('currentEpg.start:', new Date(currentEpg.start * 1000).toLocaleTimeString('lt-LT'))
            if (currentEpg) {
                this.seekOnPause = PlayerTimeUTC - currentEpg.start
            }
            // console.log('seekOnPause:', Math.floor(this.seekOnPause / 60) + ' min. ' + this.seekOnPause % 60 + ' s')
            // console.log('latency on pause:', this.latencyOnPause)
            // console.log('---')

            // setLivePlayback(false)
            clearInterval(this.bookmarksInterval)
            clearInterval(this.statsPostInterval)

            clearInterval(this.buttonsVisibilityInterval)
            this.buttonsVisibilityInterval = setInterval(() => {
                this.setButtonsVisibility()
            }, 1000)
        })

        player.on(MediaPlayer.events['STREAM_INITIALIZED'], (e) => {
            this.initializationComplete = false
            this.playbackTimeUpdated = false
            this.pauseStamp = 0
            this.nextChecked = false
            this.nextVodItem = null
            this.nextCanceled = false
            console.log('STREAM_INITIALIZED')
        })

        player.on(MediaPlayer.events['PLAYBACK_TIME_UPDATED'], (e) => {
            if (!this.playbackTimeUpdated) {
                //-- jei vodas ir jei yra jo bukmarkas, seekinam
                if (this.searchParamsVodId && this.searchParamsSection !== 'vod.advertised-movies') {
                    if (this.props.payload && this.props.payload.bookmarks && this.props.payload.bookmarks.items) {
// console.log('some bookmarks', this.props.payload.bookmarks)
                        let vodSeekTo
                        if (
                            typeof this.props.payload.bookmarks.maps['vod'] !== 'undefined' &&
                            typeof this.props.payload.bookmarks.maps['vod'][this.searchParamsVodId] !== 'undefined'
                        ) {
                            vodSeekTo = this.props.payload.bookmarks.items[this.props.payload.bookmarks.maps['vod'][this.searchParamsVodId]]['bookmark']
                            player.seek(vodSeekTo)
                        }
                    }
                }

                //-- surikiuojam takelius pagal prioritetus
                const languagePriorityOrdered = getPriorityList({
                    cookieName: config.audioPriorityCookieName,
                    defaultList: config.languagePriorityOptions,
                    defaultValue: this.props.auth.lang
                })
                for (let i = 0; i < languagePriorityOrdered.length; i++) {
                    // console.log('index:', i, 'element:', languagePriorityOrdered[i]);
                    if (typeof audioTracksAvailable[languagePriorityOrdered[i]] !== 'undefined') {
                        player.setCurrentTrack(audioTracksAvailable[languagePriorityOrdered[i]])
                        const activeTrackIndex = audioTracksAvailable[languagePriorityOrdered[i]]['index']
                        let self = document.getElementById('audioContent')
                        let nodes = self.children;
                        for (let i = 0; i < nodes.length; i++) {
                            nodes[i].selected = false
                            nodes[i].classList.remove('menu-item-selected')
                            nodes[i].classList.add('menu-item-unselected')
                            if (activeTrackIndex === (i + 1)) {
                                nodes[i].classList.add('menu-item-selected')
                            }
                        }
                        break
                    }
                }
                this.playbackTimeUpdated = true
            }

            this.initializationComplete = true
            if (!this.nextChecked) {
                this.checkForNext()
            } else if(this.nextVodItem != null) {
                //-- jet turim paruoštą next
                //-- likus tiek sekundžių, imam rodyti pranešimą apie tolesnį epizodą
                const actionAfter = 60
                const tillEnd = Math.round(timelineDuration - PlayerTime)
                if (tillEnd < actionAfter && !this.nextCanceled) {
                    this.nextWidget.current.style.display = 'block'
                    // player_next_item_title
                    // this.nextWidgetCounter.current.innerHTML =  'Next episode will play in ' + tillEnd + 's...'
                    this.nextWidgetCounter.current.innerHTML =  this.translatedMap['player_next_item_title'].replace('%1$d', tillEnd)
                    if (tillEnd <= 1) {
                        this.nextPlayAction()
                    }
                }
            }
        })

        player.on(MediaPlayer.events['PLAYBACK_STARTED'], (e) => {
            // console.log('PLAYBACK_STARTED', player.getSource())
            console.log('PLAYBACK_STARTED')
            this.initializationComplete = false
            setPlayerTime(0)
            setMouseMoveStamp(Math.round(Date.now() / 1000) + 1)
            if (this.controlBtnGroup.current) {
                this.controlBtnGroup.current.focus()
            }
            this.playPauseBtn.current.src = PauseButton

            //--- kas sekundę tikrinam ar jau laikas pasiųsti bukmarką
            clearInterval(this.buttonsVisibilityInterval)
            clearInterval(this.bookmarksInterval)
            this.secAfterStart = 0
            if (this.searchParamsType === 'tv') {
                this.bookmarksInterval = setInterval(() => {
                    //--- jei laivinis, tikrinam ar jau epg pabaiga, kad užkrautume kitą epg
                    //   const eventDate = new Date((seek + currentEpg.start) * 1000)
                    //   console.log('eventDate:' + eventDate.toLocaleString('lt-LT'))
// console.log(new Date(PlayerTimeUTC * 1000).toLocaleString('lt-LT'), new Date(currentEpg.stop * 1000).toLocaleString('lt-LT'))
                    const nowStamp = Math.round(Date.now() / 1000)
                    if (PlayerTimeUTC > (currentEpg.stop + 4) && this.initializationComplete) {
                        console.log('time for next epg ', new Date(PlayerTimeUTC * 1000).toLocaleString('lt-LT'), '>', new Date((currentEpg.stop + 4) * 1000).toLocaleString('lt-LT'))
                        this.forwardAction(true)
                    }
                    this.secAfterStart += 1
                    if (
                        !this.channelSubscribed &&
                        this.secAfterStart >= this.previewThreshold &&
                        (
                            !this.props.global_styles['shop_items'] ||
                            !this.props.global_styles['shop_items']['channel_id'] ||
                            !this.props.global_styles['shop_items']['channel_id'][currentEpg.channel_id.toString()]
                        )
                    ) {
                        handleCookie({
                            operation: 'add',
                            name: 'shop_redirect_url',
                            value: window.location.pathname + window.location.search
                        })
                        this.setState(
                            {
                                ...this.state,
                                redirectTo: "/shop/channel/" + currentEpg.channel_id + '?not_ordered=1'
                            }
                        )
                    }
                    if (this.secAfterStart >= this.bookmarkPostInterval &&
                        (!this.bookmarkPostStamp || this.bookmarkPostStamp + this.bookmarkPostInterval < nowStamp))
                    {
                        this.postBookmark()
                    }
                }, 1000)
            } else if (this.searchParamsType === 'vod') {
                this.bookmarksInterval = setInterval(() => {
                    const nowStamp = Math.round(Date.now() / 1000)
                    this.secAfterStart += 1
                    if (this.secAfterStart >= this.bookmarkPostInterval &&
                        (!this.bookmarkPostStamp || this.bookmarkPostStamp + this.bookmarkPostInterval < nowStamp))
                    {
                        this.postBookmark()
                    }
                }, 1000)
            }

            clearInterval(this.statsPostInterval)
            this.statsPostInterval = setInterval(() => {
                //-- siunčiam žiūrėjimo statistiką
                let msgData = {
                    customerId: this.props.auth.profile.customer_id,
                    profileId: this.props.auth.profile.id,
                    deviceType: 30,
                }

                /*
                long *channelID - ID of the channel that is playing
                string channelName - Name of the channel that is playing
                int channelNumber - Logical channel number of the playing channel
                string epgTitle - Title of the event that is playing
                long *epgId - ID of the event that is playing
                long *episodeId - ID of the event episode that is playing
                boolean *isLive - Is the playback 'live'?
                long *position - current playback position
                 */
                if (this.searchParamsType === 'tv') {
                    msgData['type'] = 'STATE_TV'
                    msgData['channelID'] = channelSelected['id']
                    msgData['channelName'] = channelSelected['name']
                    msgData['channelNumber'] = channelSelected['lcn']
                    msgData['epgTitle'] = currentEpg.metadata.title
                    msgData['epgId'] = currentEpg.id
                    msgData['episodeId'] = currentEpg.metadata['episode_id'] || null
                    msgData['isLive'] = isLivePlayback
                    msgData['position'] = PlayerTimeUTC
                } else {
                    msgData['type'] = 'STATE_VOD'
                    msgData['id'] = this.playerVods[this.currentItemIndex].id
                    msgData['title'] = this.playerVods[this.currentItemIndex].title
                    msgData['duration'] = this.playerVods[this.currentItemIndex].duration
                    msgData['position'] = Math.round(player.time())
                }

                const nowStamp = Math.round(Date.now() / 1000)
                msgData['tstamp'] = this.props.auth.profile_stamp ? nowStamp - this.props.auth.profile_stamp : 0
                msgData['now'] = nowStamp
                const msgPacked = msgpack.encode(msgData)

// console.log('lets post stats!', msgData)
// console.log('lets post stats position!', this.searchParamsType, msgData['statType'], msgData.position)
// console.log('PlayerTimeUTC, playerIsDynamic, isLiveEpg, isLivePlayback', PlayerTimeUTC, playerIsDynamic, isLiveEpg, isLivePlayback)

                fetch(config['statsURI'], {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.props.auth.token,
                        'Content-Type': "application/msgpack",
                    },
                    body: msgPacked
                })
                    .then(response => {
                        console.log('stats player', response.status, msgData['position'])
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }, 60000)

            clearInterval(this.buttonsVisibilityInterval)
            this.buttonsVisibilityInterval = setInterval(() => {
                this.setButtonsVisibility()
            }, 1000)
            // this.setButtonsVisibility()
        })
    }

    postBookmark() {
        let encodedData = []
        const myData = {
            bookmark: this.searchParamsType === 'tv' ? Math.round(PlayerTimeUTC) : Math.round(player.time()),
            id: this.searchParamsType === 'tv' ? currentEpg['channel_id'] : this.playerVods[this.currentItemIndex].id,
            profile_id: this.props.auth.profile['id'],
            type: this.searchParamsType
        }

// console.log('lets send bookmark', myData)
        for (let key in myData) {
            if (myData.hasOwnProperty(key)) {
                encodedData.push(key + '=' + encodeURIComponent(myData[key]));
            }
        }
        encodedData = encodedData.join('&');

        fetch(config['apiURI'] + '/bookmarks', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + this.props.auth.token,
                'Content-Type': "application/x-www-form-urlencoded",
            },
            body: encodedData
        }).then((response) => {
            this.bookmarkPostStamp = Math.round(Date.now() / 1000)
            // console.log('bookmark Response status', response.status, myData['bookmark'])
        })
    }

    componentDidMount() {
// console.log("player mount")
        if (player) {
            player.destroy()
        }

        if (this.props.payload && this.props.payload.bookmarks && this.props.payload.bookmarks.items) {
            setCurrentBookmarks(this.props.payload.bookmarks)
        }
        if (this.props.payload && this.props.payload.userinfo) {
            setUserInfo(this.props.payload.userinfo)
        }
        const searchParams = new URLSearchParams(this.props.location.search);
        this.searchParamsType = searchParams.get('type')
        this.searchParamsSection = searchParams.get('section')
        this.searchParamsShop = searchParams.get('shop')
        this.searchParamsIndex = searchParams.get('index') ? parseInt(searchParams.get('index')) : null
        this.searchParamsChannelId = parseInt(searchParams.get('channel_id'))
        if (this.searchParamsChannelId && this.props.payload.channels) {
            if (this.props.payload.channels.items[this.props.payload.channels.maps.id[this.searchParamsChannelId]]['subscribed']) {
                this.channelSubscribed = true
            }
        }

        this.searchParamsEpgId = parseInt(searchParams.get('epg_id'))
        this.searchParamsVodId = parseInt(searchParams.get('vod_id'))
        // this.searchParamsVodId = searchParams.get('vod_id')
        // const viewerProfileId = this.props.auth.profile ? this.props.auth.profile.id : null
        // setViewerProfile(viewerProfileId)

        const {intl} = this.props
        const toTranslate = [
            'settings_action_language',
            'player_quality_auto',
            'player_audio_menu_title',
            'player_video_menu_title',
            'player_caption_menu_title',
            'player_next_item_title',
            // 'player_caption_language_title',
            'subs_off'
        ]
        for (const element of toTranslate) {
            this.translatedMap[element] = intl.formatMessage({id: element})
        }
        setTranslatedStrings(this.translatedMap)
        setPlayerLocale(this.props.auth.lang)
// console.log('mount global_styles', this.props.global_styles)

        this.globalStyleOnMount = this.props.global_styles
        this.props.setAppClass('play')

        if (!this.props.auth.token) {
            this.props.history.push("/");
        }

        const nowStamp = Date.now() / 1000 | 0
        if(this.props.global_styles.adult_pin_stamp && (this.props.global_styles.adult_pin_stamp + 180 > nowStamp)) {
            this.adultPinStampValid = true
        }

        playerInit()
        this.playerBindActions()

        setPlaybackType(this.searchParamsType)
        if (this.searchParamsType === 'tv') {
            if (this.searchParamsIndex !== null && this.props.payload['search'] && this.props.payload['search']['items']) {
                const searchItem = this.props.payload['search']['items']['items'][this.searchParamsIndex]
                const searchItemKeys = Object.keys(searchItem)
                const day = ~~(searchItem[searchItemKeys[0]].start / 86400)
                this.channelEpg.push(searchItem[searchItemKeys[0]])
                this.currentItemIndex = 0

                // todo (jei neturim) kraunamės gretimų dienų epg, susidedam į this.channelEpg jame randam grojamą epg ir perrašom currentItemIndex
                const actualDays = [day - 1 + '', day + '', day + 1 + '']
                for (const item of actualDays) {
                    if (!this.props.payload.epg || !this.props.payload.epg[this.searchParamsChannelId]['items'][item] || this.props.payload.epg[this.searchParamsChannelId]['items'][item].length < 5) {
                        break
                    }
                }
            } else {
                if (this.props.payload && this.props.payload.epg && typeof this.props.payload.epg[this.searchParamsChannelId] !== 'undefined') {
                    const dayStamps = Object.keys(this.props.payload.epg[this.searchParamsChannelId]['items']).sort()
                    for (const dayStamp of dayStamps) {
                        for (const item of this.props.payload.epg[this.searchParamsChannelId]['items'][dayStamp]) {
                            this.channelEpg.push(item)
                        }
                    }
                }
            }
            this.currentItemIndex = this.channelEpg.findIndex(({id}) => id === this.searchParamsEpgId);
            if (this.currentItemIndex === -1) {
                if (this.searchParamsIndex !== null &&
                    this.props.payload[this.searchParamsSection] &&
                    this.props.payload[this.searchParamsSection]['items'] &&
                    this.props.payload[this.searchParamsSection]['items']['items'] &&
                    this.props.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex] &&
                    this.props.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex]['catchup']
                ) {
                    this.channelEpg = []
                    this.channelEpg.push(this.props.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex]['catchup'])
                    this.currentItemIndex = 0
                }
            } else {
                // console.log('no epg', this.props.payload, this.props.payload.epg)
                console.log('no epg')
            }
            this.setCurrentEpg(this.currentItemIndex)
        } else {
            this.playerVods = []
            if (this.props.vod_about && this.props.vod_about.subscribed && this.props.vod_about.id === this.searchParamsVodId) {
// console.log('this.playerVods from props.vod_about', this.props.vod_about)
                this.playerVods[0] = this.props.vod_about;
            } else if (this.props.payload &&
                this.props.payload[this.searchParamsSection] &&
                this.props.payload[this.searchParamsSection]['items'] &&
                this.props.payload[this.searchParamsSection]['items']['items'] &&
                this.props.payload[this.searchParamsSection]['items']['items'].length
            ) {
                //-- jei nerandam vod_about'o propsuose, žiūrim gal yra tarp vod.advertised-movies
                // eslint-disable-next-line array-callback-return
                this.props.payload[this.searchParamsSection]['items']['items'].map((internal, index) => {
                    for (let i in internal) {
                        if (internal[i].id === this.searchParamsVodId) {
// console.log('this.playerVods from internal[i]', internal[i])
                            this.playerVods[0] = internal[i]
                        }
                    }
                })
            }

            this.vodRequested = 0
            if (this.playerVods[0]) {
                this.setCurrentVod(0, this.searchParamsType)
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let i = 0
        if (!this.playerVods[i] &&
            this.props.vod_about &&
            this.props.vod_about.id === this.searchParamsVodId &&
            (
                this.props.vod_about.subscribed ||
                (this.vodRequested && this.props.vod_about_stamp >= this.vodRequested)
            )
        ) {
            this.playerVods[i] = this.props.vod_about
        }

        if (
            this.playerVods[0] &&
            this.props.global_styles['shop_items'] &&
            this.props.global_styles['shop_items']['id'] &&
            this.props.global_styles['shop_items']['id'][this.playerVods[0].id] &&
            !this.vodPurchased[this.playerVods[0].id]
        ) {
            this.vodPurchased[this.playerVods[0].id] = Date.now() / 1000 | 0
        }

        if (this.playerVods[i] &&
            !this.playerVods[i].subscribed &&
            ((this.redirected !== this.state.redirectTo) || (!this.redirected && !this.state.redirectTo)) &&
            !this.vodPurchased[this.playerVods[0].id]
        ) {
            handleCookie({
                operation: 'add',
                name: 'shop_redirect_url',
                value: window.location.pathname + window.location.search
            })
            this.setState(
                {
                    ...this.state,
                    redirectTo: "/shop/" + this.playerVods[i].type + "/" + (this.playerVods[i].type === 'tvod' ? this.playerVods[i].id : this.playerVods[i].provider_id)
                }
            )
            this.playerVods = []

            return true
        }

        //-- aktualu tik perkrovus brauserį
        //-- sulaukiam, kol ateis useris, kad žinotume, ar jis demo
        //-- ir profilio sulaukiam
        if (
            this.searchParamsVodId &&
            !this.vodRequested &&
            !this.playerVods[i] &&
            this.props.auth.profile &&
            this.props.payload.userinfo
        ) {
            this.userDemo = this.props.payload.userinfo['is_demo']['items']
            this.vodRequested = Math.round(Date.now() / 1000)
// console.log('lets fetch vod by id', this.searchParamsVodId)
            this.props.fetchAction(
                this.props.auth.token,
                vodAboutEndpoint(
                    {
                        'id': this.searchParamsVodId,
                        'lang': this.props.auth.lang
                    }),
                this.constructor.name,
                VodAboutWrap
            )
        } else if (this.playerVods[i]) {
            if (this.props.auth && this.props.auth.profile && !this.bookmarksRequested) {
                this.bookmarksRequested = true
                this.props.fetchAction(
                    this.props.auth.token,
                    bookmarksEndpoint(
                        {
                            'lang': this.props.auth.lang,
                            'filter_profile': this.props.auth.profile.id
                        }),
                    this.constructor.name,
                    BookmarksMap
                )
            }

            this.setCurrentVod(0, this.searchParamsType)
        }
    }

    componentWillUnmount() {
        // this.props.setPlayerEpgIndex(this.currentItemIndex)
        if (this.globalStyleOnMount.app_class !== this.props.global_styles.app_class) {
            this.props.setAppClass(this.globalStyleOnMount.app_class)
        }

        clearInterval(this.bookmarksInterval)
        clearInterval(this.spritesFetchInterval)
        clearInterval(this.statsPostInterval)

        player.destroy()
        // playerDestroy()
    }

    handlePinEntered = (enteredPin, hasError) => {
// console.log('handlePinEntered:', enteredPin, hasError)
        if (!hasError) {
            this.adultPinStampValid = true
            this.props.setAdultPin(Date.now() / 1000 | 0)
            this.adultBlur = false
            this.props.setModalSimpleState({
                show: false,
            })
            if (this.searchParamsType === 'tv') {
                this.setCurrentEpg(this.currentItemIndex)
            } else {
                this.setCurrentVod(0, this.searchParamsType)
            }
        }
    }

    handlePinSubmit = (e) => {
        e.preventDefault()
    }

    handleCloseClick = (e) => {
        e.preventDefault()
        this.props.setModalSimpleState({
            show: false,
            overlayOnly: false,
        })
        window.history.back()
    }

    render() {
        // const {auth, api_error} = this.props

        if (this.state.redirectTo && this.redirected !== this.state.redirectTo) {
            this.redirected = this.state.redirectTo
            return <Redirect to={this.redirected} />
        }

        // if (!auth.token || !auth.profile || api_error) {
        //     return <Redirect to="/"/>
        // }

        const liveStatus = this.state.isEpg ? (
            <div className={"form-control"} style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{color: this.state.isLive ? 'green' : 'red', fontSize: '0.8rem'}}>&#x2B24;</span>
                <span className="ms-1">
                    {this.state.isLive ? <FormattedMessage id="player_live"/> : <FormattedMessage id="player_catchup"/>}
                </span>
            </div>
        ) : null

        const ageRating = this.state.currentAgeRating ? (
            <div className={"age-box me-1"}>{this.state.currentAgeRating}+</div>
        ) : null

        return (
            <div className="videoContainer" id="videoContainer">
                {/*<PinDialogClass*/}
                {/*    confirmShow={this.adultBlur}*/}
                {/*    alertType={this.ageRestricted}*/}
                {/*    handleSubscribeSet={this.handlePinSubmit}*/}
                {/*    handleCloseClick={this.handleCloseClick}*/}
                {/*    pinHasError={this.state.pinHasError}*/}
                {/*    title={this.ageRestricted ? 'search_query_results_no_match' : 'pin_required_title'}*/}
                {/*    action={this.ageRestricted ? 'content_is_blocked_title' : 'content_needs_pin_message'}*/}
                {/*/>*/}
                <div id={"subtitles-container"} className={"z-1"}/>
                <div
                    id="player-top-info"
                    style={{
                        position: 'fixed',
                        width: '100%',
                        marginTop: '2rem',
                        marginLeft: '2rem',
                        display: this.adultBlur ? 'none' : 'flex',
                        alignItems: 'start',
                        zIndex: '1'
                    }}
                >
                    <div className="back-button" onClick={() => this.goBack()}>
                        <img src={BackIcon} alt=""/>
                    </div>
                    <div className="player-info-box d-flex flex-row">
                        <div>
                            <img src={this.state.currentLogo} alt="" style={{maxHeight: '48px', maxWidth: '120px'}}/>
                        </div>
                        <div className="ms-3">
                            <b>{this.state.currentTitle}</b>
                            <div className="form-control d-flex flex-row">
                                {ageRating}
                                {
                                    this.state.released && !this.state.startFormatted ?
                                        <div>{this.state.released}</div>
                                        : null
                                }
                                {
                                    this.state.duration && !this.state.startFormatted ?
                                        <div>
                                            {this.state.released ?
                                                <span>&nbsp;| </span>
                                                : null
                                            }
                                            {parseInt(this.state.duration/60) + "min."}
                                        </div>
                                        : null
                                }
                                {
                                    this.state.startFormatted ?
                                        <div>
                                            {this.state.startFormatted} – {this.state.stopFormatted}
                                        </div>
                                        : null
                                }
                                {
                                    this.state.tva_contents ?
                                        <div>
                                            <span>&nbsp;| </span>{this.state.tva_contents.join(', ')}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="ms-3" style={{ display: 'flex', alignItems: 'flex-end'}}>
                            {liveStatus}
                        </div>
                    </div>
                </div>
                <div
                    id="control-buttons"
                    className={this.adultBlur ? 'hide' : ''}
                    style={{
                        position: 'fixed',
                        margin: '0',
                        top: '50%',
                        left: '50%',
                        zIndex: '10',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <div className="control-button-group" ref={this.controlBtnGroup} tabIndex={0}>
                        <div className="control-button" style={{display: 'none'}} ref={this.prevBtn}
                             onClick={() => this.prevAction()} title="">
                            <img style={{width: '3rem'}} src={PrevButton} alt=""/>
                        </div>
                        <div className="control-button" onClick={() => this.playPauseAction()} title="">
                            <img style={{width: '5rem'}} ref={this.playPauseBtn} src={PlayButton} title="" alt=""/>
                        </div>
                        <div className="control-button" style={{display: 'none'}} ref={this.forwardBtn}
                             onClick={() => this.forwardAction()} title="">
                            <img style={{width: '3rem'}} src={ForwardButton} alt=""/>
                        </div>
                    </div>
                </div>
                <div id="player-timeline" className={'video-controller unselectable' + (this.adultBlur ? ' hide' : '')}>
                    <span id="videoTime" className="time-display" style={{display: 'none'}}>00:00</span>
                    <div className="d-flex justify-content-between" style={{fontSize: '0.9rem', color: 'rgba(255, 255, 255, 0.6)'}}>
                        <div className="ps-2" id="start-display">{this.state.startFormatted}</div>
                        <div className="pe-2" id="stop-display" style={{'textAlign': 'right'}}>{this.state.stopFormatted}</div>
                    </div>
                    <div className="seekContainer">
                        <div id="seekbar" className="seekbar seekbar-complete">
                            <div id="seekbar-live" ref={this.seekBarLive} className="seekbar seekbar-live"/>
                            <div id="seekbar-buffer" className="seekbar seekbar-buffer"/>
                            <div id="seekbar-play" className="seekbar seekbar-play"/>
                        </div>
                    </div>
                    <div id="thumbnail-container" className="thumbnail-container" style={{display: 'none'}}>
                        <div id="thumbnail-elem" className="thumbnail-elem"/>
                        <div id="thumbnail-time-label" className="thumbnail-time-label"/>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                        <div className="control-button-group sound-group">
                            <div className="settings-button" onClick={() => this.toggleMuteAction()} title="">
                                <img style={{width: '1.5rem'}} src={this.state.muted ? MutedButton : MuteButton} alt=""/>
                            </div>
                            <Slider
                                min={0}
                                max={1}
                                step={0.01}
                                value={this.state.volume}
                                onChange={this.handleVolumeChange}
                                className="volumebar"
                            />
                        </div>
                        <div>
                            <div className="settings-button" onClick={() => this.toggleFullscreen()} title="">
                                <img style={{width: '1.5rem'}} ref={this.fullScreenBtn} src={FullScreenButton} title=""
                                     alt=""/>
                            </div>
                            <div id="langBtn" className="settings-button" title="">
                                <img style={{width: '1.5rem'}} src={TextsIcon} alt=""/>
                            </div>
                            <div id="settingsBtn" className="settings-button" title="">
                                <img style={{width: '1.5rem'}} src={AdjustmentIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div id="next-widget" className="" style={{display: 'none'}}>*/}
                <div className="position-absolute bottom-0 end-0" ref={this.nextWidget} style={{display: 'none', marginBottom: '2em', marginRight: '2em'}}>
                    <div className="mb-3" style={{
                        maxWidth: '650px',
                        backgroundColor: '#212121',
                        zIndex: 2,
                        position: 'relative',
                        boxShadow: '0px 0px 12px 5px rgba(0,0,0,0.75)',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#484848',
                        opacity: 0.9
                    }}>
                        <div className="row g-0">
                            <div className="col-md-12 px-3 pt-3">
                                <p><small ref={this.nextWidgetCounter} className="text-muted">...</small></p>
                            </div>
                            <div className="col-md-6 p-3">
                                <img src="" ref={this.nextWidgetImage} className="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div className="col-md-6 p-3">
                                <div className="card-body">
                                    <h5 className="card-title" ref={this.nextWidgetTitle}>...</h5>
                                    <p ref={this.nextWidgetText}>...</p>
                                    <button className="button btn-secondary p-2" onClick={() => this.nextCancelAction()}>
                                        <small className="text-light"><FormattedMessage id="player_next_item_button_cancel"/></small>
                                    </button>
                                    <button className="button btn-primary btn-lg p-2 m-2" onClick={() => this.nextPlayAction()}>
                                        <small className="text-light"><FormattedMessage id="player_next_item_button_play"/></small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <video style={{
                    width: '100%',
                    maxHeight: '100%',
                    position: 'fixed',
                    top: '50%',
                    transform: 'translate(0, -50%)'
                }}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        api_error: state.api_data ? state.api_data.error : null,
        player: state.player,
        payload: state.api_data ? state.api_data.payload : null,
        vod_about: state.api_data && state.api_data.payload ? state.api_data.payload.vod_about : null,
        vod_about_stamp: state.api_data && state.api_data.payload ? state.api_data.payload.vod_about_stamp : null,
        global_styles: state.global_styles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params) => {
            dispatch(fetchAction(token, endpoint, source, subkey, params))
        },
        // setPlayerEpg: (items) => { dispatch(setPlayerEpg(items)) },
        setPlayerEpgId: (item) => {dispatch(setPlayerEpgId(item))},
        setPlayerEpgIndex: (item) => {dispatch(setPlayerEpgIndex(item))},
        // setModalState: (id, title, body, back_button) => {dispatch(setModalState(id, title, body, back_button))},
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
        setAdultPin: (value) => { dispatch(setAdultPin(value)) },
        setAppClass: (app_class) => {dispatch(setAppClass(app_class))}
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Play)))
