import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import msgpack from 'msgpack-lite';
import Navbar from 'components/layout/Navbar';
import Home from 'components/pages/Home';
import Tv from 'components/pages/Tv';
import Shop from 'components/pages/Shop';
import Play from 'components/pages/Play';
import Vod from 'components/pages/Vod';
import SignUpForm from 'components/auth/SignUpForm';
import Register from 'components/pages/Register';
import Conditions from 'components/auth/Conditions';
import Status from 'components/auth/Status';
import PasswordRecovery from 'components/auth/PasswordRecovery';
import AccountActivation from 'components/auth/AccountActivation';
import VodAbout from 'components/pages/VodAbout';
// import { Modal } from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'css/main.css';
import messages from './strings_web.json';
import { connect } from 'react-redux';
import { FormattedMessage, IntlProvider } from 'react-intl';
import ModalElement from 'components/layout/ModalElement';
import { setAppLang, fetchUser, setStatHello } from 'store/actions/authActions';
import { setModalState } from 'store/actions/modalStateActions';
import { setModalSimpleState } from 'store/actions/modalSimpleStateActions';
import ProfilesIcon from 'img/profiles-icon.svg';
import NotificationIcon from 'img/notification-icon.svg';
import ProfilesView from 'components/elements/ProfilesView';
import config from 'config/config';
import { pinEnterSuccess } from 'store/actions/authActions';
import { handleCookie, getCookie } from 'components/addons/CookiesHandler';
import { ifWidevineSupported } from 'components/addons/DashControlBar';
import SearchNew from 'components/pages/SearchNew';
import {
    profilesEndpoint,
    remindersEndpoint,
    userInfoEndpoint,
} from 'store/actions/apiEndpoints';
import { fetchAction } from 'store/actions/fetchActions';
import { setAdultPin } from 'store/actions/globalStylesActions';
import Group from 'components/pages/Group';
import { isMobile } from 'react-device-detect';
import { RemindersMap } from 'store/actions/ApiKeysMap';
import ToastNotification from 'components/notifications/ReactNotification';
import Demo from 'components/pages/Demo';
import ModalSimple from 'components/elements/ModalSimple';
import Footer from 'components/elements/Footer';
import MobileHomePage from 'components/pages/MobileHomePage';

/*
Autorizacija:
login:
https://pw4.smartiv.us/oauth2/authorize?client_id=c6557674-9527-4687-a8ef-c78d54a82fb1&redirect_uri=http://localhost:3001&response_type=code
logout:
https://pw4.smartiv.us/oauth2/logout?client_id=c6557674-9527-4687-a8ef-c78d54a82fb1  

https://fusionauth.io/learn/expert-advice/authentication/spa/oauth-authorization-code-grant-jwts-refresh-tokens-cookies

Resource Owner Password Credentials Grant Request
https://fusionauth.io/docs/v1/tech/oauth/endpoints
curl --location --request POST 'https://pw4.smartiv.us/oauth2/token' --header 'Content-Type: application/x-www-form-urlencoded' --data-urlencode 'grant_type=password' --data-urlencode 'username=mm' --data-urlencode 'password=password' --data-urlencode 'client_id=c6557674-9527-4687-a8ef-c78d54a82fb1' --data-urlencode 'scope=offline_access'
curl --location --request GET 'https://pw4.smartiv.us/box4.0/profiles' --header 'Authorization: Bearer eyJhbGc...'
https://documenter.getpostman.com/view/10639110/Szt5eWVX?version=latest
*/

class App extends Component {
    helloSent = false;
    widevineSupportChecked = false;
    profilesRequested = false;
    userRequested = false;
    remindersRequested = false;
    // onProfilesFetchedExec = false
    oauthLocaleSet = false;
    modalOnInit = true;
    profilesListShow = false;
    profilesListShown = false;
    langInCookie = '';
    userStatus = '';
    eventSourceOn = false;
    messagesDisplayed = {};
    notifyWorker;

    state = {
        modal_open: false,
        input: '',
        response: {},
        timeout: null,
        clicked: false,
        search: false,
        widevineSupported: false,
        notifyContent: false,
        messageId: false,
    };

    constructor(props) {
        super(props);
        this.appElRef = React.createRef();
    }

    handleCookies = (operation, name, value) => {
        if (operation === 'add') {
            handleCookie({
                operation: operation,
                name: name,
                value: JSON.stringify(value),
                expireDays: 1 / 24,
            });
        } else if (operation === 'remove') {
            handleCookie({ operation: operation, name: name });
        }
    };

    payloadToToster = (payload) => {
        let notifyBody = '';
        if (payload.data.payload) {
            let title = '';
            let startFormatted = '';
            const obj = JSON.parse(payload.data.payload);
            if (obj.epg && obj.epg.start) {
                const startDate = new Date(obj.epg.start * 1000);
                const intlObjTime = new Intl.DateTimeFormat(
                    this.props.auth.lang,
                    { hour: 'numeric', minute: 'numeric' }
                );
                startFormatted = intlObjTime.format(startDate);
            }
            if (obj.epg && obj.epg.title && obj.epg.title.length) {
                for (const item of obj.epg.title) {
                    if (item.language === navigator.language) {
                        title = item.title;
                        break;
                    }
                }
                if (!title) {
                    title = obj.epg.title[0]['title'];
                }
            }
            notifyBody = startFormatted + ' | ' + title;
        }

        let messageId = payload.messageId;
        if (!messageId) {
            messageId = (Date.now() / 1000) | 0;
        }
        // this.messagesDisplayed[messageId] = false
        this.messagesDisplayed[messageId] = false;

        //-- jei tabas neaktyvus, rodom sisteminį pranešimą
        //-- jei aktyvus – tosterį
        let hidden;
        if (typeof document.hidden !== 'undefined') {
            // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden';
        } else if (typeof document.msHidden !== 'undefined') {
            hidden = 'msHidden';
        } else if (typeof document.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
        }

        if (document[hidden]) {
            this.notifyWorker.postMessage({
                title: 'Cgates TV',
                options: {
                    body: notifyBody,
                },
                onclick: 'https://cgates.tv/tv',
            });
        } else {
            this.setState({
                ...this.state,
                messageId: messageId,
                notifyContent: {
                    title: 'action_reminder',
                    body: notifyBody,
                },
            });
        }
    };

    componentDidMount() {
        // console.log('fetchUser on mount!')
        this.props.fetchUser();
        if (window.Worker) {
            this.notifyWorker = new Worker('worker.js');
            this.notifyWorker.onmessage = function (e) {
                e.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.location.href = e.data.open;
            };
        }

        this.langInCookie = getCookie(config['appLangCookieName']);
        let lang = this.langInCookie;
        // console.log('langInCookie:', lang)
        if (!lang) {
            // lang = navigator.language.split('-')[0]
            //-- pageidavo, kad numatytoji būtų lt
            lang = 'lt';
            // console.log('set lang from navigator:', lang)
            this.props.setAppLang(lang);
        } else if (lang !== this.props.auth.lang) {
            // console.log('set lang from cookie ', config['appLangCookieName'], lang)
            this.props.setAppLang(lang);
        }

        this.props.setAdultPin(0);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('props.userinfo', this.props.userinfo)
        // console.log('props.auth', this.props.auth)
        const widevineTrueFunc = () => {
            // console.log('true func!');
        };

        if (
            this.props.userinfo &&
            this.props.userinfo.customer_id &&
            !this.eventSourceOn
        ) {
            const sseUrl =
                config['sseURI'] +
                '?chan=' +
                this.props.userinfo.customer_id.items;

            const listEventSource = new EventSource(sseUrl);
            // console.log('sse url set:', sseUrl)
            listEventSource.onmessage = (e) => {
                try {
                    const payload = JSON.parse(e.data);
                    // console.log('sse onmessage data:', payload);
                    if (
                        !payload ||
                        !payload.data ||
                        !payload.data.msg_type ||
                        payload.data.msg_type !== 'reminder'
                    ) {
                        return;
                    }
                    this.payloadToToster(payload);
                } catch (e) {
                    console.log('sse onmessage parse error', e);
                }
            };

            this.eventSourceOn = true;
        }

        const widevineFalseFunc = () => {
            // console.log('false func!');
            this.props.setModalState(
                'profiles-view',
                <FormattedMessage id="browser_not_supported_header" />,
                <FormattedMessage id="browser_not_supported_body" />,
                null,
                <NotificationIcon />,
                true
            );
        };

        //-- šitiems rodyti profilio parinkimo modalą, jei profilis neparinktas
        // let profiledPaths = ['home', 'tv', 'vod', 'search', 'group']
        //-- jei turim modalo elementą ir auth tokeną, bet neturim profilio
        //-- fečinam profilius, jei dar nebandėm ir emailas verifikuotas
        // if (!this.profilesRequested && this.props.auth && this.props.auth.token && this.props.auth.email_verified) {
        // console.log('token:', this.props.auth)
        // console.log('userinfo:', this.props.userinfo)
        // console.log('this.profilesRequested:', this.profilesRequested)
        // console.log('this.props.auth.loading:', this.props.auth.loading)
        // console.log('this.props.auth.token?:', !!this.props.auth.token, this.props.auth.loading)
        //-- sulaukiam, kol bus atsakymas iš /user, kad žinotume, ar tatai demo, ar ne
        if (!this.profilesRequested && this.props.auth.loading === false) {
            if (!this.userRequested) {
                // console.log('userInfo request!', !!this.props.auth.token, this.props.auth.loading)
                this.props.fetchAction(
                    this.props.auth.token,
                    userInfoEndpoint(),
                    this.constructor.name,
                    'userinfo'
                );
                this.userRequested = true;
            } else if (
                this.props.userinfo &&
                this.props.userinfo.customer_id &&
                this.props.userinfo.customer_id.items
            ) {
                // console.log('profiles request!', !!this.props.auth.token, this.props.auth.loading, this.constructor.name)
                this.props.fetchAction(
                    this.props.auth.token,
                    profilesEndpoint(),
                    this.constructor.name
                );
                this.profilesRequested = true;
            }
        }

        // console.log('profiledPaths:', profiledPaths, this.props.global_styles.app_class)
        // console.log('this.props.auth.profile:', this.props.auth.profile, this.props.auth.loading)
        // console.log('this.props.profiles:', this.props.profiles)
        // console.log('cookie profile:', getCookie('profile'))
        const cookieProfile = getCookie('profile');
        //-- jei profilis yra, ir yra profiliai, ar profilio customerId sutampa su userio customerId
        //-- jei ne, nuresetinam profilį į null
        if (
            this.props.userinfo &&
            this.props.auth.profile &&
            this.props.profiles
        ) {
            if (
                this.props.userinfo.customer_id.items !==
                this.props.auth.profile.customer_id
            ) {
                // console.log(this.props.userinfo.customer_id.items, '!==', this.props.auth.profile.customer_id)
                // console.log('reset profile!')
                this.handleCookies('remove', 'profile');
                this.props.pinEnterSuccess(null);
            }
            // } else if (this.props.userinfo && !this.props.auth.profile && this.props.profiles && !this.props.auth.loading && cookieProfile) {
            //     try {
            //         const parsedProfile = JSON.parse(cookieProfile);
            //         console.log('parsedProfile:', parsedProfile);
            //     } catch (error) {
            //         console.error("error reading profile cookie:", error);
            //     }
        }
        // console.log('if set profile:', !!this.props.modal_state.modal_obj, !this.props.auth.profile, this.props.profiles)
        if (
            // this.props.auth.token &&
            // profiledPaths.includes(this.props.global_styles.app_class) &&
            this.props.modal_state.modal_obj &&
            !this.props.auth.profile &&
            this.props.profiles
        ) {
            if (
                !this.oauthLocaleSet &&
                !this.langInCookie &&
                this.props.auth.locale &&
                this.props.auth.locale.length
            ) {
                for (const i of this.props.auth.locale) {
                    console.log('oauthLocaleSet', i);
                    this.props.setAppLang(i);
                    break;
                }
                this.oauthLocaleSet = true;
            }

            //-- jei nėra profilio kukio arba nepatvirtintas mailas
            // console.log('profilesListShown &', !this.profilesListShown, !getCookie('profile'), Object.keys(this.props.modal_state.modal_obj), document.getElementById('default-modal'))
            // console.log('!cookieProfile !profile !profilesListShown:', !cookieProfile, !this.props.auth.profile, !this.profilesListShown)
            if (!cookieProfile && !this.props.auth.profile) {
                if (!this.widevineSupportChecked) {
                    //-- čia patikrinam, ar brauseris tinkamas naudoti widevine
                    ifWidevineSupported(widevineTrueFunc, widevineFalseFunc);
                    this.widevineSupportChecked = true;
                }

                this.profilesListShown = false;
                const modalEl = document.getElementById('default-modal');
                if (modalEl.classList.contains('show')) {
                    this.profilesListShown = true;
                }

                //-- jei profilių vienas, be pino, arba jo needs_pin_to_login = false parenkam iš karto tą vieną
                // console.log('laikas apsispręsti dėl profilio', this.props.profiles, this.props.userinfo.is_demo.items)
                // console.log('is demo:', this.props.userinfo['is_demo']['items'])
                if (
                    this.props.profiles.items &&
                    ((this.props.profiles.items.length === 1 &&
                        (!this.props.profiles.items[0]['pin'] ||
                            !this.props.profiles.items[0][
                                'needs_pin_to_login'
                            ])) ||
                        this.props.userinfo['is_demo']['items'])
                ) {
                    this.handleCookies(
                        'add',
                        'profile',
                        this.props.profiles.items[0]
                    );
                    this.props.pinEnterSuccess(this.props.profiles.items[0]);
                    // this.modalOnInit = false
                } else if (
                    !this.profilesListShown &&
                    !this.profilesListShow &&
                    !cookieProfile
                ) {
                    //-- kitaip rodom modalą
                    //-- id, title, body, back_button, header_icon, no_close
                    this.props.setModalState(
                        'profiles-view',
                        <FormattedMessage id="profile_selection_title" />,
                        <ProfilesView
                            handleCookies={this.handleCookies}
                            backButton={null}
                            noClose={true}
                        />,
                        null,
                        <ProfilesIcon />,
                        true
                    );
                    if (!this.profilesListShow) {
                        this.props.modal_state.modal_obj.obj.toggle();
                        this.profilesListShow = true;
                    }
                    // console.log('modal toggle...')
                }
            } else if (cookieProfile) {
                // console.log('profile set from cookie')
                this.props.pinEnterSuccess(JSON.parse(cookieProfile), false);
                if (this.profilesListShown && this.profilesListShow) {
                    this.props.modal_state.modal_obj.obj.toggle();
                    this.profilesListShow = false;
                }
            }
        }

        //-- jei dar nesiuntėm, siunčiam į statistiką HELLO!
        if (
            !this.helloSent &&
            this.props.auth.token &&
            this.props.auth.profile &&
            (!this.props.auth.stat_hello ||
                (this.props.auth.stat_hello &&
                    this.props.auth.profile_stamp > this.props.auth.stat_hello))
        ) {
            const nowStamp = Math.round(Date.now() / 1000);
            let msgData = {
                type: 'HELLO',
                tstamp: this.props.auth.profile_stamp
                    ? nowStamp - this.props.auth.profile_stamp
                    : 0,
                now: nowStamp,
                customerId: this.props.auth.profile.customer_id,
                profileId: this.props.auth.profile.id,
                deviceType: 30,
                platformName: '',
                firmwareVersion: navigator.userAgent,
                appVersion: '0.1.0',
            };
            const msgPacked = msgpack.encode(msgData);

            this.helloSent = true;
            fetch(config['statsURI'], {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + this.props.auth.token,
                    'Content-Type': 'application/msgpack',
                },
                body: msgPacked,
            })
                .then((response) => {
                    this.props.setStatHello(nowStamp);
                    console.log('stats', response.status);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (
            !this.remindersRequested &&
            this.props.auth.profile &&
            !this.props.userinfo['is_demo']
        ) {
            this.props.fetchAction(
                this.props.auth.token,
                remindersEndpoint({
                    page_offset: 0,
                    page_limit: 100,
                    lang: this.props.auth.lang,
                    filter_profile: this.props.auth.profile.id,
                }),
                this.constructor.name,
                RemindersMap
            );
            this.remindersRequested = true;
        }
    }

    render() {
        const location = window.location.pathname;

        const toastNotifier =
            this.state.notifyContent &&
            !this.messagesDisplayed[this.state.messageId] ? (
                <ToastNotification
                    title={this.state.notifyContent.title}
                    body={this.state.notifyContent.body}
                />
            ) : null;

        if (this.state.messageId) {
            this.messagesDisplayed[this.state.messageId] = true;
        }

        //-- jei neprisijungęs, nukreipia į išorinį prisijungimo puslapį
        // if (typeof this.props.auth !== 'undefined') {
        //     if (typeof this.props.auth.token === 'undefined' &&
        //         window.location.pathname !== '/sign-up' &&
        //         window.location.pathname !== '/sign-up/conditions' &&
        //         window.location.pathname !== '/register' &&
        //         window.location.pathname !== '/status') {
        // console.log('but no token is set...', this.props.auth)
        // return null
        // return (
        //     <BrowserRouter>
        //         <Route exact path='/' component={() => {
        //             window.location.href = config['clientURI'] + "/login";
        //             return null;
        //         }}/>
        //     </BrowserRouter>
        // )
        // }
        // }

        //-- čia patikrinam, ar email_verified
        // console.log('this.props.auth', this.props.auth)
        if (
            typeof this.props.auth.email_verified !== 'undefined' &&
            !this.props.auth.email_verified
        ) {
            return (
                <IntlProvider
                    locale={this.props.auth.lang}
                    messages={messages[this.props.auth.lang]}
                >
                    <div>
                        <h3 align="center">
                            <FormattedMessage id="email_verified_header" />
                        </h3>
                        <p align="center">
                            <FormattedMessage id="email_verified_body" />
                        </p>
                    </div>
                </IntlProvider>
            );
        }

        const { auth, global_styles, userinfo } = this.props;
        const globalClasses = [
            'play',
            'sign-up',
            'status',
            'conditions',
            'password-recovery',
            'activate',
        ];

        if (
            typeof userinfo !== 'undefined' &&
            typeof userinfo['is_demo'] !== 'undefined' &&
            !userinfo['is_demo']['items']
        ) {
            this.userStatus = 'registered';
        }
        // console.log('userinfo:', userinfo)
        // console.log('userStatus:', this.userStatus)
        const navbar =
            !globalClasses.includes(global_styles.app_class) ||
            window.location.pathname.includes('register') ? (
                <Navbar
                    user={auth}
                    response={this.state.response}
                    clicked={this.state.clicked}
                    handleCookies={this.handleCookies}
                    setSearch={this.setSearch}
                    userStatus={this.userStatus}
                />
            ) : null;

        return (
            <IntlProvider locale={auth.lang} messages={messages[auth.lang]}>
                {isMobile &&
                location !== '/status' &&
                location !== '/password-recovery' &&
                location !== '/sign-up' &&
                location !== '/sign-up/conditions' &&
                location !== '/register' &&
                !location.includes('/activate') ? (
                    <MobileHomePage
                        lang={auth.lang}
                        setAppLang={(lang) => this.props.setAppLang(lang)}
                    />
                ) : (
                    <BrowserRouter>
                        <div
                            ref={this.appElRef}
                            className={'App ' + global_styles.app_class}
                        >
                            {toastNotifier}
                            {navbar}
                            {this.props.modal_simple_state && (
                                <ModalSimple
                                    header={
                                        this.props.modal_simple_state.header
                                    }
                                    headerStyle={
                                        this.props.modal_simple_state
                                            .headerStyle
                                    }
                                    body={this.props.modal_simple_state.body}
                                    style={this.props.modal_simple_state.style}
                                    bodyStyle={
                                        this.props.modal_simple_state.bodyStyle
                                    }
                                    show={this.props.modal_simple_state.show}
                                    closeOnClick={
                                        this.props.modal_simple_state
                                            .closeOnClick
                                    }
                                    hideClose={
                                        this.props.modal_simple_state.hideClose
                                    }
                                    overlayOnly={
                                        this.props.modal_simple_state
                                            .overlayOnly
                                    }
                                    overlayOnClose={
                                        this.props.modal_simple_state
                                            .overlayOnClose
                                    }
                                />
                            )}
                            <div
                                className="modal fade"
                                id="default-modal"
                                tabIndex="-1"
                            >
                                <ModalElement />
                            </div>
                            <div
                                id={'body-background'}
                                className={'body-background'}
                            />
                            <main>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        render={() => (
                                            <Home appElRef={this.appElRef} />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/logout"
                                        component={() => {
                                            window.location.href =
                                                config['clientURI'] + '/logout';
                                            return null;
                                        }}
                                    />
                                    <Route
                                        path="/tv/:genre_id?/:channel_id?/:epg_id?"
                                        render={() => (
                                            <Tv appElRef={this.appElRef} />
                                        )}
                                    />
                                    <Route
                                        path="/shop/:category?/:product_id?"
                                        render={() => (
                                            <Shop appElRef={this.appElRef} />
                                        )}
                                    />
                                    <Route
                                        path="/play"
                                        component={
                                            this.userStatus ? Play : Demo
                                        }
                                    />
                                    <Route path="/demo-page" component={Demo} />
                                    <Route
                                        path="/vod-play"
                                        component={
                                            this.userStatus ? Play : Demo
                                        }
                                    />
                                    <Route
                                        path="/vod"
                                        render={() => (
                                            <Vod appElRef={this.appElRef} />
                                        )}
                                    />
                                    <Route
                                        path="/search/:search_id?"
                                        component={SearchNew}
                                    />
                                    <Route
                                        path="/vod-about"
                                        render={(props) => (
                                            <VodAbout
                                                {...props}
                                                appElRef={this.appElRef}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/group"
                                        render={(props) => (
                                            <Group
                                                {...props}
                                                appElRef={this.appElRef}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/register"
                                        component={Register}
                                    />
                                    {/*<Route exact path='/register' render={(props) => <Register key={props.location.key} />} />*/}
                                    <Route
                                        exact
                                        path="/sign-up"
                                        component={SignUpForm}
                                    />
                                    <Route
                                        path="/sign-up/conditions"
                                        component={Conditions}
                                    />
                                    <Route path="/status" component={Status} />
                                    <Route
                                        path="/password-recovery"
                                        component={PasswordRecovery}
                                    />
                                    <Route
                                        path="/activate/:handle"
                                        component={AccountActivation}
                                    />
                                </Switch>
                            </main>
                        </div>
                        <Footer lang={auth ? auth.lang : null} />
                    </BrowserRouter>
                )}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        profiles: state.api_data.payload['customer_profiles'],
        userinfo: state.api_data.payload['userinfo'],
        auth: state.auth,
        modal_simple_state: state.modal_simple_state,
        modal_state: state.modal_state,
        global_styles: state.global_styles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => {
            dispatch(fetchUser());
        },
        setModalState: (
            id,
            title,
            body,
            back_button,
            header_icon,
            no_close
        ) => {
            dispatch(
                setModalState(
                    id,
                    title,
                    body,
                    back_button,
                    header_icon,
                    no_close
                )
            );
        },
        setModalSimpleState: (props) => {
            dispatch(setModalSimpleState(props));
        },
        setAppLang: (lang) => {
            dispatch(setAppLang(lang));
        },
        setStatHello: (value) => {
            dispatch(setStatHello(value));
        },
        setAdultPin: (value) => {
            dispatch(setAdultPin(value));
        },
        pinEnterSuccess: (profile, preserve_stamp) =>
            dispatch(pinEnterSuccess(profile, preserve_stamp)),
        fetchAction: (token, endpoint, source, subkey, params) => {
            dispatch(fetchAction(token, endpoint, source, subkey, params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
