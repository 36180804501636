import React from 'react'
import Logo from '../../img/logo.svg'
import { FormattedMessage } from "react-intl"

const Software = () => {
  return (
    <div className="software-content">
      <div className="software-logo"><img src={Logo} alt="" /></div>
      <div><FormattedMessage id="sw_version_title" /> 3.15</div>
    </div>
  )
}

export default Software