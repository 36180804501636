export const SET_APP_CLASS = 'SET_APP_CLASS';
export const SET_ADULT_PIN = 'SET_ADULT_PIN';
export const SET_SHOP_ITEM = 'SET_SHOP_ITEM';
export const REMOVE_SHOP_ITEM = 'REMOVE_SHOP_ITEM';

export const setAppClass = (app_class) => {
    return {
        type: SET_APP_CLASS,
        payload: app_class
    }
}

export const setAdultPin = (pin) => {
    return {
        type: SET_ADULT_PIN,
        payload: pin
    }
}

export const setShopItem = (shop_item) => {
    return {
        type: SET_SHOP_ITEM,
        payload: shop_item
    }
}

export const removeShopItem = (shop_item) => {
    return {
        type: REMOVE_SHOP_ITEM,
        payload: shop_item
    }
}
