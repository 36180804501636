import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { setAppClass } from '../../store/actions/globalStylesActions'
import TextField from './TextField'

const AccountActivation = props => {
    const [code, setCode] = useState('')
    const [codeEmpty, setCodeEmpty] = useState(false)
    const { handle } = useParams()
    const { history, setAppClass } = props
    const successMessage = 'Your email ' + handle + ' has been successfully verified. You can now ' +
        'log in using Your username and password.'
    const emptyError = 'Code can not be empty'

    useEffect(() => {
        setAppClass('activate')
    }, [setAppClass])

    const handleSubmit = e => {
        e.preventDefault()
        let canSubmit = true

        if (!code.length) {
            setCodeEmpty(true)
            canSubmit = false
        }

        if (canSubmit) {
            history.push({
                pathname: '/status',
                state: {
                    status: 'Success',
                    message1: successMessage,
                    button1Text: 'LOG IN',
                }
            })
        }
    }

    return (
        <form className="account-activation-form" onSubmit={e => handleSubmit(e)}>
            <h1>Enter code</h1>
            <p>Please enter the registration code that You received</p>
            <TextField field={code} setField={setCode} fieldCondition={codeEmpty} setFieldCondition={setCodeEmpty}
                       error={emptyError} label="Code" type="text" />
            <input className="button" type="submit" value="CONTINUE" />
        </form>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(null, mapDispatchToProps)(AccountActivation)