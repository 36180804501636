import React, {useEffect, useState} from 'react'
import { FormattedMessage } from 'react-intl'
import SubtitleIcon from '../../img/subtitle-icon.svg'
import TripleDotsIcon from '../../img/triple-dots-icon.svg'
import Logo from '../../img/logo.svg'
import LanguageSelectDropdown from './LanguageSelectDropdown'
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const ConditionsPage = props => {
    const { setShowPrivacy, handleRequest, termsURL, bindConsent } = props
    const [content, setContent] = useState('')
    useEffect(() => {
        fetch(termsURL)
            .then(response => response.text())
            .then(result => setContent(result))
            .then(bindConsent)
    }, [bindConsent, termsURL])

    return (
        <div className="conditions-page">
            <img className="cgates-logo" src={Logo} alt='cgates logo' />

            <LanguageSelectDropdown />

            <div className="conditions-container" style={{textAlign: 'center', display: 'block'}}>
                <h3><FormattedMessage id="terms_header" /></h3>
            </div><hr/>

            <div className="conditions-content-page">
                <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]} />
            </div><hr/>

            <div className="conditions-container">
                <img src={SubtitleIcon} alt="subtitle icon" />
                <p><FormattedMessage id="register_privacy" /></p>
                <button onClick={() => setShowPrivacy(true)}>
                    <img src={TripleDotsIcon} alt="details icon" />
                </button>
            </div><hr/>

            <div className="buttons-container">
                <button className="button" onClick={handleRequest}><FormattedMessage id="register_accept" /></button>
            </div>
        </div>
    )
}

export default ConditionsPage