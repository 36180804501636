import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl"
// import ProfilePin from './ProfilePin'
import { fetchAction } from '../../store/actions/fetchActions'
import { profilesEndpoint } from '../../store/actions/apiEndpoints'
import { pinEnterSuccess } from '../../store/actions/authActions'
import PinInput from "./PinInput";
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";
// import Spinner from '../../img/spinner.svg'

class ProfilesView extends Component {
    profilesRequested = false
    profileId = null

    state = {
        profiles: null
    }

    handlePinSubmit = (e) => {
        e.preventDefault()
    }

    handlePinEntered = (enteredPin, hasError) => {
// console.log('handlePinEntered:', enteredPin, hasError)
        if (!hasError) {
            let profile = this.props.api_data.payload.customer_profiles.items.find(obj => obj.id === this.profileId)
            this.props.handleCookies("add", "profile", profile)
            this.props.pinEnterSuccess(profile)
            this.props.setModalSimpleState({
                show: false
            })
        }
    }

    handleCloseClick = (e) => {
// console.log('handleCloseClick')
        e.preventDefault()
        this.props.setModalSimpleState({
            overlayOnly: true
        })
    }

    handlePinClick = (e) => {
        //-- sutrumpinta versija
        let profile = this.props.api_data.payload.customer_profiles.items.find(obj => obj.id === e)
        this.profileId = profile.id
// console.log('handlePinClick', ' backButton:', this.props.backButton, ' noClose:', this.props.noClose, typeof this.props.noClose !== 'undefined')
        const pinToCheck = profile.pin ? profile.pin : profile.pin_with_default
        if (pinToCheck && profile.needs_pin_to_login) {
            this.props.modal_state.modal_obj.obj.toggle()
            this.props.setModalSimpleState({
                show: true,
                closeOnClick: false,
                header: "pin_required_title",
                style: {width: "400px"},
                headerStyle: {marginBottom: "40px"},
                hideClose: true,
                body: (
                    <div>
                        <div className="pt-3">
                            {profile.name}
                            {/*<FormattedMessage id={"profile_pin_input_hint"} />*/}
                        </div>

                        <form onSubmit={(e) => this.handlePinSubmit(e)}>
                            <div className="form-group" style={{marginBottom: '47px', marginTop: '16px'}}>
                                <div className={"d-flex"}>
                                    <PinInput
                                        onPinEntered={this.handlePinEntered}
                                        pinToCheck={pinToCheck}
                                        passwordType={true}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button className="button btn-secondary btn-lg me-2" onClick={(e) => this.handleCloseClick(e)}>
                                    <FormattedMessage id={"profile_button_cancel"} />
                                </button>
                                <button className="button btn-primary btn-lg">
                                    <FormattedMessage id={"pin_dialog_button_ok_text"} />
                                </button>
                            </div>
                        </form>
                    </div>
                )
            })


            // id, title, body, back_button, header_icon, no_close
            // this.props.setModalState(
            //     'profile-pin',
            //     <FormattedMessage id="pin_required_title" />,
            //     <ProfilePin profile={profile} handleCookies={this.props.handleCookies} />,
            //     typeof this.props.backButton !== 'undefined' ? this.props.backButton : 'ProfilesView',
            //     null,
            //     typeof this.props.noClose !== 'undefined' ? this.props.noClose : false
            // )
        } else {
            this.props.handleCookies("add", "profile", profile)
            this.props.pinEnterSuccess(profile)
            this.props.modal_state.modal_obj.obj.toggle()
        }        
    }

    // componentDidMount() {
    componentDidUpdate(prevProps, prevState) {
        if (!this.props.api_data.payload['customer_profiles'] && !this.profilesRequested) {
            this.props.fetchAction(this.props.auth.token, profilesEndpoint(), this.constructor.name)
            this.profilesRequested = true
        }
    }
    
    render() {
        const { source, loading, payload } = this.props.api_data

        if (source === this.constructor.name && loading) {
            // console.log('loading... ', this.constructor.name)
            return (
                <div className="loader">
                    <span className="loader-img"/>
                    {/*<img className="loader-img" src={Spinner} alt="Loading" />*/}
                </div>
            )
        }

        const profilesList = (payload && payload.customer_profiles) ? (
            payload.customer_profiles.items.map(profile => {
                return (
                    <div className="card" key={profile.id}>
                        <div className="card-header" id={"heading_" + profile.id}>
                        <button className="btn btn-link single-line" onClick={() => this.handlePinClick(profile.id)}>
                            <div className="avatar-card">
                            <div className="avatar-image-card">
                                <img className="avatar-image" src={profile.avatar.url} alt="avatar icon" />
                            </div>
                            <div className="d-flex align-items-center">
                                {profile.name}
                            </div>
                            </div>
                        </button>
                        </div>
                    </div>
                )
            })
        ) : (
            '...'
        )

        return (
            <div className="profiles-list">
                {profilesList}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        api_data: state.api_data,
        auth: state.auth,
        modal_state: state.modal_state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
        pinEnterSuccess: (profile) => dispatch(pinEnterSuccess(profile)),
        setModalState: (id, title, body, back_button, header_icon, no_close) => { dispatch(setModalState(id, title, body, back_button, header_icon, no_close)) },
        fetchAction: (token, endpoint, source) => { dispatch(fetchAction(token, endpoint, source)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesView)
