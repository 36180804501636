import React, { Component } from 'react'
import { connect } from 'react-redux'
import {setAdultPin, setAppClass} from '../../store/actions/globalStylesActions'
import {fetchAction, fetchSuccess} from '../../store/actions/fetchActions'
// import {Redirect} from 'react-router-dom'
// import {vodAboutEndpoint} from "../../store/actions/apiEndpoints";
import {VodAboutWrap, WishlistMap} from "../../store/actions/ApiKeysMap";
import VodAboutMain from "../elements/VodAboutMain";
import {vodAboutEndpoint, wishlistEndpoint} from "../../store/actions/apiEndpoints";
// import {getLangProp} from "../addons/ProductsHelper";
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions";

class VodAbout extends Component {
    fetchStamps = {}
    renderStamps = {}
    searchParamsIndex = ''
    searchParamsSeries = ''
    searchParamsSection = ''
    searchParamsVodId = ''
    wishlistRequested = false
    vodRequested = false
    userDemo = true

    state = {
        redirectTo: false,
        pinHasError: false,
        confirmShow: false,
        vodCanceled: false,
    }

    componentDidMount() {
        // this.props.setAppClass('vod-about')
        // this.props.appElRef.current.className = 'App vod-about'
        this.vodRequested = false
        const nowStamp = Math.round(Date.now() / 1000)
// console.log('VodAbout mount', this.props)

        this.userDemo = !!(this.props.api_data.payload['userinfo'] &&
            this.props.api_data.payload['userinfo']['is_demo'] &&
            this.props.api_data.payload['userinfo']['is_demo']['items'])

        // if ((!this.props.auth || !this.props.auth.token) && !this.userDemo) {
        //     return <Redirect to="/" />
        // }
// console.log('userDemo:', this.userDemo)
        const searchParams = new URLSearchParams(this.props.location.search)
        this.searchParamsIndex = searchParams.get('index')
        this.searchParamsSeries = searchParams.get('series')
        this.searchParamsSection = searchParams.get('section')
        this.searchParamsVodId = searchParams.get('vod_id')
        this.fetchStamps['vod_about'] = nowStamp

        const keyIndex = 0
        if (this.props.api_data.payload[this.searchParamsSection]) {
            const key = Object.keys(this.props.api_data.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex])[keyIndex]
            let vodItem = this.props.api_data.payload[this.searchParamsSection]['items']['items'][this.searchParamsIndex][key]
            if (typeof vodItem['trailers'] === 'undefined') {
                vodItem['trailers'] = null
            }

            this.props.fetchSuccess(
                vodItem,
                this.constructor.name,
                VodAboutWrap
            )
        }

        if (!this.wishlistRequested && this.props.auth.profile) {
            this.wishlistRefresh()
            this.wishlistRequested = true
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //-- sulaukiam, kol ateis useris, kad žinotume, ar jis demo
        //-- ir profilio sulaukiam
        if (
            this.searchParamsVodId &&
            !this.props.api_data.payload[this.searchParamsSection] &&
            !this.vodRequested &&
            !this.props.auth.loading &&
            this.props.auth.profile &&
            this.props.userinfo
        ) {
            this.userDemo = this.props.userinfo['is_demo']['items']
            this.vodRequested = true
            this.props.fetchAction(
                this.props.auth.token,
                vodAboutEndpoint(
                    {
                        'id': this.searchParamsVodId,
                        'lang': this.props.auth.lang
                    }),
                this.constructor.name,
                VodAboutWrap
            )
        }
    }

    componentWillUnmount = () => {
        const rootElem = document.getElementById("root")
        rootElem.removeAttribute("style")
// console.log('on unmount:', this.props.modal_simple_state)
        if (this.props.modal_simple_state.show || this.props.modal_simple_state.overlayOnly) {
            this.props.setModalSimpleState({
                show: false,
                overlayOnly: false
            })
        }
    }

//     setVodCanceled = (value) => {
// console.log('setVodCanceled:', value)
//         this.setState({
//             ...this.state,
//             vodCanceled: value
//         })
//     }

    wishlistRefresh = () => {
        this.props.fetchAction(
            this.props.auth.token,
            wishlistEndpoint(
                {
                    'action': 'get',
                    'profile_id': this.props.auth.profile.id
                }),
            this.constructor.name,
            WishlistMap
        )
    }

    render() {
// console.log('vod render!', this.props)
        return <VodAboutMain
            props={this.props}
            vodCanceled={this.state.vodCanceled}
            // setVodCanceled={this.setVodCanceled}
            userDemo={this.userDemo}
            fetchStamps={this.fetchStamps}
            // handlePinSubmit={this.handlePinSubmit}
            pinHasError={this.state.pinHasError}
            wishlistRefresh={this.wishlistRefresh}
        />
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        player: state.player,
        api_data: state.api_data,
        auth: state.auth,
        userinfo: state.api_data.payload['userinfo'],
        global_styles: state.global_styles,
        modal_simple_state: state.modal_simple_state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAction: (token, endpoint, source, subkey, params) => { dispatch(fetchAction(token, endpoint, source, subkey, params)) },
        fetchSuccess: (payload, source, subkey) => { dispatch(fetchSuccess(payload, source, subkey)) },
        setAdultPin: (value) => { dispatch(setAdultPin(value)) },
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) },
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VodAbout)
