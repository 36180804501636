import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl";
import { createProfile } from '../../store/actions/profilesActions'
import Circle1 from '../../img/circle-1-green.svg'
import Circle2 from '../../img/circle-2-green.svg'
import Circle3 from '../../img/circle-3.svg'
import Subscriptions3 from './Subscriptions3'
import SubscriptionsIcon from '../../img/subscriptions-icon.svg'


class Subscriptions2 extends Component {

    handleContinueClick = (e) => {
      e.preventDefault()
      this.props.setModalState('subscriptions-modal-3', <FormattedMessage id="settings_action_subscription" />, <Subscriptions3 />, 'Subscriptions2', <SubscriptionsIcon />);
    } 

    render() {
        return (
          <div>

            <div className="indicators-row">

              <div>
                <img src={Circle1} alt="" />
                <p>Products</p>
              </div>

              <div>
                <img src={Circle2} alt="" />
                <p>Extras</p>
              </div>

              <div>
                <img src={Circle3} alt="" />
                <p>Confirmation</p>
              </div>

            </div>

            <div className="cards">

              <div className="row">
                <div className="card-header col">
                  <button>
                    <p className="card-header-title">Full content!</p>
                    <p className="card-header-content">20$</p>
                  </button>
                </div>

                <div className="card-header col">
                  <button className="active">
                    <p className="card-header-title">Sports Fan</p>
                    <p className="card-header-content">20$</p>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="card-header col">
                  <button>
                    <p className="card-header-title">VOD</p>
                    <p className="card-header-content">20$</p>
                  </button>
                </div>

                <div className="card-header col">
                  <button>
                    <p className="card-header-title">Fishing</p>
                    <p className="card-header-content">20$</p>
                  </button>
                </div>
              </div>

            </div>

            <div className="btn-div">
              <div className="row btn-row">
                <div className="col pl-0 pr-3">
                  <button className="button btn-secondary">Cancel</button>
                </div>
                <div className="col pl-3 pr-0">
                  <button className="continue-button" onClick={this.handleContinueClick}>Continue</button>
                </div>
              </div>
            </div>

          </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
      return {
          createProfile: (profile) => dispatch(createProfile(profile)),
          setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) }        
      }
}

export default connect(null, mapDispatchToProps)(Subscriptions2)
