import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { getLangProp } from 'components/addons/ProductsHelper';
import CompareDropDown from './CompareDropDown';
import SingleProduct from './SingleProduct';
import style from './style.module.sass';

const ProductsCompare = ({
    products,
    product_properties,
    lang,
    langDefault,
    handleSwitch,
    sliderStatus,
    handleStepBtn,
}) => {
    const [currentProduct, setCurrentProduct] = useState(0);
    const [showCompare, setShowCompare] = useState(false);

    const filteredProducts = products.filter((product) => {
        if (product.is_prepaid && sliderStatus) return false;
        if (product.is_postpaid && !sliderStatus) return false;
        return true;
    });

    const productsDropdownOptions = filteredProducts.map((product) => ({
        label: getLangProp(product, 'title', lang, langDefault),
        value: product.id,
    }));

    const [mobileCompare, setMobileCompare] = useState({
        left: productsDropdownOptions[0].value,
        right: productsDropdownOptions[1].value,
    });

    const maxProduct =
        products.filter((item) =>
            sliderStatus ? item.is_postpaid : item.is_prepaid
        ).length - 4;

    const compareLeft = filteredProducts.find(
        (product) => product.id === mobileCompare.left
    );
    const compareRight = filteredProducts.find(
        (product) => product.id === mobileCompare.right
    );

    const onNext = () => {
        setCurrentProduct(currentProduct + 1);
    };

    const onPrevious = () => {
        setCurrentProduct(currentProduct - 1);
    };

    const onShowCompare = () => {
        setShowCompare(!showCompare);
    };

    const onSelectChange = (isRight) => (newValue) => {
        setMobileCompare((currentState) => ({
            left: isRight ? currentState.left : newValue,
            right: isRight ? newValue : currentState.right,
        }));
    };

    return (
        <div
            className={classnames('compare-box', style.optionsCompareWrapper)}
            id="accordionCompare"
        >
            <h2 id="headingTwo" className={style.optionsCompareHeader}>
                <button
                    type="button"
                    onClick={onShowCompare}
                    className={style.optionsCompareHeaderButton}
                >
                    <FormattedMessage id="register_compare" />
                    <span
                        className={classnames(style.compareChevron, {
                            [style.active]: showCompare,
                        })}
                    />
                </button>
                <span className={style.optionsCompareMobileHeader}>
                    <FormattedMessage id="register_compare" />
                </span>
            </h2>
            <div
                className={classnames(style.compareGrid, {
                    [style.hidden]: !showCompare,
                })}
            >
                {currentProduct > 0 && (
                    <button
                        type="button"
                        onClick={onPrevious}
                        className={classnames(style.carouselButton, style.prev)}
                    >
                        <span className={style.chevronLeft} />
                    </button>
                )}
                {currentProduct < maxProduct && (
                    <button
                        type="button"
                        onClick={onNext}
                        className={classnames(style.carouselButton, style.next)}
                    >
                        <span className={style.chevronRight} />
                    </button>
                )}
                <div className={style.compareGridProperties}>
                    <div className={style.compareGridSwitch}>
                        <span className={'me-2'}>
                            <FormattedMessage id="plan_type_monthly" />
                        </span>
                        <label className="switch" style={{ height: '24px' }}>
                            <input
                                name="needs_pin_to_login"
                                type="checkbox"
                                checked={sliderStatus}
                                onChange={handleSwitch}
                            />
                            <span className="slider round side" />
                        </label>
                        <span className={'ms-2'}>
                            <FormattedMessage id="plan_type_subscribed" />
                        </span>
                    </div>
                    <p
                        className={style.compareGridHeadText}
                        style={{ marginBottom: 0 }}
                    >
                        <FormattedMessage id="subscribe_th_properties" />
                    </p>
                    {product_properties.map((item) => (
                        <span
                            key={item.name}
                            className={style.compareGridSideText}
                        >
                            {getLangProp(item, 'title', lang, langDefault)}
                        </span>
                    ))}
                    <div className={style.compareGridHeadGap} />
                </div>
                <div className={style.compareGridOptionsWrapper}>
                    <div
                        className={style.compareGridOptions}
                        style={{
                            transform: `translateX(-${
                                currentProduct ? 170 * currentProduct + 85 : 0
                            }px)`,
                        }}
                    >
                        {filteredProducts.map((product) => (
                            <SingleProduct
                                key={product.name}
                                lang={lang}
                                langDefault={langDefault}
                                product={product}
                                productProperties={product_properties}
                                onSelect={handleStepBtn}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className={style.mobilePlanSlider}>
                <div
                    className={classnames(style.mobilePlanSliderShadow, {
                        [style.active]: sliderStatus,
                    })}
                />
                <button
                    type="button"
                    onClick={handleSwitch}
                    className={style.mobilePlanSliderButton}
                    disabled={!sliderStatus}
                >
                    <FormattedMessage id="plan_type_monthly" />
                </button>
                <button
                    type="button"
                    onClick={handleSwitch}
                    className={style.mobilePlanSliderButton}
                    disabled={sliderStatus}
                >
                    <FormattedMessage id="plan_type_subscribed" />
                </button>
            </div>
            <div className={style.compareDropDownWrapper}>
                <CompareDropDown
                    options={productsDropdownOptions}
                    onChange={onSelectChange()}
                />
                <CompareDropDown
                    defaultValue={productsDropdownOptions[1]}
                    options={productsDropdownOptions}
                    onChange={onSelectChange(true)}
                    secondary
                />
            </div>
            <div className={style.compareMobilePlanWrapper}>
                <SingleProduct
                    lang={lang}
                    langDefault={langDefault}
                    product={compareLeft}
                    productProperties={product_properties}
                    onSelect={handleStepBtn}
                    withProperties
                    mobile
                />
                <SingleProduct
                    lang={lang}
                    langDefault={langDefault}
                    product={compareRight}
                    productProperties={product_properties}
                    onSelect={handleStepBtn}
                    mobile
                />
            </div>
        </div>
    );
};

export default ProductsCompare;
