import React from 'react'
import {Link} from 'react-router-dom'
// import Scrollbar from 'react-scrollbars-custom'

const Channels = React.memo(({channels, profileAge, loading, channelSelectedId, genreSelected, onclick, channelsListRef}) => {
// console.log('TV channels', channels.subscribed)
    const DataLoading = () => {
        return (
            <div className="loader">
                {/*<img className="loader-img" src={Spinner} alt="Loading"/>*/}
                <span className="loader-img"/>
            </div>
        )
    }

    const DataArrived = () => {
        let lcnKeys = []
        for (const i in channels['maps']['lcn']) {
            if (typeof i === 'undefined') {
console.log('channel lcn undefined', channels['maps']['lcn'][i])
                continue
            }
            lcnKeys.push(parseInt(i))
        }
        // const lcnSorted = Object.keys(channels['maps']['lcn']).sort()
        const lcnSorted = lcnKeys.sort((a, b) => a - b)
        let list = lcnSorted.map((lcnNo) => {
            const index = channels['maps']['lcn'][lcnNo]
            const channel = channels['items'][index]
            if (!channel['subscribed']) {
                return null
            }

            if (
                (genreSelected && genreSelected !== 'all' && parseInt(genreSelected) !== channel['genre_id'])
                // eslint-disable-next-line no-mixed-operators
                || profileAge && profileAge < channel['age_rating']
            ) {
                return null
            }
            let activeClass = channelSelectedId === channel.id ? "active" : ""

            return (
                <li className={activeClass} key={index}>
                    <Link
                        to={'/tv/' + (genreSelected ? genreSelected : 'all') + '/' + channel.id}
                        onClick={() => onclick(channel.id)}
                    >
                        <img className="channel-logo" src={channel.logo} alt=""/>
                    </Link>
                </li>
            )
        })

        return (
            <ol ref={channelsListRef} className="channel-list">
                {list}
            </ol>
        )
    }

    return (
        (!channels['items'] || !channels['items'].length || loading) === true ? <DataLoading/> : <DataArrived/>
        // <Scrollbar thumbYProps={{className: "thumbY"}}>
        //     {(!channels['items'] || !channels['items'].length || loading) === true ? <DataLoading/> : <DataArrived/>}
        // </Scrollbar>
    )
}, (prevProps, nextProps) => {
// console.log(prevProps, nextProps)
    return false
})

export default Channels
