import React, {Component} from 'react'
import PlaceholderImage from '../../img/placeholder-image.svg'
import PlayIcon from '../../img/play-icon.svg'
import NotificationIcon from '../../img/notification-icon.svg'
// import Scrollbar from 'react-scrollbars-custom'
import {connect} from 'react-redux'
import {FormattedMessage} from "react-intl"
import {NavLink, Redirect} from 'react-router-dom'
import {setPlayerChannelId, setPlayerEpgId} from '../../store/actions/playerActions'
import ShowSimilarIcon from "../../img/show-similar.svg";
import ShowSimilar from './ShowSimilar'
import {remindersEndpoint, searchEndpoint} from "../../store/actions/apiEndpoints"
import {fetchAction} from "../../store/actions/fetchActions"
import config from "../../config/config"
import {RemindersMap} from "../../store/actions/ApiKeysMap"
import {setModalSimpleState} from "../../store/actions/modalSimpleStateActions"

class EpgDetail extends Component {
    state = {
        confirmShow: false,
        redirectToDemoPage: false
    }

    reminderAction = ''

    componentDidMount() {
        // console.log('*** EpgDetail componentDidMount props', this.props)
    }

    handleRecordClick = () => {
        console.log('RecordClick');
    }

    handleWatchClick = () => {
        // console.log('WatchClick', this.props);
        this.props.setPlayerEpgId(this.props.epg.id)
        this.props.setPlayerChannelId(this.props.epg.channel_id)
    }

    handleSimilarClick = () => {
        this.props.fetchAction(this.props.auth.token, searchEndpoint(this.props.epg.metadata.title, this.props.auth.lang, 0, 30, 5), 'EpgDetail', 'search')
        // this.props.setModalState('show-similar', <FormattedMessage id='action_show_similar' />, <ShowSimilar />, null, <ShowSimilarIcon />)
        // this.props.modal_state.modal_obj.obj.toggle()

        this.props.setModalSimpleState({
            show: true,
            closeOnClick: true,
            header: "action_show_similar",
            body: <ShowSimilar />
        })
    }

/*
element {
	bottom: 0;
	position: absolute;
	left: 0;
	right: 0;
	margin: 48px 44px;
}
 */
    handleReminderClick = (e) => {
        e.preventDefault()
        if (!this.props.auth.token) {
            this.setState({
                redirectToDemoPage: true
            })
            return true
        }
        this.props.setModalSimpleState({
            show: true,
            closeOnClick: false,
            header: "profile_button_confirm",
            style: {width: "30%"},
            bodyStyle: {bottom: 0, position: 'absolute', left: 0, right: 0, margin: '48px 44px'},
            body: (
                <div className="btn-group w-100 justify-content-center">
                    <button className="button btn-primary btn-lg me-2 w-50" onClick={() => this.handleReminderSet()}>
                        <FormattedMessage id={'action_' + this.reminderAction + '_reminder'} />
                    </button>
                    <button className="button btn-secondary btn-lg w-50" onClick={() => this.props.setModalSimpleState({show: false})}>
                        <FormattedMessage id={'common_dialog_close'} />
                    </button>
                </div>
            )
        })
    }

    handleReminderSet = () => {
// console.log('handleReminderSet:', this.props.epg, this.reminderAction)

        const stringToSend = new URLSearchParams({
            'channel_id': this.props.epg.channel_id,
            'profile_id': this.props.auth.profile.id,
            'epg_id': this.props.epg.id
        }).toString();
        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
            // props.remindersRefresh()
// console.log('onload', xhr.status, xhr.response);
            this.props.fetchAction(
                this.props.auth.token,
                remindersEndpoint(
                    {
                        'page_offset': 0,
                        'page_limit': 100,
                        'lang': this.props.auth.lang,
                        'filter_profile': this.props.auth.profile.id
                    }),
                this.constructor.name,
                RemindersMap
            )
            this.props.setModalSimpleState({show: false})
        };
        xhr.onerror = (e) => {
            this.props.setModalSimpleState({body: e.error})
console.log("onerror", e.error);
        };
        if (this.reminderAction === 'add') {
            xhr.open("POST", config['apiURI'] + "/" + remindersEndpoint({}), true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        } else if(this.props.epg && this.props.remindersMap !== null && typeof this.props.remindersMap[this.props.epg.id] !== 'undefined') {
            xhr.open(
                "DELETE",
                config['apiURI'] + "/" + remindersEndpoint({}) + "/" + this.props.reminders[this.props.remindersMap[this.props.epg.id]]['id'],
                true
            );
        } else {
            return false
        }
        xhr.setRequestHeader("Authorization", "Bearer " + this.props.auth.token);

        this.reminderAction === 'add' ? xhr.send(stringToSend) : xhr.send()
    }

    render() {
        if (this.state.redirectToDemoPage) {
            return <Redirect to="/demo-page" />; // Nukreipiame į "/demo-page", jei redirectToDemoPage yra true
        }

// console.log('state on render:', this.state)
        const { epg, progress } = this.props
        const nowStamp = Date.now() / 1000 | 0

        const playBtn = epg && epg.start < nowStamp ? (
            <NavLink
                className={"btn-cube"}
                to={"/play?type=tv&channel_id=" + epg.channel_id + "&epg_id=" + epg.id}
            >
                <img src={PlayIcon} alt="" className="mb-2" />
                <p className={"w-100 text-center text-bright"}>
                    <FormattedMessage id="action_watch" />
                </p>
            </NavLink>
        ) : null

        this.reminderAction = (epg && this.props.remindersMap !== null && typeof this.props.remindersMap[epg.id] !== 'undefined') ? 'rm' : 'add'

        const reminderBtn = epg && epg.start > nowStamp + 10 ? (
            <NavLink
                className={"btn-cube"}
                to={""}
                onClick={(event) => {
                    event.preventDefault();
                    this.handleReminderClick(event);
                }}
            >
                <img src={NotificationIcon} alt="" className="mb-2" />
                <p className={"w-100 text-center text-bright"}>
                    <FormattedMessage id={'action_' + this.reminderAction + '_reminder'} />
                </p>
            </NavLink>
        ) : null

        return (epg && epg.metadata) ? (
            <div className="epg-detail">
                <div className="position-relative" style={{marginBottom: '2em'}}>
                    <img className="movie-preview" src={
                        typeof epg.metadata.screenshots !== "undefined" && epg.metadata.screenshots.length && !this.props.adultBlur ? epg.metadata.screenshots[0] : PlaceholderImage
                    } alt=""/>
                    <div className="progress-bar" role="progressbar" style={{width: progress * 0.94 + '%'}}/>
                    <div className="progress"/>
                </div>
                <div className="labels">
                    <div className="single-line">{this.props.adultBlur ?
                        <FormattedMessage id="parental_blocked_string_mask"/> : epg.metadata.title}</div>
                    <div className={"episode-time"}>
                        <div className={"inline-element"}>
                            {epg.startFormatted}
                            {epg.start && epg.stop ? <>
                                <span> | </span>{parseInt((epg.stop - epg.start) / 60) + " min."}</> : null}
                            {epg.metadata.tva_contents ? <>
                                <span> | </span>{epg.metadata.tva_contents.join(', ')}</> : null}
                        </div>
                    </div>
                </div>
                <div className="d-flex pt-4 pb-4">
                    {playBtn}
                    {reminderBtn}
                    <NavLink
                        className={"btn-cube"}
                        to={""}
                        onClick={(event) => {
                            event.preventDefault();
                            this.handleSimilarClick();
                        }}
                    >
                        <img src={ShowSimilarIcon} alt="" className="mb-2"/>
                        <p className={"w-100 text-center text-bright"}>
                            <FormattedMessage id={"action_show_similar"}/>
                        </p>
                    </NavLink>
                </div>
                <div className="epg-description p-4">
                    <p className={"text-bright mb-0"}>{epg.metadata.description}</p>
                </div>
            </div>
        ) : (
            <div className="image-container">
                <img className="movie-preview" src={PlaceholderImage} alt=""/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        reminders: state.api_data.payload.reminders ? state.api_data.payload.reminders.items : null,
        modal_state: state.modal_state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPlayerEpgId: (id) => { dispatch(setPlayerEpgId(id)) },
        setModalSimpleState: (props) => { dispatch(setModalSimpleState(props)) },
        setPlayerChannelId: (id) => { dispatch(setPlayerChannelId(id)) },
        // setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) },
        fetchAction: (token, endpoint, source, subkey) => { dispatch(fetchAction(token, endpoint, source, subkey)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpgDetail)

