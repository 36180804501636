import React, { useEffect, useRef } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const TextField = props => {
    const fieldMounted = useRef(false)
    const { field, setField, fieldCondition, setFieldCondition, name, error, label, type, password, cities } = props
    const citiesToOptions = () => {
        const options = cities.map((selectItem, key) => {
            return <option key={key} value={selectItem[0]}>{selectItem[1]}</option>
        })

        return (
            <select name='city' value={field} onChange={e => handleChange(e)}>
                {options}
            </select>
        )
    }

    const emptyCheck = () => {
        if (field.length === 0)
            setFieldCondition(true)
        else
            setFieldCondition(false)
    }

    const lengthCheck = () => {
        if (field.length < 8)
            setFieldCondition(true)
        else
            setFieldCondition(false)
    }

    const passwordMatchCheck = () => {
        if (field !== password && field.length !== 0)
            setFieldCondition(true)
        else
            setFieldCondition(false)
    }

    const emailFormatCheck = () => {
        if (!/.+@.+\..+/.test(field))
            setFieldCondition(true)
        else
            setFieldCondition(false)
    }

    const phoneFormatCheck = () => {
        if (field.substring(0,4) !== '3706' || field.length !== 11)
            setFieldCondition(true)
        else
            setFieldCondition(false)
    }

    const checkFunction = () => {
        if (name === 'email')
            emailFormatCheck()
        else if (name === 'phone')
            phoneFormatCheck()
        else if (name === 'password')
            lengthCheck()
        else if (name === 'passwordConfirmation')
            passwordMatchCheck()
        else
            emptyCheck()
    }

    const handleChange = e => {
        if (name === 'name' || name === 'surname') {
            setField(e.target.value.replace(/[^a-zA-ZĄČĘĖĮŠŲŪŽąčęėįšųūž]/, ''))
        } else {
            setField(e.target.value)
        }
    }

    useEffect(() => {
        if (fieldMounted.current) {
            checkFunction()
        } else
            fieldMounted.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field, password])

    return (
        <div className='text-field'>
            {name === 'phone' ?
                <PhoneInput onlyCountries={['lt']} country={'lt'} masks={{lt: '... .. ...'}} value={field} onChange={value => setField(value)} />
                :
                name === 'city' ?
                    citiesToOptions()
                    :
                    <input type={type} name={field} value={field} onChange={e => handleChange(e)} />
            }
            <label>{label}</label>
            {fieldCondition ? <p className='error-message'>{error}</p> : null}
        </div>
    )
}

export default TextField