import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setModalState } from '../../store/actions/modalStateActions'
import { FormattedMessage } from "react-intl"
import ProfileForm from './ProfileForm'
import AddProfileIcon from '../../img/add-profile-icon.svg'
import { fetchAction } from '../../store/actions/fetchActions'
import Notification from '../elements/Notification'
import config from '../../config/config'
import { profilesEndpoint } from '../../store/actions/apiEndpoints'
// import { Collapse } from "bootstrap";

class ProfilesList extends Component {
    state = {
        currentID: "",
        show: false,
    }

    handleAddClick = (e) => {
        this.props.setModalState('profile-edit', <FormattedMessage id="create_new_profile" />, <ProfileForm />, 'ProfilesList');
    }

    handleEditClick = (e) => {
        this.props.setModalState('profile-edit', <FormattedMessage id="profile_action_edit" />, <ProfileForm profile={e} />, 'ProfilesList');
    }

    handleDeleteClick = (e) => {
        // e.preventDefault()
        // console.log('handleDeleteClick', e)

        this.setState({
            ...this.state,
            show: true,
            currentID: e,
        })
    }

    componentDidMount() {
        this.props.fetchAction(this.props.auth.token, profilesEndpoint(), this.constructor.name)
    }

    handleStopShowing = () => {
        this.setState({
            ...this.state,
            show: false,
        })
    }

    handleDelete = () => {
        fetch(config['apiURI'] + "/customer/profile/" + this.state.currentID, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + this.props.auth.token,
            },
        }).then((response) => {
            return this.props.fetchAction(this.props.auth.token, profilesEndpoint(), this.constructor.name)
        })
    }

    render() {
        const { source, loading, payload } = this.props.api_data

        if (source === this.constructor.name && loading) {
            return (
                <div className="loader">
                    <span className="loader-img"/>
                </div>
            )
        }

        const Profile = (props) => {
            return (
                <div className="card" key={props.profile.id}>
                    <div className="card-header" id={"heading_" + props.profile.id}>
                        <button
                            className="btn btn-link arrow-btn"
                            data-bs-toggle="collapse"
                            data-bs-target={"#collapse_" + props.profile.id}
                            aria-expanded="false"
                            aria-controls={"collapse_" + props.profile.id}
                        >
                            <div className="avatar-card">
                                <div className="avatar-image-card">
                                    <img className="avatar-image" src={props.profile.avatar.url} alt="" />
                                </div>
                                <div className="d-flex align-items-center">
                                    {props.profile.name}
                                </div>
                            </div>
                        </button>
                    </div>
                    <div id={"collapse_" + props.profile.id} className="collapse">
                        <div className="card-body">
                            <button className="btn btn-link me-2" onClick={() => this.handleEditClick(props.profile)}>Edit</button>
                            <button className="btn btn-link" onClick={() => this.handleDeleteClick(props.profile.id)}>Delete</button>
                        </div>
                    </div>
                </div>
            )
        }

        const Profiles = () => {
            if (this.props.auth.profile.master_flag === true) {
                return (
                    payload.customer_profiles.items.map((profile, index) => {
                        return (
                            <div key={index}>
                                <Profile profile={profile}/>
                            </div>
                        )
                    })
                )
            } else {
                return (
                    <Profile profile={this.props.auth.profile} />
                )
            }
        }

        const NoProfiles = () => {
            return null
        }

        return (
            <div>
                <Notification
                    header={<FormattedMessage id="common_dialog_profile_deletion_title" />}
                    body={<FormattedMessage id="common_dialog_profile_deletion_message" />}
                    buttonText={<FormattedMessage id="profile_button_delete" />}
                    show={this.state.show}
                    handleStopShowing={this.handleStopShowing}
                    handleDelete={this.handleDelete}
                />
                <div id="profiles-accordion">
                    { payload.customer_profiles ? <Profiles /> : <NoProfiles /> }
                    <div className="card">
                        <div className="card-header single-line">
                            <button className="btn btn-link" onClick={this.handleAddClick}>
                                <img className="avatar-image" src={AddProfileIcon} alt="" />
                                <FormattedMessage id="create_new_profile" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        api_data: state.api_data,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalState: (id, title, body, back_button, header_icon) => { dispatch(setModalState(id, title, body, back_button, header_icon)) },
        fetchAction: (token, endpoint) => { dispatch(fetchAction(token, endpoint)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList)
