import { SET_MODAL_STATE, SET_MODAL_OBJ } from '../actions/modalStateActions'

const initState = {
    modal_obj: null,
    modal_state: {
      id: 'default-modal',
      title: null,
      body: null,
      footer: null,
      back_button: null,
      header_icon: null
    }
}

const modalReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_MODAL_STATE:
            return {
                ...state,
                modal_state: action.payload,
            }
        case SET_MODAL_OBJ:
            return {
                ...state,
                modal_obj: action.payload,
            }
        default: return state
    }
}

export default modalReducer