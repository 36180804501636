import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { setAppClass } from '../../store/actions/globalStylesActions'
import ConditionsContent from './ConditionsContent'
import Registering from './Registering'
import ConditionsPage from './ConditionsPage'
import config from '../../config/config'

const Conditions = props => {
    const [showTerms, setShowTerms] = useState(false)
    const [showPrivacy, setShowPrivacy] = useState(false)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const intl = useIntl()
    const { history, setAppClass, auth } = props

    useEffect(() => {
        setAppClass('conditions')
    }, [setAppClass])

    if (location.state === undefined)
        return <div className="unauthorised"><h1>Unauthorised</h1></div>

    const { name, surname, city, email, phone, password } = location.state

    let termsURL = ''
    const termsConfigKey = 'termsURL_' + auth.lang
    if (typeof config[termsConfigKey] !== 'undefined') {
        termsURL = config[termsConfigKey]
    }

    let privacyURL = ''
    const privacyConfigKey = 'privacyURL_' + auth.lang
    if (typeof config[privacyConfigKey] !== 'undefined') {
        privacyURL = config[privacyConfigKey]
    }

    const handleResponse = data => {
        setLoading(false)
        if (data['error']) {
            let error = data['error'].substring(data['error'].indexOf(':') + 2)
            if (error === 'Customer with given email already exists.') {
                error = intl.formatMessage({id: 'register_customer_email_used'})
            } else if (error === 'Error while creating customer.') {
                error = intl.formatMessage({id: 'register_customer_creation_error'})
            }
            const errors = error.split('\n')
// console.log('errors', errors)
            history.push({
                pathname: '/status',
                state: {
                    status: intl.formatMessage({id: 'register_error'}),
                    messageOne: intl.formatMessage({id: 'register_account_creation_failed'}),
                    messageTwo: intl.formatMessage({id: 'register_try_again_or_contact'}),
                    errors: errors,
                    buttonOne: intl.formatMessage({id: 'register_try_again'}),
                    buttonOneFunction: 'linkToSignUp',
                    lang: auth.lang
                }
            })
        } else {
            history.push({
                pathname: '/status',
                state: {
                    status: intl.formatMessage({id: 'register_success'}),
                    messageOne: intl.formatMessage({id: 'register_account_creation_success'}, {email: email}),
                    // buttonOne: intl.formatMessage({id: 'register_go_to_sign_in'}),
                    // buttonOneFunction: 'linkToSignIn',
                    // buttonTwo: intl.formatMessage({id: 'register_resend_email'}),
                    // buttonTwoFunction: '',
                    lang: auth.lang
                }
            })
        }
    }

    let consentSmsEmail = true
    let consentCall = false

    const bindConsent = () => {
        // console.log('bind it')
        const smsEmailCheck = document.getElementById('consent_sms_email')
        const callCheck = document.getElementById('consent_call')
        if (smsEmailCheck != null) {
            smsEmailCheck.checked = consentSmsEmail
            smsEmailCheck.addEventListener('change', function(e) {
                consentSmsEmail = e.target.checked
                // console.log('consent_sms_email changed', consentSmsEmail);
            })
        }

        if (callCheck != null) {
            callCheck.addEventListener('change', function(e) {
                consentCall = e.target.checked
                // console.log('consent_call changed', consentCall);
            })
        }
    }

    const handleRequest = () => {
        setLoading(true)
        fetch('https://s4-gw.cgates.lt/box4.0/registration', {
            method: 'POST',
            body: new URLSearchParams({
                'email': email,
                'firstname': name,
                'lastname': surname,
                'city': city,
                'phone': '+' + phone,
                'password': password,
                'locale': auth.lang,
                'consent_sms_email': consentSmsEmail,
                'consent_call': consentCall
            })
        })
            .then(response => response.json())
            .then(data => handleResponse(data))
    }

    if (showTerms)
        return <ConditionsContent setShowConditions={setShowTerms} fileURL={termsURL} />
    else if (showPrivacy)
        return <ConditionsContent setShowConditions={setShowPrivacy} fileURL={privacyURL} />
    else if (loading)
        return <Registering setLoading={setLoading} />
    else
        return <ConditionsPage
            termsURL={termsURL}
            setShowTerms={setShowTerms}
            setShowPrivacy={setShowPrivacy}
            handleRequest={handleRequest}
            bindConsent={bindConsent}
        />
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAppClass: (app_class) => { dispatch(setAppClass(app_class)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditions)