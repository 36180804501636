import React, { useRef, useState, useEffect } from 'react';

const PinInput = ({onPinEntered, hasError, pinToCheck, passwordType}) => {
    const inputRefs = useRef([]);
    const pinLength = 4;
    const [pin, setPin] = useState('');
    const [localHasError, setLocalHasError] = useState(false)

    useEffect(() => {
        if (pin.length === pinLength) {
            let hasError = false
            if (pinToCheck && pin !== pinToCheck) {
                hasError = true
            }
            setLocalHasError(hasError)
            onPinEntered(pin, hasError);
        } else {
            setLocalHasError(false)
        }
    }, [pin, onPinEntered, pinToCheck, pinLength]);


    const handleInputChange = (index, event) => {
        const value = event.target.value;

        setPin(prevPin => {
            const newPin = prevPin.slice(0, index) + value + prevPin.slice(index + 1);
            return newPin.length <= pinLength ? newPin : prevPin;
        });

        if (value === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        if (value !== '' && index < pinLength - 1) {
            inputRefs.current[index + 1].focus();
        }
    }

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text');
        if (pastedData.length === pinLength) {
            setPin(pastedData);
        }
    };

    const renderInputs = () => {
        const inputs = [];

        for (let i = 0; i < pinLength; i++) {
            const classError = (hasError || localHasError) ? ' has-error' : '';

            inputs.push(
                <div key={i} className={"d-flex input-box pin" + classError}>
                <input
                    type={passwordType ? "password" : "text"}
                    autoComplete={"off"}
                    maxLength="1"
                    ref={ref => (inputRefs.current[i] = ref)}
                    value={pin[i] || ''}
                    onChange={event => handleInputChange(i, event)}
                    onPaste={event => handlePaste(event)}
                    className={"form-control"}
                />
                </div>
            );
        }

        return inputs;
    }

    return renderInputs();
}

export default PinInput;
